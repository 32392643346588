import { Action } from 'redux';
import { connect } from 'react-redux';
import { UserPersonalDataEditDumb } from './UserPersonalDataEdit.dumb';
import { navigateBackRequest, navigateToRequest } from '../../../navigationContext/usecases/navigation.actions';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';

const mapState = () => ({});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  navigateBack: () => dispatch(navigateBackRequest()),
  navigateTo: (p: NavigationPayload) => dispatch(navigateToRequest(p)),
});

export const UserPersonalDataEditSmart = connect(mapState, mapDispatch)(UserPersonalDataEditDumb);
