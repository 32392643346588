import { useTranslation } from 'react-i18next';
import { StyleSheet } from '../../../common/domain/entities/StyleSheet';
import { FontSize, FontWeight } from '../../../assets/styles/constantStyles';

const EmailActivationCodeSent = () => {
  const { t } = useTranslation();
  // dsds
  return (
    <div className="w-100 h-100 justify-content-center align-items-center position-relative">
      <img
        className="nav-bar-logo fixed-top position-absolute mt-3 ms-3"
        alt="logoImage"
        src={require('../../../assets/images/logo.png')}
      />
      <div className="centerBoxContainer">
        <div className="h-auto justify-content-center align-items-center">
          <div className="mb-4" style={styles.headerTitle}>
            {t('ALMOST_DONE')}
          </div>
          <div className="text-center" style={styles.description}>
            {t('EMAIL_VERIFICATION_CODE_SENT_DESCRIOTION')}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  headerTitle: {
    fontSize: FontSize.H5,
    fontWeight: FontWeight.BOLD,
  },
  description: {
    fontSize: FontSize.H4,
    fontWeight: FontWeight.REGULAR,
    whiteSpace: 'pre-wrap',
  },
};

export default EmailActivationCodeSent;
