import React, { CSSProperties, useState } from 'react';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { ErrorExclamationMark } from './ErrorExclamationMark';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../domain/entities/StyleSheet';
import { PassEye } from '../../../assets/svg/PassEye';
import { AppTextInputRequirement } from '../../domain/entities/AppTextInputRequirement';

export interface AppTextInputProps {
  label: string;
  currentVal: string;
  showError?: boolean;
  onChange: (str: string) => void;
  onBlur?: (str: string) => void;
  onFocus?: (str: string) => void;
  extraStyle?: CSSProperties;
  placeholder: string;
  isPass?: boolean;
  disabled?: boolean;
  hasNumberMin?: number;
  requirementsList?: AppTextInputRequirement[];
}
export const AppTextInput = React.forwardRef<HTMLInputElement | null, AppTextInputProps>(
  (
    {
      label,
      currentVal,
      showError,
      onChange,
      onBlur,
      onFocus,
      extraStyle,
      placeholder,
      isPass,
      disabled,
      hasNumberMin,
      requirementsList,
    },
    ref,
  ) => {
    const [passIsShown, setPassIsShown] = useState<boolean>(false);

    return (
      <div style={{ ...styles.inputContainer, ...extraStyle }}>
        <div
          style={{
            ...styles.inputInnerContainer,
            background: currentVal && currentVal.length ? 'white' : Color.MONOGREY3,
            borderColor: showError ? Color.DANGER : currentVal && currentVal.length ? Color.APPBLACK : 'transparent',
          }}
        >
          <ErrorExclamationMark shown={showError} />
          <input
            ref={ref}
            disabled={disabled}
            className="withPlaceHolderColor"
            onFocus={() => onFocus?.(_.trim(currentVal))}
            style={{
              ...styles.input,
              ...(isPass && { paddingRight: AppSizes.SIZE5 }),
            }}
            width={900}
            type={isPass ? (passIsShown ? 'text' : 'password') : 'text'}
            required
            value={currentVal ?? ''}
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)}
            onBlur={() => {
              if (hasNumberMin && +_.trim(currentVal) < hasNumberMin) {
                onChange('');
              } else {
                onBlur && onBlur(_.trim(currentVal));
                onChange(_.trim(currentVal));
              }
            }}
          />
          {isPass && (
            <button
              type="button"
              onClick={() => {
                setPassIsShown((v) => !v);
              }}
              style={styles.passEyeContainer}
            >
              <PassEye open={passIsShown} />
            </button>
          )}
          <div
            style={{
              ...styles.label,
              ...styles.floatingLabel,
              ...(showError && { color: Color.DANGER }),
            }}
          >
            {label}
          </div>
        </div>
        {requirementsList && (
          <motion.div
            initial={{ transform: 'translateY(50%)', opacity: 0 }}
            animate={{ transform: 'translateY(0%)', opacity: 1 }}
            transition={{
              type: 'just',
            }}
          >
            <div style={styles.requirementsContainer}>
              {requirementsList.map((r, i) => (
                <div
                  key={`requirement-${i + 1}`}
                  style={{ ...styles.requirementItem, ...(r.fulfilled && { color: Color.VALID }) }}
                >
                  {`${r.name}`}&nbsp;
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </div>
    );
  },
);

AppTextInput.displayName = 'AppTextInput';

const styles: StyleSheet = {
  inputContainer: {
    marginTop: AppSizes.SIZE5,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputInnerContainer: {
    position: 'relative',
    width: '100%',
    cursor: 'pointer',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 3,
    transition: 'all 300ms',
    minHeight: AppSizes.SIZE5,
    marginLeft: AppSizes.SIZE1,
    marginRight: AppSizes.SIZE1,
  },
  input: {
    fontSize: FontSize.P1,
    maxWidth: '100%',
    width: '100%',
    height: AppSizes.SIZE5,
    fontWeight: FontWeight.BOLDER,
    color: Color.APPBLACK,
    outline: 'none',
    border: 'none',
    borderRadius: 3,
    background: 'transparent',
    cursor: 'text',
    margin: 0,
    padding: AppSizes.SIZE1,
  },
  label: {
    transition: 'all 300ms',
    fontSize: FontSize.P1,
    fontWeight: FontWeight.SEMIBOLD,
    color: Color.MONOGREY4,
    position: 'absolute',
    height: AppSizes.SIZE5,
    justifyContent: 'center',
    textAlign: 'left',
    top: 0,
    left: AppSizes.SIZE1,
    pointerEvents: 'none',
  },
  floatingLabel: {
    fontSize: FontSize.P3,
    fontWeight: FontWeight.REGULAR,
    color: Color.APPBLACK,
    transform: 'translateY(-40px)',
  },
  passEyeContainer: {
    width: AppSizes.SIZE5,
    zIndex: 2,
    position: 'absolute',
    right: 0,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  requirementsContainer: {
    maxWidth: '100%',
    flexDirection: 'row',
  },
  requirementItem: {
    fontSize: FontSize.P3,
    fontWeight: FontWeight.BOLD,
  },
};
