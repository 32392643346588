import { JSONValue } from '../types/JSONValue';

export type ValueFunction<Value, Arg> = (arg: Arg) => Value;
export type ValueOrFunction<Value, Arg> = Value | ValueFunction<Value, Arg>;

/**
 * check if the input is a function or not
 * @param valOrFunction the input value can be anything
 * @returns { boolean }
 */

export const isFunction = <Value, Arg>(
  valOrFunction: ValueOrFunction<Value, Arg>,
): valOrFunction is ValueFunction<Value, Arg> => typeof valOrFunction === 'function';

/**
 * this function can be used for more inputs (which support function - which returns value - or the value itself )
 * @param valOrFunction a function or a value
 * @param arg argument for the function mode (can be undefined)
 * @returns the raw value (which is the result of the function or the value itself)
 */
export const resolveValue = <Value, Arg>(valOrFunction: ValueOrFunction<Value, Arg>, arg: Arg): Value =>
  isFunction(valOrFunction) ? valOrFunction(arg) : valOrFunction;

/**
 * clean the object from null or undefined properties
 * @param obj the object (which can contain some values as undefined or null)
 * @param { boolean } removeEmptyString if it's true it would remove the string values if they're empty
 * @returns cleaned object
 */
export function cleanObj(
  obj: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    [x in string]: JSONValue | Function;
  },
  removeEmptyString = false,
) {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (
      value === null ||
      value === undefined ||
      (removeEmptyString && typeof value === 'string' && value.length === 0)
    ) {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    }
  });
  return obj;
}

/**
 * Greatest common divisor (gcd) of integers
 * @param { number[] } arr array of numbers
 * @returns { number }
 */
export const gcd = (arr: number[]): number => {
  const gcdForTwoNumbers = (x: number, y: number) => (!y ? x : gcd([y, x % y]));
  return arr.reduce((a, b) => gcdForTwoNumbers(a, b));
};

/**
 * clamp the value between min and max
 * @param { number } value
 * @param { number } min
 * @param { number } max
 * @returns { number } clapped value of input
 */
export const clamp = (value: number, min: number, max: number) => {
  if (min > max) {
    throw Error(`clamping: min: ${min} is more than max: ${max}!`);
  }
  return Math.min(Math.max(value, min), max);
};

/**
 * get timestamp value
 * @returns { number } timestamp
 */
export const now = () => Date.now();

/** extract a dictionary type from query string (url query) */
export const extractQuery = <T extends Record<string, string | boolean | number>>(queryString: string): T => {
  const params = new URLSearchParams(queryString);
  return Array.from(params).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value,
    }),
    {} as T,
  );
};

export const throttle = <T extends (...args: any) => any>(
  func: T,
  wait?: number,
  flag?: NodeJS.Timeout,
): NodeJS.Timeout => {
  flag && clearInterval(flag);

  return setTimeout(() => {
    func();
  }, wait);
};

export const deeplink = ({
  bundleLink,
  iTunesUrl,
  playStoreUrl,
  webSiteUrl,
}: {
  bundleLink: string;
  iTunesUrl?: string;
  playStoreUrl?: string;
  webSiteUrl?: string;
}) => {
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf('android') > -1; // android check
  const isIphone = ua.indexOf('iphone') > -1; // ios check
  if (isIphone) {
    const app = {
      launchApp() {
        if (bundleLink && iTunesUrl) {
          setTimeout(this.openITunes, 25);
        }
        if (bundleLink) {
          window.location.href = bundleLink;
        } else if (iTunesUrl) {
          this.openITunes();
        }
      },
      openITunes() {
        if (iTunesUrl) {
          window.location.href = iTunesUrl;
        }
      },
    };
    app.launchApp();
  } else if (isAndroid) {
    const app = {
      launchApp() {
        if (bundleLink && playStoreUrl) {
          setTimeout(this.openPlayStore, 500);
        }
        if (bundleLink) {
          window.location.replace(bundleLink);
        } else if (playStoreUrl) {
          this.openPlayStore();
        }
      },
      openPlayStore() {
        if (playStoreUrl) {
          window.location.href = playStoreUrl;
        }
      },
    };
    app.launchApp();
  } else if (webSiteUrl) {
    window.location.href = webSiteUrl;
  }
};
