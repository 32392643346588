import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AssignedQsList } from './components/AssignedQsList';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../../common/domain/entities/StyleSheet';
import { AssignedQuestionnaire } from '../../../questionnairesContext/domain/entities/AssignedQuestionnaire';
import { AppAlert } from '../../../common/ui/Others/AppAlert';
import { PatientOverviewRow } from '../patientsOverview/components/PatientOverviewRow';
import { PatientContactList } from '../assignQuestionnaire/components/PatientContactList';
import { LoadingCircle } from '../../../assets/svg/LoadingCircle';
import Messaging from '../../../core/components/messaging';
import { Patient } from '../../domain/entities/Patient';
import { PatientContact } from '../../domain/entities/PatientContact';
import { useHcpContext } from '../../../contexts/doctor/store';
import log from '../../../core/helpers/logger';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';

interface PatientDetailsProps {
  navigateBack: () => void;
  navigateTo: (p: NavigationPayload) => void;
}

interface RouterProps {
  userid: string;
}

export const PatientDetailsDumb = ({ navigateBack, navigateTo }: PatientDetailsProps) => {
  // hooks
  const {
    getContent,
    getPatient,
    getAssignedQuestionnaires,
    getContacts,
    deletePatient,
    deleteContactInvitation,
    deleteContact,
  } = useHcpContext();
  const locationState = useLocation<any>().state;
  const { userid } = useParams<RouterProps>();
  const { t } = useTranslation();
  const { push } = useHistory();

  // states
  const [alertIsOn, setAlertIsOn] = React.useState<boolean>(false);
  const [currentPatient, setCurrentPatient] = React.useState<Patient>();
  const [currentPatientContacts, setCurrentPatientContacts] = React.useState<PatientContact[]>([]);
  const [assignedQuestionnaire, setAssignedQuestionnaire] = React.useState<AssignedQuestionnaire[]>([]);
  const [contactTabIsOn, setContactTabIsOn] = React.useState<boolean>(false);
  const [patientToDelete, setPatientToDelete] = React.useState<Patient>();
  const [patientContactToDelete, setPatientContactToDelete] = React.useState<PatientContact>();
  const [questionnaireToDelete, setQuestionnaireToDelete] = React.useState<AssignedQuestionnaire>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    (locationState?.selectedContacts?.length || locationState?.backToContactsTab) && setContactTabIsOn(true);
  }, [locationState]);

  React.useEffect(() => {
    setIsLoading(true);

    Promise.all([
      getPatient(userid).then((patient) => {
        setCurrentPatient(patient);
      }),
      getAssignedQuestionnaires(userid).then((result) => {
        setAssignedQuestionnaire(result);
      }),
      getContacts(userid).then(setCurrentPatientContacts),
    ])
      .finally(() => setIsLoading(false))
      .catch((err) => {
        log.error(err);
      });
  }, []);

  const deleteThePatient = (patient: Patient) => {
    deletePatient(patient.uuid)
      .then(() => {
        Messaging.toast(t('IT_WORKED'));
        navigateBack();
      })
      .catch((err) => alert(`#temporary deleting patient failed\n${err}`));
  };

  const deleteQ = (q: AssignedQuestionnaire) => {
    if (!q.uuid) {
      return;
    }
    deleteContactInvitation(q.uuid)
      .then(() => {
        Messaging.toast(t('IT_WORKED'));
        getContent(['invitations'], true);
        setAssignedQuestionnaire((qs) => qs?.filter((item) => item.uuid !== q.uuid));
      })
      .catch((err) => alert(`deleting assigned questionnaire  failed\n${err}`));
  };

  const newQ = () => {
    push(`/Assign-Questionnaire/${userid}`);
  };

  const goToPatientCreationScreen = () => {
    if (currentPatient) {
      navigateTo({
        routeName: 'Patient-Creation',
        params: currentPatient,
      });
    }
  };

  const goToPatientContactCreationScreen = () => {
    push({ pathname: `/Patient-Contact-Creation`, search: `patientuuid=${userid}` });
  };

  const refreshInvitations = () => {
    setIsLoading(true);
    getContent(['invitations'], true)
      .then(() => {
        getAssignedQuestionnaires(userid).then(setAssignedQuestionnaire);
      })
      .catch(() => {
        alert('error refreshing invitations');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deletePatientContact = (pc: PatientContact) => {
    deleteContact(pc)
      .then(() => {
        getAssignedQuestionnaires(userid).then(setAssignedQuestionnaire);
        getContacts(userid).then(setCurrentPatientContacts);
        Messaging.toast(t('IT_WORKED'));
      })
      .catch((err) => alert(`deleting patient failed\n${err}`));
  };

  if (!currentPatient) {
    return null;
  }
  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <div style={styles.headerTitleWrapper}>
          <div style={{ fontWeight: FontWeight.BOLD, fontSize: FontSize.H4 }}>Profil</div>
        </div>

        <PatientOverviewRow
          patient={currentPatient}
          patientAssignedQs={assignedQuestionnaire}
          goToEdit={goToPatientCreationScreen}
          deletePatient={(p: Patient) => {
            setPatientToDelete(p);
            setAlertIsOn(true);
          }}
        />

        <div style={styles.overviewTabs}>
          <button
            type="button"
            style={{ ...styles.tabButtonItem, ...(!contactTabIsOn ? styles.activeTabButtonItem : {}) }}
            onClick={() => setContactTabIsOn(false)}
          >
            Fragebogen
          </button>
          <button
            type="button"
            onClick={() => setContactTabIsOn(true)}
            style={{ ...styles.tabButtonItem, ...(contactTabIsOn ? styles.activeTabButtonItem : {}) }}
          >
            Kontakt
          </button>
          <div style={styles.dummySpaceTabItem} />
        </div>
        <div style={styles.tabPanelContent}>
          {!contactTabIsOn && (
            <>
              {isLoading && (
                <div style={styles.assignQuestionsLoadingContainer}>
                  <div style={{ width: AppSizes.SIZE4, height: AppSizes.SIZE4, position: 'relative' }}>
                    <LoadingCircle size={AppSizes.SIZE4} />
                  </div>
                </div>
              )}
              {isLoading === false && (
                <AssignedQsList
                  refreshButtonTapped={refreshInvitations}
                  newQuestionButtonTapped={newQ}
                  QsList={assignedQuestionnaire}
                />
              )}
            </>
          )}
          {contactTabIsOn && (
            <PatientContactList
              questionnaires={assignedQuestionnaire}
              newContactPressed={goToPatientContactCreationScreen}
              allPatientContacts={currentPatientContacts}
              setPatientContactToDelete={setPatientContactToDelete}
            />
          )}
        </div>

        <AppAlert
          isOn={alertIsOn}
          content={
            <div style={{ padding: AppSizes.SIZE3, paddingTop: AppSizes.SIZE4 }}>
              {patientToDelete && (
                <>
                  <p>
                    Sind Sie sicher, dass sie
                    {` die Patientin  “${patientToDelete?.first_name}” `}
                    löschen wollen?
                  </p>
                  <p>Gelöschte Patienten finden Sie im Archiv.</p>
                </>
              )}

              {questionnaireToDelete && (
                <>
                  <p>
                    Sind Sie sicher, dass sie
                    {` den Fragebogen “${questionnaireToDelete?.questionnaire.title}” `}
                    löschen wollen?
                  </p>
                  <p>Die Daten lassen sich nicht wiederherstellen.</p>
                </>
              )}
            </div>
          }
          close={() => {
            setAlertIsOn(false);
          }}
          buttons={[
            {
              label: 'Abbrechen',
              action: () => {
                setQuestionnaireToDelete(undefined);
                setPatientToDelete(undefined);
                setAlertIsOn(false);
              },
              strokeOnly: true,
            },
            {
              label: 'Löschen',
              action: () => {
                patientToDelete && deleteThePatient(patientToDelete);
                questionnaireToDelete && deleteQ(questionnaireToDelete);
                setAlertIsOn(false);
              },
            },
          ]}
        />

        <AppAlert
          isOn={!!patientContactToDelete}
          content={
            <div style={{ padding: AppSizes.SIZE3, paddingTop: AppSizes.SIZE4 }}>
              <p>
                Sind Sie sicher, dass Sie den Kontakt
                {patientContactToDelete && ` “${patientContactToDelete?.email}” `}
                löschen wollen?
              </p>
              <p>Die Daten lassen sich nicht wiederherstellen.</p>
            </div>
          }
          close={() => {
            setPatientContactToDelete(undefined);
          }}
          buttons={[
            {
              label: 'Abbrechen',
              action: () => {
                setPatientContactToDelete(undefined);
              },
              strokeOnly: true,
            },
            {
              label: 'Löschen',
              action: () => {
                patientContactToDelete && deletePatientContact(patientContactToDelete);
                setPatientContactToDelete(undefined);
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  headerTitleWrapper: {
    paddingBottom: `${AppSizes.SIZE3}px`,
  },
  assignQuestionsLoadingContainer: {
    flex: 1,
    marginTop: AppSizes.DEFAULT,
    minHeight: 300,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    padding: AppSizes.SIZE1,
  },
  innerContainer: {
    alignSelf: 'center',
    maxWidth: 1368,
    width: '100%',
  },
  personalInfoContainer: {
    background: Color.MONOGREY3,
    borderRadius: 10,
    overflow: 'hidden',
    marginBottom: AppSizes.SIZE2,
  },
  sectionsContainer: {
    flexDirection: 'row',
  },
  rowSection: {
    flex: 1,
    padding: AppSizes.SIZE1,
    borderRight: `1px solid ${Color.PUREWHITE}`,
  },
  rowTitleContainer: {
    fontWeight: FontWeight.BOLD,
    fontSize: FontSize.P1,
  },
  overviewTabs: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: `${AppSizes.SIZE2}px ${AppSizes.SIZE2}px 0px ${AppSizes.SIZE2}px`,
    columnGap: AppSizes.SIZE3,
    marginBottom: '-1px',
    zIndex: 2,
  },
  tabPanelContent: {
    zIndex: 0,
    border: '1px solid #3F3F3F',
    borderRadius: '10px',
    padding: `${AppSizes.SIZE5}px ${AppSizes.SIZE2}px`,
  },
  dummySpaceTabItem: {
    flex: 1,
    flexGrow: 1,
  },
  activeTabButtonItem: {
    borderTop: '1px solid #3F3F3F',
    borderLeft: '1px solid #3F3F3F',
    borderRight: '1px solid #3F3F3F',
    borderBottom: '0px solid #3F3F3F',
    background: '#FFFFFF',
    color: '#000000',
  },
  tabButtonItem: {
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    flexGrow: 1,
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    background: '#A1A1A1',
    borderBottom: '1px solid #3F3F3F',
    color: '#FFFFFF',
    height: `${AppSizes.SIZE5}px`,
  },
  singleTab: {
    background: Color.MONOGREY3,
    fontWeight: FontWeight.REGULAR,
    fontSize: FontSize.P1,
    width: '50%',
    height: 64,
    borderRadius: 40,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Color.PUREBLACK,
    justifyContent: 'center',
    alignItems: 'center',
  },
  singleTabFocused: {
    background: Color.MONOGREY9,
    fontWeight: FontWeight.BOLDER,
    borderColor: Color.PUREBLACK,
  },
};
