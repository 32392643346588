import { Link } from 'react-router-dom';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../../common/domain/entities/StyleSheet';
import { AppButton } from '../../../common/ui/Others/AppButton';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { Footer } from '../../../navigationContext/ui/components/Footer';

interface WelcomingPageProps {
  navigateTo: (p: NavigationPayload) => void;
}
export const WelcomingPageDumb = ({ navigateTo }: WelcomingPageProps) => (
  <div className="full-screen-container">
    <div className="container-fluid" style={{ flex: 1 }}>
      <div className="flex-fill align-self-stretch justify-content-center align-items-center d-flex">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 text-center">
              <img
                alt="logoImage"
                className="mb-5 align-self-center"
                style={styles.logoImage}
                src={require('../../../assets/images/logo.png')}
              />
              <p className="align-self-center my-5" style={styles.title}>
                Herzlich Willkommen!
              </p>
              <div className="row">
                <div className="col-md-6  d-flex justify-content-center align-items-center">
                  <AppButton
                    label="Registrieren"
                    color={Color.MONOGREY6}
                    action={() => {
                      navigateTo({ routeName: 'Sign-Up' });
                    }}
                  />
                  <p className="mt-3" style={styles.buttonUnderText}>
                    Sie sind neu hier? Dann legen Sie einen
                    <br />
                    Account an über{' '}
                    <Link
                      to="Sign-Up"
                      style={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        color: Color.MONOGREY6,
                      }}
                    >
                      Registrieren.
                    </Link>
                  </p>
                </div>
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                  <AppButton
                    label="Anmelden"
                    color={Color.MONOGREY6}
                    strokeOnly
                    action={() => {
                      navigateTo({ routeName: 'Sign-In' });
                    }}
                  />
                  <p className="mt-3" style={styles.buttonUnderText}>
                    Sie haben bereits einen Account?
                    <br />
                    Dann wählen Sie{' '}
                    <Link
                      to="Sign-In"
                      style={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        color: Color.MONOGREY6,
                      }}
                    >
                      Anmelden.
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

const styles: StyleSheet = {
  container: {
    flex: 1,
    padding: AppSizes.SIZE1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontWeight: FontWeight.BOLD,
    fontSize: FontSize.H4,
    textAlign: 'center',
    width: '100vw',
    maxWidth: 300,
  },
  buttonUnderText: {
    textAlign: 'center',
    fontSize: FontSize.P3,
    fontWeight: FontWeight.REGULAR,
    color: Color.MONOGREY6,
  },
  logoImage: {
    width: '584px',
    maxWidth: '100%',
  },
};
