import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { Close } from '../../../assets/svg/Close';
import { StyleSheet } from '../../domain/entities/StyleSheet';

export interface AppCheckBoxV2Props {
  label: string;
  checked: boolean;
  errorShown: boolean;
  action?: () => void;
}
export const AppCheckBoxV2 = ({ label, checked, errorShown, action }: AppCheckBoxV2Props) => (
  <button
    type="button"
    onClick={action}
    style={{
      ...styles.itemContainer,
      borderColor: errorShown ? Color.DANGER : 'transparent',
    }}
  >
    <div style={styles.innerContainer}>
      <div style={styles.checkBox}>
        {checked && (
          <div
            style={{
              width: '100%',
              height: '100%',
              background: Color.APPBLACK,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Close color={Color.APPWHITE} />
          </div>
        )}
      </div>
      {label && <div style={styles.itemText}>{label}</div>}
    </div>
  </button>
);

const styles: StyleSheet = {
  itemContainer: {
    cursor: 'pointer',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    margin: AppSizes.DEFAULT,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemText: {
    fontSize: FontSize.P1,
    fontWeight: FontWeight.SEMIBOLD,
    color: Color.APPBLACK,
    marginLeft: AppSizes.DEFAULT,
  },
  checkBox: {
    background: Color.APPWHITE,
    borderRadius: 5,
    border: `1px solid ${Color.APPBLACK}`,
    width: 16,
    height: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
};
