import React from 'react';
import useStorage from './useStorage';

export function usePersistedReducer<State, Action>(
  reducer: (state: State, action: Action) => State,
  initialState: State,
  storageKey: string,
): [State, React.Dispatch<Action>] {
  const { value, setValue } = useStorage<State>(storageKey, initialState);

  const reducerLocalStorage = React.useCallback(
    (state: State, action: Action): State => {
      const newState = reducer(state, action);
      setValue(newState);
      return newState;
    },
    [value],
  );

  return React.useReducer(reducerLocalStorage, value);
}
