import { BACKEND_CONFIG } from '../../configuration';
import { AuthTokens } from '../domain/entities/AuthTokens';

const tokenContainer: AuthTokens = {
  access_token: undefined,
  renewal_token: undefined,
  bearer_token: undefined,
  accessExpirationTime: undefined,
  renewExpirationTime: undefined,
  ready: undefined,
};

export const setTokensToFetch = (tokens: AuthTokens | undefined) => {
  tokenContainer.access_token = tokens?.access_token;
  tokenContainer.renewal_token = tokens?.renewal_token;
  tokenContainer.accessExpirationTime = tokens?.accessExpirationTime;
  tokenContainer.renewExpirationTime = tokens?.renewExpirationTime;
  tokenContainer.bearer_token = tokens?.bearer_token;
  tokenContainer.ready = tokens?.ready;
};
export const getTokensToFetch = () => tokenContainer;
export const deleteTokensToFetch = () => {
  tokenContainer.access_token = undefined;
  tokenContainer.renewal_token = undefined;
  tokenContainer.accessExpirationTime = undefined;
  tokenContainer.renewExpirationTime = undefined;
  tokenContainer.bearer_token = undefined;
  tokenContainer.ready = undefined;
};

export const HTTP_STATUS_EXPIRED_TOKEN = 401;
export const EMAIL_ACTIVATION_ERROR = 402;
export const HTTP_STATUS_NOT_FOUND = 404;
export const ACCOUNT_ALREADY_EXISTS = 422;

export const fetchWithToken = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { access_token, renewal_token } = getTokensToFetch();
  if (!access_token || !renewal_token) {
    throw new Error('no token found in the container in fetch helper');
  }
  const response: Response = await fetchV2(input.toString(), access_token, init?.method, init?.body);
  if (response.ok) {
    return response;
  }

  if (response.status === HTTP_STATUS_EXPIRED_TOKEN) {
    const refreshTokensRes = await fetchV2(BACKEND_CONFIG.endpoints.docRenew, renewal_token, 'POST');
    if (refreshTokensRes.ok) {
      const secondTryResponse: Response = await fetchV2(input.toString(), access_token, init?.method, init?.body);
      if (secondTryResponse.ok) {
        return secondTryResponse;
      }
    } else {
      const error = await response.json();
      const err: Error = { name: `failed to refresh token:\n ${JSON.stringify(error)}`, message: 'logout' };
      return Promise.reject(err);
    }
  }
  const error = await response.json();
  return Promise.reject(new Error(response.status.toString(), { cause: error.message }));

  // if (!response.ok) {
  //   const errorText = await response.text();
  //   throw new Error(`${response.status.toString()}|||\n${response.statusText.toString()}|||${errorText}`);
  // }
};

export const fetchV2 = async (
  url: string,
  authorization?: string,
  method?: string,
  body?: BodyInit | null,
): Promise<Response> => {
  if (authorization === '') {
    const err: Error = {
      name: `no token found in the container in fetch helper`,
      message: 'logout',
    };
    throw err;
  }
  const response = await fetch(url, {
    method: method ?? 'GET',
    headers: {
      ...(authorization && { Authorization: authorization }),
      Accept: 'application/json',
      'Accept-Encoding': 'gzip',
      'Content-Type': 'application/json',
    },
    body,
  });
  return response;
};
