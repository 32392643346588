import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { patientsReducer } from '../../patientsContext/usecases/Patients.reducers';
import { questionnairesReducer } from '../../questionnairesContext/usecases/questionnaires.reducers';
import { commonReducer } from '../../common/usecases/common.reducers';

export const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    common: commonReducer,
    patients: patientsReducer,
    questionnaires: questionnairesReducer,
  });
