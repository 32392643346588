import { applicationContextDependencies } from '../common/configuration/applicationContextDependencies';
import { navigationContextDependencies } from '../navigationContext/configuration/navigationContextDependencies';
import { patientsContextDependencies } from '../patientsContext/configuration/PatientsContextDependencies';
import { questionnairesContextDependencies } from '../questionnairesContext/configuration/QuestionnairesContextDependencies';

export const dependencies = {
  ...questionnairesContextDependencies,
  ...patientsContextDependencies,
  ...navigationContextDependencies,
  ...applicationContextDependencies,
};

export type DependenciesType = typeof dependencies;
