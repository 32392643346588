import React, { useState } from 'react';
import { StyleSheet } from '../../../../common/domain/entities/StyleSheet';

type Props = {
  label: string;
  value: string;
  onChange: (value: string) => void;
};

const NumericInput = ({ label, value, onChange }: Props) => {
  const [number, setNumber] = useState<string>(value || '');
  React.useEffect(() => {
    setNumber('');
  }, []);
  React.useEffect(() => {
    setNumber(value);
  }, [value]);

  const handleState = (input_value: string) => {
    if (input_value.length === 0) {
      onChange('');
      return;
    }
    const newNumberValue = parseInt(input_value);
    if (Number.isNaN(newNumberValue) || newNumberValue < 0) {
      return;
    }
    setNumber(input_value);
    onChange(input_value);
  };

  return (
    <div style={styles.containerStyle}>
      <input
        type="number"
        value={number ?? ''}
        onChange={(event) => handleState(event.target.value)}
        style={styles.inputStyle}
      />
      {label}
    </div>
  );
};

const styles: StyleSheet = {
  containerStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputStyle: {
    width: '80px',
    height: '56px',
    borderRadius: '8px',
    backgroundColor: '#E6EDFF',
    textAlign: 'center',
    marginRight: '10px',
  },
};

export default NumericInput;
