import React from 'react';
import { SingleQuestionDisplay } from './components/SingleQuestionDisplay';
import { QuestionnaireSubmissionView } from './components/QuestionnaireSubmissionView';
import { QuestionnaireProgress } from './components/QuestionnaireProgress';
import { AppSizes } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../../common/domain/entities/StyleSheet';
import CommonFunctions from '../../../common/helpers/CommonFunctions';
import { AppButton } from '../../../common/ui/Others/AppButton';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import QuestionnaireCommonFunctions from '../../../common/helpers/QuestionnaireCommonFunctions';
import { AppFormErrorList } from '../../../common/ui/Others/AppFormErrorList';
import TopBar from '../TopBar';
import { Footer } from '../../../navigationContext/ui/components/Footer';
import { useContact } from '../../../contexts/patientContact/store';
import { LoadingCircle } from '../../../assets/svg/LoadingCircle';
import { ContactInvitation } from '../../../contexts/patientContact/types';
import { SingleQuestion } from '../../domain/entities/SingleQuestion';
import { QuestionnaireAnswer } from '../../domain/entities/QuestionnaireAnswer';
import { InvitationStatus, isDeadEndStatus } from '../../domain/entities/QInvitation';
import { QuestionnaireObj } from '../../domain/entities/QuestionnaireObj';
import { deeplink, throttle } from '../../../core/helpers/handy';
import { AppAlert } from '../../../common/ui/Others/AppAlert';

import {
  DELETED_QUESTIONNAIRE_ERROR_TEXT,
  FALSE_QUESTIONNAIRE_DATA_P1,
  FALSE_QUESTIONNAIRE_DATA_P2,
  FALSE_QUESTIONNAIRE_DATA_P3,
} from '../../../common/helpers/ConstantTexts';

const AGE_QUESTION_CODE = 'SSVFBBB06'; // age question that should be skipped
type AnswersHashMap = { [x in QuestionnaireAnswer['question_uuid']]: QuestionnaireAnswer };

type Props = {
  navigateTo: (p: NavigationPayload) => void;
  navigateBack: () => void;
};
export const AnsweringPageDumb = ({ navigateTo, navigateBack }: Props) => {
  const [direction, setDirection] = React.useState<number>(1);
  const { content, isFetching, update: updateInvitation, reject: rejectInvitation } = useContact();
  const [questionnaireQs, setQuestionnaireQs] = React.useState<SingleQuestion[]>();
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState<number>(0);
  const [currentQuestion, setCurrentQuestion] = React.useState<SingleQuestion>();
  const [answersHashMap, setAnswersHashMap] = React.useState<AnswersHashMap>({});
  const [submitViewIsOn, setSubmitViewIsOn] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<boolean>(false);
  const [pauseModalVisible, setPauseModalVisible] = React.useState<boolean>(false);
  const shouldSendAnswersOnIndexNextAction = React.useRef<number>();
  const [isExcludedVisible, setIsExcludedVisible] = React.useState(false);

  const throttleFlag = React.useRef<NodeJS.Timeout>();

  const rejectQ = () => {
    rejectInvitation()
      .then(() => {
        // Messaging.toast(
        //   'Der Arzt wird benachrichtigt, um Korrekturen vorzunehmen und den Fragebogen erneut einzureichen.',
        // );
        navigateTo({
          routeName: 'Dead-End-Screen',
          params: {
            content: `${FALSE_QUESTIONNAIRE_DATA_P1} ${content.contact?.email}${FALSE_QUESTIONNAIRE_DATA_P2} ${content.doctor?.first_name} ${content.doctor?.last_name} ${FALSE_QUESTIONNAIRE_DATA_P3}`,
          },
        });
      })
      .catch((err) => alert(`rejecting assigned questionnaire caught error\n${err}`));
  };

  React.useEffect(() => {
    if (content.invitation) {
      setCurrentQuestionIndex(content.invitation.step);

      const map = content.invitation.answers.reduce<AnswersHashMap>(
        (acc, item) => ({
          ...acc,
          [item.question_uuid]: item,
        }),
        {} as AnswersHashMap,
      );
      setAnswersHashMap(map);
    } else {
      setCurrentQuestionIndex(0);
      setAnswersHashMap({});
    }
  }, []);

  React.useEffect(() => {
    if (content.invitation?.status && isDeadEndStatus(content.invitation?.status)) {
      navigateTo({ routeName: 'Dead-End-Screen', params: { content: DELETED_QUESTIONNAIRE_ERROR_TEXT } });
    }

    // if the invitation has not been accepted yet
    if (content.invitation?.tos_accepted_at === undefined) {
      navigateTo({ routeName: 'Accept-Terms' });
    }

    if (content.invitation?.status === 'expired') {
      navigateTo({ routeName: 'Expired-Questionnaire' });
    } else if (content.invitation?.locked) {
      navigateTo({ routeName: 'Questionnaire-Finish-Line' });
    }

    if (content.questionnaire) {
      refreshQuestions(content.questionnaire, content.invitation?.answers ?? []);
      refreshStep(content.questionnaire, content.invitation?.answers ?? []);
    }
  }, [content]);

  React.useEffect(() => {
    questionnaireQs &&
      setCurrentQuestion(questionnaireQs[currentQuestionIndex] ?? questionnaireQs[questionnaireQs.length - 1]);
  }, [currentQuestionIndex, questionnaireQs]);

  React.useEffect(() => {
    // automatically sending the answer of age question (if it's available)
    if (currentQuestion && currentQuestion.question_code === AGE_QUESTION_CODE && content.patient) {
      const age = CommonFunctions.getAge(content.patient.birth_date);
      const ageAnswer: QuestionnaireAnswer = {
        question_uuid: currentQuestion.uuid,
        value: age < 6 ? '0' : age < 11 ? '1' : '2',
      };

      updateAnswersInServer({
        answers: Object.values({
          ...answersHashMap,
          [ageAnswer.question_uuid]: ageAnswer,
        }),
      });
      setCurrentQuestionIndex(currentQuestionIndex + direction);
    }
  }, [currentQuestion]);

  const refreshQuestions = (theQuestionnaire: QuestionnaireObj, theAnswers: QuestionnaireAnswer[]) => {
    let newSections = theQuestionnaire.sections;
    if (QuestionnaireCommonFunctions.hideFSection(theQuestionnaire, theAnswers)) {
      newSections = theQuestionnaire.sections.filter((sec) => sec.label.toLowerCase() !== 'f');
    }
    const questions = QuestionnaireCommonFunctions.questionsFromSections(newSections);
    setQuestionnaireQs(questions);
  };

  const refreshStep = (theQuestionnaire: QuestionnaireObj, theAnswers: QuestionnaireAnswer[]) => {
    if (QuestionnaireCommonFunctions.hideFSection(theQuestionnaire, theAnswers)) {
      const abAnswers = QuestionnaireCommonFunctions.getNoneFSectionAnswers(theQuestionnaire, theAnswers);
      setCurrentQuestionIndex(abAnswers.length);
    }
  };

  const updateAnswersInServer = (
    inputs:
      | {
          answers?: QuestionnaireAnswer[];
          finishAnswering?: boolean;
        }
      | undefined = undefined,
  ) => {
    if (!content.invitation || !answersHashMap) {
      return;
    }
    const arrayOfAnswers = inputs?.answers ?? Object.values(answersHashMap);
    let toSend: ContactInvitation = {
      ...content.invitation,
      answers: arrayOfAnswers,
      status: InvitationStatus.STARTED,
      step: CommonFunctions.getMax([currentQuestionIndex + 1, content.invitation?.step ?? 0]),
    };
    if (inputs?.finishAnswering) {
      toSend = { ...toSend, locked: true, status: InvitationStatus.FINISHED };
    }
    if (toSend.step && toSend.step < currentQuestionIndex + 1) {
      toSend = { ...toSend, step: currentQuestionIndex + 1 };
    }
    throttleFlag.current = throttle(
      () => {
        updateInvitation(toSend).catch((err) => {
          alert(
            `updateAnswersInServerAsync Error caught trying to update assigned questionnaire\n${JSON.stringify(err)}`,
          );
        });
      },
      1000,
      throttleFlag.current,
    );
  };

  const prevButtonPressed = () => {
    if (currentQuestionIndex - 1 >= 0) {
      setDirection(-1);
      submitViewIsOn ? setSubmitViewIsOn(false) : setCurrentQuestionIndex((n) => n - 1);
    } else {
      navigateBack();
    }
  };
  const nextButtonPressed = () => {
    if (!currentQuestion) {
      return;
    }
    const isInclusive = (label: string) => {
      const labelStarters = ['Consent', 'Confirmation', 'Inclusion'];
      const index = labelStarters.findIndex((x) => label.startsWith(x));
      return index !== -1;
    };
    const isExclusive = (label: string) => {
      const labelStarters = ['Exclusion'];
      const index = labelStarters.findIndex((x) => label.startsWith(x));
      return index !== -1;
    };
    if (answersHashMap[currentQuestion.uuid]) {
      const selectedAnswer = answersHashMap[currentQuestion.uuid].value;
      if (isInclusive(currentQuestion.variable_label_in)) {
        // const arrayOfAnswers = inputs?.answers ?? Object.values(answersHashMap);
        if (selectedAnswer === '1') {
          setIsExcludedVisible(true);
          return;
        }
      }
      if (isExclusive(currentQuestion.variable_label_in)) {
        if (selectedAnswer === '0') {
          setIsExcludedVisible(true);
          return;
        }
      }
    }
    if (questionnaireQs && currentQuestionIndex + 1 < questionnaireQs.length) {
      if (
        shouldSendAnswersOnIndexNextAction.current !== undefined &&
        shouldSendAnswersOnIndexNextAction.current < currentQuestionIndex + 1
      ) {
        // if there's something clicked and need to be submitted (to server) on next
        updateAnswersInServer();
        shouldSendAnswersOnIndexNextAction.current = undefined;
      }
      setDirection(1);
      setCurrentQuestionIndex((n) => n + 1);
    } else {
      setSubmitViewIsOn(true);
    }
  };

  const getCurrentAnswer = (QID: string): string => answersHashMap?.[QID]?.value ?? '';

  const answerChanged = (QID: string, newAnswerValue: string) => {
    const newAnswer: QuestionnaireAnswer = {
      question_uuid: QID,
      value: newAnswerValue,
    };
    shouldSendAnswersOnIndexNextAction.current = currentQuestionIndex;
    setAnswersHashMap((p) => ({
      ...p,
      [newAnswer.question_uuid]: newAnswer,
    }));
    setShowError(false);
  };

  const submitAnswers = () => {
    updateAnswersInServer({ finishAnswering: true });
    navigateTo({ routeName: 'Questionnaire-Finish-Line' });
  };

  if (isFetching || !currentQuestion) {
    return (
      <div className="vw-100 vh-100 align-items-center justify-content-center text-center">
        <LoadingCircle />
      </div>
    );
  }
  const disabled =
    (getCurrentAnswer(currentQuestion.uuid) === undefined || getCurrentAnswer(currentQuestion.uuid) === '') &&
    currentQuestion.question_type !== 'String';
  return (
    <div className="full-screen-container">
      <AppAlert
        isOn={isExcludedVisible}
        title="Sind Sie sich sicher mit Ihrer Auswahl?"
        content={
          <div style={styles.overlayContent}>
            <br />
            Dies beendet den Fragebogen und damit ihre Teilnahme an der Studie.
          </div>
        }
        close={() => {
          setIsExcludedVisible(false);
        }}
        buttons={[
          {
            label: 'Nein, ich bin mir nicht sicher',
            action: () => {
              setIsExcludedVisible(false);
            },
            strokeOnly: true,
          },
          {
            label: 'Ja, ich bin mir sicher',
            action: () => {
              updateAnswersInServer();
              rejectQ();
              setIsExcludedVisible(false);
            },
            color: 'red',
          },
        ]}
      />
      <div className="container-fluid pb-3" style={{ flex: 1 }}>
        <TopBar />
        <QuestionnaireProgress
          currentQuestionIndex={currentQuestionIndex}
          totalVal={questionnaireQs?.length ?? 0}
          answersLength={content.invitation?.answers.length ?? 0}
        />
        {submitViewIsOn && (
          <QuestionnaireSubmissionView
            backButtonPressed={(i?: number) => {
              if (i !== undefined) {
                setCurrentQuestionIndex(i);
              }
              setSubmitViewIsOn(false);
            }}
            submitButtonPressed={submitAnswers}
          />
        )}
        {!submitViewIsOn && (
          <div className="container mt-3 mt-sm-5">
            <div className="row mt-sm-5 mt-3">
              <div className="col-lg-10 offset-lg-1">
                <div className="w-100" style={styles.questionsContainer}>
                  {currentQuestion && (
                    <SingleQuestionDisplay
                      question={currentQuestion}
                      key={currentQuestion.sort_order}
                      currentAnswer={getCurrentAnswer(currentQuestion.uuid)}
                      setAnswer={answerChanged}
                    />
                  )}
                </div>
                <AppFormErrorList formErrorsList={['Bitte wählen Sie eine der Antwortoptionen.']} shown={showError} />
                <div className="row mt-4">
                  <div className="col-6 col-md-4 offset-sm-0 offset-md-2 mb-3 pe-sm-auto pe-1">
                    <AppButton
                      extraStyle={{ maxWidth: 'inherit', borderColor: '#1246DA' }}
                      label="Zurück"
                      color="#1246DA"
                      strokeOnly
                      action={prevButtonPressed}
                    />
                  </div>
                  <div className="col-6 col-md-4 offset-sm-0 offset-sd-2 ps-sm-auto ps-1">
                    <AppButton
                      extraStyle={{ maxWidth: 'inherit' }}
                      disabled={disabled}
                      label="Weiter"
                      color="#1246DA"
                      action={() => {
                        if (disabled) {
                          setShowError(true);
                        } else {
                          nextButtonPressed();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {content.invitation?.source === 'app' && (
        <>
          <button
            type="button"
            className="m-3 text-decoration-underline"
            style={{ color: '#1246DA' }}
            onClick={() => {
              setPauseModalVisible(true);
            }}
          >
            Später weiter ausfüllen”
          </button>
          <AppAlert
            isOn={pauseModalVisible}
            content={
              <div style={styles.overlayContent}>
                <p className="fw-bold fs-4">Fragebogen abbrechen</p>
                <p className="fs-6">
                  Du wirst nun zurück zur App geleitet. Du kannst den Fragebogen später noch einmal starten.
                </p>
              </div>
            }
            close={() => {
              setPauseModalVisible(false);
            }}
            buttons={[
              {
                label: 'Zurück zur App',
                color: '#1246DA',
                action: () => {
                  deeplink({
                    bundleLink: 'sleep://',
                    iTunesUrl: 'https://apps.apple.com/sg/app/eyelevel-sleep/id6443792657',
                    playStoreUrl: 'https://play.google.com/store/apps/details?id=care.eyelevel.sleep',
                  });
                  setPauseModalVisible(false);
                },
              },
            ]}
          />
        </>
      )}
      <div className="d-none d-md-block">
        <Footer />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  outerContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  questionsContainer: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    alignSelf: 'center',
  },
  buttonsOuterContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonsCouple: {
    flexDirection: 'row',
    columnGap: AppSizes.SIZE2,
    columnWidth: '50%',
    width: '95%',
    maxWidth: 500,
  },
};
