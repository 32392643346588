import { InMemoryApplicationEventListener } from '../adapter/inmemory/InMemoryApplicationEventListener';
import { ApplicationEventListener } from '../domain/gateways/ApplicationEvent.listener';
import { ENV } from '../../configuration';
import { RealHelpSpaceInteractor } from '../adapter/real/RealHelpSpaceInteractor';
import { HelpSpaceInteractor } from '../domain/gateways/HelpSpace.interactor';
import { InMemoryHelpSpaceInteractor } from '../adapter/inmemory/InMemoryHelpSpaceInteractor';

export class ApplicationContextFactory {
  static applicationListener(): ApplicationEventListener {
    return new InMemoryApplicationEventListener();
  }

  static helpSpace(): HelpSpaceInteractor {
    switch (ENV) {
      case 'inmemory':
        return new InMemoryHelpSpaceInteractor();
      default:
        return new RealHelpSpaceInteractor();
    }
  }
}
