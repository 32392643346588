import { NavLink as Link } from 'react-router-dom';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { sideBarScreens } from '../../configuration/navScreens';
import { StyleSheet } from '../../../common/domain/entities/StyleSheet';
import { SettingsCog } from '../../../assets/svg/SettingsCog';
import { ExitDoor } from '../../../assets/svg/ExitDoor';
import { TwoPeople } from '../../../assets/svg/TwoPeople';
import { HelpQuestionMark } from '../../../assets/svg/HelpQuestionMark';
import { useUser } from '../../../contexts/user/store';
import { NavigationPayload } from '../../domain/entities/NavigationPayload';
import DownloadLogo from '../../../assets/svg/DownloadLogo';
import CommonFunctions from '../../../common/helpers/CommonFunctions';
import { useHcpContext } from '../../../contexts/doctor/store';

const versionString = `v0.${new Date().getDate()}${new Date().getMonth() + 1}${476 + 1}`; // day of the push to main + month + number of commits from github of the main branch

interface SideBarProps {
  sideBarExpanded: boolean;
  navigateTo: (p: NavigationPayload) => void;
}

export const SideBar = ({ sideBarExpanded, navigateTo }: SideBarProps) => {
  const { logout } = useUser();
  const { getAllInvitationReport } = useHcpContext();
  const getLinkContent = (routePath: string) => {
    if (sideBarExpanded) {
      switch (routePath) {
        case 'Patients-List':
          return 'Patientinnen / Patienten';
        default:
          return routePath;
      }
    }
    // here we would return icons
    return routePath.toUpperCase();
  };

  const sendSignOut = () => {
    logout();
  };

  function downloadInvitationReport() {
    getAllInvitationReport().then((res) => {
      CommonFunctions.downloadFile(res, 'answers.zip');
    });
  }

  return (
    <div
      style={{
        ...styles.sideBarContainer,
        width: 300,
      }}
    >
      <div>
        <img style={styles.sideBarLogoStyle} alt="logoImage" src={require('../../../assets/images/logo.png')} />
        {sideBarScreens.map((scr, index) => (
          <Link key={`${index * 675}`} to={`/${scr.routePath}`} style={styles.sideBarLink}>
            <TwoPeople />
            <span>{getLinkContent(scr.routePath)}</span>
          </Link>
        ))}
      </div>
      <div>
        <button
          type="button"
          style={styles.sideBarLink}
          onClick={() => {
            downloadInvitationReport();
          }}
        >
          <DownloadLogo />
          <span>Download All Data</span>
        </button>
        <button
          type="button"
          style={styles.sideBarLink}
          onClick={() => {
            navigateTo({ routeName: 'Settings' });
          }}
        >
          <SettingsCog />
          <span>Einstellungen</span>
        </button>
        <button
          type="button"
          style={styles.sideBarLink}
          onClick={() => {
            navigateTo({ routeName: 'Help-Space' });
          }}
        >
          <HelpQuestionMark />
          <span>Hilfe & Feedback</span>
        </button>
        <button type="button" style={styles.sideBarLink} onClick={sendSignOut}>
          <ExitDoor />
          <span>Ausloggen</span>
        </button>
        <div style={styles.versionStyle}>{versionString}</div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  sideBarContainer: {
    backgroundColor: Color.MONOGREY8,
    height: '100vh',
    borderRightWidth: 1,
    borderColor: Color.PRIMARYBLUE,
    borderRight: `1px solid ${Color.MONOGREY3}`,
    justifyContent: 'space-between',
  },
  sideBarLink: {
    display: 'flex',
    color: Color.MONOGREY6,
    fontSize: FontSize.P1,
    fontWeight: FontWeight.BOLDER,
    padding: AppSizes.SIZE1,
    flexDirection: 'row',
    columnGap: AppSizes.DEFAULT,
    paddingLeft: AppSizes.SIZE2,
    alignItems: 'center',
    cursor: 'pointer',
  },
  sideBarLogoStyle: {
    backgroundSize: 'cover',
    width: '100%',
    maxWidth: 194,
    display: 'inline-block',
    alignSelf: 'center',
    marginTop: AppSizes.SIZE2,
    marginBottom: AppSizes.SIZE2,
  },
  versionStyle: {
    fontSize: FontSize.CAPTION,
    fontWeight: FontWeight.LIGHT,
    width: '100%',
    // alignItems: 'end',
    justifyContent: 'center',
    height: AppSizes.SIZE1,
    marginLeft: 2,
  },
};
