import {
  // AppChangeEmitterType,
  ApplicationEventListener,
  Unsubscribe,
} from '../../domain/gateways/ApplicationEvent.listener';

export class InMemoryApplicationEventListener implements ApplicationEventListener {
  subscribeToAppStateChangeEvent(): Unsubscribe {
    throw new Error('Method not implemented.');
  }
}
