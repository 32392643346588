import { useEffect, useState } from 'react';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import CommonFunctions from '../../../common/helpers/CommonFunctions';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { StyleSheet } from '../../../common/domain/entities/StyleSheet';
import { AppButton } from '../../../common/ui/Others/AppButton';
import QuestionnaireCommonFunctions from '../../../common/helpers/QuestionnaireCommonFunctions';
import { Footer } from '../../../navigationContext/ui/components/Footer';
import TopBar from '../TopBar';
import { useContact } from '../../../contexts/patientContact/store';
import { LoadingCircle } from '../../../assets/svg/LoadingCircle';
import { DELETED_QUESTIONNAIRE_ERROR_TEXT } from '../../../common/helpers/ConstantTexts';
import { isDeadEndStatus } from '../../domain/entities/QInvitation';

interface AssessmentSheetProps {
  navigateTo: (p: NavigationPayload) => void;
  navigateBack: () => void;
}
export const AssessmentSheetDumb = ({ navigateTo, navigateBack }: AssessmentSheetProps) => {
  const [numberOfQuestions, setNumberOfQuestions] = useState<number>(0);
  const { isFetching, content } = useContact();

  useEffect(() => {
    if (content.invitation?.status && isDeadEndStatus(content.invitation?.status)) {
      navigateTo({ routeName: 'Dead-End-Screen', params: { content: DELETED_QUESTIONNAIRE_ERROR_TEXT } });
    }

    // if the invitation has not been accepted yet
    if (content.invitation?.tos_accepted_at === undefined) {
      navigateTo({ routeName: 'Accept-Terms' });
    }
    setNumberOfQuestions(
      QuestionnaireCommonFunctions.questionsFromSections(content.questionnaire?.sections ?? []).length,
    );
  }, [content]);

  if (isFetching) {
    return (
      <div className="vw-100 vh-100 align-items-center justify-content-center text-center">
        <LoadingCircle />
      </div>
    );
  }

  const patientName = content.patient?.first_name || '...';
  return (
    <div className="full-screen-container">
      <div className="container-fluid" style={{ flex: 1 }}>
        <TopBar />
        <div className="container mt-3 mt-sm-5">
          <div className="row mt-sm-5 mt-3">
            <div className="col-lg-8 offset-lg-2">
              <p style={styles.paragraph}>
                Dieser Fragebogen beschreibt Verhaltensweisen und Verhaltensprobleme sowie emotionale Probleme von
                Kindern und Jugendlichen.
              </p>
              <p style={{ ...styles.paragraph, fontWeight: FontWeight.BOLD }}>
                Sie werden nachfolgend Beschreibungen von Verhalten sehen. Klicken Sie bitte immer die Aussage an, die
                angibt, wie zutreffend das beschriebene Verhalten für {patientName} ist. Wenn Sie zu einer Beschreibung
                keine Aussage treffen können, klicken Sie bitte auf “gar nicht“.
              </p>
              <p style={styles.paragraph}>
                Beurteilen Sie alle Aussagen so gut Sie können, auch wenn Ihnen nicht alle Informationen vorliegen.
                Beurteilen Sie bitte ansonsten alle Aussagen, auch dann, wenn Ihnen einige Aussagen dem Alter des Kindes
                nicht angemessen erscheinen (dieser Beurteilungsbogen wird sowohl zur Beurteilung von Kindern als auch
                von Jugendlichen eingesetzt).
              </p>

              <p style={styles.paragraph}>
                Der Fragebogen beinhaltet {numberOfQuestions} Fragen und dauert etwa {Math.ceil(numberOfQuestions / 2)}{' '}
                Minuten. Ihre Antworten werden gespeichert. Sie können den Fragebogen jederzeit unterbrechen und später
                weitermachen.
              </p>
            </div>
            <div className="row mt-4">
              <div className="col-sm-6 col-md-4 offset-sm-0 offset-md-2 mb-3">
                <AppButton
                  label="Zurück"
                  extraStyle={{ maxWidth: 'inherit' }}
                  color={Color.MONOGREY6}
                  strokeOnly
                  action={() => {
                    navigateBack();
                  }}
                />
              </div>
              <div className="col-sm-6 col-md-4 offset-sm-0 offset-sd-2 mb-4">
                <AppButton
                  label="Weiter"
                  extraStyle={{ maxWidth: 'inherit' }}
                  color={Color.MONOGREY6}
                  action={() => {
                    navigateTo({ routeName: 'Answering-Page' });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-none d-md-block">
        <Footer />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  outerContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  innerContainer: {
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    width: '100%',
    maxWidth: CommonFunctions.mobileSized() ? '95vw' : 1000,
  },
  title: {
    marginBottom: AppSizes.SIZE2,
    fontWeight: FontWeight.BOLDER,
    fontSize: FontSize.H5,
    textAlign: 'center',
  },
  paragraph: {
    textAlign: 'left',
    fontWeight: FontWeight.REGULAR,
    fontSize: FontSize.P1,
    width: '100%',
  },
  buttonsOuterContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: AppSizes.SIZE10,
  },
  buttonsCouple: {
    flexDirection: 'row',
    columnGap: 10,
    columnWidth: '50%',
    width: '95%',
    maxWidth: 500,
  },
};
