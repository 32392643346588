import { connect } from 'react-redux';
import { RejectionScreenDumb } from './RejectionScreen.dumb';
import { AppState } from '../../configuration/app.states';
import { getBreadcrumbs } from '../../usecases/common.selectors';

const mapState = (state: AppState) => ({
  breadcrumbs: getBreadcrumbs(state),
});

const mapDispatch = () => ({});

export const RejectionScreenSmart = connect(mapState, mapDispatch)(RejectionScreenDumb);
