import { TermsAndConditionsContent } from './TermsAndConditionsContent';
// eslint-disable-next-line import/no-cycle
import { Footer } from '../../../navigationContext/ui/components/Footer';

export const TOS_VERSION_FE = 'v1.1';
export const DU_VERSION_FE = 'no_version';

export const TermsAndConditions = () => (
  <div className="full-screen-container">
    <div className="container" style={{ flex: 1 }}>
      <div className="row do-not-break-out">
        <div className="col-md-10 offset-md-1">
          <TermsAndConditionsContent />
        </div>
      </div>
    </div>
    <div className="d-none d-md-block">
      <Footer />
    </div>
  </div>
);
