import { useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { useTranslation } from 'react-i18next';
import { SupportForm } from './components/SupportForm';
import { FeedbackForm } from './components/FeedbackForm';
import { AppSizes, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import CommonFunctions from '../../helpers/CommonFunctions';
import { AppDropDownMenu } from '../Others/AppDropDownMenu';
import Messaging from '../../../core/components/messaging';
import { StyleSheet } from '../../domain/entities/StyleSheet';
import { HelpObj } from '../../domain/entities/HelpObj';

const supportList = ['Ich habe ein Problem / Etwas funktioniert nicht', 'Ich möchte Feedback geben'];
export interface HelpSpaceProps {
  sendHelpAnswersAsync(obj: HelpObj): Promise<DispatchAsyncResult>;
}
export const HelpSpaceDumb = ({ sendHelpAnswersAsync }: HelpSpaceProps) => {
  const [chosenSuport, setChosenSuport] = useState<string>();
  const { t } = useTranslation();

  const resetChoice = () => {
    setChosenSuport(undefined);
  };
  const sendHelpAnswers = (helpObj: HelpObj) => {
    sendHelpAnswersAsync(helpObj)
      .then((res) => {
        if (!res.success) {
          alert(`Error at help space sendHelpAnswersAsync\n${JSON.stringify(res.error)}`);
        } else {
          Messaging.toast(t('IT_WORKED'));
          resetChoice();
        }
      })
      .catch((err) => alert(`Error caught at help space sendHelpAnswersAsync\n${err}`));
  };
  return (
    <div style={styles.outerContainer}>
      <div style={styles.innerContainer}>
        <p style={styles.boldText}>
          Hier können Sie mit uns Kontakt aufnehmen, wenn etwas nicht so funktioniert wie es sollte, wenn Sie ein
          Problem bei der Benutzung der Anwendung haben oder Sie uns Feedback schicken möchten.
        </p>
        <p style={{ ...styles.boldText, fontWeight: FontWeight.REGULAR }}>
          Eine detaillierte Beschreibung Ihres Anliegens hilft uns, dieses bestmöglich zu bearbeiten.
        </p>
        <AppDropDownMenu
          label="Anliegen*"
          list={supportList}
          shownValue={chosenSuport}
          placeholder="Anliegen"
          disabled={!chosenSuport}
          onChangeFunc={(newVal: string) => {
            setChosenSuport(newVal);
          }}
          extraStyle={{ width: '100%' }}
        />
        {chosenSuport === supportList[0] && <SupportForm resetChoice={resetChoice} sendHelpAnswers={sendHelpAnswers} />}
        {chosenSuport === supportList[1] && (
          <FeedbackForm resetChoice={resetChoice} sendHelpAnswers={sendHelpAnswers} />
        )}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  outerContainer: {
    flex: 1,
  },
  innerContainer: {
    width: '100%',
    maxWidth: CommonFunctions.mobileSized() ? '95vw' : '40vw',
    marginTop: AppSizes.SIZE4,
    alignSelf: 'center',
    alignItems: 'center',
  },
  boldText: {
    fontSize: FontSize.H5,
    fontWeight: FontWeight.EXTRABOLD,
    textAlign: 'center',
  },
};
