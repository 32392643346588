import { useTranslation } from 'react-i18next';
import { AppSizes, Color, FontSize, FontWeight } from '../../../../assets/styles/constantStyles';
import { StyleSheet } from '../../../../common/domain/entities/StyleSheet';
import { ProgressBar } from '../../../../common/ui/Others/ProgressBar';

interface QuestionnaireProgressProps {
  currentQuestionIndex: number;
  totalVal: number;
  hideLabel?: boolean;
  answersLength: number;
}
export const QuestionnaireProgress = ({
  currentQuestionIndex,
  totalVal,
  hideLabel = false,
  answersLength,
}: QuestionnaireProgressProps) => {
  const { t } = useTranslation();
  return (
    <div style={styles.outerContainer} className="d-block d-md-inline w-auto align-self-md-start align-self-stretch">
      {hideLabel === false &&
        t('STEPSOF', {
          current: currentQuestionIndex + 1,
          total: totalVal,
        })}
      <ProgressBar
        backgroundColor="#1246DA33"
        progressColor="#1246DA"
        roundedBorders
        height={10}
        currentVal={answersLength}
        totalVal={totalVal}
      />
    </div>
  );
};

const styles: StyleSheet = {
  outerContainer: {
    fontWeight: FontWeight.MEDIUM,
    fontSize: FontSize.CAPTION,
    color: Color.MONOGREY5,
    margin: AppSizes.SIZE1,
    marginLeft: AppSizes.SIZE1,
    display: 'inline',
    width: 100,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
};
