/* eslint-disable react/jsx-props-no-spreading */
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import DoctorPanelScreensWrapperScreen from './components/DoctorPanelScreensWrapperScreen';
import { DU_VERSION_FE, TOS_VERSION_FE } from '../../../common/ui/Others/TermsAndConditions';
import { AppAcceptTermsPopUp } from '../../../common/ui/Others/AppAcceptTermsPopUp';
import { AGB_VERSION_FE } from '../../../common/ui/Others/AgbPage';
import { useUser } from '../../../contexts/user/store';
import { LoadingCircle } from '../../../assets/svg/LoadingCircle';

import {
  DoctorPanelAuthenticationScreens,
  DoctorPanelScreens,
  PatientContactScreens,
} from '../../configuration/navScreens';
import { AppSizes } from '../../../assets/styles/constantStyles';
import { CommonNavScreens } from '../../configuration/commonScreens';
import ToastContainer from '../../../common/ui/Toast/container';

const Navigator = () => {
  const loginUrlParams = useLocation<string>().pathname;
  const { push } = useHistory();
  const { authorities, profile, isAuthorized, authorizeWithToken } = useUser();

  useEffect(() => {
    if (isAuthorized && authorities.doctor && profile !== undefined && profile.last_name === undefined) {
      push('/User-Personal-Data');
    }
  }, [isAuthorized, profile]);

  useEffect(() => {
    if (loginUrlParams.includes('/api/v1/login/')) {
      getAccessToken();
    }
  }, [loginUrlParams]);

  const getAccessToken = () => {
    const token: string = loginUrlParams.replace('/api/v1/login/', '');
    authorizeWithToken(token)
      .then(() => {
        setTimeout(() => {
          push('/Accept-Terms');
        }, 500);
      })
      .catch(() => {
        /** error handling must be here! */
      });
  };

  if (loginUrlParams.includes('/api/v1/login/') || (isAuthorized && authorities.doctor && profile === undefined)) {
    return (
      <div className="text-center justify-cotent-center align-items-center vh-100 vw-100">
        Processing...
        <div style={{ width: AppSizes.SIZE4, height: AppSizes.SIZE4, position: 'relative' }}>
          <LoadingCircle />
        </div>
      </div>
    );
  }

  return (
    <div className="position-relative vh-100 vw-100">
      <Switch>
        {CommonNavScreens.map((src, index) => (
          <Route
            exact
            key={`${src.routePath + index}`}
            path={`/${src.routePath.toLowerCase()}`}
            component={src.component}
          />
        ))}

        {authorities.doctor && (
          <Route
            exact
            path="/user-personal-data"
            component={DoctorPanelScreens[DoctorPanelScreens.length - 1].component}
          />
        )}
        {authorities.doctor &&
          DoctorPanelScreens.filter((ite) => ite.routePath !== 'User-Personal-Data').map((src, index) => (
            <Route
              exact
              key={`${src.routePath + index}`}
              path={`/${src.routePath.toLowerCase()}`}
              render={(props) => (
                <DoctorPanelScreensWrapperScreen>
                  <src.component {...props} />
                </DoctorPanelScreensWrapperScreen>
              )}
            />
          ))}
        {authorities.doctor === false &&
          DoctorPanelAuthenticationScreens.map((src, index) => (
            <Route
              exact
              key={`${src.routePath + index}`}
              path={`/${src.routePath.toLowerCase()}`}
              component={src.component}
            />
          ))}

        {authorities.patient &&
          PatientContactScreens.map((src, index) => (
            <Route
              exact
              key={`${src.routePath + index}`}
              path={`/${src.routePath.toLowerCase()}`}
              component={src.component}
            />
          ))}
        <Route path="*" render={() => <Redirect to="/" />} />
      </Switch>
      <ToastContainer position="top-right" />
      {authorities.doctor && (
        <AppAcceptTermsPopUp
          isOn={
            isAuthorized &&
            profile !== undefined &&
            ((!!profile.tos_accepted_at && profile.tos_version !== TOS_VERSION_FE) ||
              (!!profile.data_use_accepted_at && profile.data_use_version !== DU_VERSION_FE) ||
              (!!profile.data_security_accepted_at && profile.data_security_version !== AGB_VERSION_FE))
          }
        />
      )}
      <div />
    </div>
  );
};

export default Navigator;
