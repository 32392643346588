import { fetchWithToken } from '../../../common/adapter/fetch.helper';
import { BACKEND_CONFIG } from '../../../configuration';
import { Patient } from '../../domain/entities/Patient';
import { PatientsInteractor } from '../../domain/gateways/Patients.interactor';

export class RealPatientsInteractor implements PatientsInteractor {
  async loadPatients(): Promise<Patient[]> {
    const url = `${BACKEND_CONFIG.endpoints.patients}`;
    const response = await fetchWithToken(url);
    const patientsData = await response.json();
    return patientsData.data;
  }

  async insertPatient(newPatient: Patient): Promise<Patient> {
    const url = `${BACKEND_CONFIG.endpoints.patients}`;
    const objectToSend = {
      patient: {
        birth_date: newPatient.birth_date,
        class: newPatient.class,
        first_name: newPatient.first_name,
        gender: newPatient.gender,
        last_name: newPatient.last_name,
        pinned: newPatient.pinned,
        school: newPatient.school,
      },
    };
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify(objectToSend),
    });
    const patientsData = await response.json();
    return patientsData.data;
  }

  async updatePatient(updatedPatient: Patient): Promise<Patient> {
    const url = `${BACKEND_CONFIG.endpoints.patients}/${updatedPatient.uuid}`;
    const objectToSend = {
      patient: updatedPatient,
    };
    const response = await fetchWithToken(url, {
      method: 'PUT',
      body: JSON.stringify(objectToSend),
    });
    const patientsData = await response.json();
    return patientsData.data;
  }

  async deletePatient(patientToDelete: Patient): Promise<Patient> {
    const url = `${BACKEND_CONFIG.endpoints.patients}/${patientToDelete.uuid}`;
    const response = await fetchWithToken(url, {
      method: 'DELETE',
    });
    if (response.ok) {
      return patientToDelete;
    }
    const res = await response.json();
    return res;
  }
}
