import { createAsyncActions } from '../../common/usecases/actions.helper';
import { Patient } from '../domain/entities/Patient';
import { PatientContact } from '../domain/entities/PatientContact';

export const {
  request: setAppCurrentPatientRequest,
  success: setAppCurrentPatientSuccess,
  failure: setAppCurrentPatientFailure,
} = createAsyncActions<Patient | null, Patient | null, unknown>('SET_CURRENT_PATIENT');

export const {
  request: loadPatientsRequest,
  success: loadPatientsSuccess,
  failure: loadPatientsFailure,
} = createAsyncActions<void, Patient[], unknown>('LOAD_PATIENTS');

export const {
  request: insertPatientRequest,
  success: insertPatientSuccess,
  failure: insertPatientFailure,
} = createAsyncActions<Patient, Patient, unknown>('INSERT_PATIENT');

export const {
  request: updatePatientRequest,
  success: updatePatientSuccess,
  failure: updatePatientFailure,
} = createAsyncActions<Patient, Patient, unknown>('UPDATE_PATIENT');

export const {
  request: deletePatientRequest,
  success: deletePatientSuccess,
  failure: deletePatientFailure,
} = createAsyncActions<Patient, Patient, unknown>('DELETE_PATIENT');

export const {
  request: loadPatientContactsRequest,
  success: loadPatientContactsSuccess,
  failure: loadPatientContactsFailure,
} = createAsyncActions<Patient, PatientContact[], unknown>('LOAD_PATIENT_CONTACTS');

export const {
  request: insertPatientContactRequest,
  success: insertPatientContactSuccess,
  failure: insertPatientContactFailure,
} = createAsyncActions<PatientContact, PatientContact, unknown>('INSERT_PATIENT_CONTACT');

export const {
  request: updatePatientContactRequest,
  success: updatePatientContactSuccess,
  failure: updatePatientContactFailure,
} = createAsyncActions<PatientContact, PatientContact, unknown>('UPDATE_PATIENT_CONTACT');

export const {
  request: deletePatientContactRequest,
  success: deletePatientContactSuccess,
  failure: deletePatientContactFailure,
} = createAsyncActions<PatientContact, PatientContact, unknown>('DELETE_PATIENT_CONTACT');

export const {
  request: loadAllContactsRequest,
  success: loadAllContactsSuccess,
  failure: loadAllContactsFailure,
} = createAsyncActions<void, PatientContact[], unknown>('LOAD_ALL_CONTACTS');
