import { BigBoxRadioButton } from './BigBoxRadioButton';
import { AppSizes, FontSize } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../domain/entities/StyleSheet';

export interface BigBoxRadioButtonGroupProps {
  groupLabel?: string;
  selectedIndex?: number;
  options: string[];
  action: (newIndex: number) => void;
}
export const BigBoxRadioButtonGroup = ({ groupLabel, selectedIndex, options, action }: BigBoxRadioButtonGroupProps) => (
  <div style={styles.container}>
    {groupLabel && <div style={styles.title}>{groupLabel}</div>}
    <div className="row justify-content-center">
      {Object.values(options).map((opt, i) => (
        <div key={`answer-${i + 1}`} className="col-12 col-md-3 mb-3">
          <BigBoxRadioButton
            // indicatorLabel={`${i}`}
            content={opt}
            selected={selectedIndex === i}
            action={() => action(i)}
          />
        </div>
      ))}
    </div>
  </div>
);

const styles: StyleSheet = {
  container: {
    marginTop: AppSizes.SIZE3,
    width: '100%',
  },
  optionsContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: FontSize.P3,
    marginLeft: AppSizes.SIZE1,
  },
};
