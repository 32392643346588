import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../domain/entities/StyleSheet';

export interface ErrorExclamationMarkProps {
  shown?: boolean;
}
export const ErrorExclamationMark = ({ shown }: ErrorExclamationMarkProps) => (
  <div
    style={{
      ...styles.errorMark,
      opacity: shown ? 1 : 0,
      transform: shown ? 'translate(150%, -50%)' : 'translate(150%, 0%)',
    }}
  >
    !
  </div>
);

const styles: StyleSheet = {
  errorMark: {
    borderRadius: 1000,
    width: 16,
    height: 16,
    position: 'absolute',
    top: '50%',
    right: 0,
    background: Color.PUREWHITE,
    border: `1px solid ${Color.DANGER}`,
    alignItems: 'center',
    justifyContent: 'center',
    color: Color.DANGER,
    fontSize: FontSize.CAPTION,
    fontWeight: FontWeight.BOLD,
    marginLeft: AppSizes.DEFAULT,
    transition: 'all 300ms',
    cursor: 'default',
    pointerEvents: 'none',
  },
};
