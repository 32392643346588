import { useState } from 'react';
import _ from 'lodash';
import { motion } from 'framer-motion';

import { StyleSheet } from '../../../../common/domain/entities/StyleSheet';
import { AppSizes, Color, FontSize, FontWeight } from '../../../../assets/styles/constantStyles';
import { AppAlert } from '../../../../common/ui/Others/AppAlert';
import { AppFormErrorList } from '../../../../common/ui/Others/AppFormErrorList';
import { AppTextInput } from '../../../../common/ui/Others/AppTextInput';
import { EMPTY_INPUT_ERROR_TEXT, WRONG_CURRENT_PASSWORD_ERROR_TEXT } from '../../../../common/helpers/ConstantTexts';
import { NavigationPayload } from '../../../../navigationContext/domain/entities/NavigationPayload';
import { useUser } from '../../../../contexts/user/store';
import Messaging from '../../../../core/components/messaging';

interface UserSettingsDeleteAccountRowProps {
  navigateTo: (p: NavigationPayload) => void;
}
export const UserSettingsDeleteAccountRow = ({ navigateTo }: UserSettingsDeleteAccountRowProps) => {
  const [alertIsOn, setAlertIsOn] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');
  const { deleteAccount: deleteApiReq } = useUser();

  const deleteAccount = () => {
    if (_.isEmpty(password)) {
      setErrorText(EMPTY_INPUT_ERROR_TEXT);
    } else {
      deleteApiReq(password)
        .then(() => {
          Messaging.toast('Benutzer gelöscht');
          navigateTo({ routeName: '' });
        })
        .catch(() => {
          setErrorText(WRONG_CURRENT_PASSWORD_ERROR_TEXT);
        });
    }
  };

  const deleteAccountPressed = () => {
    setAlertIsOn(true);
  };
  const closeAlert = () => {
    setPassword('');
    setErrorText('');
    setAlertIsOn(false);
  };

  const hasError = !_.isEmpty(errorText);

  return (
    <div style={styles.container}>
      <div style={styles.infoSide}>
        Wenn Sie Ihren Account löschen möchten, können Sie das über die Schaltfläche “Löschen” tun. Dadurch werden alle
        Daten im Zusammenhang mit Ihrem Account gelöscht. Sie haben dann keine Möglichkeit mehr, sich in diesen Account
        einzuloggen oder ihn in irgendeiner Weise zu verwenden. Wenn Sie medigital® expert in Zukunft erneut nutzen
        möchten, können Sie einen neuen Account anlegen.
        <br />
        <br />
        Sie können Ihren Account aktiviert lassen, auch wenn Sie medigital® expert aktuell nicht nutzen. Dadurch
        entstehen Ihnen keine Nachteile.
      </div>
      <div style={styles.buttonsSide}>
        <motion.button
          whileHover={{ background: 'rgba(0,0,0,.1)' }}
          type="button"
          style={styles.rowButton}
          onClick={deleteAccountPressed}
        >
          Löschen
        </motion.button>
      </div>
      <AppAlert
        isOn={alertIsOn}
        content={
          <div style={{ marginTop: AppSizes.SIZE5 }}>
            <p className="text-center">
              Sind Sie sicher, dass Sie Ihren Account löschen wollen? Nach dem Löschen können Sie nicht mehr darauf
              zugreifen.
            </p>
            <AppTextInput
              isPass
              label="Passwort*"
              placeholder="Passwort eingeben"
              currentVal={password}
              onChange={(str: string) => {
                setPassword(str);
                setErrorText('');
              }}
              showError={hasError}
            />
            <AppFormErrorList formErrorsList={[errorText]} shown={hasError} />
          </div>
        }
        close={closeAlert}
        buttons={[
          {
            label: 'Nicht löschen',
            action: closeAlert,
            strokeOnly: true,
          },
          {
            label: 'Löschen',
            action: deleteAccount,
            disabled: password.length === 0 || hasError,
          },
        ]}
      />
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flexDirection: 'row',
    alignItems: 'baseline',
    fontSize: FontSize.P3,
    fontWeight: FontWeight.REGULAR,
    borderBottom: `1px solid ${Color.APPWHITE}`,
  },
  infoSide: {
    flex: 1,
    borderRight: `1px solid ${Color.APPWHITE}`,
    padding: AppSizes.SIZE2,
  },
  buttonsSide: {
    width: '20%',
    minWidth: 200,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: AppSizes.SIZE1,
  },
  rowButton: {
    fontWeight: FontWeight.REGULAR,
    fontSize: FontSize.P4,
    borderRadius: AppSizes.SIZE4,
    border: `1px solid${Color.APPBLACK}`,
    padding: `${4}px ${AppSizes.SIZE1}px`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    columnGap: 8,
    background: 'rgba(0, 0, 0, 0)',
    transition: 'all 300ms',
  },
};
