import { Color } from '../styles/constantStyles';

export interface ExitDoorProps {
  color?: string;
}

export const ExitDoor = ({ color }: ExitDoorProps) => (
  <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 9L5 13V10L14 10V8L5 8V5L0 9Z" fill={color || Color.MONOGREY6} />
    <path
      d="M11.0007 3.43611e-05C9.81825 -0.00324434 8.64687 0.228145 7.55447 0.680798C6.46206 1.13345 5.47032 1.79837 4.63672 2.63703L6.05072 4.05103C7.37272 2.72903 9.13072 2.00003 11.0007 2.00003C12.8707 2.00003 14.6287 2.72903 15.9507 4.05103C17.2727 5.37303 18.0017 7.13103 18.0017 9.00103C18.0017 10.871 17.2727 12.629 15.9507 13.951C14.6287 15.273 12.8707 16.002 11.0007 16.002C9.13072 16.002 7.37272 15.273 6.05072 13.951L4.63672 15.365C6.33572 17.065 8.59572 18.002 11.0007 18.002C13.4057 18.002 15.6657 17.065 17.3647 15.365C19.0647 13.666 20.0017 11.406 20.0017 9.00103C20.0017 6.59604 19.0647 4.33603 17.3647 2.63703C16.5311 1.79837 15.5394 1.13345 14.447 0.680798C13.3546 0.228145 12.1832 -0.00324434 11.0007 3.43611e-05Z"
      fill={color || Color.MONOGREY6}
    />
  </svg>
);
