import React from 'react';
import { Action } from './actions';
import { defaultMessagingStore, messagingStoreReducer } from './reducer';
import { DefaultToastOptions, Toast, ToastKind } from './type';

let memoryMessageDispatcher: React.Dispatch<Action>;
const defaultTimeouts: {
  [key in ToastKind['type']]: number;
} = {
  blank: 4000,
  error: 4000,
  success: 3000,
  loading: Infinity,
  'has-action': 9000,
};

export const dispatchOnMessageStore = (action: Action) => {
  memoryMessageDispatcher?.(action);
};

const useMessagingStore = (toastOptions: DefaultToastOptions = {}) => {
  const [store, dispatch] = React.useReducer(messagingStoreReducer, defaultMessagingStore);
  memoryMessageDispatcher = dispatch;

  const updateToastsWithToastOptions = (toasts: Toast[]): Toast[] =>
    toasts.map((t) => ({
      ...toastOptions,
      ...toastOptions[t.kind.type],
      ...t,
      duration:
        t.duration || toastOptions[t.kind.type]?.duration || toastOptions?.duration || defaultTimeouts[t.kind.type],
      style: {
        ...toastOptions.style,
        ...toastOptions[t.kind.type]?.style,
        ...t.style,
      } as StyleSheet,
    }));

  return React.useMemo(
    () => ({
      ...store,
      toasts: updateToastsWithToastOptions(store.toasts),
    }),
    [store],
  );
};

export default useMessagingStore;
