import { QuestionnaireAnswer } from './QuestionnaireAnswer';

export enum InvitationStatus {
  DRAFTED = 'drafted',
  UNREAD = 'unread',
  OPENED = 'opened',
  STARTED = 'started',
  REJECTED = 'rejected',
  FINISHED = 'finished',
  FAILED = 'failed',
  REVIEWED = 'reviewed',
  COMMENTED = 'commented',
  SENT = 'sent',
  PROCESSED = 'processed',
  DELETED = 'deleted',
  EXPIRED = 'expired',
}

export const isDeletable = (invitationStatus: string) =>
  [InvitationStatus.DRAFTED, InvitationStatus.UNREAD, InvitationStatus.OPENED, InvitationStatus.SENT].some(
    (status) => status === invitationStatus,
  );

export const getGrStatusString = (enStatus: string) => {
  switch (enStatus) {
    case InvitationStatus.STARTED:
      return 'Begonnen';
    case InvitationStatus.OPENED:
      return 'Offen';
    case InvitationStatus.UNREAD:
      return 'Gesendet';
    case InvitationStatus.REJECTED:
      return 'Abgelehnt';
    case InvitationStatus.FAILED:
      return 'Fehler';
    case InvitationStatus.EXPIRED:
      return 'Abgelaufen';
    case InvitationStatus.SENT:
      return 'Berichtserstellung';
    case InvitationStatus.FINISHED:
    case InvitationStatus.PROCESSED:
    case InvitationStatus.COMMENTED:
      return 'Abgeschlossen';
    case InvitationStatus.REVIEWED:
      return 'Betrachtet';
    default:
      return 'Entwurf';
  }
};

export const isDeadEndStatus = (status: string) =>
  status === 'deleted' ||
  status === 'rejected' ||
  // status === 'failed' ||
  // status === 'finished' ||
  // status === 'reviewed' ||
  // status === 'commented' ||
  // status === 'processed' ||
  status === 'expired';

export interface QInvitation {
  contact_uuid: string;
  patient_uuid: string;
  questionnaire_uuid: string;
  doctor_uuid?: string;
  link?: string;
  title?: string;
  uuid?: string;
  created_at?: string;
  updated_at?: string;

  status: InvitationStatus;

  answers: QuestionnaireAnswer[];
  locked?: boolean;
  assessment?: string;
  exploration?: string;
}
