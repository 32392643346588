import { createAsyncActions } from '../../common/usecases/actions.helper';
import { AssignedQuestionnaire } from '../domain/entities/AssignedQuestionnaire';
import { QInitialData } from '../domain/entities/QInitialData';
import { QInvitation } from '../domain/entities/QInvitation';
import { QuestionnaireObj } from '../domain/entities/QuestionnaireObj';

export const {
  request: loadQuestionnairesRequest,
  success: loadQuestionnairesSuccess,
  failure: loadQuestionnairesFailure,
} = createAsyncActions<void, QuestionnaireObj[], unknown>('LOAD_QUESTIONNAIRES');

export const {
  request: loadQInitialDataRequest,
  success: loadQInitialDataSuccess,
  failure: loadQInitialDataFailure,
} = createAsyncActions<void, QInitialData, unknown>('LOAD_Q_INITIAL_DATA');

export const {
  request: loadSingleQuestionnaireRequest,
  success: loadSingleQuestionnaireSuccess,
  failure: loadSingleQuestionnaireFailure,
} = createAsyncActions<string, QuestionnaireObj, unknown>('LOAD_SINGLE_QUESTIONNAIRE');

export const {
  request: loadQInvitationsRequest,
  success: loadQInvitationsSuccess,
  failure: loadQInvitationsFailure,
} = createAsyncActions<void, QInvitation[], unknown>('LOAD_Q_INVITATION');

export const {
  request: sendQInvitationRequest,
  success: sendQInvitationSuccess,
  failure: sendQInvitationFailure,
} = createAsyncActions<QInvitation[], QInvitation[], unknown>('SEND_Q_INVITATION');

export const {
  request: deleteQInvitationRequest,
  success: deleteQInvitationSuccess,
  failure: deleteQInvitationFailure,
} = createAsyncActions<string, boolean, unknown>('DELETE_Q_INVITATION');

export const {
  request: loadAllAssignedQsRequest,
  success: loadAllAssignedQsSuccess,
  failure: loadAllAssignedQsFailure,
} = createAsyncActions<void, AssignedQuestionnaire[], unknown>('LOAD_ALL_ASSIGNED_QUESTIONNAIRES');

// export const {
//   request: loadInvitationReportRequest,
//   success: loadInvitationReportSuccess,
//   failure: loadInvitationReportFailure,
// } = createAsyncActions<string, string, unknown>('LOAD_INVITATION_REPORT');
