import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PatientContactList } from './components/PatientContactList';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../../common/domain/entities/StyleSheet';
import { QuestionnaireObj } from '../../../questionnairesContext/domain/entities/QuestionnaireObj';
import { AppButton } from '../../../common/ui/Others/AppButton';
import { AppDropDownMenu } from '../../../common/ui/Others/AppDropDownMenu';
import { AppAlert } from '../../../common/ui/Others/AppAlert';
import { InvitationStatus, QInvitation } from '../../../questionnairesContext/domain/entities/QInvitation';
import { useFeedback } from '../../../contexts/feedback/store';
import Messaging from '../../../core/components/messaging';
import { PatientContact } from '../../domain/entities/PatientContact';
import { useHcpContext } from '../../../contexts/doctor/store';
import { Patient } from '../../domain/entities/Patient';
import { AssignedQuestionnaire } from '../../../questionnairesContext/domain/entities/AssignedQuestionnaire';

export const AssignQuestionnaireDumb = () => {
  // hooks
  const { t } = useTranslation();
  const { getFeedbackIfNecessary } = useFeedback();
  const { getContacts, getContent, getPatient, getAssignedQuestionnaires, createContactInvitation, deleteContact } =
    useHcpContext();
  const { patientuuid } = useParams<{ patientuuid: string }>();
  const { push, goBack } = useHistory();

  // states
  const [selectedQsTitle, setSelectedQsTitle] = useState<string>();
  const [selectedPatientContacts, setSelectedPatientContacts] = useState<PatientContact[]>([]);
  const [showError, setShowError] = useState<boolean>(false);
  const [patientContactToDelete, setPatientContactToDelete] = useState<PatientContact>();
  const [allQuestionnaires, setAllQuestionnaires] = React.useState<QuestionnaireObj[]>([]);
  const [patientContacts, setPatientContacts] = React.useState<PatientContact[]>([]);
  const [questionnaires, setAssignedQuestionnaire] = React.useState<AssignedQuestionnaire[]>([]);
  const currentPatient = React.useRef<Patient>();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  useEffect(() => {
    getContent(['questionnaires'])
      .then((result) => {
        setAllQuestionnaires(result.questionnaires ?? []);
      })
      .catch(() => {});
    getContacts(patientuuid)
      .then((value) => {
        setPatientContacts(value);
      })
      .catch(() => {});
    getPatient(patientuuid).then((patient) => {
      currentPatient.current = patient;
    });
    getAssignedQuestionnaires(patientuuid).then((result) => {
      setAssignedQuestionnaire(result);
    });
  }, []);

  const patientContactPressed = (pc: PatientContact) => {
    setSelectedPatientContacts((oldSelection) => {
      const foundPC: PatientContact | undefined = oldSelection.find((spc) => spc.uuid === pc.uuid);
      if (foundPC) {
        return oldSelection.filter((spc) => spc.uuid !== pc.uuid);
      }
      return [...oldSelection, pc];
    });
  };

  const newContactPressed = () => {
    push({ pathname: `/Patient-Contact-Creation`, search: `patientuuid=${patientuuid}` });
  };

  const getPresentableTitleForQs = (qs: QuestionnaireObj) =>
    `${qs.title ?? ''}${qs.category !== undefined ? `(${qs.category})` : ''}`;

  const assignQ = async () => {
    if (!selectedQsTitle || selectedPatientContacts.length === 0) {
      setShowError(true);
      return;
    }
    const selectedQuestionnaireUUID = allQuestionnaires.find(
      (q) => q.title !== undefined && selectedQsTitle.includes(q.title),
    )?.uuid;

    if (selectedQuestionnaireUUID === undefined) {
      setShowError(true);
      return;
    }

    const invites: QInvitation[] = selectedPatientContacts.map((contact) => {
      const inv: QInvitation = {
        contact_uuid: contact.uuid ?? '',
        patient_uuid: patientuuid,
        questionnaire_uuid: selectedQuestionnaireUUID,
        answers: [],
        status: InvitationStatus.DRAFTED,
      };
      return inv;
    });
    sendQInvitation(invites);
  };

  const sendQInvitation = (invites: QInvitation[]) => {
    setIsSubmitting(true);
    Promise.all(invites.map((inv) => createContactInvitation(inv).rawPromise()))
      .then(() => {
        Messaging.toast(t('IT_WORKED'));
        getFeedbackIfNecessary('Create questionnaire');
        goBack();
      })
      .catch((err) => alert(`sendQInvitation: caught error sending questionnaire invitation\n ${JSON.stringify(err)}`))
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const deletePatientContact = (pc: PatientContact) => {
    deleteContact(pc)
      .then(() => {
        Messaging.toast(t('IT_WORKED'));
      })
      .catch((err) => alert(`deleting patient failed\n${err}`));
  };
  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <div style={styles.title}>Bitte vervollständigen Sie die Angaben zum neuen Fragebogen:</div>
        <AppDropDownMenu
          label="Fragebogen*"
          list={allQuestionnaires
            .map(getPresentableTitleForQs)
            .sort((x, y) => (x.split('T').length === 2 ? parseInt(x.split('T')[1]) - parseInt(y.split('T')[1]) : 1))}
          shownValue={selectedQsTitle}
          placeholder="Bitte wählen Sie Ihren Fragebogen"
          onChangeFunc={setSelectedQsTitle}
          showError={showError && _.isEmpty(selectedQsTitle)}
        />
        <div style={styles.separator} />
        <PatientContactList
          ignoreSorting
          ignoreFiltering
          allPatientContacts={patientContacts}
          setPatientContactToDelete={setPatientContactToDelete}
          onRowClick={patientContactPressed}
          selectedPatientContacts={selectedPatientContacts}
          newContactPressed={newContactPressed}
          selectable
          questionnaires={questionnaires}
        />
        <div style={styles.separator} />
        <div className="flex-row align-items-center justify-content-center" style={{ columnGap: 30 }}>
          <AppButton label="Abbrechen" color={Color.MONOGREY6} strokeOnly action={() => goBack()} />
          <AppButton
            isLoading={isSubmitting}
            label="Senden"
            color={Color.MONOGREY6}
            action={assignQ}
            loadingIndicatorColor="#FFF"
          />
        </div>
      </div>
      <AppAlert
        isOn={!!patientContactToDelete}
        content={
          <div style={{ padding: AppSizes.SIZE3, paddingTop: AppSizes.SIZE4 }}>
            <p>
              Sind Sie sicher, dass Sie den Kontakt
              {` “${patientContactToDelete?.email}” `}
              löschen wollen?
            </p>
            <p>Die Daten lassen sich nicht wiederherstellen.</p>
          </div>
        }
        close={() => {
          setPatientContactToDelete(undefined);
        }}
        buttons={[
          {
            label: 'Abbrechen',
            action: () => {
              setPatientContactToDelete(undefined);
            },
            strokeOnly: true,
          },
          {
            label: 'Löschen',
            action: () => {
              patientContactToDelete && deletePatientContact(patientContactToDelete);
              setPatientContactToDelete(undefined);
            },
          },
        ]}
      />
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    padding: AppSizes.SIZE1,
  },
  innerContainer: {
    marginTop: AppSizes.SIZE2,
    justifyContent: 'space-evenly',
    maxWidth: '40vw',
    alignSelf: 'center',
    width: '100%',
  },
  title: {
    marginBottom: AppSizes.SIZE2,
    fontWeight: FontWeight.BOLD,
    fontSize: FontSize.H4,
    textAlign: 'center',
    width: '100vw',
    alignSelf: 'center',
    maxWidth: 600,
  },
  separator: {
    height: 1,
    background: Color.MONOGREY6,
    margin: `${AppSizes.SIZE1}px ${AppSizes.SIZE1}px`,
  },
};
