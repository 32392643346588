export const TermsAndConditionsContent = () => (
  <div
    style={{
      textAlign: 'justify',
      textJustify: 'inter-word',
    }}
  >
    <h2>Datenschutzerklärung Medigital expert</h2>
    <p>Letzte Aktualisierung der Datenschutzerklärung:16.08.2024</p>
    <h2>Orientierungshilfe</h2>

    <p>
      Der Schutz Ihrer personenbezogenen Daten ist uns ein großes Anliegen. Mit dieser Datenschutzerklärung möchten wir
      Sie über Umfang und Zweck der von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten informieren.{' '}
    </p>
    <p>Eine Übersicht der einzelnen Kapitel zur besseren Orientierung finden Sie hier:</p>
    <ol>
      <li>
        <strong>Präambel</strong> - Hier finden Sie eine kurze Übersicht zum Inhalt der Applikation und den
        Datenschutzthemen.
      </li>
      <li>
        <strong>Kontakt</strong> - Wie können Sie einfach und schnell mit uns Kontakt aufnehmen?
      </li>
      <li>
        <strong>Datenverarbeitung und -speicherung</strong> - Welche Ihrer Daten werden wie, wozu, wo und wie lange von
        uns gespeichert?
      </li>
      <li>
        <strong>Rechtsgrundlage</strong> - Auf welcher Rechtsgrundlage verarbeiten wir Ihre Daten?
      </li>
      <li>
        <strong>Datenübermittlung</strong> - An welche Dienste und andere Dritte werden Ihre Daten übermittelt und diese
        verarbeitet?
      </li>
      <li>
        <strong>Datensicherheit</strong> - Was tun wir, um Ihre Daten größtmöglich zu schützen?
      </li>
      <li>
        <strong>Ihre Rechte</strong> - Hier finden Sie einen Überblick zu all Ihren Rechten als Betroffene.
      </li>
    </ol>
    <h3>1. Präambel</h3>
    <p>
      Medigital expert ist ein im Auftrag der Medigital GmbH entwickeltes eCRF-System (electronic Case Report Form) /
      eine Software für elektronische Fallberichte zur Durchführung und Dokumentation von klinischen Studien. Die
      Studienteilnehmer erhalten per E-Mail einen Link zu den jeweiligen elektronischen Fragenbögen, in denen die im
      Studienprotokoll festgelegten Daten erfasst werden. Dabei werden im Rahmen der Kontaktaufnahme und Datenerhebung
      nach Einwilligung zur
    </p>
    <p>
      Studienteilnahme werden von Ihnen und Ihrem Kind verschiedene personenbezogene Daten erhoben. Bei diesen Daten
      handelt es sich u.a. um Gesundheitsdaten im Sinne der Datenschutzgrundverordnung (DSGVO) (Art. 9), die gesetzlich
      besonders geschützt sind. Nähere Informationen dazu finden Sie in der{' '}
      <a href="https://static.sleep.eyelevel.dev/20240821_hiPanya_Studieninformation_fuer_Erwachsene_V3.0.pdf">
        {' '}
        Studieninformation
      </a>
      .
    </p>
    <h3>2. Kontakt</h3>
    <p>
      <strong>Verantwortlicher</strong> iSd. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (nachfolgend „
      <strong>DSGVO</strong>“), des Bundesdatenschutzgesetzes (nachfolgend „<strong>BDSG</strong>“) sowie sonstiger
      datenschutzrechtlicher Bestimmungen ist die:
    </p>
    <p>
      Medigital GmbH
      <br />
      Medice-Allee 1<br />
      58638 Iserlohn
      <br />
      E-Mail: <a href="info-medigital@medice.de">info-medigital@medice.de</a>
    </p>
    <p>
      <strong>Vertretungsberechtigte Person</strong>
      <br />
      Dr. Felix Lambrecht
      <br />
      <strong>Interne Datenschutzbeauftragte:</strong>
      <br />
      Sollten Sie Fragen zu unseren Datenschutzmaßnahmen, zur Verarbeitung Ihrer Daten oder bezüglich der Wahrung Ihrer
      Betroffenenrechte haben, erreichen Sie unsere Datenschutzbeauftragte wie folgt:
      <br />
      <br />
      Medigital GmbH
      <br />
      Datenschutz
      <br />
      Medice-Allee 1<br />
      58638 Iserlohn
      <br />
      Telefon: +49 (0)2371 937 0<br />
      E-Mail: <a href="email:privacy-medigital@medice.de">privacy-medigital@medice.de</a>
    </p>
    <h3>3. Datenverarbeitung und -speicherung</h3>

    <strong>Kontaktaufnahme</strong>
    <p>
      Bei der Kommunikation mit Ihnen verarbeiten wir personenbezogene Daten zum Zwecke der Kontaktaufnahme und
      Übermittlung der Links zu den Studienfragefragebögen. Im Rahmen der damit verbundenen technische Administration
      speichern und verarbeiten wir Ihre Kontaktdaten (E- Mail-Adresse) in Medigital expert.
    </p>
    <strong>E-Mails an Studienteilnehmer</strong>
    <p>
      Für den Versand von transaktionalen E-Mails wie Bestätigungen, Links zu den Studienfragebögen und andere
      Benachrichtigungen setzen wir den Versanddienstleister Malgun ein. Dabei werden die E-Mail-Adressen unserer Nutzer
      verarbeitet. Mailgun speichert und verarbeitet diese Daten auf Servern innerhalb der Europäischen Union und
      entspricht den Anforderungen der DSGVO. Der Versand transaktionaler E-Mails erfolgt über den Versanddienstleister
      Sinch Email. Anbieter ist Sinch Email, Frankreich. 4 rue Jules Lefebvre, 75009 Paris.
      <br />
      Zweck der Datenverarbeitung ist die Zusendung von Newslettern. Mailgun kann dabei Ihre Daten in pseudonymer Form
      (ohne Zuordnung zu einem Nutzer), zur Optimierung oder Verbesserung der eigenen Services nutzen, z.B. zur
      technischen Optimierung des Versandes und der Darstellung der Newsletter oder für statistische Zwecke verwenden.
      Sie werden dabei weder von Mailgun selbst angeschrieben, noch werden Ihre Daten an Dritte weitergegeben. Die
      Verwendung des Versanddienstleisters erfolgt auf Grundlage eines Auftragsverarbeitungsvertrages gem. Art. 28
      DSGVO.
      <br />
      Wenn Sie keine Analyse mittels Mailgun wünschen, müssen Sie den Newsletter abbestellen. Hierfür stellen wir in
      jeder Newsletternachricht einen entsprechenden Link zur Verfügung. Nähere Informationen zu Datenschutzbestimmungen
      von Mailgun finden Sie unter:{' '}
      <a href="https://www.mailgun.com/de/rechtliches/datenschutzerklaerung">
        https://www.mailgun.com/de/rechtliches/datenschutzerklaerung
      </a>
    </p>
    <strong>Ort der Verarbeitung</strong>
    <p>
      Die in den Studienfragebögen eingegebenen personenbezogenen Daten (einschließlich Gesundheitsdaten), sowie Ihre
      Kontaktdaten (E-Mail-Adresse) werden auf zugriffsgesicherten Servern der Medigital GmbH in Deutschland
      verarbeitet. Zugriff- und Bearbeitungsrechte zu den zu verarbeitenden Dateien haben ausschließlich festgelegte,
      berechtigte Mitarbeiter der Medigital GmbH.
    </p>
    <strong>Hosting durch Webflow</strong>
    <p>
      Wir hosten Medigital expert bei Webflow. Anbieter ist die Webflow, Inc., 398 11th Street, 2nd Floor, San
      Francisco, CA 94103, USA (nachfolgend: Webflow). Wenn Sie unsere Website besuchen, erfasst Webflow verschiedene
      Logfiles inklusive Ihrer IP-Adressen. 3 / 10 Webflow ist ein Tool zum Erstellen und zum Hosten von Websites.
    </p>
    <p>
      Webflow speichert Cookies oder sonstige Wiedererkennungstechnologien, die für die Darstellung der Seite, zur
      Bereitstellung bestimmter Webseitenfunktionen und zur Gewährleistung der Sicherheit erforderlich sind (notwendige
      Cookies). Details entnehmen Sie der Datenschutzerklärung von Webflow:{' '}
      <a href="https://webflow.com/legal/eu-privacy-policy">https://webflow.com/legal/eu-privacy-policy</a>.
    </p>
    <p>
      Die Verwendung von Webflow erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f) DSGVO. Wir haben ein berechtigtes
      Interesse an einer möglichst zuverlässigen Darstellung. Sofern eine entsprechende Einwilligung abgefragt wurde,
      erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a) DSGVO und § 25 Abs. 1 TTDSG,
      soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z.
      B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
    </p>
    <p>
      Die Einwilligung ist jederzeit widerrufbar. Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
      der EU-Kommission gestützt. Details finden Sie hier:{' '}
      <a href="https://webflow.com/legal/eu-privacy-policy">https://webflow.com/legal/eu-privacy-policy</a>
    </p>
    <strong>Speicher- und Löschfristen</strong>
    <p>
      Die in Medigital expert erhobenen personenbezogenen Daten von ihnen und Ihrem Kind (inkl. Gesundheitsdaten) werden
      in pseudonymisierter Form für die weitere Verarbeitung und Auswertung auf die zugriffsgesicherten Server der
      MEDICE Arzneimittel Pütter GmbH & Co. KG, als Mutterkonzern der Medigital GmbH in Deutschland, übertragen und
      gespeichert. Die in Medigital expert erhobenen Daten werden hiernach, mit Erfüllung des Erhebungszwecks manuell
      durch die Medigital GmbH gelöscht.
    </p>
    <h3>4. Rechtsgrundlage</h3>
    <p>
      Die Rechtsgrundlagen für die Verarbeitung Ihrer personenbezogenen Daten bildet Ihre informierte, freiwillige
      Einwilligung nach Art. 6 Abs. 1 lit. a) und Art. 9 Abs. 2 lit. a) DSGVO.
    </p>
    <h3>5. Datenübermittlung</h3>
    <strong>An folgende Dritte Parteien können die personenbezogenen Daten übermittelt werden:</strong>
    <ul>
      <li>
        Zuständige Behörden: Unter gewissen Umständen könnten betreffende Daten bei einem Angriff auf unsere Systeme,
        zur Erfüllung von Anforderungen der nationalen Sicherheit oder zur Strafverfolgung an die
        Strafverfolgungsbehörden weitergegeben werden.
      </li>
      <li>Auftragsverarbeiter der Medigital GmbH (Webflow, Inc., Mailgun)</li>
    </ul>
    <p>
      Im Rahmen der in dieser Datenschutzerklärung beschriebenen Verarbeitungsvorgänge ist es möglich, dass die
      personenbezogenen Daten in die USA übermittelt bzw. dort verarbeitet werden. Die Medigital GmbH setzt nur
      Unternehmen aus den USA ein, welche über ein angemessenes Datenschutzniveau verfügen, sofern sie sich unter dem
      EU-US Data Privacy Framework zertifiziert haben und somit der Angemessenheitsbeschluss der EU-Kommission gem. Art.
      45 DSGVO greift.
    </p>
    <p>
      Um Ihre Daten zu schützen und uns gegebenenfalls eine Datenübermittlung in Drittländer (außerhalb der EU/des EWR)
      zu ermöglichen, haben wir darüber hinaus Vereinbarungen zur Auftragsverarbeitung auf Grundlage der
      Standardvertragsklauseln der Europäischen Kommission abgeschlossen.
    </p>
    <p>
      Die Medigital GmbH versichert Ihre Daten nur an Stellen weiterzugeben, die ein geeignetes Datenschutzkonzept
      entsprechend den geltenden Verordnungen und Gesetzen vorweisen können und mit denen entsprechende vertragliche
      Vereinbarungen bestehen.
    </p>
    <h3>6. Datensicherheit</h3>
    <p>
      Die Sicherheit Ihrer persönlichen Informationen und der Ihres Kindes ist uns sehr wichtig. Wir verarbeiten daher
      die von Ihnen angegebenen Daten und die Ihres Kindes nur mit Ihrer ausdrücklichen Einwilligung.
    </p>
    <p>
      Bei jeder Erhebung, Speicherung, Nutzung und Übermittlung von Daten bestehen Vertraulichkeitsrisiken (z.B. die
      Möglichkeit, die betreffende Person zu identifizieren). Diese Risiken lassen sich nicht völlig ausschließen und
      steigen, je mehr Daten miteinander verknüpft werden können. Die Medigital GmbH versichert Ihnen, alles nach dem
      Stand der Technik Mögliche zum Schutz der Übermittlung Ihrer Daten zu tun.
    </p>
    <strong>Hierfür ergreifen wir unter anderem folgende technischen und organisatorischen Maßnahmen:</strong>
    <ul>
      <li>
        <strong>SSL/TLS-Verschlüsselung</strong>: Die personenbezogenen Daten werden nur über, nach dem aktuellen Stand
        der Technik verschlüsselte Verbindungen übertragen. Wir setzen hierbei die geltenden Vorgaben des Bundesamts für
        Sicherheit in der Informationstechnik um und setzen diese Technologie ein, um die Übermittlung Ihrer Daten zu
        schützen.
      </li>
      <li>
        <strong>Strikte Trennung</strong> der Speicherung und Verarbeitung der Gesundheits- und Kontaktdaten mit
        Zugriffsberechtigung nur für autorisierte Mitarbeiter der Medigital GmbH.
      </li>
      <li>
        <strong>Löschung nach Erfüllung des Erhebungszwecks.</strong>
      </li>
      <li>unterschiedliche Passwörter für alle Software-Tools</li>
      <li>Viren-Schutz für die gesamte verwendete IT-Hardware</li>
      <li>Firewall für unser internes Firmennetzwerk</li>
      <li>regelmäßige Schulungen zu Datensicherheit und -schutz für alle Mitarbeiter</li>
      <li>regelmäßige Updates aller Software-Komponenten</li>
      <li>regelmäßige Datensicherung, um die Verfügbarkeit zu gewährleisten</li>
      <li>regelmäßige Risikoanalysen zu den entsprechenden IT-Systemen</li>
    </ul>
    <h3>7. Ihre Rechte</h3>
    <p>
      Im Rahmen der Verarbeitung Ihrer personenbezogenen Daten ist es unser Ziel Ihre Datenschutzrechte zu jedem
      Zeitpunkt zu gewährleisten. Unsere Servicezeiten, sowie alle Kontaktmöglichkeiten finden Sie unter Punkt 2
      „Kontakt“ der ausführlichen Datenschutzerklärung.
    </p>
    <strong>Folgenden Rechte können Sie in Bezug auf Ihre personenbezogenen Daten wahrnehmen:</strong>
    <ul>
      <li>
        Sie können <strong>Auskunft</strong> über die Verarbeitung Ihrer Daten verlangen.
      </li>
      <li>
        Sie können die <strong>Anpassung</strong> Ihrer personenbezogenen Daten fordern, sofern diese fehlerhaft oder
        unvollständig sind. Für die Dauer der Überprüfung der Richtigkeit Ihrer Daten können Sie zudem die{' '}
        <strong>Einschränkung der Verarbeitung</strong>
        Ihrer personenbezogenen Daten fordern.
      </li>
      <li>
        Sie können Ihre Einwilligung zur Datenverarbeitung jederzeit formlos, ohne Angabe von Gründen{' '}
        <strong>widerrufen</strong>.
      </li>
    </ul>
    <p>
      Bei einem Widerruf entstehen Ihnen keine Nachteile. Der Widerruf gilt dabei mit Wirkung für die Zukunft; die
      bisherigen Datenweitergaben bleiben rechtmäßig. Im Rahmen Ihres Widerrufes wird Ihr Benutzerkonto deaktiviert und
      alle Ihre bisher eingegebenen Daten gelöscht.
    </p>
    <p>
      Ihre Daten zur Einwilligung werden fortan nur noch eingeschränkt durch die Medigital GmbH verarbeitet. Diese
      eingeschränkte Verarbeitung erfolgt aufgrund gesetzlicher Vorgaben nach Art. 6 Abs. 1 lit. c) DSGVO.
    </p>
    <p>
      Falls Sie weitere Fragen zum Umgang mit Ihren personenbezogenen Daten haben oder von Ihren Rechten Gebrauch machen
      möchten, wenden Sie sich an unsere Datenschutzbeauftragte unter:
    </p>
    <p>
      Medigital GmbH
      <br />
      Medice-Allee 1<br />
      58638 Iserlohn
      <br />
      Telefon: +49 (0)2371 937 0<br />
      E-Mail: <a href="privacy-medigital@medice.de">privacy-medigital@medice.de</a>
    </p>
    <ul>
      <li>
        <p>
          Sie können, bei Vorliegen einer Beschwerdegrundlage, eine{' '}
          <strong>Beschwerde bei der zuständigen Datenschutzbehörde einreichen</strong> unter:
        </p>
        <p>
          Landesbeauftragte für Datenschutz und Informationsfreiheit
          <br />
          Nordrhein-Westfalen
          <br />
          Kavalleriestraße 2 - 4<br />
          40213 Düsseldorf
          <br />
          Telefon: 0211 38424-999
          <br />
          E-Mail: <a href="poststelle@ldi.nrw.de">poststelle@ldi.nrw.de</a>
        </p>
        <strong>oder unter:</strong>
        <br />
        <a href="https://www.ldi.nrw.de/kontakt/ihre-beschwerde">
          Ihre Beschwerde | LDI - Landesbeauftragte für Datenschutz und Informationsfreiheit Nordrhein-Westfalen
          (nrw.de)
        </a>
        <br />
        <p>
          Die Kontaktdaten der Datenschutzaufsichtsbehörden aller Bundesländer finden Sie unter folgender
          Internetadresse:{' '}
          <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
          </a>
        </p>
      </li>
    </ul>
  </div>
);
