import { fetchWithToken } from '../../../common/adapter/fetch.helper';
import { BACKEND_CONFIG } from '../../../configuration';
import { QInitialData } from '../../domain/entities/QInitialData';
import { QInvitation } from '../../domain/entities/QInvitation';
import { QuestionnaireObj } from '../../domain/entities/QuestionnaireObj';
import { QuestionnairesInteractor } from '../../domain/gateways/Questionnaires.interactor';
import { HardCodedInitialData } from '../inmemory/InMemoryQuestionnairesInteractor';

export class RealQuestionnairesInteractor implements QuestionnairesInteractor {
  async loadQuestionnaires(): Promise<QuestionnaireObj[]> {
    const url = `${BACKEND_CONFIG.endpoints.questionnaires}`;
    const response = await fetchWithToken(url);
    const doc = await response.json();
    return doc.data;
  }

  loadQInitialData(): Promise<QInitialData> {
    return Promise.resolve(HardCodedInitialData);
  }

  async loadSingleQuestionnaire(uuid = 'ADHSFBB'): Promise<QuestionnaireObj> {
    const url = `${BACKEND_CONFIG.endpoints.questionnaires}/${uuid}`;
    const response = await fetchWithToken(url);
    const doc = await response.json();
    return doc.data;
  }

  async loadQInvitations(): Promise<QInvitation[]> {
    const url = `${BACKEND_CONFIG.endpoints.invitations}`;
    const response = await fetchWithToken(url);
    const invitations = await response.json();
    return invitations.data;
  }

  // async loadInvitationReport(inv_uuid: string): Promise<string> {
  //   const url = `${BACKEND_CONFIG.endpoints.invitations}${inv_uuid}/pdf-notused!`;
  //   const response = await fetchWithToken(url);
  //   if (response.ok) {
  //     const resp = await response.json();
  //     return resp.redirect_to;
  //   }
  //   throw new Error('nope');
  // }

  async sendQInvitation(invites: QInvitation[]): Promise<QInvitation[]> {
    const url = `${BACKEND_CONFIG.endpoints.invitations}`;
    const results = await Promise.all(
      invites.map(async (inv) => {
        const objectToSend = {
          invitation: inv,
        };
        const response = await fetchWithToken(url, {
          method: 'POST',
          body: JSON.stringify(objectToSend),
        });
        const res = await response.json();
        return res.data;
      }),
    );
    return results;
  }

  async deleteQInvitation(invUuid: string): Promise<boolean> {
    const url = `${BACKEND_CONFIG.endpoints.invitations}/${invUuid}`;
    const response = await fetchWithToken(url, {
      method: 'DELETE',
    });
    if (response.ok) {
      return true;
    }
    return false;
  }
}
