import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AppAlert } from './AppAlert';
import { AppCheckBoxV3 } from './AppCheckBoxV3';
import { AppFormErrorList } from './AppFormErrorList';
import { DU_VERSION_FE, TOS_VERSION_FE } from './TermsAndConditions';
import { AGB_VERSION_FE } from './AgbPage';
import { EMPTY_INPUT_ERROR_TEXT } from '../../helpers/ConstantTexts';
import { AppSizes, Color } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../domain/entities/StyleSheet';
import { RouteName } from '../../../navigationContext/domain/entities/NavigatorTypes';
import { useUser } from '../../../contexts/user/store';
import { CommonNavScreens } from '../../../navigationContext/configuration/commonScreens';
import { User } from '../../../contexts/user/entities/User';

export interface AppAcceptTermsPopUpProps {
  isOn: boolean;
}

export const AppAcceptTermsPopUp = ({ isOn }: AppAcceptTermsPopUpProps) => {
  const [showError, setShowError] = useState<boolean>(false);
  const [tosAccepted, setTosAccepted] = useState<boolean>(true);
  const [dataSecurityAccepted, setDataSecurityAccepted] = useState<boolean>(false);
  const [dataUseAccepted, setDataUseAccepted] = useState<boolean>(false);
  const [userIsOnTermsPage, setUserIsOnTermsPage] = useState<boolean>(false);
  const [formIsValid, setFormIsValid] = useState<boolean>();
  const [formErrorsList, setFormErrorsList] = useState<string[]>();
  const { profile, logout, updateProfile } = useUser();

  const location = useLocation<RouteName>().pathname;

  useEffect(() => {
    CommonNavScreens.forEach((screen) => {
      if (location.includes(screen.routePath)) {
        setUserIsOnTermsPage(true);
      }
    });
  }, [location]);

  useEffect(() => {
    updateErrors();
  }, [showError, tosAccepted, dataSecurityAccepted, dataUseAccepted]);

  const updateErrors = () => {
    const errList: string[] = [];
    (!tosAccepted || !dataSecurityAccepted) && errList.push(EMPTY_INPUT_ERROR_TEXT);
    setFormErrorsList([...errList]);
    setFormIsValid(!errList.length);
  };

  const acceptTerms = () => {
    if (!profile) {
      return;
    }
    const updatedUser: User = { ...profile };
    updateProfile({
      ...updatedUser,
      tos_version: TOS_VERSION_FE,
      data_security_version: AGB_VERSION_FE,
      data_use_version: DU_VERSION_FE,
    })
      .then(() => {
        /** no need to do anything */
      })
      .catch((err) => {
        alert(`acceptTermsAsync: Caught error accepting terms\n${JSON.stringify(err)}`);
      });
  };
  const sendSignOut = () => {
    logout();
  };

  return (
    <AppAlert
      isOn={isOn && !userIsOnTermsPage}
      content={
        <div style={styles.boxContent}>
          <p>
            span Es gibt eine neue Version der AGB/Datenschutzerklärung. Bitte lesen Sie diese durch und akzeptieren
            sie, wenn Sie damit einverstanden sind.
          </p>
          <AppCheckBoxV3
            hidden
            topLabel="AGB*"
            labelJSX={
              <p style={{ margin: 0 }}>
                Ich akzeptiere die{' '}
                <Link
                  to="AGB"
                  target="_blank"
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: tosAccepted ? Color.APPWHITE : Color.APPBLACK,
                  }}
                  onClick={() => {
                    setTosAccepted((v) => !v);
                  }}
                >
                  AGB
                  <br />
                </Link>
              </p>
            }
            checked={tosAccepted}
            action={() => {
              setTosAccepted((v) => !v);
            }}
            errorShown={showError && !tosAccepted}
          />
          <AppCheckBoxV3
            topLabel="Datenschutz*"
            labelJSX={
              <p style={{ margin: 0 }}>
                Ich akzeptiere die{' '}
                <Link
                  to="Datenschutzerklärung"
                  target="_blank"
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: dataSecurityAccepted ? Color.APPWHITE : Color.APPBLACK,
                  }}
                  onClick={() => {
                    setDataSecurityAccepted((v) => !v);
                  }}
                >
                  Datenschutzerklärung
                  <br />
                </Link>
              </p>
            }
            checked={dataSecurityAccepted}
            action={() => {
              setDataSecurityAccepted((v) => !v);
            }}
            errorShown={showError && !dataSecurityAccepted}
          />
          <AppCheckBoxV3
            topLabel="Datennutzung"
            labelJSX={
              <p style={{ margin: 0 }}>
                Ich akzeptiere, dass meine Daten zum Zwecke der dauerhaften Gewährleistung der technischen
                Funktionsfähigkeit, der Nutzerfreundlichkeit und der Weiterentwicklung der Applikation verarbeitet
                werden.
              </p>
            }
            checked={dataUseAccepted}
            action={() => {
              setDataUseAccepted((v) => !v);
            }}
            errorShown={false}
          />
          <AppFormErrorList formErrorsList={formErrorsList ?? []} shown={showError} />
        </div>
      }
      buttons={[
        {
          label: 'Ausloggen',
          strokeOnly: true,
          action: sendSignOut,
        },
        {
          label: 'Akzeptieren',
          disabled: !tosAccepted || !dataSecurityAccepted,
          action: () => {
            setShowError(true);
            formIsValid && acceptTerms();
          },
        },
      ]}
    />
  );
};

const styles: StyleSheet = {
  boxContent: {
    height: '100%',
    margin: AppSizes.SIZE5,
  },
};
