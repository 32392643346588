import React from 'react';
import { clamp } from './handy';

function useClamp<T extends number | number[]>({
  initialValue,
  min,
  max,
}: {
  initialValue?: T;
  min?: number;
  max?: number;
}): [T | undefined, (value: T | undefined) => void] {
  const [clampedValue, setClampedValue] = React.useState<T | undefined>(initialValue);

  const singleValueClamp = (value: T) => {
    const signleValue = value as number;
    const result = clamp(signleValue, min === undefined ? signleValue : min, max === undefined ? signleValue : max);
    setClampedValue(result as T);
  };

  const multipleValueClamp = (value: T) => {
    if (Array.isArray(value)) {
      const result = value.map((val) => clamp(val, min || val, max || val));
      setClampedValue(result as T);
    }
  };

  const setValue = (value: T | undefined) => {
    if (typeof value === 'number') {
      singleValueClamp(value);
    } else if (typeof value === 'undefined') {
      setClampedValue(undefined);
    } else {
      multipleValueClamp(value);
    }
  };

  return [clampedValue, setValue];
}

export default useClamp;
