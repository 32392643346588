import { hardCodedPatients } from './Patient';
import { PcRoleEn } from './PatientContactRole';

export interface PatientContact {
  uuid?: string;
  first_name: string;
  last_name: string;
  email: string;
  patient_uuid: string;
  role: string;
  relationship: string;
  otherRole?: string;
  updated_at?: string;
  created_at?: string;
}

export const hardcodedPatientContacts: PatientContact[] = [
  {
    uuid: 'U-001',
    first_name: 'Led',
    last_name: 'Zep',
    email: 'ledzep@example.com',
    patient_uuid: hardCodedPatients[0].uuid ?? '',
    role: '',
    relationship: '',
  },
  {
    uuid: 'U-002',
    first_name: 'Jimmy',
    last_name: 'Hendrix',
    email: 'Hendrix@example.com',
    patient_uuid: hardCodedPatients[1].uuid ?? '',
    role: PcRoleEn.TEACHER,
    relationship: '',
  },
  {
    uuid: 'U-003',
    first_name: 'Nirvana',
    last_name: 'Just Nirvana',
    email: 'nirvana@example.com',
    patient_uuid: hardCodedPatients[0].uuid ?? '',
    role: PcRoleEn.PARENT,
    relationship: '',
  },
  {
    uuid: 'U-004',
    first_name: 'Carl',
    last_name: 'Young',
    email: 'young.carl@example.com',
    patient_uuid: hardCodedPatients[0].uuid ?? '',
    role: PcRoleEn.TEACHER,
    relationship: '',
  },
  {
    uuid: 'U-005',
    first_name: 'Max',
    last_name: 'Mustermann',
    email: 'mustermann@gmail.com',
    patient_uuid: hardCodedPatients[5].uuid ?? '',
    role: PcRoleEn.PARENT,
    relationship: '',
  },
];

export const defaultEmptyPatientContact: PatientContact = {
  first_name: '',
  last_name: '',
  email: '',
  patient_uuid: '',
  role: '',
  relationship: '',
};
