import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FetchiError } from 'fetchi-request';
import { useTranslation } from 'react-i18next';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { useUser } from '../../../contexts/user/store';
import { Footer } from '../../../navigationContext/ui/components/Footer';
import TopBar from '../../../questionnairesContext/ui/TopBar';
import { LoadingCircle } from '../../../assets/svg/LoadingCircle';
import { FontSize, FontWeight } from '../../../assets/styles/constantStyles';

interface RouterProps {
  key?: string;
}

interface EmailActivationProps {
  navigateTo: (p: NavigationPayload) => void;
}

export const EmailActivationDumb = ({ navigateTo }: EmailActivationProps) => {
  const [showError, setShowError] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);
  const { emailVerify } = useUser();
  const { t } = useTranslation();
  const { key } = useParams<RouterProps>();

  useEffect(() => {
    if (key) {
      sendEmailActivationCode(key);
    } else {
      navigateTo({ routeName: 'Sign-In' });
    }
  }, [key]);

  const sendEmailActivationCode = (code: string) => {
    if (!code || code.length === 0) {
      return;
    }
    setIsPending(true);
    emailVerify(code)
      .then(() => {
        navigateTo({ routeName: 'User-Personal-Data' });
      })
      .catch((error: FetchiError) => {
        if (error.status === 402) {
          // setShowAppAlert(true);
        } else if (error.status === 404) {
          // setWrongActivationCode(code);
          setShowError(true);
        } else {
          alert(`Error sendEmailActivationCode`);
        }
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  return (
    <div className="full-screen-container">
      <div className="container-fluid" style={{ flex: 1 }}>
        <TopBar />
        <div className="row justify-content-md-center mt-5">
          <div className="col-md-4 align-self-center justify-content-center align-items-center text-center">
            {isPending && (
              <>
                <p>{t('PLEASE_WAIT')}</p>
                <LoadingCircle />
              </>
            )}
            {showError && (
              <p style={{ fontSize: FontSize.H5 }}>
                <p>
                  <span className="text-center text-bold" style={{ fontWeight: FontWeight.BOLD }}>
                    Da ist leider etwas schiefgelaufen!
                  </span>
                </p>
                <br />
                <p>
                  Das hätte nicht passieren sollen. Bitte{' '}
                  <span>
                    <Link to="/Sign-Up" type="link">
                      registrieren
                    </Link>
                  </span>{' '}
                  Sie sich noch einmal.
                </p>
                Sollte das Problem weiterhin bestehen, kontaktieren Sie bitte unseren Support.
              </p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
