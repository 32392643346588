import { NavLink as Link } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { CommonNavScreens } from '../../configuration/commonScreens';

export const Footer = () => (
  <footer
    className="px-3 d-flex flex-wrap justify-content-between align-items-center py-3 mt-4 border-top"
    style={{ backgroundColor: '#F1F1F1' }}
  >
    <ul className="nav col-md-4 justify-content-start">
      {CommonNavScreens.map((scr, index) => (
        <li key={`footer-link-${index + 1}`} className="nav-item">
          <Link to={`${scr.routePath}`} target="_blank" className="nav-link px-2 text-muted">
            {scr.routePath}
          </Link>
        </li>
      ))}
    </ul>
    <div className="col-md-4 px-1 mb-0 text-muted justify-content-end">
      <p className="text-end mb-0 text-muted justify-content-end">© {new Date().getFullYear()} Medigital GmbH</p>
    </div>
  </footer>
);
