import { go, goBack, push, replace } from 'connected-react-router';
import { Action } from 'redux-actions';
import { all, put, takeLatest } from 'redux-saga/effects';
import {
  navigateBackRequest,
  navigateToFailure,
  navigateToRequest,
  navReplaceAndRemoveRequest,
  navReplaceToRequest,
} from './navigation.actions';
import { NavigationPayload } from '../domain/entities/NavigationPayload';

function* navigateToSaga(action: Action<NavigationPayload>) {
  try {
    // const user: User | undefined = undefined;
    const { routeName, params } = action.payload;
    if (routeName !== undefined) {
      // TODO - cover the matomo tracking from another approach

      // const instance = getMatomoInstance(user?.matomo_status);
      // // const path: Path = { pathname: `/${routeName}`, search: '', hash: '' };
      // instance?.trackPageView({
      //   documentTitle: routeName, // optional
      //   href: routeName, // optional
      // });
      // instance?.trackLink({
      //   href: routeName,
      // });
      // if (user) {
      //   instance?.trackPageView({ documentTitle: routeName });
      //   const data: any[] = [{ uuid: user.uuid }];
      //   instance?.track({
      //     documentTitle: routeName,
      //     href: routeName,
      //     data,
      //   });
      // }
      yield put(push(`/${routeName}`, params));
    }
  } catch (error) {
    yield put(navigateToFailure(error));
  }
}

function* navReplaceAndRemoveSaga(action: Action<NavigationPayload>) {
  try {
    const { routeName, params } = action.payload;
    if (routeName !== undefined) {
      yield put(replace(`/${routeName}`, params));
      yield put(go(-1));
    }
  } catch (error) {
    yield put(navigateToFailure(error));
  }
}

function* navReplaceToSaga(action: Action<NavigationPayload>) {
  try {
    const { routeName, params } = action.payload;
    if (routeName !== undefined) {
      yield put(replace(`/${routeName}`, params));
    }
  } catch (error) {
    yield put(navigateToFailure(error));
  }
}
function* navigateBackSaga() {
  yield put(goBack());
}

export function* navigationSagas() {
  yield all([
    takeLatest(navigateToRequest, navigateToSaga),
    takeLatest(navReplaceAndRemoveRequest, navReplaceAndRemoveSaga),
    takeLatest(navReplaceToRequest, navReplaceToSaga),
    takeLatest(navigateBackRequest, navigateBackSaga),
  ]);
}
