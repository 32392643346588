import { hardcodedUsers, User } from '../../../contexts/user/entities/User';
import { AssignedQuestionnaire } from '../../domain/entities/AssignedQuestionnaire';
import { QInitialData } from '../../domain/entities/QInitialData';
import { QInvitation } from '../../domain/entities/QInvitation';
import { hardCodedQs, QuestionnaireObj } from '../../domain/entities/QuestionnaireObj';
import { QuestionnairesInteractor } from '../../domain/gateways/Questionnaires.interactor';

export const HardCodedInitialData: QInitialData = {
  questionnaire: hardCodedQs[0],
  user: {
    uuid: '',
    first_name: '',
    last_name: '',
    email: '',
    patient_uuid: '',
    role: '',
    relationship: '',
  },
};
export class InMemoryQuestionnairesInteractor implements QuestionnairesInteractor {
  getInvitationWithAccessToken(): Promise<AssignedQuestionnaire | undefined> {
    throw new Error('Method not implemented.');
  }

  loadInvitationReport(): Promise<string> {
    throw new Error('Method not implemented.');
  }

  updateInvitationWithAccessToken(assignedQ: AssignedQuestionnaire): Promise<AssignedQuestionnaire> {
    return Promise.resolve(assignedQ);
  }

  loadQInvitations(): Promise<QInvitation[]> {
    return Promise.resolve([]);
  }

  sendQInvitation(inv: QInvitation[]): Promise<QInvitation[]> {
    return Promise.resolve(inv);
  }

  deleteQInvitation(): Promise<boolean> {
    return Promise.resolve(true);
  }

  loadQuestionnaires(): Promise<QuestionnaireObj[]> {
    return Promise.resolve(hardCodedQs);
  }

  loadQInitialData(): Promise<QInitialData> {
    return Promise.resolve(HardCodedInitialData);
  }

  loadSingleQuestionnaire(): Promise<QuestionnaireObj> {
    return Promise.resolve(hardCodedQs[0]);
  }

  getContactQuestionnaireWithAccessToken(): Promise<QuestionnaireObj> {
    return Promise.resolve(hardCodedQs[0]);
  }

  getDoctorWithAccessToken(): Promise<User> {
    return Promise.resolve(hardcodedUsers[0]);
  }
}
