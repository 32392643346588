import { fetchWithToken } from '../../../common/adapter/fetch.helper';
import { BACKEND_CONFIG } from '../../../configuration';
import { Patient } from '../../domain/entities/Patient';
import { PatientContact } from '../../domain/entities/PatientContact';
import { PatientContactsInteractor } from '../../domain/gateways/PatientContacts.interactor';

export class RealPatientContactsInteractor implements PatientContactsInteractor {
  async loadAllContacts(): Promise<PatientContact[]> {
    const url = `${BACKEND_CONFIG.endpoints.contacts}`;
    const response = await fetchWithToken(url);
    const contactsData = await response.json();
    return contactsData.data;
  }

  async loadPatientContacts(patient: Patient): Promise<PatientContact[]> {
    const url = `${BACKEND_CONFIG.endpoints.patients}/${patient.uuid}/contacts`;
    const response = await fetchWithToken(url);
    const patientContactsData = await response.json();
    return patientContactsData.data;
  }

  async insertPatientContact(newPatientContact: PatientContact): Promise<PatientContact> {
    const url = `${BACKEND_CONFIG.endpoints.patients}/${newPatientContact.patient_uuid}/contacts`;
    const objectToSend = {
      contact: newPatientContact,
    };
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify(objectToSend),
    });
    const res = await response.json();
    return res.data;
  }

  async updatePatientContact(newPatientContact: PatientContact): Promise<PatientContact> {
    const url = `${BACKEND_CONFIG.endpoints.patients}/${newPatientContact.patient_uuid}/contacts//${newPatientContact.uuid}`;
    const objectToSend = {
      contact: newPatientContact,
    };
    const response = await fetchWithToken(url, {
      method: 'PUT',
      body: JSON.stringify(objectToSend),
    });
    const res = await response.json();
    return res.data;
  }

  async deletePatientContact(patientContactToDelete: PatientContact): Promise<PatientContact> {
    const url = `${BACKEND_CONFIG.endpoints.patients}/${patientContactToDelete.patient_uuid}/contacts/${patientContactToDelete.uuid}`;
    const response = await fetchWithToken(url, {
      method: 'DELETE',
    });
    if (response.ok) {
      return patientContactToDelete;
    }
    const res = await response.json();
    return res;
  }
}
