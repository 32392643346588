import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { StyleSheet } from '../../../../common/domain/entities/StyleSheet';
import { AssignedQuestionnaire } from '../../../../questionnairesContext/domain/entities/AssignedQuestionnaire';
import QuestionnaireCommonFunctions from '../../../../common/helpers/QuestionnaireCommonFunctions';
import { AppSizes, BorderRadius, Color, FontSize, FontWeight } from '../../../../assets/styles/constantStyles';
import CommonFunctions from '../../../../common/helpers/CommonFunctions';
import { getGrStatusString } from '../../../../questionnairesContext/domain/entities/QInvitation';
import { LoadingCircle } from '../../../../assets/svg/LoadingCircle';
import DownloadLogo from '../../../../assets/svg/DownloadLogo';
import { PatientRowColumn } from '../../../domain/entities/PatientRowColumn';
// import { PcRoleEn, PcRoleGr } from '../../../domain/entities/PatientContactRole';
// import { getShownGermanRelation } from '../../../domain/entities/ContactPatientRelation';
import { useHcpContext } from '../../../../contexts/doctor/store';

interface AssignedQsRowProps {
  assignedQ: AssignedQuestionnaire;
  onRowClick?: () => void;
}
export const AssignedQsRow = ({ assignedQ, onRowClick }: AssignedQsRowProps) => {
  const { getInvitationReport } = useHcpContext();
  const [reportReady, setReportReady] = useState<boolean>(false);
  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);

  useEffect(() => {
    const status =
      (QuestionnaireCommonFunctions.isQuestionnaireDone(assignedQ) && !!assignedQ.locked) ||
      assignedQ.questionnaire.category === 'ECRF';
    setReportReady(status);
  }, [assignedQ.status]);

  const loadInvitationReport = () => {
    if (assignedQ.uuid) {
      const isCsv = assignedQ.questionnaire.category === 'ECRF';
      getInvitationReport(isCsv, assignedQ.uuid)
        .then((res) => {
          setIsLoadingReport(false);
          CommonFunctions.downloadFile(res, 'resultPdf');
        })
        .catch((err) => {
          alert(`Error loadInvitationReportAsync\n${JSON.stringify(err)}`);
        });
    }
  };

  const singleCol = (colTitle: string, colRows: PatientRowColumn[], withBorderRight?: boolean) => (
    <div style={{ ...styles.columnContainer, ...(withBorderRight && { borderRight: `1px solid${Color.PUREWHITE}` }) }}>
      <div style={{ ...styles.columnRow, ...(colTitle && { flexDirection: 'column' }) }}>
        <div style={styles.columnTitle}>{colTitle}&nbsp;</div>
      </div>
      <div style={styles.columnRowsContainer}>
        {colRows.map((row, i) => (
          <div key={`col-${i + 1}`} style={styles.columnRow}>
            <p style={{ ...styles.columnCell, ...(row.gotNew && { fontWeight: FontWeight.BOLDER }) }}>
              {row.columnLabel}
            </p>
            <p style={{ ...styles.columnCell, ...(row.gotNew && { fontWeight: FontWeight.BOLDER }) }}>
              {row.columnValue}
              {row.gotNew !== 0 && <span style={styles.newTag}>Neu</span>}
            </p>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div
      style={{
        ...styles.container,
      }}
    >
      <button
        type="button"
        style={{ ...styles.infoSide, ...(onRowClick && { cursor: 'pointer' }) }}
        onClick={onRowClick}
      >
        <div style={styles.rowTitleContainer}>
          {assignedQ.questionnaire?.title}
          {assignedQ.questionnaire.category !== undefined ? `(${assignedQ.questionnaire.category})` : ''}
        </div>
        <div style={styles.infoTable}>
          {singleCol(
            'Kontaktangaben',
            [
              // {
              //   columnLabel: 'Name:',
              //   columnValue: assignedQ.patientContact?.first_name || '-',
              //   gotNew: 0,
              // },
              // {
              //   columnLabel: 'DISYPS-Form:',
              //   columnValue: assignedQ.patientContact?.role === PcRoleEn.PARENT ? PcRoleGr.PARENT : PcRoleGr.TEACHER,
              //   gotNew: 0,
              // },
              // {
              //   columnLabel: 'Art des Kontakts:',
              //   columnValue: assignedQ.patientContact?.relationship
              //     ? getShownGermanRelation(assignedQ.patientContact.relationship)
              //     : '',
              //   gotNew: 0,
              // },
              {
                columnLabel: 'E-Mail-Adresse:',
                columnValue: assignedQ.patientContact?.email || '',
                gotNew: 0,
              },
              {
                columnLabel: 'Erstellt/Gesendet:',
                columnValue: assignedQ.created_at ? CommonFunctions.convertToAppDate(assignedQ.created_at) : '',
                gotNew: 0,
              },
            ],
            true,
          )}

          {singleCol(
            'FBB-Status',
            [
              {
                columnLabel: 'Status:',
                columnValue: getGrStatusString(assignedQ.status),
                gotNew: QuestionnaireCommonFunctions.getQsNotSeen([assignedQ]).length,
              },
              {
                columnLabel: 'Letzte Änderung:',
                columnValue: assignedQ.updated_at ? CommonFunctions.convertToAppDate(assignedQ.updated_at) : '',
                gotNew: QuestionnaireCommonFunctions.getQsNotSeen([assignedQ]).length,
              },
            ],
            false,
          )}
        </div>
      </button>
      <div style={styles.buttonsSide}>
        <motion.button
          whileHover={{ background: 'rgba(0,0,0,.1)' }}
          type="button"
          style={{
            ...styles.rowButton,
            paddingRight: isLoadingReport ? 25 : AppSizes.SIZE1,
            ...((!reportReady || isLoadingReport) && {
              opacity: 0.3,
            }),
          }}
          disabled={!reportReady || isLoadingReport}
          onClick={() => {
            setIsLoadingReport(true);
            loadInvitationReport();
          }}
        >
          <div style={styles.iconButton}>
            {isLoadingReport && <LoadingCircle size={20} />}
            {!isLoadingReport && <DownloadLogo />}
          </div>
          Auswertung
        </motion.button>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    background: Color.MONOGREY3,
    marginBottom: AppSizes.DEFAULT,
    borderRadius: BorderRadius.R5,
    border: '1px solid transparent',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  infoSide: {
    width: '85%',
  },
  iconButton: {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: `${AppSizes.SIZE1}px`,
    height: `${AppSizes.SIZE1}px`,
  },
  buttonsSide: {
    borderLeft: `1px solid${Color.PUREWHITE}`,
    padding: `${AppSizes.DEFAULT}px ${AppSizes.SIZE1}px`,
    flex: 1,
    justifyContent: 'space-evenly',
    alignItems: 'end',
  },
  infoTable: {
    flexDirection: 'row',
    width: '100%',
    borderTop: `1px solid${Color.PUREWHITE}`,
  },
  rowTitleContainer: {
    fontWeight: FontWeight.BOLDER,
    fontSize: FontSize.P1,
    marginLeft: AppSizes.SIZE2,
    height: AppSizes.SIZE5,
    justifyContent: 'center',
    width: '100%',
    textAlign: 'left',
  },
  columnContainer: {
    width: '50%',
    overflow: 'hidden',
    padding: `${AppSizes.SIZE1}px ${AppSizes.SIZE2}px`,
  },
  columnTitle: {
    fontWeight: FontWeight.REGULAR,
    fontSize: FontSize.P3,
    width: '100%',
    color: Color.MONOGREY10,
    textAlign: 'left',
  },
  columnRowsContainer: {
    width: '100%',
  },
  columnRow: {
    flexDirection: 'row',
    width: '100%',
  },
  columnCell: {
    fontSize: FontSize.P3,
    fontWeight: FontWeight.REGULAR,
    minWidth: '50%',
    margin: '0.24em 0',
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'left',
  },
  rowButton: {
    fontWeight: FontWeight.REGULAR,
    fontSize: FontSize.P4,
    borderRadius: AppSizes.SIZE4,
    border: `1px solid${Color.APPBLACK}`,
    padding: `${4}px ${AppSizes.SIZE1}px`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    columnGap: 8,
    background: 'rgba(0, 0, 0, 0)',
    transition: 'all 300ms',
    position: 'relative',
  },
  newTag: {
    position: 'absolute',
    display: 'inline-flex',
    background: Color.MONOGREY6,
    borderRadius: AppSizes.SIZE1,
    lineHeight: '1.5em',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 4px',
    fontSize: 10,
    fontWeight: FontWeight.REGULAR,
    marginLeft: AppSizes.SIZE1,
    top: 3,
    color: Color.APPWHITE,
  },
};
