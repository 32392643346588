import { createAsyncActions, createTypedAction } from '../../common/usecases/actions.helper';
import { NavigationPayload } from '../domain/entities/NavigationPayload';

// export const navigateTo = createTypedAction<NavigationPayload>('NAVIGATE_TO')
export const navigateBackRequest = createTypedAction<void>('NAVIGATE_BACK');
// export const popToTop = createTypedAction<null>('POP_TO_TOP')

export const {
  request: navigateToRequest,
  success: navigateToSuccess,
  failure: navigateToFailure,
} = createAsyncActions<NavigationPayload, string, unknown>('NAVIGATE_TO');

export const {
  request: navReplaceToRequest,
  success: navReplaceToSuccess,
  failure: navReplaceToFailure,
} = createAsyncActions<NavigationPayload, string, unknown>('NAVIGATE_REPLACE_TO');

export const {
  request: navReplaceAndRemoveRequest,
  success: navReplaceAndRemoveSuccess,
  failure: navReplaceAndRemoveFailure,
} = createAsyncActions<NavigationPayload, string, unknown>('NAVIGATE_REPLACE_AND_REMOVE');
