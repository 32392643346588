import { motion } from 'framer-motion';
import { StyleSheet } from '../../../../common/domain/entities/StyleSheet';
import { AppSizes, Color, FontSize, FontWeight } from '../../../../assets/styles/constantStyles';
import { PatientRowColumn } from '../../../../patientsContext/domain/entities/PatientRowColumn';
import { User } from '../../../../contexts/user/entities/User';

interface UserSettingsPageRowProps {
  connectedUser: User | undefined;
  editClicked: () => void;
}
export const UserSettingsPageRow = ({ connectedUser, editClicked }: UserSettingsPageRowProps) => {
  const genderTranslations: { [x in string]: string } = {
    male: 'männlich',
    female: 'weiblich',
    divers: 'divers',
  };
  const singleCol = (colTitle: string, colRows: PatientRowColumn[], withBorderRight?: boolean) => (
    <div style={{ ...styles.columnContainer, ...(withBorderRight && { borderRight: `1px solid${Color.PUREWHITE}` }) }}>
      <div style={{ ...styles.columnRow, ...(colTitle && { flexDirection: 'column' }) }}>
        <div style={styles.columnTitle}>{colTitle}&nbsp;</div>
      </div>
      <div style={styles.columnRowsContainer}>
        {colRows.map((row, i) => (
          <div key={`col-${i + 1}`} style={styles.columnRow}>
            <p style={{ ...styles.columnCell, ...(row.gotNew && { fontWeight: FontWeight.BOLDER }) }}>
              {row.columnLabel}
            </p>
            <p style={{ ...styles.columnCell, ...(row.gotNew && { fontWeight: FontWeight.BOLDER }) }}>
              {row.columnValue}
              {row.gotNew !== 0 && <span style={styles.newTag}>{row.gotNew} Neu</span>}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
  if (!connectedUser) {
    return null;
  }
  return (
    <div style={styles.container}>
      <div style={styles.infoSide}>
        <div style={styles.rowTitleContainer}>
          {connectedUser.last_name}, {connectedUser.first_name}
        </div>
        <div style={styles.infoTable}>
          <div style={{ flex: 1, display: 'block', overflow: 'hidden' }}>
            {singleCol('Accountangaben', [
              {
                columnLabel: 'Email:',
                columnValue: connectedUser?.email ?? '',
                gotNew: 0,
              },
            ])}
            {singleCol('Nutzer:in', [
              {
                columnLabel: 'Titel:',
                columnValue: connectedUser.title,
                gotNew: 0,
              },
              {
                columnLabel: 'Gender:',
                columnValue: connectedUser.gender ? genderTranslations[connectedUser.gender] ?? '' : '',
                gotNew: 0,
              },
            ])}
          </div>
          <div style={{ flex: 1, display: 'block', overflow: 'hidden' }}>
            {singleCol(
              '',
              [
                {
                  columnLabel: 'Passwort:',
                  columnValue: '***********',
                  gotNew: 0,
                },
              ],
              true,
            )}
            {singleCol(
              '',
              [
                {
                  columnLabel: 'Vorname:',
                  columnValue: connectedUser.first_name,
                  gotNew: 0,
                },
                {
                  columnLabel: 'Nachname:',
                  columnValue: connectedUser.last_name,
                  gotNew: 0,
                },
              ],
              true,
            )}
          </div>
          {singleCol('Anschrift', [
            {
              columnLabel: 'Straße:',
              columnValue: connectedUser.street,
              gotNew: 0,
            },
            {
              columnLabel: 'Hausnr:',
              columnValue: connectedUser.house_number,
              gotNew: 0,
            },
            {
              columnLabel: 'Adresszusatz:',
              columnValue: connectedUser.address || '-',
              gotNew: 0,
            },
          ])}
          {singleCol('', [
            {
              columnLabel: 'PLZ:',
              columnValue: connectedUser.postcode,
              gotNew: 0,
            },
            {
              columnLabel: 'Ort:',
              columnValue: connectedUser.city,
              gotNew: 0,
            },
          ])}
        </div>
      </div>
      <div style={styles.buttonsSide}>
        <motion.button
          whileHover={{ background: 'rgba(0,0,0,.1)' }}
          type="button"
          style={styles.rowButton}
          onClick={editClicked}
        >
          Bearbeiten
        </motion.button>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    background: Color.MONOGREY3,
    borderRadius: AppSizes.DEFAULT,
    flexDirection: 'row',
    overflow: 'hidden',
  },
  infoSide: {
    width: '85%',
  },
  buttonsSide: {
    borderLeft: `1px solid${Color.PUREWHITE}`,
    padding: `${AppSizes.SIZE2}px ${AppSizes.SIZE1}px`,
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  infoTable: {
    flexDirection: 'row',
    width: '100%',
  },
  rowTitleContainer: {
    fontWeight: FontWeight.BOLDER,
    fontSize: FontSize.P1,
    borderBottom: `1px solid${Color.PUREWHITE}`,
    padding: `${AppSizes.DEFAULT}px ${AppSizes.SIZE1}px`,
  },
  columnContainer: {
    flex: 1,
    flexGrow: 1,
    overflow: 'hidden',
    padding: `${AppSizes.DEFAULT}px ${AppSizes.SIZE1}px`,
  },
  columnTitle: {
    fontWeight: FontWeight.REGULAR,
    fontSize: FontSize.P3,
    width: '100%',
    color: Color.MONOGREY4,
    textAlign: 'left',
  },
  columnRowsContainer: {
    width: '100%',
  },
  columnRow: {
    flexDirection: 'row',
    width: '100%',
  },
  columnCell: {
    fontSize: FontSize.P3,
    fontWeight: FontWeight.REGULAR,
    width: '50%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'left',
  },
  rowButton: {
    fontWeight: FontWeight.REGULAR,
    fontSize: FontSize.P4,
    borderRadius: AppSizes.SIZE4,
    border: `1px solid${Color.APPBLACK}`,
    padding: `${4}px ${AppSizes.SIZE1}px`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    columnGap: 8,
    background: 'rgba(0, 0, 0, 0)',
    transition: 'all 300ms',
  },
  newTag: {
    position: 'absolute',
    display: 'inline-flex',
    lineHeight: '1.5em',
    background: Color.MONOGREY6,
    borderRadius: AppSizes.SIZE1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 4px',
    fontSize: 10,
    fontWeight: FontWeight.REGULAR,
    marginLeft: AppSizes.SIZE1,
    top: 3,
    color: Color.APPWHITE,
  },
};
