import { Action, ActionType } from './actions';
import { MessagingStore } from './type';

const TOAST_LIMIT = 20;

export const defaultMessagingStore: MessagingStore = {
  toasts: [],
  popupsQueue: [],
};

export const messagingStoreReducer = (state: MessagingStore, action: Action): MessagingStore => {
  switch (action.type) {
    case ActionType.ADD_TOAST:
      return {
        ...state,
        toasts: [action.toast, ...state.toasts].slice(0, TOAST_LIMIT),
      };

    case ActionType.UPDATE_TOAST:
      return {
        ...state,
        toasts: state.toasts.map((t) => (t.id === action.toast.id ? { ...t, ...action.toast } : t)),
      };

    case ActionType.UPSERT_TOAST: {
      const { toast } = action;
      return state.toasts.find((t) => t.id === toast.id)
        ? messagingStoreReducer(state, { type: ActionType.UPDATE_TOAST, toast })
        : messagingStoreReducer(state, { type: ActionType.ADD_TOAST, toast });
    }

    case ActionType.DISMISS_TOAST: {
      const { toastId } = action;
      return {
        ...state,
        toasts: state.toasts.map((t) =>
          t.id === toastId || toastId === undefined
            ? {
                ...t,
                visible: false,
              }
            : t,
        ),
      };
    }
    case ActionType.REMOVE_TOAST:
      if (action.toastId === undefined) {
        return {
          ...state,
          toasts: [],
        };
      }
      return {
        ...state,
        toasts: state.toasts.filter((t) => t.id !== action.toastId),
      };

    case ActionType.START_PAUSE_TOAST:
      return {
        ...state,
        pausedAt: action.time,
      };

    case ActionType.END_PAUSE_TOAST: {
      const diff = action.time - (state.pausedAt || 0);
      return {
        ...state,
        pausedAt: undefined,
        toasts: state.toasts.map((t) => ({
          ...t,
          pauseDuration: t.pauseDuration + diff,
        })),
      };
    }

    case ActionType.ADD_POPUP_MESSAGE:
    case ActionType.ADD_CONFIMATION: {
      const { content } = action;
      return {
        ...state,
        popupsQueue: state.popupsQueue.concat(content),
      };
    }
    case ActionType.REMOVE_POPUP_MESSAGE: {
      return {
        ...state,
        popupsQueue: state.popupsQueue.filter(
          (item) => (item.type === 'popupMessage' && item.id === action.id) === false,
        ),
      };
    }
    case ActionType.REMOVE_CONFIMATION: {
      return {
        ...state,
        popupsQueue: state.popupsQueue.filter(
          (item) => (item.type === 'confirmation' && item.id === action.id) === false,
        ),
      };
    }
    default:
      return defaultMessagingStore;
  }
};
