import moment from 'moment';
import DateSelection, { Date } from './DateSelection';
import TimeSelection, { Time } from './TimeSelection';
import QuestionnaireTextInput from './QuestionnaireTextInput';
import NumericInput from './NumericInput';
import NullableNumericInput from './NullableNumericInput';
import { AppSizes, FontSize, FontWeight } from '../../../../assets/styles/constantStyles';
import { StyleSheet } from '../../../../common/domain/entities/StyleSheet';
import { BigBoxRadioButtonGroup } from '../../../../common/ui/Others/BigBoxRadioButtonGroup';
import { SingleQuestion } from '../../../domain/entities/SingleQuestion';

type Props = {
  question: SingleQuestion;
  currentAnswer: string;
  setAnswer: (questionnaireId: string, value: string) => void;
};
export const SingleQuestionDisplay = ({ question, currentAnswer = '', setAnswer }: Props) => {
  const extractTime = (value: string): Time | undefined => {
    const number = parseInt(value);
    if (Number.isNaN(number)) {
      return undefined;
    }
    const duration = moment.duration(number, 'minute');

    return {
      hour: Math.floor(duration.asHours()),
      minute: duration.minutes(),
    };
  };
  const extractDate = (value: string): Date | undefined => {
    const duration = value.split('.');

    return {
      year: parseInt(duration[0]),
      month: parseInt(duration[1]),
    };
  };

  const render = (the_question: SingleQuestion) => {
    const possibleValues = the_question.possible_values ?? {};
    const subType = the_question.possible_values?.type;
    switch (subType) {
      case 'Text':
        return (
          <QuestionnaireTextInput value={currentAnswer} onChange={(value) => setAnswer(the_question.uuid, value)} />
        );
      case 'Jahre + Monate':
        return (
          <DateSelection
            labels={['Jahre', 'Monate']}
            onChange={(val) => setAnswer(the_question.uuid, `${val.year}.${val.month}`)}
            initialValue={currentAnswer ? extractDate(currentAnswer) : undefined}
          />
        );
      case 'Time':
      case 'Duration':
      case 'Stunden + Minuten':
      case 'Stunde + Minute':
        return (
          <TimeSelection
            onChange={(val) => setAnswer(the_question.uuid, `${val.hour * 60 + val.minute}`)}
            type={subType.toLowerCase() as 'time' | 'duration'}
            initialValue={currentAnswer ? extractTime(currentAnswer) : undefined}
          />
        );
      default:
        switch (the_question.question_type) {
          case 'List':
            return (
              <BigBoxRadioButtonGroup
                selectedIndex={Object.keys(possibleValues).indexOf(currentAnswer)}
                options={Object.values(possibleValues)}
                action={(newValue) => {
                  const key = Object.keys(possibleValues)[newValue];
                  setAnswer(the_question.uuid, `${key}`);
                }}
              />
            );
          case 'Numeric':
            return (
              <NumericInput
                label={subType || ''}
                value={currentAnswer || ''}
                onChange={(value) => setAnswer(the_question.uuid, value)}
              />
            );

          case 'NullableNumeric':
            return (
              <NullableNumericInput
                label={subType || ''}
                value={currentAnswer || ''}
                onChange={(value) => setAnswer(the_question.uuid, value)}
              />
            );

          default:
            return <div />;
        }
    }
  };

  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;

  const renderLongText = (long_text: string) => {
    if (long_text === '-' || !long_text) {
      return null;
    }

    return long_text.split(/[\n\r]+/).map((label) => (
      <p className="fs-6" key={label}>
        {label.split('**').map((text, index) => {
          if (index % 2 === 1) {
            return <strong key={index}>{text}</strong>;
          }
          const matches = text.match(emailRegex);

          if (!matches) {
            // no email included in the text
            return text;
          }
          return text.split(emailRegex).map((part, pindex) => {
            if (matches[pindex]) {
              return (
                <span key={pindex}>
                  {part}
                  <a href={`mailto:${matches[pindex]}`}>{matches[pindex]}</a>
                </span>
              );
            }
            return part;
          });
        })}
      </p>
    ));
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  let { description, variable_label_ex, category } = question;
  description = description || '-';
  variable_label_ex = variable_label_ex || '-';
  category = category || '-';
  return (
    <div style={styles.outerContainer} className="do-not-break-out">
      <div style={styles.answersContainer}>
        {category !== '-' && <h1 className="text-start fw-bold pb-3 fs-4">{category}</h1>}
        <h1 className="text-start fw-bold pb-3 fs-5">{renderLongText(description)}</h1>
        {renderLongText(variable_label_ex)}
        {render(question)}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  outerContainer: {
    flex: 1,
    width: '100%',
  },
  answersContainer: {
    flex: 1,
  },
  explanationContainer: {
    flexDirection: 'row',
    columnGap: AppSizes.DEFAULT,
    fontWeight: FontWeight.BOLD,
    fontSize: FontSize.P1,
    width: '100%',
  },
};
