type Props = {
  size?: number;
  color?: string;
};

const DownloadLogo = ({ size = 16, color = '#0A0A0A' }: Props) => (
  <svg width={`${size}`} height={`${size}`} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.03924 6.71103L8.00204 10.6738L11.9648 6.71103" stroke={color} />
    <path d="M8.00195 10.6641L8.00195 1.6" stroke={color} />
    <path d="M1.34863 8.00136L1.34863 13.8936L14.663 13.8936L14.663 8.00136" stroke={color} />
  </svg>
);

export default DownloadLogo;
