export interface Patient {
  uuid: string;
  doctor_uuid?: string;
  age?: number;
  title?: string;
  first_name: string;
  last_name: string;
  status: string;
  comments: string;
  birth_date: string;
  gender: string;
  school: string;
  class?: string;
  pinned?: boolean;
  readyForDiag?: boolean;
  updated_at?: string;
  created_at?: string;
}

export const defaultEmptyPatient: Omit<Patient, 'uuid'> = {
  first_name: '',
  last_name: '',
  status: 'aktiv',
  comments: '',
  birth_date: '',
  gender: '',
  school: '',
  class: '',
  // updated_at: Date.now().toString(),
  // created_at: Date.now().toString(),
};
// const dayInSeconds = 86400000;
export const hardCodedPatients: Patient[] = [
  {
    uuid: 'p001',
    first_name: 'Pew',
    last_name: 'Diepie',
    status: 'aktiv',
    comments: '',
    birth_date: '27.11.2000',
    gender: 'male',
    school: 'p001School',
    class: 'p001Class',
  },
  {
    uuid: 'p002',
    first_name: 'Mark',
    last_name: 'Iplier',
    status: 'aktiv',
    comments: '',
    birth_date: '27.12.1999',
    gender: 'male',
    school: 'p002School',
    class: 'p002Class',
    readyForDiag: true,
  },
  {
    uuid: 'p003',
    first_name: 'Jack',
    last_name: 'Septiceye',
    status: 'aktiv',
    comments: '',
    birth_date: '15.03.1985',
    gender: 'male',
    school: 'p003School',
    class: 'p003Class',
  },
  {
    uuid: 'p004',
    first_name: 'Levi',
    last_name: 'Ackerman',
    status: 'aktiv',
    comments: '',
    birth_date: '01.07.1989',
    gender: 'male',
    school: 'p004School',
    class: 'p004Class',
    pinned: true,
  },
  {
    uuid: 'p005',
    first_name: 'One',
    last_name: 'PunchMan',
    status: 'aktiv',
    comments: '',
    birth_date: '01.09.1979',
    gender: 'male',
    school: 'p005School',
    class: 'p005Class',
    pinned: true,
  },
  {
    uuid: 'p006',
    first_name: 'Maxine',
    last_name: 'Mustermann',
    status: 'aktiv',
    comments: '',
    birth_date: '13.07.2016',
    gender: 'female',
    school: 'Grundschule',
    class: '1',
    pinned: false,
  },
];
