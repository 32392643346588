import { all } from 'redux-saga/effects';
import { applicationSagas } from '../../common/usecases/application/application.sagas';
import { commonSagas } from '../../common/usecases/common.sagas';
import { navigationSagas } from '../../navigationContext/usecases/navigation.sagas';
import { patientsSagas } from '../../patientsContext/usecases/Patients.sagas';
import { questionnairesSagas } from '../../questionnairesContext/usecases/questionnaires.sagas';

export function* sagas() {
  yield all([questionnairesSagas(), patientsSagas(), navigationSagas(), commonSagas(), applicationSagas()]);
}
