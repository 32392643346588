import { User } from './entities/User';
import { Role } from './types';

export enum ActionTypes {
  Authorize = 'USER_AUTHENTICATED',
  GetProfile = 'USER_GET_PROFILE',
  UpdateProfile = 'UPDATE_USER_PROFILE',
  ContactLogout = 'Patient_Contact_Logout',
  Logout = 'USER_LOGOUT',
}

export type AuthenticateUserAction = {
  type: ActionTypes.Authorize;
  role: Role;
  sessionKey: string;
  isFirstTimePanelAppOpen?: boolean;
};

export type ContactLogoutAction = {
  type: ActionTypes.ContactLogout;
};

export type LogoutAction = {
  type: ActionTypes.Logout;
};

export type GetProfileAction = {
  type: ActionTypes.GetProfile;
  profile: User;
};

export type UpdateProfileAction = {
  type: ActionTypes.UpdateProfile;
  profile: User;
};

export type Actions =
  | AuthenticateUserAction
  | LogoutAction
  | GetProfileAction
  | UpdateProfileAction
  | ContactLogoutAction;
