import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../domain/entities/StyleSheet';

export interface AppFormErrorListProps {
  shown: boolean;
  formErrorsList: string[];
}
export const AppFormErrorList = ({ shown, formErrorsList }: AppFormErrorListProps) => {
  if (!shown) {
    return null;
  }
  return (
    <div style={{ marginBottom: AppSizes.SIZE1, marginTop: AppSizes.SIZE5 }}>
      {formErrorsList.map((err, i) => (
        <div key={`Error-${i + 1}`} style={styles.errorText}>
          {err}
        </div>
      ))}
    </div>
  );
};

const styles: StyleSheet = {
  errorText: {
    color: Color.DANGER,
    fontSize: FontSize.P3,
    fontWeight: FontWeight.BOLD,
    textAlign: 'center',
  },
};
