import React from 'react';
import ToastItem from './item';
import { StyleSheet } from '../../domain/entities/StyleSheet';
import { useMessaging } from '../../../core/components/messaging';

type ToastPosition = 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';

type Props = {
  // toastOptions?: DefaultToastOptions;
  position?: ToastPosition;
  isOrderReversed?: boolean;
  containerStyle?: StyleSheet;
};

const ToastContainer = ({ position = 'top-center', containerStyle, isOrderReversed = false }: Props) => {
  const { toasts, handlers } = useMessaging();

  const positioning: React.CSSProperties = {
    alignItems:
      position === 'top-center' || position === 'bottom-center'
        ? 'center'
        : position === 'bottom-right' || position === 'top-right'
        ? 'flex-end'
        : 'flex-start',
    justifyContent:
      position === 'top-center' || position === 'top-right' || position === 'bottom-right' ? 'flex-start' : 'flex-end',
  };
  return (
    <div className="box-none" style={{ ...containerStyle, ...styles.container, ...positioning }}>
      {(isOrderReversed ? toasts.reverse() : toasts).map((toast) => (
        <ToastItem removeToast={handlers.removeToast} key={toast.id} toast={toast} />
      ))}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'absolute',
    zIndex: 9999,
    flex: 1,
    flexGrow: 1,
    top: 16,
    left: 16,
    right: 16,
    bottom: 16,
  },
};

export default ToastContainer;
