import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { Action } from 'redux';
import { HelpSpaceDumb } from './HelpSpace.dumb';
import { sendHelpAnswersRequest } from '../../usecases/common.actions';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { navigateToRequest } from '../../../navigationContext/usecases/navigation.actions';
import { HelpObj } from '../../domain/entities/HelpObj';

const mapState = () => ({});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  navigateTo: (p: NavigationPayload) => dispatch(navigateToRequest(p)),
  sendHelpAnswersAsync: (helpObj: HelpObj) => dispatchAsync(dispatch, sendHelpAnswersRequest(helpObj)),
});

export const HelpSpaceSmart = connect(mapState, mapDispatch)(HelpSpaceDumb);
