import { motion } from 'framer-motion';
import { AppSizes, BorderRadius, Color, FontSize, FontWeight } from '../../../../assets/styles/constantStyles';
import { StyleSheet } from '../../../../common/domain/entities/StyleSheet';
import { AppCheckBoxV2 } from '../../../../common/ui/Others/AppCheckBoxV2';
import { PatientContact } from '../../../domain/entities/PatientContact';

interface PatientContactRowProps {
  selected: boolean;
  deletable: boolean;
  selectable?: boolean;
  patientContact: PatientContact;
  onRowClick?: () => void;
  deletePatientContact: () => void;
}
export const PatientContactRow = ({
  selected,
  selectable,
  deletable,
  patientContact,
  onRowClick,
  deletePatientContact,
}: PatientContactRowProps) => (
  <div
    style={{
      ...styles.container,
      ...(selected && { borderColor: Color.APPBLACK }),
    }}
  >
    <div role="none" onClick={onRowClick} style={{ ...(onRowClick && { cursor: 'pointer' }) }}>
      <div style={styles.nameAndCheckBox}>
        {selectable && <AppCheckBoxV2 checked={selected} action={() => {}} errorShown={false} label="" />}
        <div
          style={{
            ...styles.rowTitleContainer,
            marginLeft: selectable ? 12 : AppSizes.SIZE2,
          }}
        >
          {patientContact.email}
        </div>
      </div>
      <div style={styles.infoAndButtons}>
        <div style={styles.infoSide}>
          <div style={{ marginLeft: selectable ? AppSizes.SIZE5 : AppSizes.SIZE2 }}>
            <p style={{ ...styles.infoCell, color: Color.MONOGREY10 }}>Kontaktangaben:</p>
          </div>
          <div style={{ marginLeft: selectable ? AppSizes.SIZE5 : AppSizes.SIZE2 }}>
            {[
              {
                key: 'E-Mail-Adresse:',
                value: patientContact.email,
              },
              // {
              //   key: 'DISYPS-Form:',
              //   value: patientContact.role === PcRoleEn.PARENT ? PcRoleGr.PARENT : PcRoleGr.TEACHER,
              // },
              // {
              //   key: 'Art des Kontakts:',
              //   value: getShownGermanRelation(patientContact.relationship),
              // },
            ].map((item) => (
              <div key={item.key} className="row">
                <div className="col-md-3 col-sm-6">
                  <p style={styles.infoCell}>{item.key}</p>
                </div>
                <div className="col-md-9 col-sm-6">
                  <p style={styles.infoCell}>{item.value}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div style={styles.buttonsSide}>
          <motion.button
            whileHover={{ background: 'rgba(0,0,0,.1)' }}
            type="button"
            disabled={!deletable}
            style={styles.rowButton}
            onClick={deletePatientContact}
          >
            Löschen
          </motion.button>
        </div>
      </div>
    </div>
  </div>
);

const styles: StyleSheet = {
  container: {
    background: Color.MONOGREY3,
    marginBottom: AppSizes.DEFAULT,
    borderRadius: BorderRadius.R5,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    overflow: 'hidden',
  },
  nameAndCheckBox: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: `1px solid ${Color.APPWHITE}`,
    height: AppSizes.SIZE5,
  },
  infoAndButtons: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoSide: {
    marginTop: AppSizes.DEFAULT,
    marginBottom: AppSizes.DEFAULT,
    fontWeight: FontWeight.REGULAR,
    fontSize: FontSize.P3,
    width: '100%',
  },
  infoCell: {
    fontSize: FontSize.P3,
    fontWeight: FontWeight.REGULAR,
    margin: '0.24em 0',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'left',
  },
  buttonsSide: {
    alignItems: 'center',
    width: '100%',
    height: '100%',
    maxWidth: 128,
    borderLeft: `1px solid${Color.APPWHITE}`,
  },
  rowButton: {
    fontWeight: FontWeight.REGULAR,
    fontSize: FontSize.P4,
    borderRadius: AppSizes.SIZE4,
    border: `1px solid${Color.MONOGREY10}`,
    padding: `${4}px ${AppSizes.SIZE1}px`,
    transition: 'all 300ms',
    background: 'rgba(0, 0, 0, 0)',
    marginTop: AppSizes.SIZE2,
  },
  rowTitleContainer: {
    fontWeight: FontWeight.BOLDER,
    fontSize: FontSize.P1,
  },
};
