import { Color } from '../styles/constantStyles';

export interface PinProps {
  color?: string;
}

export const Pin = ({ color }: PinProps) => (
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.04591e-07 3.99963C7.24929e-05 3.22986 0.222257 2.47645 0.639895 1.82982C1.05753 1.18319 1.65288 0.670801 2.3545 0.354136C3.05613 0.0374708 3.83422 -0.0700184 4.59542 0.044566C5.35662 0.15915 6.06858 0.490941 6.64589 1.00013C7.2232 1.50931 7.64132 2.17426 7.85009 2.91518C8.05885 3.65611 8.0494 4.44153 7.82285 5.17722C7.5963 5.9129 7.16229 6.56759 6.57289 7.06272C5.98349 7.55786 5.26374 7.87241 4.5 7.96863V13.4996C4.5 13.6322 4.44732 13.7594 4.35355 13.8532C4.25979 13.947 4.13261 13.9996 4 13.9996C3.86739 13.9996 3.74022 13.947 3.64645 13.8532C3.55268 13.7594 3.5 13.6322 3.5 13.4996V7.96963C2.53297 7.84779 1.64369 7.37707 0.999215 6.64586C0.354742 5.91466 -0.000577511 4.97331 7.04591e-07 3.99863V3.99963Z"
      fill={color || Color.APPBLACK}
    />
  </svg>
);
