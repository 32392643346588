import { Action } from 'redux';
import { connect } from 'react-redux';
import { PatientsOverviewDumb } from './PatientOverview.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { getAllPatients } from '../../usecases/Patients.selectors';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { navigateToRequest } from '../../../navigationContext/usecases/navigation.actions';
import { getAllAssignedQs } from '../../../questionnairesContext/usecases/questionnaires.selectors';

const mapState = (state: AppState) => ({
  statePatients: getAllPatients(state),
  stateAllAssignedQs: getAllAssignedQs(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  navigateTo: (p: NavigationPayload) => dispatch(navigateToRequest(p)),
});

export const PatientsOverviewSmart = connect(mapState, mapDispatch)(PatientsOverviewDumb);
