import React from 'react';
import { omit } from 'lodash';
import fetchi, { AnyAsyncService, FetchiError } from 'fetchi-request';
import { userStoreReducer } from './reducer';
import { Credentials, NotImpAsyncService, SignUpPayload, SignUpResult, UserContext, UserStore } from './types';
import { ActionTypes } from './actions';
import { User } from './entities/User';
import { usePersistedReducer } from '../../core/helpers/persistedReducer';
import { AuthTokens } from '../../common/domain/entities/AuthTokens';
import { now } from '../../core/helpers/handy';
import { Optional } from '../../core/types/common';
import useToken from '../../core/helpers/useToken';

const notImplemented = new NotImpAsyncService();
const UserContextContainer = React.createContext<UserContext>({
  isAuthorized: false,
  authorities: { doctor: false, patient: false },
  isFirstTimePanelAppOpen: true,
  logout: () => {},
  login: () => notImplemented,
  register: () => notImplemented,
  getProfile: () => notImplemented,
  emailVerify: () => notImplemented,
  deleteAccount: () => notImplemented,
  updateProfile: () => notImplemented,
  changePass: () => notImplemented,
  authorizeWithToken: () => notImplemented,
  forgetPass: () => notImplemented,
  resetPass: () => notImplemented,
});

const userInitialState: UserStore = {
  isFirstTimePanelAppOpen: false,
  authorities: { doctor: false, patient: false },
  isAuthorized: false,
};

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [store, dispatch] = usePersistedReducer(userStoreReducer, userInitialState, 'UserStorageState');
  const { setTokens, clearTokens, getTokens } = useToken();

  React.useEffect(() => {
    const localAuthToken = getTokens();
    if (store.isAuthorized && store.authorities.doctor) {
      if (localAuthToken?.access_token) {
        if (localAuthToken.accessExpirationTime && localAuthToken.accessExpirationTime < now()) {
          logout();
        } else {
          setTokens(localAuthToken);
          if (store.profile === undefined) {
            getProfile({ useCache: false });
          }
        }
      } else {
        logout();
      }
    } else if (store.isAuthorized && store.authorities.patient) {
      if (localAuthToken?.bearer_token) {
        setTokens(localAuthToken);
      }
    } else if (store.isAuthorized) {
      logout();
    }
  }, []);

  const getProfile = ({
    useCache = true,
    retryConfig = undefined,
  }: {
    useCache?: boolean;
    retryConfig?: {
      count: number;
      delay: number; // milliseconds
    };
  }): AnyAsyncService<User> => {
    if (useCache && store.profile !== undefined) {
      return fetchi.resolve(store.profile);
    }
    return fetchi<{ data: User }>({ url: `/doctor`, retryConfig }).then((user) => {
      if (user.data) {
        dispatch({ type: ActionTypes.GetProfile, profile: user.data });
      }
      return user.data;
    });
  };

  const updateProfile = (newUser: User): AnyAsyncService<User> => {
    if (store.authorities.doctor === false) {
      return notImplemented;
    }

    return fetchi<{ data: User }>({
      url: '/doctor',
      method: 'PUT',
      params: {
        doctor: omit(newUser, [
          'uuid',
          'email',
          'updated_at',
          'created_at',
          'data_security_accepted_at',
          'data_use_accepted_at',
        ]),
      },
    }).then((profile) => {
      if (profile.data) {
        dispatch({ type: ActionTypes.UpdateProfile, profile: profile.data });
      }
      return profile.data;
    });
  };

  const login = (credentials: Credentials): AnyAsyncService<Optional<User>> =>
    fetchi<{ data: AuthTokens }>({
      url: '/doctor/session',
      method: 'POST',
      params: { user: credentials },
    })
      .fullResponse((result) => {
        if (result.status === 201) {
          throw new FetchiError({
            data: SignUpResult.EmailVerificationCodeSent,
            status: result.status,
            config: result.config,
          });
        }
        return result.response;
      })
      .then((response) => {
        if (response.data.access_token) {
          setTokens(response.data);
          dispatch({
            type: ActionTypes.Authorize,
            role: 'doctor',
            sessionKey: response.data.access_token.slice(-10),
          });
        }
        return response.data;
      })
      .then((authToken) => {
        if (authToken.access_token) {
          // is authorized
          return getProfile({ useCache: false });
        }
        return undefined;
      });

  const authorizeWithToken = (token: string) => {
    dispatch({ type: ActionTypes.ContactLogout });
    // remove possible tokens for previous user
    const tokens = getTokens();
    setTokens(omit(tokens, ['bearer_token']));

    return fetchi<AuthTokens>({ url: `/login/${token}` }).then((onlineTokens) => {
      setTokens((p) => ({ ...p, bearer_token: onlineTokens.access_token }));
      dispatch({
        type: ActionTypes.Authorize,
        role: 'patient',
        sessionKey: token.slice(-10),
      });
    });
  };

  const register = (credential: SignUpPayload): AnyAsyncService<SignUpResult> =>
    fetchi({ url: '/doctor/registration', method: 'POST', params: { user: credential } }).fullResponse((response) => {
      switch (response.status) {
        case 201: {
          return SignUpResult.EmailVerificationCodeSent;
        }
        case 203:
          return SignUpResult.EmailVerificationCodeSentButWithNotAuthorizedEmailAddress;
        default:
          throw Error('register error');
      }
    });

  const emailVerify = (code: string) =>
    fetchi<{ data: AuthTokens }>({ url: `/doctor/confirm/`, method: 'POST', params: { code } })
      .then((tokens) => {
        if (tokens.data.access_token) {
          setTokens(tokens.data);
          dispatch({
            type: ActionTypes.Authorize,
            role: 'doctor',
            isFirstTimePanelAppOpen: true,
            sessionKey: tokens.data.access_token.slice(-10),
          });
        }
        return tokens.data;
      })
      .then(() => getProfile({ useCache: false }));

  const logout = () => {
    clearTokens();
    dispatch({ type: ActionTypes.Logout });
    // send the request to server in parallel
    if (store.authorities.doctor) {
      fetchi({ url: '/doctor/session/', method: 'DELETE' }).catch(() => {
        /** do nothing */
      });
    }
    fetchi.global.config.headers.delete('Authorization');
  };

  const deleteAccount = (password: string): AnyAsyncService<void> =>
    fetchi({
      url: '/delete_account',
      method: 'POST',
      params: { password },
      validateStatus: (status) => status === 205,
    }).then((response) => {
      logout();
      return response;
    });

  const forgetPass = (email: string): AnyAsyncService<void> =>
    fetchi<void>({
      url: '/doctor/forgot_password',
      method: 'POST',
      params: { email },
    }).catch((error) => {
      if (error.status === 404) {
        // the email does not exist!
        // consider it as success (don't inform the user about this error)
      } else {
        throw error;
      }
    });

  const resetPass = ({ token, newPass }: { token: string; newPass: string }): AnyAsyncService<void> =>
    fetchi({
      url: `/doctor/reset_password/${token}`,
      method: 'POST',
      params: { password: newPass, password_confirmation: newPass },
    });

  const changePass = ({ previousPass, newPass }: { previousPass: string; newPass: string }): AnyAsyncService<void> =>
    fetchi({
      url: `/doctor/reset_password`,
      method: 'POST',
      params: { current_password: previousPass, password: newPass, password_confirmation: newPass },
    });

  const value = React.useMemo(
    () => ({
      ...store,
      login,
      logout,
      register,
      resetPass,
      changePass,
      forgetPass,
      getProfile,
      emailVerify,
      updateProfile,
      deleteAccount,
      authorizeWithToken,
    }),
    [store],
  );

  return <UserContextContainer.Provider value={value}>{children}</UserContextContainer.Provider>;
};

export const useUser = () => React.useContext(UserContextContainer);

export default UserContextProvider;
