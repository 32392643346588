import { createAsyncActions } from './actions.helper';
import { HelpObj } from '../domain/entities/HelpObj';

export const {
  request: setBreadCrumbsRequest,
  success: setBreadCrumbsSuccess,
  failure: setBreadCrumbsFailure,
} = createAsyncActions<string[], string[], unknown>('SET_BREADCRUMBS');

export const {
  request: sendHelpAnswersRequest,
  success: sendHelpAnswersSuccess,
  failure: sendHelpAnswersFailure,
} = createAsyncActions<HelpObj, string, unknown>('SEND_HELP_ANSWERS');
