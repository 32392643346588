import { useEffect, useState } from 'react';
import _ from 'lodash';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../../common/domain/entities/StyleSheet';
import CommonFunctions from '../../../common/helpers/CommonFunctions';
import { AppButton } from '../../../common/ui/Others/AppButton';
import { AppTextInput } from '../../../common/ui/Others/AppTextInput';
import { AppFormErrorList } from '../../../common/ui/Others/AppFormErrorList';
import { BAD_EMAIL_ERROR_TEXT } from '../../../common/helpers/ConstantTexts';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { useUser } from '../../../contexts/user/store';
import Messaging from '../../../core/components/messaging';

interface PasswordForgottenProps {
  navigateTo: (p: NavigationPayload) => void;
  navigateBack: () => void;
}
export const PasswordForgottenDumb = ({ navigateTo, navigateBack }: PasswordForgottenProps) => {
  const [showError, setShowError] = useState<boolean>(false);
  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [formErrorsList, setFormErrorsList] = useState<string[]>();
  const [email, setEmail] = useState<string>();
  const { forgetPass } = useUser();

  useEffect(() => {
    updateErrors();
  }, [email, showError]);

  const updateErrors = () => {
    const errList = [];
    if (_.isEmpty(email) || !CommonFunctions.isValidEmail(email)) {
      errList.push(BAD_EMAIL_ERROR_TEXT);
    }
    setFormErrorsList(errList);
    setFormIsValid(errList.length === 0);
  };

  const sendPasswordForgottenEmail = () => {
    if (email !== undefined) {
      forgetPass(email)
        .catch((err) => {
          // ignore any server errors exepct network error
          if (err.status === undefined) {
            alert('network error');
            throw err;
          }
        })
        .then(() => {
          email && Messaging.toast(`E-Mail gesendet an ${email}`);
          navigateTo({ routeName: 'Welcome-To-Diagnostics' });
        })
        .catch(() => {});
    }
  };

  return (
    <div style={styles.container}>
      <img alt="logoImage" style={styles.sideBarLogoStyle} src={require('../../../assets/images/logo.png')} />
      <div style={styles.innerContainer}>
        <p style={styles.title}>Passwort vergessen? Hier können Sie ihr Passwort zurücksetzen:</p>
        <p style={styles.PasswordForgottenText}>
          Geben Sie Ihre E-Mail-Adresse an, mit der Sie registriert sind. Wir senden Ihnen dann eine E-Mail mit einem
          Link, mit dessen Hilfe Sie ihr Passwort zurücksetzen können.
        </p>
        <AppTextInput
          label="E-Mail-Adresse*"
          placeholder="E-Mail-Adresse"
          currentVal={email ?? ''}
          onChange={(str: string) => {
            setEmail(str);
          }}
          showError={showError && !CommonFunctions.isValidEmail(email)}
        />
        <AppFormErrorList formErrorsList={formErrorsList ?? []} shown={showError} />
        <div style={styles.buttonsOuterContainer}>
          <div style={styles.buttonsCouple}>
            <AppButton
              label="Zurück"
              color={Color.MONOGREY6}
              strokeOnly
              action={() => {
                navigateBack();
              }}
            />
            <AppButton
              disabled={!formIsValid}
              label="Senden"
              color={Color.MONOGREY6}
              action={() => {
                formIsValid ? sendPasswordForgottenEmail() : setShowError(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    padding: AppSizes.SIZE1,
  },
  innerContainer: {
    marginTop: AppSizes.SIZE2,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    maxWidth: CommonFunctions.mobileSized() ? '95vw' : '40vw',
    alignSelf: 'center',
  },
  sideBarLogoStyle: {
    width: 200,
  },
  buttonsCouple: {
    flexDirection: 'row',
    columnGap: AppSizes.SIZE5,
    columnWidth: '50%',
    width: '95vw',
    maxWidth: 600,
  },
  buttonsOuterContainer: {
    width: '95vw',
    maxWidth: 600,
    alignItems: 'center',
    marginTop: AppSizes.SIZE5,
  },
  title: {
    marginBottom: AppSizes.SIZE2,
    fontWeight: FontWeight.BOLDER,
    fontSize: FontSize.H4,
    textAlign: 'center',
    width: '100vw',
    maxWidth: 600,
  },
  checkContainer: {
    marginTop: AppSizes.SIZE2,
    marginBottom: AppSizes.SIZE5,
  },
  theForgottenContainer: {
    marginTop: AppSizes.DEFAULT,
    marginBottom: AppSizes.DEFAULT,
    fontSize: FontSize.P3,
    fontWeight: FontWeight.REGULAR,
    textDecoration: 'underline',
  },
  PasswordForgottenText: {
    textAlign: 'center',
    fontWeight: FontWeight.REGULAR,
    fontSize: FontSize.P1,
  },
};
