import { motion } from 'framer-motion';
import { StyleSheet } from '../../../../common/domain/entities/StyleSheet';
import { Pin } from '../../../../assets/svg/Pin';
import { UnPin } from '../../../../assets/svg/UnPin';
import QuestionnaireCommonFunctions from '../../../../common/helpers/QuestionnaireCommonFunctions';
import { AppSizes, BorderRadius, Color, FontSize, FontWeight } from '../../../../assets/styles/constantStyles';
import { Patient } from '../../../domain/entities/Patient';
import { PatientRowColumn } from '../../../domain/entities/PatientRowColumn';
import { InvitationStatus, isDeletable } from '../../../../questionnairesContext/domain/entities/QInvitation';

interface Invitation {
  status: InvitationStatus;
}

interface PatientOverviewRowProps {
  patient: Patient;
  patientAssignedQs: Invitation[];
  onRowClick?: () => void;
  goToEdit?: () => void;
  togglePin?: () => void;
  deletePatient?: (patient: Patient) => void;
  noStatus?: boolean;
}
export const PatientOverviewRow = ({
  patient,
  patientAssignedQs,
  onRowClick,
  goToEdit,
  togglePin,
  deletePatient,
  noStatus,
}: PatientOverviewRowProps) => {
  const singleCol = ({
    colTitle,
    colRows,
    withBorderRight,
    colNumbers,
  }: {
    colTitle: string;
    colRows: PatientRowColumn[];
    colNumbers: number;
    withBorderRight?: boolean;
  }) => (
    <div
      className={`col-md-${Math.round(12 / colNumbers)}`}
      style={{ ...(withBorderRight && { borderRight: `1px solid${Color.PUREWHITE}` }) }}
    >
      <div className="flex-row" style={{ ...(colTitle && { flexDirection: 'column' }) }}>
        <div className="my-3" title={colTitle} style={styles.columnTitle}>
          {colTitle}&nbsp;
        </div>
      </div>
      <div>
        {colRows.map((row, i) => (
          <div key={`col-${i + 1}`} className="flex-row">
            <p
              title={row.columnLabel}
              style={{ ...styles.columnCell, ...(row.gotNew && { fontWeight: FontWeight.BOLDER }) }}
            >
              {row.columnLabel}
            </p>
            <p
              title={row.columnValue}
              style={{ ...styles.columnCell, ...(row.gotNew && { fontWeight: FontWeight.BOLDER }) }}
            >
              {row.columnValue}
              {row.gotNew !== 0 && <span style={styles.newTag}>{row.gotNew} Neu</span>}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
  return (
    <div style={{ ...styles.container }}>
      <button
        type="button"
        style={{ ...styles.infoSide, ...(!onRowClick && { cursor: 'default' }) }}
        onClick={onRowClick}
      >
        <div style={styles.rowTitleContainer}>
          <div style={styles.rowTitle}>{patient.first_name}</div>
        </div>
        <div className="container">
          <div className="row">
            {singleCol({
              colTitle: 'Angaben zur / zum Patient:in',
              colRows: [
                // {
                //   columnLabel: 'Geburtsdatum:',
                //   columnValue: patient.birth_date
                //     ? `${patient.birth_date} (${CommonFunctions.getAge(patient.birth_date)} Jahre)`
                //     : '',
                //   gotNew: 0,
                // },
                {
                  columnLabel: 'Status:',
                  columnValue: patient.status,
                  gotNew: 0,
                },
              ],
              colNumbers: noStatus ? 2 : 4,
              withBorderRight: true,
            })}
            {/* {singleCol({
              colTitle: '',
              colRows: [
                {
                  columnLabel: 'Schultyp:',
                  columnValue: patient.school,
                  gotNew: 0,
                },
                {
                  columnLabel: 'Klasse:',
                  columnValue: patient.class ?? '',
                  gotNew: 0,
                },
              ],
              colNumbers: noStatus ? 2 : 4,
              withBorderRight: !noStatus,
            })} */}
            {!noStatus &&
              singleCol({
                colTitle: 'FBB-Status',
                colRows: [
                  {
                    columnLabel: 'Gesendet:',
                    columnValue: `${patientAssignedQs.length}`,
                    gotNew: 0,
                  },
                  {
                    columnLabel: 'Offen:',
                    columnValue: `${QuestionnaireCommonFunctions.getQsOpen(patientAssignedQs).length}`,
                    gotNew: QuestionnaireCommonFunctions.getQsNotSeen(
                      QuestionnaireCommonFunctions.getQsOpen(patientAssignedQs),
                    ).length,
                  },
                ],
                colNumbers: noStatus ? 2 : 4,
                withBorderRight: false,
              })}
            {!noStatus &&
              singleCol({
                colTitle: '',
                colRows: [
                  {
                    columnLabel: 'Begonnen:',
                    columnValue: `${QuestionnaireCommonFunctions.getQsBegan(patientAssignedQs).length}`,
                    gotNew: QuestionnaireCommonFunctions.getQsNotSeen(
                      QuestionnaireCommonFunctions.getQsBegan(patientAssignedQs),
                    ).length,
                  },
                  {
                    columnLabel: 'Abgeschlossen:',
                    columnValue: `${QuestionnaireCommonFunctions.getQsDone(patientAssignedQs).length}`,
                    gotNew: QuestionnaireCommonFunctions.getQsNotSeen(
                      QuestionnaireCommonFunctions.getQsDone(patientAssignedQs),
                    ).length,
                  },
                ],
                colNumbers: noStatus ? 2 : 4,
              })}
          </div>
        </div>
      </button>
      {(togglePin || goToEdit || deletePatient) && (
        <div style={styles.buttonsSide}>
          {togglePin && (
            <motion.button
              whileHover={{ background: 'rgba(0,0,0,.1)' }}
              type="button"
              style={{
                ...styles.rowButton,
                borderColor: patient.pinned ? Color.APPBLACK : Color.MONOGREY10,
              }}
              onClick={togglePin}
            >
              {patient.pinned ? <Pin /> : <UnPin />}
              <div>{patient.pinned ? 'Pin entfernen' : 'Anpinnen'}</div>
            </motion.button>
          )}
          {goToEdit && (
            <motion.button
              whileHover={{ background: 'rgba(0,0,0,.1)' }}
              type="button"
              style={styles.rowButton}
              onClick={goToEdit}
            >
              Bearbeiten
            </motion.button>
          )}
          {false && deletePatient && (
            <motion.button
              whileHover={{ background: 'rgba(0,0,0,.1)' }}
              type="button"
              style={styles.rowButton}
              disabled={patientAssignedQs.some((q) => !isDeletable(q.status))}
              onClick={() => {
                // deletePatient(patient);
              }}
            >
              Löschen
            </motion.button>
          )}
        </div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    border: '1px solid transparent',
    background: Color.MONOGREY3,
    marginBottom: AppSizes.DEFAULT,
    borderRadius: BorderRadius.R5,
    flexDirection: 'row',
    overflow: 'hidden',
    minHeight: 168,
  },
  infoSide: {
    flex: 1,
  },
  buttonsSide: {
    borderLeft: `1px solid${Color.PUREWHITE}`,
    width: 152,
    alignItems: 'end',
    rowGap: AppSizes.SIZE2,
    paddingTop: 24,
  },
  infoTable: {
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  rowTitleContainer: {
    fontWeight: FontWeight.BOLDER,
    fontSize: FontSize.P1,
    borderBottom: `1px solid${Color.PUREWHITE}`,
    width: '100%',
  },
  rowTitle: {
    marginLeft: AppSizes.SIZE2,
    textAlign: 'left',
    height: AppSizes.SIZE5,
    justifyContent: 'center',
  },
  columnContainer: {
    flex: 1,
    overflow: 'hidden',
    justifyContent: 'space-evenly',
    marginLeft: AppSizes.SIZE2,
  },
  columnTitle: {
    fontWeight: FontWeight.REGULAR,
    fontSize: FontSize.P3,
    width: '100%',
    color: Color.MONOGREY4,
    textAlign: 'left',
  },
  columnCell: {
    fontSize: FontSize.P3,
    fontWeight: FontWeight.REGULAR,
    width: '50%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'left',
    textTransform: 'capitalize',
  },
  rowButton: {
    fontWeight: FontWeight.REGULAR,
    fontSize: FontSize.P4,
    borderRadius: AppSizes.SIZE4,
    border: `1px solid${Color.MONOGREY10}`,
    padding: `${4}px ${AppSizes.SIZE1}px`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    columnGap: 8,
    background: 'rgba(0, 0, 0, 0)',
    transition: 'all 300ms',
    marginRight: AppSizes.SIZE1,
  },
  newTag: {
    position: 'absolute',
    display: 'inline-flex',
    lineHeight: '1.5em',
    background: Color.MONOGREY6,
    borderRadius: AppSizes.SIZE1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 4px',
    fontSize: 10,
    fontWeight: FontWeight.REGULAR,
    marginLeft: AppSizes.SIZE1,
    top: 3,
    color: Color.APPWHITE,
  },
};
