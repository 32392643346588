import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../../common/domain/entities/StyleSheet';
import CommonFunctions from '../../../common/helpers/CommonFunctions';
import { AppButton } from '../../../common/ui/Others/AppButton';
import { AppTextInput } from '../../../common/ui/Others/AppTextInput';
import { AppFormErrorList } from '../../../common/ui/Others/AppFormErrorList';
import {
  BAD_PASSWORD_ERROR_TEXT,
  EMPTY_INPUT_ERROR_TEXT,
  PASS_REPEAT_NO_MATCH_ERROR_TEXT,
} from '../../../common/helpers/ConstantTexts';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { AppTextInputRequirement } from '../../../common/domain/entities/AppTextInputRequirement';
import { useUser } from '../../../contexts/user/store';
import Messaging from '../../../core/components/messaging';

interface PasswordResetProps {
  navigateTo: (p: NavigationPayload) => void;
}
export const PasswordResetDumb = ({ navigateTo }: PasswordResetProps) => {
  const [showError, setShowError] = useState<boolean>(false);
  const [formIsValid, setFormIsValid] = useState<boolean>();
  const [formErrorsList, setFormErrorsList] = useState<string[]>();
  const [inputRequirements, setInputRequirements] = useState<AppTextInputRequirement[]>();
  const [newPassword, setNewPassword] = useState<string>();
  const [passRepeat, setPassRepeat] = useState<string>();
  const { resetPass } = useUser();
  const { key: passResetToken } = useParams<{ key: string }>();

  useEffect(() => {
    updateErrors();
  }, [newPassword, passRepeat, showError]);

  const updateErrors = () => {
    const errList: string[] = [];
    !passwordValid() && errList.push(BAD_PASSWORD_ERROR_TEXT);
    !passwordRepeatValid() && errList.push(PASS_REPEAT_NO_MATCH_ERROR_TEXT);
    (!newPassword || !passRepeat) && errList.push(EMPTY_INPUT_ERROR_TEXT);
    setFormErrorsList([...errList]);
    setFormIsValid(!errList.length);
  };

  const passwordValid = () => {
    const reqs: AppTextInputRequirement[] = [];
    reqs.push({ name: 'Mindestens', fulfilled: false });
    reqs.push({ name: '8 Zeichen,', fulfilled: !!newPassword && newPassword.length >= 8 });
    reqs.push({ name: '1 Groß,', fulfilled: CommonFunctions.containsUpperCase(newPassword ?? '') });
    reqs.push({
      name: '-und 1 Kleinbuchstabe,',
      fulfilled: CommonFunctions.containsLowerCase(newPassword ?? ''),
    });
    reqs.push({ name: '1 Zahlen', fulfilled: CommonFunctions.containsNumber(newPassword) });
    setInputRequirements(reqs);

    const passIsValid = !_.isEmpty(newPassword) && reqs.filter((r) => !r.fulfilled).length === 1; // 1 because Mindestens should be the only false
    return passIsValid;
  };
  const passwordRepeatValid = () => {
    const passRepeatIsValid = newPassword === passRepeat;
    return passRepeatIsValid;
  };
  const sendNewPasswords = () => {
    newPassword &&
      passRepeat &&
      passResetToken &&
      resetPass({ newPass: newPassword, token: passResetToken })
        .then(() => {
          Messaging.toast('Passwort erfolgreich geändert.');
          navigateTo({ routeName: 'Welcome-To-Diagnostics' });
        })
        .catch((err) => {
          alert(`Error sendNewPasswords\n${JSON.stringify(err)}`);
        });
  };

  return (
    <div style={styles.container}>
      <img alt="logoImage" style={styles.sideBarLogoStyle} src={require('../../../assets/images/logo.png')} />
      <div style={styles.innerContainer}>
        <p style={styles.title}>Bitte vergeben Sie ein neues Passwort:</p>
        <AppTextInput
          label="Neues Passwort*"
          placeholder="Neues Passwort"
          currentVal={newPassword ?? ''}
          onChange={(str: string) => {
            setNewPassword(str);
          }}
          showError={showError && !inputRequirements?.filter((r) => !r.fulfilled).length}
          isPass
          requirementsList={inputRequirements}
        />

        <AppTextInput
          label="Neues Passwort wiederholen*"
          placeholder="Neues Passwort wiederholen"
          currentVal={passRepeat ?? ''}
          onChange={(str: string) => {
            setPassRepeat(str);
          }}
          showError={showError && (_.isEmpty(passRepeat) || passRepeat !== newPassword)}
          isPass
        />
        <AppFormErrorList formErrorsList={formErrorsList ?? []} shown={showError} />
        <div style={styles.buttonsOuterContainer}>
          <div style={styles.buttonsCouple}>
            <AppButton
              label="Zurück"
              color={Color.MONOGREY6}
              strokeOnly
              action={() => {
                navigateTo({ routeName: 'Welcome-To-Diagnostics' });
              }}
            />
            <AppButton
              disabled={!formIsValid}
              label="Senden"
              color={Color.MONOGREY6}
              action={() => {
                formIsValid ? sendNewPasswords() : setShowError(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    padding: AppSizes.SIZE1,
  },
  innerContainer: {
    marginTop: AppSizes.SIZE2,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    maxWidth: CommonFunctions.mobileSized() ? '95vw' : '40vw',
    alignSelf: 'center',
  },
  sideBarLogoStyle: {
    width: 200,
  },
  buttonsCouple: {
    flexDirection: 'row',
    columnGap: AppSizes.SIZE5,
    columnWidth: '50%',
    width: '95vw',
    maxWidth: 600,
  },
  buttonsOuterContainer: {
    width: '95vw',
    maxWidth: 600,
    alignItems: 'center',
    marginTop: AppSizes.SIZE5,
  },
  title: {
    marginBottom: AppSizes.SIZE2,
    fontWeight: FontWeight.BOLDER,
    fontSize: FontSize.H4,
    textAlign: 'center',
    width: '100vw',
    maxWidth: 600,
  },
  checkContainer: {
    marginTop: AppSizes.SIZE2,
    marginBottom: AppSizes.SIZE5,
  },
  theForgottenContainer: {
    marginTop: AppSizes.DEFAULT,
    marginBottom: AppSizes.DEFAULT,
    fontSize: FontSize.P3,
    fontWeight: FontWeight.REGULAR,
    textDecoration: 'underline',
  },
  PasswordResetText: {
    textAlign: 'center',
    fontWeight: FontWeight.REGULAR,
    fontSize: FontSize.P1,
  },
};
