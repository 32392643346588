import { Modal } from 'react-bootstrap';
import { AppButton, AppButtonProps } from './AppButton';
import { AppSizes, Color } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../domain/entities/StyleSheet';

export interface AppAlertProps {
  isOn: boolean;
  title?: string;
  content: string | JSX.Element;
  buttons: AppButtonProps[];
  close?: () => void;
}
export const AppAlert = ({ isOn, content, title, buttons, close }: AppAlertProps) => {
  if (!isOn) {
    return null;
  }
  return (
    <Modal centered show={isOn} onHide={close}>
      <Modal.Header className="border-0" closeLabel="fuck" closeButton onClick={close} />
      <Modal.Body>
        <Modal.Title className="text-center">{title}</Modal.Title>
        {typeof content === 'string' ? <div>{content}</div> : content}
        {buttons.length && (
          <div style={styles.buttonsContainer}>
            {buttons.map((btn, i) => (
              <AppButton
                key={`AppButton-${i * 990}`}
                label={btn.label}
                color={btn.color ?? Color.MONOGREY6}
                strokeOnly={btn.strokeOnly}
                disabled={btn.disabled}
                action={btn.action}
                extraStyle={{ flex: 1, maxWidth: 'auto' }}
              />
            ))}
          </div>
        )}
        {/* </div> */}
      </Modal.Body>
    </Modal>
  );
};

const styles: StyleSheet = {
  buttonsContainer: {
    height: AppSizes.SIZE12,
    width: '100%',
    gap: '16px',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'row',
  },
};
