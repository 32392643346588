import { useEffect, useState } from 'react';
import { browserName, osName } from 'react-device-detect';
import { AnimatePresence, motion } from 'framer-motion';
import { AppSizes, Color, FontSize, FontWeight } from '../../../../assets/styles/constantStyles';
import { EMPTY_INPUT_ERROR_TEXT } from '../../../helpers/ConstantTexts';
import { AppButton } from '../../Others/AppButton';
import { AppDropDownMenu } from '../../Others/AppDropDownMenu';
import { AppFormErrorList } from '../../Others/AppFormErrorList';
import { AppTextArea } from '../../Others/AppTextArea';
import { AppTextInput } from '../../Others/AppTextInput';
import { HelpObj } from '../../../domain/entities/HelpObj';
import { StyleSheet } from '../../../domain/entities/StyleSheet';

const recurrenceList = ['Ja', ' Nein'];
export interface SupportFormProps {
  resetChoice: () => void;
  sendHelpAnswers: (obj: HelpObj) => void;
}
export const SupportForm = ({ resetChoice, sendHelpAnswers }: SupportFormProps) => {
  const [showError, setShowError] = useState<boolean>(false);
  const [formIsValid, setFormIsValid] = useState<boolean>();
  const [recurrence, setRecurrence] = useState<string>();
  const [scenario, setScenario] = useState<string>();
  const [problemDescription, setProblemDescription] = useState<string>();
  const [internetBrowser, setInternetBrowser] = useState<string>();
  const [operatingSystem, setOperatingSystem] = useState<string>();
  const [formErrorsList, setFormErrorsList] = useState<string[]>();

  useEffect(() => {
    setInternetBrowser(browserName);
    setOperatingSystem(osName);
  }, [browserName, osName]);

  useEffect(() => {
    updateErrors();
  }, [scenario, recurrence, problemDescription, showError]);

  const sendHelp = () => {
    const helpObj: HelpObj = {
      browser: internetBrowser,
      intention: scenario,
      os: operatingSystem,
      problem: problemDescription,
      repeated: recurrence,
      text: undefined,
      type: 'support',
    };
    sendHelpAnswers(helpObj);
  };

  const updateErrors = () => {
    const errList: string[] = [];
    if (!recurrence || !scenario || !problemDescription) {
      errList.push(EMPTY_INPUT_ERROR_TEXT);
    }
    setFormErrorsList(errList);
    setFormIsValid(!errList.length);
  };

  return (
    <AnimatePresence initial={false}>
      <motion.section
        key="content"
        initial="collapsed"
        animate="open"
        exit="collapsed"
        variants={{
          open: { opacity: 1, height: 'auto' },
          collapsed: { height: 0 },
        }}
        transition={{ duration: 0.5 }}
        style={{ width: '100%', overflow: 'hidden' }}
      >
        <div style={{ ...styles.formContainer }}>
          <p style={styles.title}>Diese Infos sind für uns hilfreich:</p>
          <AppTextArea
            label="Was wollten Sie tun, als das Problem auftrat?*"
            maxChar={800}
            placeholder="Was wollten Sie tun, als das Problem auftrat?"
            shownValue={scenario}
            onChangeFunc={(newVal: string) => {
              setScenario(newVal);
            }}
            showError={showError && !scenario}
          />
          <AppTextArea
            label="Bitte beschreiben Sie das Problem möglichst genau.*"
            maxChar={800}
            placeholder="Bitte beschreiben Sie das Problem möglichst genau."
            shownValue={problemDescription}
            onChangeFunc={(newVal: string) => {
              setProblemDescription(newVal);
            }}
            showError={showError && !problemDescription}
          />
          <AppDropDownMenu
            label="Tritt das Problem immer wieder auf?*"
            list={recurrenceList}
            shownValue={recurrence}
            placeholder="Tritt das Problem immer wieder auf?"
            disabled={!recurrence}
            onChangeFunc={(newVal: string) => {
              setRecurrence(newVal);
            }}
            extraStyle={{ width: '100%' }}
            showError={showError && !recurrence}
          />
          <AppTextInput
            label="Welchen Internetbrowser verwenden Sie?"
            placeholder="Welchen Internetbrowser verwenden Sie?"
            currentVal={internetBrowser ?? ''}
            onChange={(str: string) => {
              setInternetBrowser(str);
            }}
          />
          <AppTextInput
            label="Welches Betriebssystem verwenden Sie?"
            placeholder="Welches Betriebssystem verwenden Sie?"
            currentVal={operatingSystem ?? ''}
            onChange={(str: string) => {
              setOperatingSystem(str);
            }}
          />
          <AppFormErrorList formErrorsList={formErrorsList ?? []} shown={showError} />
          <div style={styles.buttonsOuterContainer}>
            <div style={styles.buttonsCouple}>
              <AppButton
                label="Abbrechen"
                color={Color.MONOGREY6}
                strokeOnly
                action={() => {
                  resetChoice();
                }}
              />
              <AppButton
                disabled={!formIsValid}
                label="Senden"
                color={Color.MONOGREY6}
                action={() => {
                  formIsValid ? sendHelp() : setShowError(true);
                }}
              />
            </div>
          </div>
        </div>
      </motion.section>
    </AnimatePresence>
  );
};

const styles: StyleSheet = {
  formContainer: {
    flex: 1,
    overflow: 'hidden',
    transition: 'all ease 500ms',
    padding: 2,
  },
  title: {
    flex: 1,
    fontSize: FontSize.H5,
    fontWeight: FontWeight.REGULAR,
    textAlign: 'center',
  },
  buttonsOuterContainer: {
    width: '100%',
    maxWidth: 600,
    alignItems: 'center',
    marginTop: AppSizes.SIZE5,
    marginBottom: AppSizes.SIZE5,
  },
  buttonsCouple: {
    flexDirection: 'row',
    columnGap: AppSizes.SIZE5,
    columnWidth: '50%',
    width: '100%',
    maxWidth: 600,
  },
};
