import { useHistory } from 'react-router-dom';
import {
  AppSizes,
  BorderRadius,
  Color,
  FontSize,
  FontWeight,
  TopBarHeight,
} from '../../../assets/styles/constantStyles';
import { MiniPerson } from '../../../assets/svg/MiniPerson';
import { ToRightArrow } from '../../../assets/svg/ToRightArrow';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { useUser } from '../../../contexts/user/store';
import { StyleSheet } from '../../domain/entities/StyleSheet';

export type ClickableBreadcrumb = { crumbStr: string; action: () => void };
export interface AppTopBarProps {
  hideBack?: boolean;
  navigateTo: (p: NavigationPayload) => void;
}
export const AppTopBarDumb = ({ hideBack, navigateTo }: AppTopBarProps) => {
  const history = useHistory();
  const { profile } = useUser();

  return (
    <div style={styles.outerContainer}>
      <div style={styles.innerContainer}>
        {!hideBack && history.location.pathname !== '/' && (
          <button
            type="button"
            onClick={() => {
              history.goBack();
            }}
            style={styles.backArrowContainer}
          >
            <div style={styles.arrow}>
              <ToRightArrow color={Color.APPBLACK} />
            </div>
          </button>
        )}
        <div style={styles.rightPart}>
          <div style={styles.docName}>
            {profile && (
              <button
                type="button"
                onClick={() => {
                  navigateTo({ routeName: 'Settings' });
                }}
                style={styles.docName}
              >
                {profile?.first_name && (
                  <div>
                    {profile.first_name[0].toUpperCase()}. {profile.last_name}
                  </div>
                )}
                <MiniPerson />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  outerContainer: {
    width: '100%',
    height: TopBarHeight,
    background: Color.PUREWHITE,
    borderBottom: `2px solid ${Color.MONOGREY8}`,
  },
  innerContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: AppSizes.SIZE2,
    // paddingLeft: AppSizes.SIZE2,
  },
  rightPart: {
    justifyContent: 'right',
    flexDirection: 'row',
    flex: 1,
  },
  arrow: {
    transform: 'rotate(180deg)',
  },
  backArrowContainer: {
    cursor: 'pointer',
    width: 40,
    height: TopBarHeight,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  docName: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: FontSize.P3,
    fontWeight: FontWeight.REGULAR,
    columnGap: AppSizes.DEFAULT,
  },
  helpMenuButton: {
    position: 'relative',
  },
  helpMenuContainer: {
    position: 'absolute',
    top: 0,
    right: 34, // question mark icon width + 10px space between them
    background: Color.MONOGREY6,
    width: 170,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3,
    borderRadius: BorderRadius.R5,
  },
  helpMenuItem: {
    height: 43,
    width: '100%',
    color: Color.APPWHITE,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: FontSize.P3,
    fontWeight: FontWeight.REGULAR,
    display: 'flex',
  },
};
