import { ReactElement } from 'react';
import { ActionType } from './actions';
import { dispatchOnMessageStore } from './store';
import { DefaultToastOptions, Renderable, Toast, ToastAction, ToastKind, ToastOptions } from './type';
import { resolveValue, ValueOrFunction } from '../../helpers/handy';

type Message = ValueOrFunction<Renderable, Toast>;

const genId = (() => {
  let count = 0;
  return () => {
    count += 1;
    return `${count}`;
  };
})();

const createToast = (message: Message, kind: ToastKind = { type: 'blank' }, opts?: ToastOptions): Toast => ({
  createdAt: Date.now(),
  visible: true,
  kind,
  message,
  pauseDuration: 0,
  ...opts,
  id: opts?.id || genId(),
});

type SimpleToastHanlder = (message: Message, opts?: ToastOptions) => string;

const createSimpleToastHandler =
  (type: 'success' | 'error' | 'loading' | 'blank'): SimpleToastHanlder =>
  (message, opts) => {
    const toast = createToast(message, { type }, opts);
    dispatchOnMessageStore({ type: ActionType.UPSERT_TOAST, toast });
    return toast.id;
  };

const toast = (message: Message, opts?: ToastOptions): string => createSimpleToastHandler('blank')(message, opts);

toast.error = createSimpleToastHandler('error');
toast.success = createSimpleToastHandler('success');
toast.loading = createSimpleToastHandler('loading');

toast.withActions = (message: Message, actions: ToastAction[], opts?: ToastOptions): string => {
  const newToast = createToast(message, { type: 'has-action', actions }, opts);
  dispatchOnMessageStore({ type: ActionType.UPSERT_TOAST, toast: newToast });
  return newToast.id;
};

toast.dismiss = (toastId?: string) => {
  dispatchOnMessageStore({
    type: ActionType.DISMISS_TOAST,
    toastId,
  });
};

toast.remove = (toastId?: string) => dispatchOnMessageStore({ type: ActionType.REMOVE_TOAST, toastId });

toast.promise = <T>(
  promise: Promise<T>,
  msgs: {
    loading: Renderable;
    success: ValueOrFunction<Renderable, T>;
    error: ValueOrFunction<Renderable, Error>;
  },
  opts?: DefaultToastOptions,
) => {
  const id = toast.loading(msgs.loading, { ...opts, ...opts?.loading });

  promise
    .then((p) => {
      toast.success(resolveValue(msgs.success, p), {
        id,
        ...opts,
        ...opts?.success,
      });
      return p;
    })
    .catch((e) => {
      toast.error(resolveValue(msgs.error, e), {
        id,
        ...opts,
        ...opts?.error,
      });
    });

  return promise;
};

const showPopUp = (content: { message: string; dismissLabel?: string; title?: string; icon?: ReactElement }) =>
  new Promise<void>((resolve) => {
    dispatchOnMessageStore({
      type: ActionType.ADD_POPUP_MESSAGE,
      content: { ...content, id: genId(), callBack: resolve, type: 'popupMessage' },
    });
  });

const getConfirmation = (content: { title?: string; message?: string; confirmLabel?: string; rejectLabel?: string }) =>
  new Promise<boolean>((resolve) => {
    dispatchOnMessageStore({
      type: ActionType.ADD_CONFIMATION,
      content: { ...content, id: genId(), callBack: resolve, type: 'confirmation' },
    });
  });

const Messaging = {
  toast,
  showPopUp,
  getConfirmation,
};

export default Messaging;
