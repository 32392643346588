import React, { useState } from 'react';
import { StyleSheet } from '../../../../common/domain/entities/StyleSheet';

type Props = {
  label: string;
  value: string;
  onChange: (value: string) => void;
};

const NullableNumericInput = ({ label, value, onChange }: Props) => {
  const [number, setNumber] = useState<string>(value || '');
  const [checked, setChecked] = useState(value === "don't know");
  React.useEffect(() => {
    setNumber('');
    setChecked(false);
  }, []);

  React.useEffect(() => {
    setNumber(value);
    setChecked(value === "don't know");
  }, [value]);

  const handleState = (input_value: string) => {
    if (input_value === 'on') {
      setChecked(true);
      setNumber('');
      onChange("don't know");
      return;
    }
    if (input_value === 'off') {
      // toggle off
      setChecked(false);
      setNumber('');
      onChange('');
      return;
    }
    if (input_value.length === 0) {
      onChange('');
      setNumber('');
      return;
    }
    const newNumberValue = parseInt(input_value);
    if (Number.isNaN(newNumberValue) || newNumberValue < 0) {
      onChange('');
      setNumber('');
      return;
    }
    setNumber(input_value);
    onChange(input_value);
    setChecked(false);
  };

  return (
    <div style={styles.containerStyle}>
      <div style={styles.containerStyle}>
        <input
          type="number"
          value={number ?? ''}
          placeholder={label}
          onChange={(event) => handleState(event.target.value)}
          style={styles.inputStyle}
        />
        {number && !checked && <div style={styles.title}>({label})</div>}
      </div>
      <div style={styles.containerStyle}>
        <input
          type="checkbox"
          value="on"
          checked={checked}
          onChange={() => handleState(checked ? 'off' : 'on')}
          style={styles.checkboxStyle}
        />
        <span style={styles.title} aria-hidden="true" onClick={() => handleState(checked ? 'off' : 'on')}>
          weiß nicht
        </span>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  containerStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputStyle: {
    width: '120px',
    height: '56px',
    borderRadius: '8px',
    backgroundColor: '#E6EDFF',
    textAlign: 'center',
    marginRight: '10px',
  },
  checkboxStyle: {
    height: '18px',
    width: '18px',
    appearance: 'checkbox',
  },
  title: {
    width: '120px',
    marginLeft: '5px',
  },
};

export default NullableNumericInput;
