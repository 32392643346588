import React from 'react';
import fetchi from 'fetchi-request';
import { resolveValue, ValueOrFunction } from './handy';
import useSecureStorage from './useSecureStorage';
import { AuthTokens } from '../../common/domain/entities/AuthTokens';
import CommonFunctions from '../../common/helpers/CommonFunctions';
import { setTokensToFetch } from '../../common/adapter/fetch.helper';

let globalTokenContainer: AuthTokens | undefined;
let isFirstMemoryLoad = true;

const useToken = () => {
  /** useStorage must be replaced by useSecureStorage */
  const {
    value: localAuthToken,
    setValue: setLocalAuthToken,
    clear: removeLocalAuthToken,
  } = useSecureStorage<AuthTokens | undefined>('authToken', {});

  if (localAuthToken && localAuthToken.access_token && isFirstMemoryLoad) {
    // setting for fetchi requests
    isFirstMemoryLoad = false;
    fetchi.global.config.headers.set('Authorization', localAuthToken.access_token);
    // for backward compatibilities
    setTokensToFetch(localAuthToken);
  }

  React.useEffect(() => {
    globalTokenContainer = localAuthToken;
    if (localAuthToken) {
      setTokensToMemory(localAuthToken);
    }
  }, [localAuthToken]);

  const setTokensToMemory = (tokens: AuthTokens) => {
    // setting for fetchi requests
    tokens.access_token && fetchi.global.config.headers.set('Authorization', tokens.access_token);
    // for backward compatibilities
    setTokensToFetch(localAuthToken);
  };

  const setTokens = (input: ValueOrFunction<AuthTokens, AuthTokens | undefined>) => {
    const tokensValue = resolveValue(input, globalTokenContainer);
    setTokensToMemory(tokensValue);
    setLocalAuthToken({
      ...tokensValue,
      accessExpirationTime: tokensValue.accessExpirationTime ?? CommonFunctions.createTokenExpiryTime(),
    });
  };

  const clearTokens = () => {
    removeLocalAuthToken();
    // setting for fetchi requests
    fetchi.global.config.headers.delete('Authorization');
    // for backward compatibilities
    setTokensToFetch(undefined);
  };

  const getTokens = (): AuthTokens | undefined => globalTokenContainer;

  return {
    setTokens,
    clearTokens,
    getTokens,
  };
};

export default useToken;
