import React from 'react';
import fetchi, { AnyAsyncService } from 'fetchi-request';
import { ContentMap, HCPContext } from './type';
import { ContactInvitation } from '../patientContact/types';
import { Patient } from '../../patientsContext/domain/entities/Patient';
import { PatientContact } from '../../patientsContext/domain/entities/PatientContact';
import { QuestionnaireObj } from '../../questionnairesContext/domain/entities/QuestionnaireObj';
import { AssignedQuestionnaire } from '../../questionnairesContext/domain/entities/AssignedQuestionnaire';
import { NotImpAsyncService } from '../user/types';

const notImpt = new NotImpAsyncService();
const HCPContextContainer = React.createContext<HCPContext>({
  getPatient: () => Promise.reject(Error('not implemented')),
  getQuestionnaire: () => Promise.reject(Error('not implemented')),
  getContent: () => Promise.reject(Error('not implemented')),
  getInvitations: () => Promise.reject(Error('not implemented')),
  getContacts: () => Promise.reject(Error('not implemented')),
  getAssignedQuestionnaires: () => Promise.reject(Error('not implemented')),

  createPatient: () => notImpt,
  deletePatient: () => notImpt,
  updatePatient: () => notImpt,

  createContact: () => notImpt,
  deleteContact: () => notImpt,
  updateContact: () => notImpt,

  createContactInvitation: () => notImpt,
  deleteContactInvitation: () => notImpt,

  getInvitationReport: () => notImpt,
  getAllInvitationReport: () => notImpt,
});

const HCPContextProvider = ({ children }: { children: React.ReactNode }) => {
  const memoryStorage = React.useRef<ContentMap>({});
  const queuedTask: {
    invitations: { resolvers: ((input: ContactInvitation[]) => void)[]; rejects: ((err: any) => void)[] };
    contacts: { resolvers: ((input: PatientContact[]) => void)[]; rejects: ((err: any) => void)[] };
    patients: { resolvers: ((input: Patient[]) => void)[]; rejects: ((err: any) => void)[] };
    questionnaires: { resolvers: ((input: QuestionnaireObj[]) => void)[]; rejects: ((err: any) => void)[] };
  } = {
    invitations: { resolvers: [], rejects: [] },
    contacts: { resolvers: [], rejects: [] },
    patients: { resolvers: [], rejects: [] },
    questionnaires: { resolvers: [], rejects: [] },
  };

  const getContent: HCPContext['getContent'] = (inputs, forceRefresh = false) => {
    // some comments
    const promises: Promise<ContentMap>[] = inputs.map((item): Promise<ContentMap> => {
      switch (item) {
        case 'invitations':
          return new Promise<{ invitations: ContactInvitation[] }>((resolve, reject) => {
            if (memoryStorage.current.invitations !== undefined && forceRefresh === false) {
              resolve({ invitations: memoryStorage.current.invitations });
              return;
            }
            queuedTask.invitations.resolvers.push((value) => resolve({ invitations: value }));
            queuedTask.invitations.rejects.push(reject);

            if (queuedTask.invitations.resolvers.length <= 1) {
              fetchi<{ data: ContactInvitation[] }>({ url: '/invitations' })
                .rawPromise()
                .then((result) => {
                  let filteredItems: ContactInvitation[] = [];
                  if (result.response.data) {
                    filteredItems = result.response.data.filter((inv) => inv.status !== 'deleted');
                  }
                  memoryStorage.current.invitations = filteredItems;
                  queuedTask.invitations.resolvers.forEach((func) => func(filteredItems));
                  queuedTask.invitations.rejects = [];
                  queuedTask.invitations.resolvers = [];
                })
                .catch((err) => {
                  queuedTask.invitations.rejects.forEach((func) => func(err));
                  queuedTask.invitations.resolvers = [];
                  queuedTask.invitations.rejects = [];
                });
            }
          });
        case 'patients':
          return new Promise<{ patients: Patient[] }>((resolve, reject) => {
            if (memoryStorage.current.patients !== undefined && forceRefresh === false) {
              resolve({ patients: memoryStorage.current.patients });
              return;
            }
            queuedTask.patients.resolvers.push((value) => resolve({ patients: value }));
            queuedTask.patients.rejects.push(reject);

            if (queuedTask.patients.resolvers.length <= 1) {
              fetchi<{ data: Patient[] }>({ url: '/patients/' })
                .rawPromise()
                .then((result) => {
                  memoryStorage.current.patients = result.response.data;
                  queuedTask.patients.resolvers.forEach((func) => func(result.response.data));
                  queuedTask.patients.resolvers = [];
                  queuedTask.patients.rejects = [];
                })
                .catch((err) => {
                  queuedTask.patients.rejects.forEach((func) => func(err));
                  queuedTask.patients.resolvers = [];
                  queuedTask.patients.rejects = [];
                });
            }
          });
        case 'contacts':
          return new Promise<{ contacts: PatientContact[] }>((resolve, reject) => {
            if (memoryStorage.current.contacts !== undefined && forceRefresh === false) {
              resolve({ contacts: memoryStorage.current.contacts });
              return;
            }

            queuedTask.contacts.resolvers.push((value) => resolve({ contacts: value }));
            queuedTask.contacts.rejects.push(reject);

            if (queuedTask.contacts.resolvers.length <= 1) {
              fetchi<{ data: PatientContact[] }>({ url: '/contacts' })
                .rawPromise()
                .then((result) => {
                  memoryStorage.current.contacts = result.response.data;
                  queuedTask.contacts.resolvers.forEach((func) => func(result.response.data));
                  queuedTask.contacts.resolvers = [];
                  queuedTask.contacts.rejects = [];
                })
                .catch((err) => {
                  queuedTask.contacts.rejects.forEach((func) => func(err));
                  queuedTask.contacts.resolvers = [];
                  queuedTask.contacts.rejects = [];
                });
            }
          });
        case 'questionnaires':
          return new Promise<{ questionnaires: QuestionnaireObj[] }>((resolve, reject) => {
            if (memoryStorage.current.questionnaires !== undefined && forceRefresh === false) {
              resolve({ questionnaires: memoryStorage.current.questionnaires });
              return;
            }
            queuedTask.questionnaires.resolvers.push((value) => resolve({ questionnaires: value }));
            queuedTask.questionnaires.rejects.push(reject);

            if (queuedTask.questionnaires.resolvers.length <= 1) {
              fetchi<{ data: QuestionnaireObj[] }>({ url: '/questionnaires' })
                .rawPromise()
                .then((result) => {
                  memoryStorage.current.questionnaires = result.response.data;
                  queuedTask.questionnaires.resolvers.forEach((func) => func(result.response.data));
                  queuedTask.questionnaires.resolvers = [];
                  queuedTask.questionnaires.rejects = [];
                })
                .catch((err) => {
                  queuedTask.questionnaires.rejects.forEach((func) => func(err));
                  queuedTask.questionnaires.resolvers = [];
                  queuedTask.questionnaires.rejects = [];
                });
            }
          });
        default:
          throw Error(`not valid content namespace : ${item}`);
      }
    });
    return Promise.all(promises).then((results) =>
      results.reduce(
        (ac, result) => ({
          ...ac,
          ...result,
        }),
        {},
      ),
    );
  };

  const getAssignedQuestionnaires = React.useCallback(
    (patientUUID: string, forceRefresh = false): Promise<AssignedQuestionnaire[]> =>
      Promise.all([
        getPatient(patientUUID, forceRefresh),
        getContacts(patientUUID, forceRefresh),
        getInvitations(patientUUID, forceRefresh),
      ]).then((results) => {
        const [patient, contacts, invitations] = results;
        return Promise.all(
          invitations.map((item) =>
            getQuestionnaire(item.questionnaire_uuid).then(
              (questionnaire): AssignedQuestionnaire => ({
                patient,
                patientContact: contacts.find((contact) => contact.uuid === item.contact_uuid),
                questionnaire,
                ...item,
              }),
            ),
          ),
        );
      }),
    [memoryStorage.current.invitations],
  );

  const getInvitations = (patientUUId: string, forceRefresh = false): Promise<ContactInvitation[]> =>
    getContent(['invitations'], forceRefresh).then(
      (result) => result.invitations?.filter((invitation) => invitation.patient_uuid === patientUUId) ?? [],
    );

  const getContacts = (patientUUId: string, forceRefresh = false): Promise<PatientContact[]> =>
    getContent(['contacts'], forceRefresh).then(
      (result) => result.contacts?.filter((contact) => contact.patient_uuid === patientUUId) ?? [],
    );

  const getQuestionnaire = React.useCallback(
    (uuid: string): Promise<QuestionnaireObj> =>
      getContent(['questionnaires']).then((result) => {
        const founded = result.questionnaires?.filter((q) => q.uuid === uuid);
        if (founded && founded.length > 0) {
          return founded[0];
        }
        return Promise.reject(Error(`Questionnaire Not Found with ${uuid} uuid`));
      }),
    [],
  );

  const getPatient = (uuid: string, forceRefresh = false) =>
    getContent(['patients'], forceRefresh).then((result) => {
      const foundContents = result.patients?.filter((item) => item.uuid === uuid);
      if (foundContents && foundContents.length > 0) {
        return foundContents[0];
      }
      return Promise.reject(Error(`User Not Found with ${uuid} uuid`));
    });

  const createPatient = (
    patient: Pick<Patient, 'birth_date' | 'class' | 'first_name' | 'gender' | 'last_name' | 'pinned' | 'school'>,
  ): AnyAsyncService<void> =>
    fetchi<{ data: Patient }>({ url: '/patients', params: { patient }, method: 'POST' }).then((result) => {
      memoryStorage.current.patients = [...(memoryStorage.current.patients ?? []), result.data];
    });

  const deletePatient = (uuid: string): AnyAsyncService<void> =>
    fetchi({ url: `/patients/${uuid}`, method: 'DELETE' }).then(() => {
      memoryStorage.current.patients = memoryStorage.current.patients?.filter((item) => item.uuid !== uuid);
      memoryStorage.current.contacts = memoryStorage.current.contacts?.filter((item) => item.patient_uuid !== uuid);
      memoryStorage.current.invitations = memoryStorage.current.invitations?.filter(
        (item) => item.patient_uuid !== uuid,
      );
    });

  const updatePatient = (patient: Omit<Patient, 'updated_at' | 'created_at'>): AnyAsyncService<void> =>
    fetchi<{ data: Patient }>({ url: `/patients/${patient.uuid}`, params: { patient }, method: 'PUT' }).then(
      (result) => {
        memoryStorage.current.patients = memoryStorage.current.patients?.map((item) =>
          item.uuid === patient.uuid ? result.data : item,
        );
      },
    );

  const createContact = (contact: Omit<PatientContact, 'uuid' | 'created_at' | 'updated_at'>): AnyAsyncService<void> =>
    fetchi<{ data: PatientContact }>({
      url: `/patients/${contact.patient_uuid}/contacts`,
      params: { contact },
      method: 'POST',
    }).then((result) => {
      memoryStorage.current.contacts = [...(memoryStorage.current.contacts ?? []), result.data];
    });

  const deleteContact = (contact: PatientContact): AnyAsyncService<void> =>
    fetchi({ url: `/patients/${contact.patient_uuid}/contacts/${contact.uuid}`, method: 'DELETE' }).then(() => {
      memoryStorage.current.contacts = memoryStorage.current.contacts?.filter((item) => item.uuid !== contact.uuid);
      memoryStorage.current.invitations = memoryStorage.current.invitations?.filter(
        (item) => item.contact_uuid !== contact.uuid,
      );
    });

  const updateContact = (contact: Omit<PatientContact, 'created_at' | 'updated_at'>): AnyAsyncService<void> =>
    fetchi<{ data: PatientContact }>({
      url: `/patients/${contact.patient_uuid}/contacts/${contact.uuid}`,
      params: { contact },
      method: 'PUT',
    }).then((result) => {
      memoryStorage.current.contacts = memoryStorage.current.contacts?.map((item) =>
        item.uuid === contact.uuid ? result.data : item,
      );
    });

  const createContactInvitation = (
    invitation: Pick<ContactInvitation, 'contact_uuid' | 'questionnaire_uuid'>,
  ): AnyAsyncService<void> =>
    fetchi<{ data: ContactInvitation }>({ url: `/invitations`, method: 'POST', params: { invitation } }).then(
      (result) => {
        memoryStorage.current.invitations = [...(memoryStorage.current.invitations ?? []), result.data];
      },
    );

  const deleteContactInvitation = (uuid: string): AnyAsyncService<void> =>
    fetchi({ url: `/invitations/${uuid}`, method: 'DELETE' }).then(() => {
      memoryStorage.current.invitations = memoryStorage.current.invitations?.filter((item) => item.uuid !== uuid);
    });

  const getInvitationReport = (isCsv: boolean, invitationUUid: string): AnyAsyncService<string> =>
    fetchi<{ redirect_to: string }>({ url: `/invitations/${invitationUUid}/${isCsv ? 'csv' : 'pdf'}` }).then(
      (result) => result.redirect_to,
    );

  const getAllInvitationReport = (): AnyAsyncService<string> =>
    fetchi<{ redirect_to: string }>({ url: '/questionnaires.zip' }).then((result) => result.redirect_to);

  const value = React.useMemo(
    () => ({
      getContent,
      getPatient,
      getContacts,
      createContact,
      createPatient,
      updateContact,
      updatePatient,
      deleteContact,
      deletePatient,
      getInvitations,
      getQuestionnaire,
      getInvitationReport,
      getAllInvitationReport,
      createContactInvitation,
      deleteContactInvitation,
      getAssignedQuestionnaires,
    }),
    [],
  );

  return <HCPContextContainer.Provider value={value}>{children}</HCPContextContainer.Provider>;
};

export const useHcpContext = () => React.useContext(HCPContextContainer);

export default HCPContextProvider;
