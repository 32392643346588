import { NavigationInteractor } from '../../domain/gateways/Navigation.interactor';

export class ReactNavigationInteractor implements NavigationInteractor {
  // popToTop(): void {
  //   InteractionManager.runAfterInteractions(() => {
  //     dispatchNavigation(StackActions.popToTop())
  //   })
  // }
  // navigateTo(routeName: RouteName, params?: any): void {
  //   InteractionManager.runAfterInteractions(() => {
  //     dispatchNavigation(CommonActions.navigate(routeName, params))
  //   })
  // }
  async navigateToAsync(): Promise<void> {
    // navigation.dispatch(NavigationActions.back({
    // history.push('/some-route')
    // use history here push and pop
    // await waitAsync(params)
    // dispatchNavigation(CommonActions.navigate(routeName, params))
    // await waitAsync(params)
  }

  async navigateBack(): Promise<void> {
    return Promise.resolve();
  }
}
