import React from 'react';
import { StyleSheet } from '../../../../common/domain/entities/StyleSheet';
import useClamp from '../../../../core/helpers/useClamp';

export type Time = {
  hour: number;
  minute: number;
};

type Props = {
  initialValue?: Time;
  type: 'time' | 'duration';
  onChange?: (value: Time) => void;
};

const TimeSelectionView = ({ initialValue, type, onChange }: Props) => {
  const [presentingHour, setPresentingHour] = useClamp({ initialValue: initialValue?.hour, min: 0, max: 23 });
  const [presentingMinute, setPresentingMinute] = useClamp({ initialValue: initialValue?.minute, min: 0, max: 59 });

  React.useEffect(() => {
    setPresentingHour(initialValue?.hour);
    setPresentingMinute(initialValue?.minute);
  }, [initialValue]);

  React.useEffect(() => {
    if (presentingHour !== undefined && presentingMinute !== undefined) {
      onChange?.({ hour: presentingHour, minute: presentingMinute });
    }
    if (presentingMinute !== undefined && type === 'duration' && presentingHour === undefined) {
      onChange?.({ hour: 0, minute: presentingMinute });
    }
  }, [presentingHour, presentingMinute]);

  const handleState = (setState: (val: number | undefined) => void) => (newVale: string) => {
    if (newVale.length === 0) {
      setState(undefined);
      return;
    }
    const newNumberValue = parseInt(newVale);
    if (Number.isNaN(newNumberValue)) {
      return;
    }
    setState(newNumberValue);
  };

  return (
    <div className="d-flex flex-row justify-content-center align-items-center">
      <input
        type="number"
        value={presentingHour ?? ''}
        onChange={(event) => handleState(setPresentingHour)(event.target.value)}
        placeholder="SS"
        className="me-1"
        style={styles.inputStyle}
      />
      <p className="ms-1 me-2 p-0 m-0">:</p>
      <input
        type="number"
        value={presentingMinute ?? ''}
        onChange={(event) => handleState(setPresentingMinute)(event.target.value)}
        placeholder="MM"
        className="ms-1"
        style={styles.inputStyle}
      />
    </div>
  );
};

const styles: StyleSheet = {
  inputStyle: {
    backgroundColor: '#E6EDFF',
    borderRadius: '8px',
    width: '80px',
    height: '56px',
    border: 0,
    textAlign: 'center',
  },
};

export default TimeSelectionView;
