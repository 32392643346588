import { StyleSheet } from '../../../../common/domain/entities/StyleSheet';
import { AppSizes, Color, FontSize, FontWeight } from '../../../../assets/styles/constantStyles';
import { AppSwitch } from '../../../../common/ui/Others/AppSwitch';

interface UserSettingsSwitchRowProps {
  label: string;
  isOn: boolean;
  onClick: () => void;
}
export const UserSettingsSwitchRow = ({ label, isOn, onClick }: UserSettingsSwitchRowProps) => (
  <div style={styles.switchRow}>
    <div style={styles.switchLabel}>{label}</div>
    <div style={styles.switchButton}>
      <div style={{ fontWeight: isOn ? FontWeight.REGULAR : FontWeight.BOLDER }}>Aus</div>
      <AppSwitch isOn={isOn} onClick={onClick} />
      <div style={{ fontWeight: !isOn ? FontWeight.REGULAR : FontWeight.BOLDER }}>Ein</div>
    </div>
  </div>
);

const styles: StyleSheet = {
  switchRow: {
    flexDirection: 'row',
    alignItems: 'baseline',
    fontSize: FontSize.P3,
    fontWeight: FontWeight.REGULAR,
    borderBottom: `1px solid ${Color.APPWHITE}`,
  },
  switchLabel: {
    flex: 1,
    borderRight: `1px solid ${Color.APPWHITE}`,
    padding: AppSizes.SIZE2,
  },
  switchButton: {
    width: '20%',
    minWidth: 200,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: AppSizes.SIZE1,
  },
};
