import { AppSizes, Color } from '../styles/constantStyles';
import './loadingCricle.css';

export interface PenProps {
  color?: string;
  size?: number;
}

export const LoadingCircle = ({ color = Color.PUREBLACK, size }: PenProps) => (
  <div style={{ width: size ?? AppSizes.SIZE2, height: size ?? AppSizes.SIZE2, position: 'relative' }}>
    <svg width={size ?? 25} height={size ?? 25} className="circular-loader" viewBox="25 25 50 50">
      <circle className="loader-path" cx="50" cy="50" r="20" fill="none" stroke={color} strokeWidth="2" />
    </svg>
  </div>
);
