import { QuestionnaireSection } from './QuestionnaireSection';

export interface QuestionnaireObj {
  uuid: string;
  title?: string;
  category?: string;
  type: string;
  description: string | null;
  sections: QuestionnaireSection[];
}

export const hardCodedQs: QuestionnaireObj[] = [
  {
    description: null,
    title: 'ADHSFBB',
    type: 'hcp',
    sections: [
      {
        description: null,
        label: 'A',
        questions: [
          {
            category: 'A',
            description: 'B',
            label: '01',
            sort_order: 2,
            possible_values: {
              0: 'gar nicht',
              1: 'ein wenig',
              2: 'weitgehend',
              3: 'besonders',
            },
            question_code: 'ADHSFBBA01',
            question_type: 'List',
            variable_label_in: '',
            uuid: '231effac-64eb-4ab5-8ff5-bcf05bed56b2',
            variable_label_ex:
              'Beachtet bei den Schularbeiten, bei anderen Tätigkeiten oder bei der Arbeit häufig Einzelheiten nicht oder macht häufig Flüchtigkeitsfehler (z. B. übersieht Einzelheiten, arbeitet ungenau).',
          },
          {
            category: 'A',
            description: 'B',
            sort_order: 1,
            label: '02',
            possible_values: {
              0: 'gar nicht',
              1: 'ein wenig',
              2: 'weitgehend',
              3: 'besonders',
            },
            question_code: 'ADHSFBBA02',
            question_type: 'List',
            variable_label_in: '',
            uuid: '6ca7eca6-e939-4f3d-a0e9-302ad62c029e',
            variable_label_ex:
              'Hat bei Aufgaben oder Spielen oft Schwierigkeiten, die Aufmerksamkeit längere Zeit aufrechtzuerhalten und dabeizubleiben (z. B. kann sich im Unterricht, bei Unterhaltungen oder beim Lesen von Texten nicht lange konzentrieren).',
          },
        ],
        uuid: 'adf6b092-25fe-4047-8967-855b60cf3b43',
      },
    ],
    uuid: '1eacd25d-211e-4bba-8893-faa84f41ec7a',
  },
];
