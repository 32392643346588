import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { Toast } from '../../../core/components/messaging/type';
import { resolveValue } from '../../../core/helpers/handy';
import { StyleSheet } from '../../domain/entities/StyleSheet';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';

type Props = {
  toast: Toast;
  removeToast: (id: string) => void;
};

const ToastItem = ({ toast, removeToast }: Props) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (toast.visible) {
      setTimeout(() => {
        setIsVisible(true);
      }, 1);
    } else {
      setIsVisible(false);
      setTimeout(() => {
        removeToast(toast.id);
      }, 300);
    }
  }, [toast.visible]);

  return (
    <div
      style={{
        ...styles.container,
        transform: isVisible ? 'translateY(10px)' : 'translateY(-100px)',
        opacity: isVisible ? 1 : 0,
      }}
    >
      <div style={styles.contentContainer}>
        <div style={styles.closeIconContainer}>
          <IoMdClose
            size={16}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              removeToast(toast.id);
            }}
          />
        </div>
        <div style={styles.textContainer}>{resolveValue(toast.message, toast)}</div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    zIndex: 99,
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: FontSize.P3,
    fontWeight: FontWeight.BOLD,
    transition: 'all 300ms',
    marginTop: '10px',
  },
  contentContainer: {
    background: Color.APPWHITE,
    padding: AppSizes.SIZE1,
    paddingLeft: AppSizes.SIZE2,
    paddingRight: AppSizes.SIZE2,
    maxWidth: 400,
    maxHeight: 80,
    borderRadius: 14,
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 1px 10px rgba(0,0,0,.3)',
  },
  closeIconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: AppSizes.DEFAULT,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    flexWrap: 'wrap',
    flex: 1,
    textAlign: 'center',
  },
};

export default ToastItem;
