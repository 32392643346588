import { connect } from 'react-redux';
import { Action } from 'redux';
import { AppTopBarDumb } from './AppTopBar.dumb';
import { AppState } from '../../configuration/app.states';
import { getBreadcrumbs } from '../../usecases/common.selectors';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { navigateToRequest } from '../../../navigationContext/usecases/navigation.actions';

const mapState = (state: AppState) => ({
  breadcrumbs: getBreadcrumbs(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  navigateTo: (p: NavigationPayload) => dispatch(navigateToRequest(p)),
});

export const AppTopBarSmart = connect(mapState, mapDispatch)(AppTopBarDumb);
