import { hardCodedPatients, Patient } from '../../domain/entities/Patient';
import { PatientsInteractor } from '../../domain/gateways/Patients.interactor';

export class InMemoryPatientsInteractor implements PatientsInteractor {
  loadPatients(): Promise<Patient[]> {
    return Promise.resolve(hardCodedPatients);
  }

  insertPatient(newPatient: Patient): Promise<Patient> {
    return Promise.resolve(newPatient);
  }

  updatePatient(updatedPatient: Patient): Promise<Patient> {
    return Promise.resolve(updatedPatient);
  }

  deletePatient(patientToDelete: Patient): Promise<Patient> {
    return Promise.resolve(patientToDelete);
  }
}
