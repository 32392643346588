import { NavigationScreen } from '../domain/entities/NavigationScreen';
import * as QuestionnairesContextScreens from '../../questionnairesContext/ui';
import * as PatientContextScreens from '../../patientsContext/ui';
import * as AuthContextScreens from '../../authContext/ui';
import * as CommonContextScreens from '../../common/ui';
import AcceptTermsPage from '../../questionnairesContext/ui/acceptTermsPage';
// Add4Nav
// this file is mostly for determining which component shows on which  string in the address bar
const combinedScreens = {
  ...PatientContextScreens,
  ...QuestionnairesContextScreens,
  ...AuthContextScreens,
  ...CommonContextScreens,
};

export const DoctorPanelScreens: NavigationScreen[] = [
  {
    routePath: '',
    component: combinedScreens.PatientsOverview,
  },
  {
    routePath: 'Patients-List',
    component: combinedScreens.PatientsOverview,
  },
  {
    routePath: 'Patient-Creation',
    component: combinedScreens.PatientCreation,
  },
  {
    routePath: 'Patient-Details/:userid',
    component: combinedScreens.PatientDetails,
  },
  {
    routePath: 'Assign-Questionnaire/:patientuuid',
    component: combinedScreens.AssignQuestionnaire,
  },
  {
    routePath: 'Patient-Contact-Creation',
    component: combinedScreens.PatientContactCreation,
  },
  {
    routePath: 'Settings',
    component: combinedScreens.UserSettingsPage,
  },
  {
    routePath: 'Settings-Edition',
    component: combinedScreens.UserPersonalDataEdit,
  },
  {
    routePath: 'Password-Edit',
    component: combinedScreens.PasswordEdit,
  },
  {
    routePath: 'Help-Space',
    component: combinedScreens.HelpSpace,
  },
  {
    routePath: 'User-Personal-Data',
    component: combinedScreens.UserPersonalDataPage,
  },
];

export const DoctorPanelAuthenticationScreens: NavigationScreen[] = [
  {
    routePath: '',
    component: combinedScreens.WelcomingPage,
  },
  {
    routePath: 'Welcome-To-Diagnostics',
    component: combinedScreens.WelcomingPage,
  },
  {
    routePath: 'Sign-In',
    component: combinedScreens.SignIn,
  },
  {
    routePath: 'Sign-Up',
    component: combinedScreens.SignUp,
  },
  {
    routePath: 'Email-Activation/:key',
    component: combinedScreens.EmailActivation,
  },
  {
    routePath: 'Email-Activation-Code-Sent',
    component: combinedScreens.EmailActivationSent,
  },
  {
    routePath: 'Password-Forgotten',
    component: combinedScreens.PasswordForgotten,
  },
  {
    routePath: 'Reset-Password/:key',
    component: combinedScreens.PasswordReset,
  },
  {
    routePath: 'Dead-End-Screen',
    component: combinedScreens.RejectionScreen,
  },
];

export const sideBarScreens: NavigationScreen[] = [
  {
    routePath: 'Patients-List',
    component: combinedScreens.PatientsOverview,
  },
];

export const PatientContactScreens: NavigationScreen[] = [
  {
    routePath: 'Accept-Terms',
    component: AcceptTermsPage,
  },
  {
    routePath: 'Data-Confirmation',
    component: combinedScreens.DataConfirmation,
  },
  {
    routePath: 'Patient-Data',
    component: combinedScreens.PatientDataForm,
  },
  {
    routePath: 'Assessment-Sheet',
    component: combinedScreens.AssessmentSheet,
  },
  {
    routePath: 'Answering-Page',
    component: combinedScreens.AnsweringPage,
  },
  {
    routePath: 'Questionnaire-Finish-Line',
    component: combinedScreens.QuestionnaireFinishline,
  },
  {
    routePath: 'Expired-Questionnaire',
    component: combinedScreens.ExpiredQuestionnaire,
  },
];
