import { EmailNotifTypes } from './EmailNotifTypes';

export type User = {
  uuid: string;
  email?: string;
  title: string;
  first_name: string;
  last_name: string;
  street: string;
  house_number: string;
  postcode: string;
  city: string;
  gender?: string;
  address?: string;
  country?: string;

  notification_status_list: EmailNotifTypes[];
  matomo_status: boolean;

  data_security_accepted_at?: string;
  data_security_version?: string;
  data_use_accepted_at?: string;
  data_use_version?: string;
  tos_accepted_at?: string;
  tos_version?: string;

  updated_at?: string;
  created_at?: string;
};

export const emptyUser: User = {
  uuid: '',
  email: '',
  title: '',
  first_name: '',
  last_name: '',
  gender: '',
  street: '',
  house_number: '',
  address: '',
  postcode: '',
  city: '',
  created_at: '',
  updated_at: '',
  matomo_status: true,
  notification_status_list: [],
  data_security_accepted_at: '',
  data_security_version: '',
  data_use_accepted_at: '',
  data_use_version: '',
  tos_accepted_at: '',
  tos_version: '',
};

export const hardcodedUsers: User[] = [
  {
    ...emptyUser,
    uuid: 'user01Id',
    first_name: 'Mike',
    last_name: 'Tyson',
    title: 'doktor',
    gender: 'männlich',
    street: 'TysonStreet',
    house_number: 'Tysonhouse_number',
    address: 'TysonAdditionals',
    postcode: 'Tysonpostcode',
    city: 'TysonCity',
  },
  {
    ...emptyUser,
    uuid: 'user02Id',
    first_name: 'Floyd',
    last_name: 'Mayweather',
    title: 'nurse',
  },
  {
    ...emptyUser,
    uuid: 'user03Id',
    first_name: 'Muhammad',
    last_name: 'Ali',
    title: 'doctor',
  },
];
