import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import CommonFunctions from '../../../common/helpers/CommonFunctions';
import { StyleSheet } from '../../../common/domain/entities/StyleSheet';
import { AppButton } from '../../../common/ui/Others/AppButton';
import { AppTextInput } from '../../../common/ui/Others/AppTextInput';
import { AppFormErrorList } from '../../../common/ui/Others/AppFormErrorList';
import {
  BAD_EMAIL_ERROR_TEXT,
  CONTACT_ALREADY_EXISTS_ERROR_TEXT,
  // EMPTY_INPUT_ERROR_TEXT,
} from '../../../common/helpers/ConstantTexts';
import { useFeedback } from '../../../contexts/feedback/store';
import Messaging from '../../../core/components/messaging';
import { PatientContact } from '../../domain/entities/PatientContact';
import { useHcpContext } from '../../../contexts/doctor/store';
import { extractQuery } from '../../../core/helpers/handy';

export const PatientContactCreationDumb = () => {
  const [hasAccepted, setHasAccepted] = useState<boolean>(true);
  const [filledData, setFilledData] = useState<PatientContact>({
    first_name: '-',
    last_name: '-',
    email: '',
    patient_uuid: '',
    role: 'parent',
    relationship: '-',
  });

  // hooks
  const history = useHistory();
  const { t } = useTranslation();
  const { getContacts, createContact, updateContact } = useHcpContext();
  const { getFeedbackIfNecessary } = useFeedback();
  const searchQuery = useLocation().search;

  // states
  const [formIsValid, setFormIsValid] = useState<boolean>();
  // const [roleIsBad, setRoleIsBad] = useState<boolean>(false); // meaning it's either not selected or it's other but nothing was given in the text input
  const [showError, setShowError] = useState<boolean>(false);
  const [formErrorsList, setFormErrorsList] = useState<string[]>();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  // references
  const currentPatientUUID = React.useRef<string>();
  const allContacts = React.useRef<PatientContact[]>([]);

  React.useEffect(() => {
    if (searchQuery === undefined) {
      alert('must select a patient');
      history.goBack();
      return;
    }

    const params = extractQuery<{ patientuuid: string; contactuuid?: string }>(searchQuery);
    if (params.patientuuid === undefined) {
      alert('must select a patient');
      history.goBack();
      return;
    }

    setFilledData((p) => ({ ...p, patient_uuid: params.patientuuid }));
    getContacts(params.patientuuid)
      .then((value) => {
        allContacts.current = value;

        if (params.contactuuid) {
          const foundedContact = value.find((item) => item.uuid === params.contactuuid);
          foundedContact && setFilledData(foundedContact);
        }
      })
      .catch(() => {});
    currentPatientUUID.current = params.patientuuid;
  }, []);

  useEffect(() => {
    filledData.uuid && setHasAccepted(true);
    updateErrors();
  }, [filledData, hasAccepted, showError]);

  const updateErrors = () => {
    // roleIsValid();
    const errList: string[] = [];
    // !mandatoryFieldsValid() && !hasAccepted && errList.push(EMPTY_INPUT_ERROR_TEXT);
    // _.isEmpty(filledData.email) && errList.push(EMPTY_INPUT_ERROR_TEXT);
    !CommonFunctions.isValidEmail(filledData.email) && errList.push(BAD_EMAIL_ERROR_TEXT);
    if (filledData.email && allContacts.current.filter((contact) => contact.email === filledData.email).length) {
      errList.push(CONTACT_ALREADY_EXISTS_ERROR_TEXT);
      setShowError(true);
    }
    // errList.length === 0 && !roleIsValid() && errList.push(EMPTY_INPUT_ERROR_TEXT);
    setFormErrorsList([...errList]);
    setFormIsValid(!errList.length);
  };

  // const roleIsValid = () => {
  //   const badRole = _.isEmpty(filledData.role);
  //   setRoleIsBad(badRole);
  //   return !badRole;
  // };

  // const mandatoryFieldsValid = () => {
  // let allFilled = true;
  // Object.entries(filledData).map(([key, val]) => {
  //   if (key !== 'uuid' && key !== 'otherRole') {
  //     if (_.isEmpty(val)) {
  //       allFilled = false;
  //     }
  //   }
  //   return null;
  // });
  // allFilled = allFilled && hasAccepted;
  // return allFilled;
  // };

  const saveAndNavigate = () => {
    filledData.uuid ? updatePatientContact() : insertPatientContact();
  };

  const insertPatientContact = () => {
    setIsSubmitting(true);
    createContact(filledData)
      .then(() => {
        Messaging.toast(t('IT_WORKED'));
        getFeedbackIfNecessary('Create contact');
        history.goBack();
      })
      .catch((err) => {
        alert(`adding new PatientContact failed\n${JSON.stringify(err)}`);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  const updatePatientContact = () => {
    setIsSubmitting(true);
    updateContact(filledData)
      .then(() => {
        Messaging.toast(t('IT_WORKED'));
        history.goBack();
      })
      .catch((err) => alert(`updating PatientContact failed\n${err}`))
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <p style={styles.title}>
          Bitte vervollständigen Sie <br /> die Angaben zum neuen Kontakt:
        </p>
        {/* <AppTextInput
          label="Vorname*"
          placeholder="Vorname"
          currentVal={filledData.first_name}
          onChange={(str: string) => {
            setFilledData((v) => ({ ...v, first_name: str }));
          }}
          showError={showError && _.isEmpty(filledData.first_name)}
        />
        <AppTextInput
          label="Nachname*"
          placeholder="Nachname"
          currentVal={filledData.last_name}
          onChange={(str: string) => {
            setFilledData((v) => ({ ...v, last_name: str }));
          }}
          showError={showError && _.isEmpty(filledData.last_name)}
        /> */}
        <AppTextInput
          label="E-Mail-Adresse*"
          placeholder="Email"
          currentVal={filledData.email}
          onChange={(str: string) => {
            setFilledData((v) => ({ ...v, email: str }));
          }}
          showError={showError && (_.isEmpty(filledData.email) || !CommonFunctions.isValidEmail(filledData.email))}
        />
        {/* <AppDropDownMenuWithTextInput
          label="Beziehung zum Kind/Jugendlichen*"
          options={Object.values(ContactPatientRelationGr)}
          selectedOption={getShownGermanRelation(filledData.relationship)}
          placeholder="Beziehung zum Kind/Jugendlichen"
          freeTextPlaceholder="Andere Rolle"
          onChangeFunc={(newVal: string) => {
            setFilledData((v) => ({ ...v, relationship: getEnglishRelation(newVal) }));
          }}
          showError={showError && _.isEmpty(filledData.relationship)}
        />
        <AppDropDownMenu
          label="DISYPS-Form*"
          list={Object.values(PcRoleGr)}
          shownValue={
            filledData.role === PcRoleEn.PARENT
              ? PcRoleGr.PARENT
              : filledData.role === PcRoleEn.TEACHER
              ? PcRoleGr.TEACHER
              : undefined
          }
          placeholder="Bitte wählen Sie die relevante DISYPS-Form aus."
          onChangeFunc={(newVal: string) => {
            setFilledData((v) => ({ ...v, role: newVal === PcRoleGr.PARENT ? PcRoleEn.PARENT : PcRoleEn.TEACHER }));
          }}
          showError={showError && roleIsBad}
          extraStyle={{ width: '100%' }}
        />
        {filledData.uuid === undefined && (
          <div style={{ marginTop: AppSizes.SIZE1, width: '100%' }}>
            <AppButtonV2
              topTitle="Datenschutz*"
              label="Ja, ich habe die Erlaubnis eingeholt, das Umfeld des Patienten / der Patientin zu kontaktieren."
              isOn={hasAccepted}
              showError={showError && !hasAccepted}
              action={() => {
                setHasAccepted((v) => !v);
              }}
            />
          </div>
        )} */}
      </div>
      <AppFormErrorList formErrorsList={formErrorsList ?? []} shown={showError} />
      <div style={styles.buttonsOuterContainer}>
        <div style={styles.buttonsCouple}>
          <AppButton label="Abbrechen" color={Color.MONOGREY6} strokeOnly action={history.goBack} />
          <AppButton
            isLoading={isSubmitting}
            loadingIndicatorColor="#FFF"
            disabled={!formIsValid}
            label="Speichern"
            color={Color.MONOGREY6}
            action={() => {
              formIsValid ? saveAndNavigate() : setShowError(true);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    alignItems: 'center',
  },
  topbar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: AppSizes.SIZE1,
    marginTop: AppSizes.DEFAULT,
  },
  innerContainer: {
    marginTop: AppSizes.SIZE2,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    maxWidth: CommonFunctions.mobileSized() ? '95vw' : '40vw',
    alignSelf: 'center',
  },
  title: {
    marginBottom: AppSizes.SIZE2,
    fontWeight: FontWeight.BOLD,
    fontSize: FontSize.H4,
    textAlign: 'center',
    width: '100vw',
    maxWidth: 600,
  },
  buttonsOuterContainer: {
    width: '100%',
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonsCouple: {
    flexDirection: 'row',
    columnGap: 10,
    columnWidth: '50%',
    width: '95vw',
    maxWidth: 500,
  },
  breadcrumbs: {
    flexDirection: 'row',
    fontWeight: FontWeight.BOLD,
    fontSize: FontSize.P1,
  },
  roleContainer: {
    transition: 'all 300ms',
    marginTop: AppSizes.SIZE3,
    borderRadius: 30,
    position: 'relative',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
  },
};
