import { AppState } from '../../common/configuration/app.states';

export const getAllPatients = (state: AppState) => state.patients.patients;

export const getPatientContact = (state: AppState) => state.patients.patientContact;

export const getAllContacts = (state: AppState) => state.patients.contacts;

export const getAllPatientContacts = (state: AppState) => state.patients.allPatientContacts;

export const getCurrentPatient = (state: AppState) => state.patients.currentPatient;
