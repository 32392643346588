import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from '../../assets/locales/de.json';

const resources = {
  de: {
    translation: de,
  },
};

i18n.use(initReactI18next).init({
  lng: 'de',
  fallbackLng: 'de',
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
  resources,
});
