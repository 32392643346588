import { Footer } from '../../../navigationContext/ui/components/Footer';
import TopBar from '../TopBar';
import { useContact } from '../../../contexts/patientContact/store';
import { QuestionnaireProgress } from '../answeringPage/components/QuestionnaireProgress';
import { AppButton } from '../../../common/ui/Others/AppButton';
import { deeplink } from '../../../core/helpers/handy';

export const QuestionnaireFinishlineDumb = () => {
  const { content } = useContact();
  const isFromSleepApp = content.invitation?.source === 'app';

  if (isFromSleepApp) {
    return (
      <div className="full-screen-container">
        <div className="container-fluid" style={{ flex: 1 }}>
          <TopBar />
          <QuestionnaireProgress
            hideLabel
            totalVal={1}
            currentQuestionIndex={1}
            answersLength={content.invitation?.answers.length ?? 0}
          />
          <div className="container mt-3 mt-sm-5">
            <p className="fs-5 fw-bold">Nun geht es zurück</p>
            <p className="fs-6">
              Sie werden nun zur sleep App zurückgeleitet. Dort können Sie Ihre Ergebnisse als PDF herunterladen.
            </p>
            <AppButton
              extraStyle={{ maxWidth: 'inherit' }}
              className="fs-6"
              label="Zurück zur sleep App"
              color="#1246DA"
              action={() => {
                deeplink({
                  bundleLink: 'sleep://',
                  iTunesUrl: 'https://apps.apple.com/sg/app/eyelevel-sleep/id6443792657',
                  playStoreUrl: 'https://play.google.com/store/apps/details?id=care.eyelevel.sleep',
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="full-screen-container">
      <div className="container-fluid" style={{ flex: 1 }}>
        <TopBar />
        <div className="container mt-3 mt-sm-5">
          <div className="row mt-sm-5 mt-3">
            <div className="col-lg-10 offset-lg-1">
              <p>
                Liebe:r Studienteilnehmer:in, Sie haben den Fragebogen erfolgreich bearbeitet und er wurde bereits an
                das Studienteam verschickt. Vielen Dank für Ihre Teilnahme. Sie bekommen noch eine Bestätigungsemail von
                uns.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-none d-md-block">
        <Footer />
      </div>
    </div>
  );
};
