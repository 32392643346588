import { StyleSheet } from '../../domain/entities/StyleSheet';

export interface BigBoxRadioButtonProps {
  selected: boolean;
  content: string;
  indicatorLabel?: string;
  action: () => void;
}
export const BigBoxRadioButton = ({ selected, content, indicatorLabel, action }: BigBoxRadioButtonProps) => (
  <button
    type="button"
    onClick={action}
    style={{
      ...styles.BigBoxRadioRadioContainer,
      background: selected ? '#00144B' : '#CCDAFF',
    }}
  >
    <div className="flex-fill w-100 justify-content-center align-items-center text-center p-3 py-md-4">
      {indicatorLabel && (
        <div
          className={`text-center mb-2 fs-6 p-2 fw-bold d-md-block d-none ${
            selected ? 'border border-white rounded-circle text-white' : ''
          }`}
          style={{ width: '32px', height: '32px', lineHeight: '16px' }}
        >
          {indicatorLabel}
        </div>
      )}
      {content && (
        <div className="fs-6" style={{ color: selected ? '#FFFFFF' : '#0A0A0A' }}>
          {content}
        </div>
      )}
    </div>
  </button>
);

const styles: StyleSheet = {
  BigBoxRadioRadioContainer: {
    cursor: 'pointer',
    alignItems: 'center',
    borderRadius: 14,
    width: '100%',
    overflow: 'hidden',
  },
};
