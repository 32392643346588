import { AppSizes, Color } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../domain/entities/StyleSheet';
import { CheckMark } from '../../../assets/svg/CheckMark';

export interface AppSwitchProps {
  isOn: boolean;
  onClick: () => void;
}
export const AppSwitch = ({ isOn, onClick }: AppSwitchProps) => (
  <button type="button" onClick={onClick} style={styles.switchContainer}>
    <div style={{ ...styles.checkDisk, ...(isOn && { right: 0 }) }}>
      <div style={styles.checkStyle}>
        <CheckMark color={Color.APPWHITE} />
      </div>
    </div>
  </button>
);

const styles: StyleSheet = {
  switchContainer: {
    background: Color.MONOGREY9,
    borderRadius: AppSizes.SIZE2,
    position: 'relative',
    width: AppSizes.SIZE2 * 2,
    height: AppSizes.SIZE2,
  },
  checkDisk: {
    background: Color.MONOGREY6,
    borderRadius: '100%',
    width: AppSizes.SIZE2,
    height: AppSizes.SIZE2,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    transition: 'all 300ms',
  },
  checkStyle: {
    transform: 'scale(1.5)',
  },
};
