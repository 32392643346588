import { Action } from 'redux';
import { connect } from 'react-redux';
import { PatientDetailsDumb } from './PatientDetails.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { navigateBackRequest, navigateToRequest } from '../../../navigationContext/usecases/navigation.actions';
import { getAllAssignedQs } from '../../../questionnairesContext/usecases/questionnaires.selectors';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';

const mapState = (state: AppState) => ({
  stateAllAssignedQs: getAllAssignedQs(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  navigateBack: () => dispatch(navigateBackRequest()),
  navigateTo: (p: NavigationPayload) => dispatch(navigateToRequest(p)),
});

export const PatientDetailsSmart = connect(mapState, mapDispatch)(PatientDetailsDumb);
