import { useLocation } from 'react-router-dom';
import { AppSizes, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../domain/entities/StyleSheet';

export interface RejectionScreenRouteParams {
  content: string;
}
export const RejectionScreenDumb = () => {
  const { content } = useLocation<RejectionScreenRouteParams>().state;

  return (
    <div style={styles.outerContainer}>
      <img alt="logoImage" style={styles.sideBarLogoStyle} src={require('../../../assets/images/logo.png')} />
      <div style={styles.innerContainer}>
        <div style={styles.contentText}>{content}</div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  outerContainer: {
    height: '100vh',
  },
  innerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  sideBarLogoStyle: {
    width: 200,
    marginTop: AppSizes.SIZE1,
    marginLeft: AppSizes.SIZE1,
  },
  contentText: {
    fontWeight: FontWeight.BOLDER,
    fontSize: FontSize.H4,
    height: 200,
    maxWidth: 600,
    textAlign: 'center',
  },
};
