import { Color } from '../styles/constantStyles';

export interface TwoPeopleProps {
  color?: string;
}

export const TwoPeople = ({ color }: TwoPeopleProps) => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 10.6667C12.7072 10.6667 13.3855 10.9476 13.8856 11.4477C14.3857 11.9478 14.6667 12.6261 14.6667 13.3333V15.3333C14.6667 18.072 11.8827 20 7.33333 20C2.784 20 0 18.072 0 15.3333V13.3333C0 12.6261 0.280951 11.9478 0.781048 11.4477C1.28115 10.9476 1.95942 10.6667 2.66667 10.6667H12ZM18.6667 10.6667C19.3739 10.6667 20.0522 10.9476 20.5523 11.4477C21.0524 11.9478 21.3333 12.6261 21.3333 13.3333V14C21.3333 16.7853 19.2427 18.6667 15.3333 18.6667C15.1453 18.6667 14.96 18.6627 14.7813 18.6533C15.5092 17.8165 15.935 16.7596 15.9907 15.652L16 15.3333V13.3333C16 12.4107 15.688 11.56 15.1627 10.884L14.98 10.6667H18.6667ZM7.33333 0C7.94617 -9.13196e-09 8.553 0.120707 9.11919 0.355229C9.68538 0.589751 10.1998 0.933495 10.6332 1.36683C11.0665 1.80018 11.4102 2.31462 11.6448 2.88081C11.8793 3.447 12 4.05383 12 4.66667C12 5.2795 11.8793 5.88634 11.6448 6.45252C11.4102 7.01871 11.0665 7.53316 10.6332 7.9665C10.1998 8.39984 9.68538 8.74358 9.11919 8.97811C8.553 9.21263 7.94617 9.33333 7.33333 9.33333C6.09566 9.33333 4.90867 8.84167 4.0335 7.9665C3.15833 7.09133 2.66667 5.90434 2.66667 4.66667C2.66667 3.42899 3.15833 2.242 4.0335 1.36683C4.90867 0.491665 6.09566 1.84428e-08 7.33333 0ZM16.6667 2.66667C17.5507 2.66667 18.3986 3.01786 19.0237 3.64298C19.6488 4.2681 20 5.11595 20 6C20 6.88406 19.6488 7.7319 19.0237 8.35702C18.3986 8.98214 17.5507 9.33333 16.6667 9.33333C15.7826 9.33333 14.9348 8.98214 14.3096 8.35702C13.6845 7.7319 13.3333 6.88406 13.3333 6C13.3333 5.11595 13.6845 4.2681 14.3096 3.64298C14.9348 3.01786 15.7826 2.66667 16.6667 2.66667Z"
      fill={color || Color.MONOGREY6}
    />
  </svg>
);
