import { Action } from 'redux';
import { connect } from 'react-redux';
import { AppTopBar } from '../../../../common/ui';
import { navigateToRequest } from '../../../usecases/navigation.actions';
import { SideBar } from '../../components/SideBar';
import { NavigationPayload } from '../../../domain/entities/NavigationPayload';

type Props = {
  navigateTo: (p: NavigationPayload) => void;
  children: JSX.Element;
};

const DoctorPanelScreensWrapperScreen = ({ children, navigateTo }: Props) => (
  <div style={{ flexDirection: 'row', position: 'relative', flex: 1 }}>
    <SideBar sideBarExpanded navigateTo={navigateTo} />
    <div style={{ flex: 1, height: '100vh', overflow: 'scroll' }}>
      <AppTopBar />
      {children}
    </div>
  </div>
);

const mapState = () => ({});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  navigateTo: (p: NavigationPayload) => dispatch(navigateToRequest(p)),
});

export default connect(mapState, mapDispatch)(DoctorPanelScreensWrapperScreen);
