import React from 'react';
import { resolveValue, ValueOrFunction } from './handy';
import log from './logger';

function useStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = React.useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      log.error(error);
      return initialValue;
    }
  });

  const setValue = (value: ValueOrFunction<T, T>) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = resolveValue(value, storedValue);
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      log.error(error);
    }
  };

  const clear = () => {
    setStoredValue(initialValue);
    window.localStorage.removeItem(key);
  };

  return { value: storedValue, setValue, clear };
}

export default useStorage;
