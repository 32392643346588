import { BACKEND_CONFIG } from '../../../configuration';
import { HelpObj } from '../../domain/entities/HelpObj';
import { HelpSpaceInteractor } from '../../domain/gateways/HelpSpace.interactor';
import { fetchWithToken } from '../fetch.helper';

export class RealHelpSpaceInteractor implements HelpSpaceInteractor {
  async sendHelpAnswers(helpObj: HelpObj): Promise<string> {
    const url = `${BACKEND_CONFIG.endpoints.tickets}`;
    const objectToSend = {
      ...helpObj,
    };
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify(objectToSend),
    });

    if (!response.ok) {
      throw new Error(`${response.status}`);
    }
    const res = await response.json();
    return res.message;
  }
}
