import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { ACCESS_TOKEN_LIFE_SPAN } from '../../configuration';
import { InvitationStatus } from '../../questionnairesContext/domain/entities/QInvitation';

export default class CommonFunctions {
  static containsLetter = (str: string | undefined) => {
    const regExp = /[a-zA-Z]/g;
    if (str) {
      return regExp.test(str);
    }
    return false;
  };

  static containsNumber = (str: string | undefined) => {
    const regExp = /\d/;
    if (str) {
      return regExp.test(str);
    }
    return false;
  };

  static containsNoOtherUnwantedChars = (str: string | undefined) => {
    if (str) {
      return !str.includes(' ') && !str.includes('"') && !str.includes('^');
    }
    return false;
  };

  // static isValidPasswordFormat = (pass: string | undefined) =>
  //   this.containsNoOtherUnwantedChars(pass) && this.containsLetter(pass) && this.containsNumber(pass);

  static isValidEmail = (email: string | undefined) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (typeof email === 'string' && email.length) {
      return re.test(_.trim(String(email).toLowerCase()));
    }
    return false;
  };

  static mobileSized = () => {
    const res = isMobile || window.innerWidth < 800;
    return res;
  };

  static searchArrayForString = (SearchQuery: string, searchPool: any[]) => {
    if (_.trim(SearchQuery).length === 0) {
      return searchPool;
    }
    const trimmedQuery: string = _.trim(SearchQuery).toLowerCase();
    const foundResults: any[] = searchPool.filter((item) => JSON.stringify(item).toLowerCase().includes(trimmedQuery));
    return foundResults;
  };

  static patientStatusString = (progress: number, max: number) => {
    let status: string = InvitationStatus.OPENED;
    if (progress > 0) {
      status = InvitationStatus.STARTED;
    }
    if (progress === max) {
      status = InvitationStatus.FINISHED;
    }
    if (progress > max) {
      status = `Error ${progress}/${max}`;
    }
    return status;
  };

  static convertToAppDate = (date: string) => moment(date).format('DD.MM.YYYY');

  static containsUpperCase = (str: string) => /[A-Z]/.test(str);

  static containsLowerCase = (str: string) => /[a-z]/.test(str);

  static getMax(arr: number[]): number {
    if (arr.length === 0) {
      return 0;
    }
    return arr.reduce((a, b) => Math.max(a, b), -Infinity);
  }

  static getAge(dateString: string): number {
    return moment().diff(moment(dateString, 'DD.MM.YYYY'), 'years');
  }

  static createTokenExpiryTime = (lifeSpan = ACCESS_TOKEN_LIFE_SPAN) =>
    moment().add(moment.duration(lifeSpan, 'seconds')).valueOf();

  static translateGenderToGr(str: string | undefined) {
    switch (str) {
      case 'female':
        return 'weiblich';
      case 'male':
        return 'männlich';
      case 'divers':
        return 'divers';
      default:
        return '';
    }
  }

  static translateGenderToEn(str: string) {
    switch (str) {
      case 'weiblich':
        return 'female';
      case 'männlich':
        return 'male';
      case 'divers':
        return 'divers';
      default:
        return '';
    }
  }

  static downloadFile(fileURL: string, fileName: string) {
    // for non-IE
    if (!window.ActiveXObject) {
      const save = document.createElement('a');
      save.href = fileURL;
      save.target = '_blank';
      const filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
      save.download = fileName || filename;
      if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search('Chrome') < 0) {
        document.location = save.href;
        // window event not working here
      } else {
        const evt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }
    // for IE < 11
    else {
      const popUp = window.open(fileURL, '_blank');
      popUp?.document.close();
      popUp?.document.execCommand('SaveAs', true, fileName || fileURL);
      popUp?.close();
    }
  }
}
