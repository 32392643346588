import React from 'react';
import _ from 'lodash';
import { FetchiError } from 'fetchi-request';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../../common/domain/entities/StyleSheet';
import CommonFunctions from '../../../common/helpers/CommonFunctions';
import { emptyUserAccount, UserAccount } from '../../../contexts/user/entities/UserAccount';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { AppButton } from '../../../common/ui/Others/AppButton';
import { AppTextInput } from '../../../common/ui/Others/AppTextInput';
import { AppFormErrorList } from '../../../common/ui/Others/AppFormErrorList';
import {
  BAD_EMAIL_ERROR_TEXT,
  BETA_LOGIN_WARNING,
  EMPTY_INPUT_ERROR_TEXT,
  WRONG_CREDENTIALS_ERROR_TEXT,
} from '../../../common/helpers/ConstantTexts';
import { AppAlert } from '../../../common/ui/Others/AppAlert';
import { useUser } from '../../../contexts/user/store';
import { Footer } from '../../../navigationContext/ui/components/Footer';

interface SignInProps {
  navigateTo: (p: NavigationPayload) => void;
  navigateBack: () => void;
}
export const SignInDumb = ({ navigateTo, navigateBack }: SignInProps) => {
  const [showNotAllowedEmailOverlay, setShowNotAllowedEmailOverlay] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<boolean>(false);
  const [wrongCredentials, setWrongCredentials] = React.useState<boolean>(false);
  const [formIsValid, setFormIsValid] = React.useState<boolean>(false);
  const [isSubmittionPending, setIsSubmitionPending] = React.useState<boolean>(false);
  const [filledData, setFilledData] = React.useState<UserAccount>({
    ...emptyUserAccount,
  });
  const [formErrorsList, setFormErrorsList] = React.useState<string[]>();
  const isFocused = React.useRef<boolean>(false);
  const { login } = useUser();

  React.useEffect(() => {
    updateErrors();
  }, [filledData.email, filledData.password, wrongCredentials]);

  React.useEffect(() => {
    isFocused.current = true;
    return () => {
      isFocused.current = false;
    };
  }, []);

  const updateErrors = () => {
    let isValid = true;
    const errText = [];
    Object.entries(filledData).map(([key, val]) => {
      if (key !== 'uuid' && key !== 'otherRole') {
        if (_.isEmpty(val)) {
          isValid = false;
          errText.push(EMPTY_INPUT_ERROR_TEXT);
        }
      }
      return null;
    });
    if (!CommonFunctions.isValidEmail(filledData.email)) {
      isValid = false;
      errText.push(BAD_EMAIL_ERROR_TEXT);
    }
    if (wrongCredentials) {
      isValid = false;
      errText.push(WRONG_CREDENTIALS_ERROR_TEXT);
    }

    setFormIsValid(isValid);
    setFormErrorsList(errText);
    return isValid;
  };

  const sendSignIn = () => {
    setFormErrorsList(undefined);
    setShowError(false);
    setIsSubmitionPending(true);
    login({ email: filledData.email, password: filledData.password })
      .then((res) => {
        navigateTo({ routeName: '', params: res });
      })
      .catch((err: FetchiError) => {
        if (err.status === 201) {
          // Confirmation code sent
          navigateTo({ routeName: 'Email-Activation-Code-Sent' });
        } else if (err.status === 402) {
          // Not an allowed test account
          setShowNotAllowedEmailOverlay(true);
        } else if (err.status === 401) {
          // Invalid email or password
          setWrongCredentials(true);
          setShowError(true);
        } else {
          alert(`Error sendSignIn\n${JSON.stringify(err)}`);
        }
      })
      .finally(() => {
        if (isFocused.current) {
          setIsSubmitionPending(false);
        }
      });
  };

  return (
    <div className="full-screen-container">
      <div className="container-fluid" style={{ flex: 1 }}>
        <img alt="logoImage" style={styles.sideBarLogoStyle} src={require('../../../assets/images/logo.png')} />
        <div className="flex-fill align-self-stretch justify-content-center align-items-center d-flex">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3 text-center">
                <p style={styles.title}>Bitte geben Sie Ihre Anmeldedaten ein:</p>
                <AppTextInput
                  label="E-Mail-Adresse*"
                  placeholder="E-Mail-Adresse"
                  currentVal={filledData.email}
                  onChange={(str: string) => {
                    setFilledData((v) => ({ ...v, email: str }));
                    setWrongCredentials(false);
                  }}
                  showError={showError && !CommonFunctions.isValidEmail(filledData.email)}
                />
                <AppTextInput
                  isPass
                  label="Passwort*"
                  placeholder="Passwort"
                  currentVal={filledData.password}
                  onChange={(str: string) => {
                    setFilledData((v) => ({ ...v, password: str }));
                    setWrongCredentials(false);
                  }}
                  showError={showError && _.isEmpty(filledData.password)}
                />
                <button
                  type="button"
                  onClick={() => {
                    navigateTo({ routeName: 'Password-Forgotten' });
                  }}
                  style={styles.theForgottenContainer}
                >
                  Passwort vergessen?
                </button>
                <AppFormErrorList formErrorsList={formErrorsList ?? []} shown={showError} />
                <div className="row mt-5">
                  <div className="col-md-6 align-items-center">
                    <AppButton label="Zurück" color={Color.MONOGREY6} strokeOnly action={navigateBack} />
                  </div>
                  <div className="col-md-6 align-items-center mt-3 mt-md-0">
                    <AppButton
                      disabled={!formIsValid || isSubmittionPending}
                      label="Anmelden"
                      color={Color.MONOGREY6}
                      loadingIndicatorColor={Color.APPWHITE}
                      isLoading={isSubmittionPending}
                      action={() => {
                        setShowError(true);
                        if (formIsValid) {
                          sendSignIn();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <AppAlert
                isOn={showNotAllowedEmailOverlay}
                content={
                  <div>
                    <p>{BETA_LOGIN_WARNING}</p>
                  </div>
                }
                close={() => {
                  setShowNotAllowedEmailOverlay(false);
                }}
                buttons={[
                  {
                    label: 'Verstanden',
                    action: () => {
                      setShowNotAllowedEmailOverlay(false);
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const styles: StyleSheet = {
  sideBarLogoStyle: {
    width: 200,
    paddingTop: AppSizes.SIZE1,
  },
  title: {
    marginBottom: AppSizes.SIZE2,
    fontWeight: FontWeight.BOLDER,
    fontSize: FontSize.H4,
    textAlign: 'center',
    width: '100vw',
    maxWidth: 600,
  },
  theForgottenContainer: {
    marginTop: 12,
    marginBottom: AppSizes.DEFAULT,
    fontSize: FontSize.P3,
    fontWeight: FontWeight.REGULAR,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};
