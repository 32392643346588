export type UserAccount = {
  email: string;
  password: string;
  passRepeat?: string;
  rememberUser?: boolean;
  tos_version?: string;
  data_security_version?: string;
  data_use_version?: string;
};

export const emptyUserAccount: UserAccount = {
  email: '',
  password: '',
};
