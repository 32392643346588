export const WRONG_CREDENTIALS_ERROR_TEXT = 'Die angegebene E-Mail-Adresse oder das Passwort sind Falsch.';
export const WRONG_CURRENT_PASSWORD_ERROR_TEXT = 'Das angegebene Passwort ist falsch.';
export const NEW_EQUALS_CURRENT_PASSWORD_ERROR_TEXT = 'Das neue Passwort darf nicht mit dem vorherigen übereinstimmen.';
export const CONTACT_ALREADY_EXISTS_ERROR_TEXT = 'Es besteht schon ein Account mit dieser E-Mail-Adresse!';
export const NO_Q_SELECTED_ERROR_TEXT = 'DCL auswählen';
export const NO_ASMNT_SELECTED_ERROR_TEXT = 'Beurteilungsmethoden auswählen';
export const WRONG_EMAIL_ACTIVATION_CODE_ERROR_TEXT = 'Falscher Aktivierungscode';
export const EXISTING_EMAIL_ERROR_TEXT = 'Es gibt schon einen Nutzer mit Ihrer E-Mailadresse';
export const BAD_EMAIL_ERROR_TEXT = 'Bitte geben Sie eine gültige E-Mail Adresse ein.';
export const BAD_PASSWORD_ERROR_TEXT = 'Bitte geben Sie ein gültiges Passwort ein.';
export const PASS_REPEAT_NO_MATCH_ERROR_TEXT = 'Passwort und Bestätigung stimmen nicht überein.';
export const AGB_NOT_ACCEPTED_ERROR_TEXT = 'Bitte stimmen Sie der Datenschutzerklärung zu.';
export const PRIVACY_NOT_ACCEPTED_ERROR_TEXT = 'Bitte stimmen Sie den AGB zu.';
export const EMPTY_INPUT_ERROR_TEXT = 'Bitte füllen Sie alle markierten Pflichtfelder aus.';
export const ACC_NO_EXIST_ERROR_TEXT =
  'Die Email-Adresse und das Passwort stimmen nicht mit einem aktiven Konto überein';
export const BETA_REGISTRATION_WARNING =
  'Ihre E-Mail Adresse ist leider nicht für diese Testphase registriert. Im Anschluss werden Sie sich nicht anmelden können. Bei Fragen kontaktieren Sie uns bitte.';
export const BETA_LOGIN_WARNING =
  'Ihre E-Mail Adresse ist leider nicht für diese Testphase registriert. Bei Fragen kontaktieren Sie uns bitte.';
export const FALSE_QUESTIONNAIRE_DATA_P1 = 'Liebe:r ';
export const FALSE_QUESTIONNAIRE_DATA_P2 =
  ', Sie haben die Bearbeitung des Fragebogens abgelehnt. Der / die Behandler:in ';
export const FALSE_QUESTIONNAIRE_DATA_P3 = 'wird automatisch informiert. ';
export const DELETED_QUESTIONNAIRE_ERROR_TEXT =
  'Der Fragebogen, auf den Sie zugreifen wollen, ist nicht mehr zugänglich.';

export const USER_DATA_CORRECT_OPTIONS = ['Diese Angaben sind korrekt.', 'Diese Angaben sind nicht korrekt.'];
export const CONFIRMATION_CODE_SENT = 'Confirmation code sent';
