import TopBar from './TopBar';
import { Footer } from '../../navigationContext/ui/components/Footer';

export const ExpiredQuestionnaire = () => (
  <div className="full-screen-container">
    <div className="container-fluid" style={{ flex: 1 }}>
      <TopBar />
      <div className="container mt-3 mt-sm-5">
        <div className="row mt-sm-5 mt-3">
          <div className="col-lg-10 offset-lg-1">
            <p>
              <strong>Liebe/r Studienteilnehmer/in,</strong>
            </p>
            <p>
              <strong>...wollten Sie gerade die erste Umfrage der hiPanya-Studie beantworten?</strong>
            </p>
            <p>
              Bitte setzen Sie sich mit unserem Studienteam in Verbindung, da Sie manuell erneut freigeschaltet werden
              müssen:
            </p>
            <p>Tel: 02371/973-8710</p>
            <p>
              E-Mail: <a href="mailto:hipanya-studienteam@medice.de">hipanya-studienteam@medice.de</a>
            </p>
            <p>
              <strong>...wollten Sie gerade die zweite oder dritte Umfrage beantworten?</strong>
            </p>
            <p>
              Sie erhalten diese Meldung, weil Sie an dieser Umfrage zu diesem Zeitpunkt leider nicht mehr teilnehmen
              können. Um die Aussagekraft der Studienergebnisse zu gewährleisten, können Umfragen generell nur innerhalb
              von 2 Wochen beantwortet werden.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
