import { ContactInvitation } from './types';
import { Patient } from '../../patientsContext/domain/entities/Patient';
import { PatientContact } from '../../patientsContext/domain/entities/PatientContact';
import { QuestionnaireObj } from '../../questionnairesContext/domain/entities/QuestionnaireObj';
import { User } from '../user/entities/User';

export enum ActionTypes {
  getInvitation = 'GET_INVITATION',
  getContact = 'GET_CONTACT',
  getPatient = 'GET_PATIENT',
  getContent = 'GET_CONTENT',
  getQuestionnaire = 'GET_QUESTINNAIRE',
  getDoctor = 'GET_DOCTOR',
  gotError = 'GET_ERROR',
  setToken = 'SET_TOKEN',
  flushContent = 'FLUSH_CONTENT',
  presetDataOnce = 'FETCHED_DATA_PRESENTED',
}

type PresetDataAction = {
  type: ActionTypes.presetDataOnce;
};

type GetContentAction = {
  type: ActionTypes.getContent;
  questionnaire?: QuestionnaireObj;
  contact?: PatientContact;
  invitation?: ContactInvitation;
  patient?: Patient;
  doctor?: User;
};

type GetInvitationAction = {
  type: ActionTypes.getInvitation;
  invitation: ContactInvitation;
};

type SetTokenAction = {
  type: ActionTypes.setToken;
  token: string;
};

type FlushContent = {
  type: ActionTypes.flushContent;
};

type GetContactInfoAction = {
  type: ActionTypes.getContact;
  contact: PatientContact;
};

type GetPatientInfoAction = {
  type: ActionTypes.getPatient;
  patient: Patient;
};

type GetDoctorInfoAction = {
  type: ActionTypes.getDoctor;
  doctor: User;
};

type ErrorRecieveAction = {
  type: ActionTypes.gotError;
  error: Error;
};

type GetQuestionnaireAction = {
  type: ActionTypes.getQuestionnaire;
  questionnaire: QuestionnaireObj;
};

export type Actions =
  | FlushContent
  | SetTokenAction
  | PresetDataAction
  | GetContentAction
  | ErrorRecieveAction
  | GetDoctorInfoAction
  | GetInvitationAction
  | GetContactInfoAction
  | GetPatientInfoAction
  | GetQuestionnaireAction;
