import { Feedback, FeedbackCategory, ServerStoredFeedback } from './types';

export enum ActionTypes {
  SendFeed = 'Send_Feedback',
  UpdateStore = 'Update_Store',
}

export type UpdateStorections = {
  type: ActionTypes.UpdateStore;
  payload: ServerStoredFeedback[];
};

export type SendFeedbackAction = {
  type: ActionTypes.SendFeed;
  feedback: Feedback;
  category: FeedbackCategory;
  sessionKey: string;
};

export type Actions = SendFeedbackAction | UpdateStorections;
