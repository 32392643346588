import { useEffect, useState } from 'react';
import { Color } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../domain/entities/StyleSheet';

interface ProgressBarProps {
  currentVal: number;
  totalVal: number;
  height?: number;
  width?: number;
  progressColor?: string;
  backgroundColor?: string;
  roundedBorders?: boolean;
  // vertical?: boolean;
}

export const ProgressBar = ({
  currentVal,
  totalVal,
  height,
  width,
  progressColor,
  backgroundColor,
  roundedBorders,
}: ProgressBarProps) => {
  const [FillBarWidth, setFillBarWidth] = useState<number>(0);

  useEffect(() => {
    setFillBarWidth(currentVal * (1 / totalVal));
  }, [currentVal, totalVal]);

  return (
    <div
      style={{
        ...styles.outerContainer,
        width: width || '100%',
        height: height || 4,
        borderRadius: roundedBorders ? 1000 : 0,
        background: backgroundColor || Color.MONOGREY7,
      }}
    >
      <div
        style={{
          height: '100%',
          background: progressColor || Color.MONOGREY6,
          width: `${FillBarWidth * 100}%`,
          transition: 'all 300ms',
        }}
      />
    </div>
  );
};

const styles: StyleSheet = {
  outerContainer: {
    width: '100%',
    background: Color.MONOGREY7,
    overflow: 'hidden',
  },
};
