import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import {
  deletePatientContactFailure,
  deletePatientContactRequest,
  deletePatientContactSuccess,
  deletePatientFailure,
  deletePatientRequest,
  deletePatientSuccess,
  insertPatientContactFailure,
  insertPatientContactRequest,
  insertPatientContactSuccess,
  insertPatientFailure,
  insertPatientRequest,
  insertPatientSuccess,
  loadAllContactsFailure,
  loadAllContactsRequest,
  loadAllContactsSuccess,
  loadPatientContactsFailure,
  loadPatientContactsRequest,
  loadPatientContactsSuccess,
  loadPatientsFailure,
  loadPatientsRequest,
  loadPatientsSuccess,
  setAppCurrentPatientRequest,
  setAppCurrentPatientSuccess,
  updatePatientContactFailure,
  updatePatientContactRequest,
  updatePatientContactSuccess,
  updatePatientFailure,
  updatePatientRequest,
  updatePatientSuccess,
} from './Patients.actions';
import { getDependencies } from '../../common/usecases/common.sagas';
import { DependenciesType } from '../../configuration/dependencies';
import { Patient } from '../domain/entities/Patient';
import { PatientContact } from '../domain/entities/PatientContact';

function* setAppCurrentPatientSaga(action: Action<Patient | null>) {
  yield put(setAppCurrentPatientSuccess(action.payload));
}

function* loadPatientsSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: Patient[] = yield call(dep.patientsInteractor.loadPatients);
    yield put(loadPatientsSuccess(res));
  } catch (error) {
    yield put(loadPatientsFailure(error));
  }
}
function* insertPatientSaga(action: Action<Patient>) {
  try {
    const newPatient: Patient = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: Patient = yield call(dep.patientsInteractor.insertPatient, newPatient);
    yield put(insertPatientSuccess(res));
  } catch (error) {
    yield put(insertPatientFailure(error));
  }
}

function* updatePatientSaga(action: Action<Patient>) {
  try {
    const updatedPatient: Patient = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: Patient = yield call(dep.patientsInteractor.updatePatient, updatedPatient);
    yield put(updatePatientSuccess(res));
  } catch (error) {
    yield put(updatePatientFailure(error));
  }
}
function* deletePatientSaga(action: Action<Patient>) {
  try {
    const patientToDelete: Patient = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: Patient = yield call(dep.patientsInteractor.deletePatient, patientToDelete);
    yield put(deletePatientSuccess(res));
  } catch (error) {
    yield put(deletePatientFailure(error));
  }
}
function* loadPatientContactsSaga(action: Action<Patient>) {
  try {
    const patient: Patient = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: PatientContact[] = yield call(dep.patientContactsInteractor.loadPatientContacts, patient);
    yield put(loadPatientContactsSuccess(res));
  } catch (error) {
    yield put(loadPatientContactsFailure(error));
  }
}

function* deletePatientContactSaga(action: Action<PatientContact>) {
  try {
    const patientContact: PatientContact = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: PatientContact = yield call(dep.patientContactsInteractor.deletePatientContact, patientContact);
    yield put(deletePatientContactSuccess(res));
  } catch (error) {
    yield put(deletePatientContactFailure(error));
  }
}

function* insertPatientContactSaga(action: Action<PatientContact>) {
  try {
    const patientContact: PatientContact = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: PatientContact = yield call(dep.patientContactsInteractor.insertPatientContact, patientContact);
    yield put(insertPatientContactSuccess(res));
  } catch (error) {
    yield put(insertPatientContactFailure(error));
  }
}

function* updatePatientContactSaga(action: Action<PatientContact>) {
  try {
    const patientContact: PatientContact = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: PatientContact = yield call(dep.patientContactsInteractor.updatePatientContact, patientContact);
    yield put(updatePatientContactSuccess(res));
  } catch (error) {
    yield put(updatePatientContactFailure(error));
  }
}
function* loadAllContactsSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: PatientContact[] = yield call(dep.patientContactsInteractor.loadAllContacts);
    yield put(loadAllContactsSuccess(res));
  } catch (error) {
    yield put(loadAllContactsFailure(error));
  }
}

export function* patientsSagas() {
  yield all([
    takeLatest(loadPatientsRequest, loadPatientsSaga),
    takeLatest(insertPatientRequest, insertPatientSaga),
    takeLatest(updatePatientRequest, updatePatientSaga),
    takeLatest(deletePatientRequest, deletePatientSaga),
    takeLatest(setAppCurrentPatientRequest, setAppCurrentPatientSaga),
    takeLatest(loadPatientContactsRequest, loadPatientContactsSaga),
    takeLatest(insertPatientContactRequest, insertPatientContactSaga),
    takeLatest(updatePatientContactRequest, updatePatientContactSaga),
    takeLatest(deletePatientContactRequest, deletePatientContactSaga),
    takeLatest([loadAllContactsRequest, insertPatientContactSuccess], loadAllContactsSaga),
  ]);
}
