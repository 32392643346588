import _ from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { AppSizes, Color } from '../../../../assets/styles/constantStyles';
import { StyleSheet } from '../../../../common/domain/entities/StyleSheet';
import { AppTextInput } from '../../../../common/ui/Others/AppTextInput';
import { ErrorExclamationMark } from '../../../../common/ui/Others/ErrorExclamationMark';
import { User } from '../../../../contexts/user/entities/User';
import { AppDropDownMenu } from '../../../../common/ui/Others/AppDropDownMenu';
import { genderChoicesGr } from '../../../../patientsContext/domain/entities/Gender';
import CommonFunctions from '../../../../common/helpers/CommonFunctions';

const titleList: string[] = ['Doktor', 'Psychotherapeut', 'Anderer'];
interface UserPersonalDataFormProps {
  showError: boolean;
  filledData: User;
  setFilledData: Dispatch<SetStateAction<User>>;
}
export const UserPersonalDataForm = ({ showError, filledData, setFilledData }: UserPersonalDataFormProps) => (
  <div style={styles.container}>
    <AppDropDownMenu
      label="Rolle*"
      list={titleList}
      shownValue={filledData.title}
      placeholder="Rolle"
      disabled={!filledData.title}
      onChangeFunc={(newVal: string) => {
        setFilledData((v) => ({ ...v, title: newVal }));
      }}
      showError={showError && _.isEmpty(filledData.title)}
      extraStyle={{ width: '100%', marginBottom: -4 }}
    />
    <AppTextInput
      label="Vorname*"
      placeholder="Vorname"
      currentVal={filledData.first_name}
      onChange={(str: string) => {
        setFilledData((v) => ({ ...v, first_name: str }));
      }}
      showError={showError && _.isEmpty(filledData.first_name)}
    />
    <AppTextInput
      label="Nachname*"
      placeholder="Nachname"
      currentVal={filledData.last_name}
      onChange={(str: string) => {
        setFilledData((v) => ({ ...v, last_name: str }));
      }}
      showError={showError && _.isEmpty(filledData.last_name)}
    />

    <AppDropDownMenu
      label="Gender"
      list={genderChoicesGr}
      shownValue={CommonFunctions.translateGenderToGr(filledData.gender ?? '')}
      placeholder="Gender auswählen"
      disabled={!filledData.gender}
      onChangeFunc={(newVal: string) => {
        setFilledData((v) => ({ ...v, gender: CommonFunctions.translateGenderToEn(newVal) }));
      }}
      showError={showError && _.isEmpty(filledData.gender)}
      extraStyle={{ width: '100%', marginBottom: -4 }}
    />
    <div
      style={{
        ...styles.inlineInputsContainer,
        border: `1px solid ${
          showError && (_.isEmpty(filledData.street) || _.isEmpty(filledData.house_number))
            ? Color.DANGER
            : 'transparent'
        }`,
      }}
    >
      <ErrorExclamationMark shown={showError && (_.isEmpty(filledData.street) || _.isEmpty(filledData.house_number))} />
      <div
        style={{
          ...styles.inlineInputsContainerInner,
          ...(showError &&
            (_.isEmpty(filledData.street) || _.isEmpty(filledData.house_number)) && {
              margin: AppSizes.SIZE1,
              marginTop: 0,
            }),
        }}
      >
        <AppTextInput
          label="Straße*"
          placeholder="Straße"
          currentVal={filledData.street ?? ''}
          onChange={(str: string) => {
            setFilledData((v) => ({
              ...v,
              street: str,
            }));
          }}
          extraStyle={{ margin: 0 }}
        />
        <AppTextInput
          label="Hausnr.*"
          placeholder="Hausnr."
          currentVal={filledData.house_number ?? ''}
          onChange={(str: string) => {
            setFilledData((v) => ({
              ...v,
              house_number: str,
            }));
          }}
          extraStyle={{ width: '20%', margin: 0 }}
        />
      </div>
    </div>

    <AppTextInput
      label="Adresszusatz"
      placeholder="Adresszusatz"
      currentVal={filledData.address ?? ''}
      onChange={(str: string) => {
        setFilledData((v) => ({
          ...v,
          address: str,
        }));
      }}
      // showError={showError && _.isEmpty(filledData.address)}
    />

    <div
      style={{
        ...styles.inlineInputsContainer,
        border: `1px solid ${
          showError && (_.isEmpty(filledData.city) || _.isEmpty(filledData.postcode)) ? Color.DANGER : 'transparent'
        }`,
      }}
    >
      <ErrorExclamationMark shown={showError && (_.isEmpty(filledData.city) || _.isEmpty(filledData.postcode))} />
      <div
        style={{
          ...styles.inlineInputsContainerInner,
          ...(showError &&
            (_.isEmpty(filledData.city) || _.isEmpty(filledData.postcode)) && {
              margin: AppSizes.SIZE1,
              marginTop: 0,
            }),
        }}
      >
        <AppTextInput
          label="PLZ*"
          placeholder="PLZ"
          currentVal={filledData.city ?? ''}
          onChange={(str: string) => {
            setFilledData((v) => ({
              ...v,
              city: str,
            }));
          }}
          extraStyle={{ width: '30%', margin: 0 }}
        />
        <AppTextInput
          label="Ort*"
          placeholder="Ort"
          currentVal={filledData.postcode ?? ''}
          onChange={(str: string) => {
            setFilledData((v) => ({
              ...v,
              postcode: str,
            }));
          }}
          extraStyle={{ margin: 0 }}
        />
      </div>
    </div>
  </div>
);

const styles: StyleSheet = {
  container: {
    flex: 1,
    padding: AppSizes.SIZE1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  inlineInputsContainer: {
    transition: 'all 300ms',
    marginTop: AppSizes.SIZE3,
    paddingTop: AppSizes.SIZE2,
    borderRadius: 30,
    position: 'relative',
    width: '100%',
  },
  inlineInputsContainerInner: {
    transition: 'all 300ms',
    flexDirection: 'row',
    columnGap: AppSizes.SIZE1,
    justifyContent: 'space-between',
  },
};
