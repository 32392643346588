import { AppSizes, Color, FontSize, FontWeight, LineHeight } from '../../../assets/styles/constantStyles';
// eslint-disable-next-line import/no-cycle
import { Footer } from '../../../navigationContext/ui/components/Footer';
import { StyleSheet } from '../../domain/entities/StyleSheet';

// &ldquo;
export const Impressum = () => (
  <div className="full-screen-container">
    <div className="container" style={{ flex: 1 }}>
      <div className="row do-not-break-out">
        <div className="col-md-10 offset-md-1">
          <div style={styles.textContainer}>
            <p style={styles.termsTitle}>Impressum</p>
            <p style={{ ...styles.termsText, ...styles.termsSubTitle }}>Verantwortlich</p>
            <p style={styles.termsText}>
              Medigital GmbH
              <br />
              Medice-Allee 1
              <br />
              58638 Iserlohn
            </p>
            <p style={styles.termsText}>
              <a href="mailto:info-medigital@medice.de">info-medigital@medice.de</a>
            </p>
            <p style={{ ...styles.termsText, ...styles.termsSubTitle }}>Vertretungsberechtigte Person:</p>
            <p style={styles.termsText}>
              Dr. Felix Lambrecht
              <br />
              Handelsregister Amtsgericht Iserlohn HRB 10758
              <br />
              Umsatzsteuer-Identifikations-Nr.: DE 329579300
            </p>

            <p style={{ ...styles.termsText, ...styles.termsSubTitle }}>Angaben für Notfalle</p>
            <p style={styles.termsText}>
              In Notfällen oder bei Bedarf wenden Sie sich an Ihren behandelnden Arzt oder Psychotherapeuten oder an die
              Telefonseelsorge. Die Telefonseelsorge können Sie rund um die Uhr erreichen unter den gebührenfreien
              Telefonnummern 0800 111 0 111 oder 0800 111 0 222.
            </p>
            <p style={styles.termsText}>
              Hier erhalten Sie kompetente, vertrauliche und anonyme Hilfe. Weitere Hilfe erhalten Sie auch unter den
              bekannten Notrufnummern Polizei: 110 und Feuerwehr: 112
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

const styles: StyleSheet = {
  textContainer: {
    padding: AppSizes.DEFAULT,
  },
  termsText: {
    color: Color.PUREBLACK,
    fontSize: FontSize.P1,
    lineHeight: LineHeight.SHYSPACE,
    marginBottom: AppSizes.SIZE1,
  },
  termsTextBold: {
    fontWeight: FontWeight.BOLD,
  },
  termsTitle: {
    textAlign: 'center',
    color: Color.PUREBLACK,
    fontSize: FontSize.H1,
    fontWeight: FontWeight.BOLD,
  },
  termsSubTitle: {
    marginBottom: 0,
    fontWeight: FontWeight.BOLD,
    fontSize: FontSize.H5,
  },
  termsSubTitle2: {
    marginBottom: 0,
    fontWeight: FontWeight.BOLD,
    fontSize: FontSize.P2,
  },
};
