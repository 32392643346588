import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PatientContactRow } from './PatientContactRow';
import { AppSizes, Color, FontSize, FontWeight } from '../../../../assets/styles/constantStyles';
import { StyleSheet } from '../../../../common/domain/entities/StyleSheet';
import { AppButton } from '../../../../common/ui/Others/AppButton';
import { AppSearchInput } from '../../../../common/ui/Others/AppSearchInput';
import CommonFunctions from '../../../../common/helpers/CommonFunctions';
import { AppFilterBox } from '../../../../common/ui/Others/AppFilterBox';
import { AppOrderBox } from '../../../../common/ui/Others/AppOrderBox';
import { ListFilter } from '../../../domain/entities/ListFilter';
import { PcRoleEn, PcRoleGr } from '../../../domain/entities/PatientContactRole';
import { PatientContact } from '../../../domain/entities/PatientContact';
import { AssignedQuestionnaire } from '../../../../questionnairesContext/domain/entities/AssignedQuestionnaire';
import { isDeletable } from '../../../../questionnairesContext/domain/entities/QInvitation';

const patientContactsFilters: ListFilter[] = [
  {
    displayName: `DISYPS-Form: ${PcRoleGr.PARENT}`,
    actualValue: PcRoleEn.PARENT,
  },
  {
    displayName: `DISYPS-Form: ${PcRoleGr.TEACHER}`,
    actualValue: PcRoleEn.TEACHER,
  },
];

enum PatientContactsOrders {
  F_NAME_AZ = 'Vorname A-Z',
  L_NAME_AZ = 'Nachname A-Z',
  F_NAME_ZA = 'Vorname Z-A',
  L_NAME_ZA = 'Nachname Z-A',
  LAST_CREATED = 'Zuletzt hinzugefügt',
  LAST_MODIFIED = 'Zuletzt geändert',
}
interface PatientContactListProps {
  selectable?: boolean;
  ignoreSorting?: boolean;
  ignoreFiltering?: boolean;
  allPatientContacts: PatientContact[];
  selectedPatientContacts?: PatientContact[];
  onRowClick?: (pc: PatientContact) => void;
  newContactPressed?: () => void;
  setPatientContactToDelete: (pc: PatientContact) => void;
  questionnaires: AssignedQuestionnaire[];
}
export const PatientContactList = ({
  selectable,
  ignoreSorting = false,
  ignoreFiltering = false,
  allPatientContacts,
  selectedPatientContacts,
  onRowClick,
  newContactPressed,
  setPatientContactToDelete,
  questionnaires,
}: PatientContactListProps) => {
  const [shownResults, setShownResults] = useState<PatientContact[]>(allPatientContacts);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [currentOrder, setCurrentOrder] = useState<string>(PatientContactsOrders.F_NAME_AZ);
  const [currentFilters, setCurrentFilters] = useState<ListFilter[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    changeShownResults();
  }, [searchQuery, currentOrder, currentFilters, allPatientContacts]);

  const changeShownResults = (patientContacts?: PatientContact[]) => {
    // patients just for faking deletion's sake
    let finalResults: PatientContact[] = patientContacts ? [...patientContacts] : [...allPatientContacts];
    // apply search
    if (_.trim(searchQuery).length >= 3) {
      finalResults = CommonFunctions.searchArrayForString(searchQuery, allPatientContacts);
    }
    // apply filter
    if (currentFilters.length) {
      finalResults = filterResults(finalResults);
    }
    // apply order
    finalResults = applyOrder(finalResults);
    setShownResults(finalResults);
  };

  const clickedFilter = (daFilter: ListFilter) => {
    const exists: boolean = [...currentFilters].includes(daFilter);
    let newArr: ListFilter[] = currentFilters.filter((filter) => filter !== daFilter);
    if (patientContactsFilters.filter((f) => f.actualValue === daFilter.actualValue).length) {
      // this means the new filter is in the troublesome group with opposing filters, so let's make sure we remove any opposing filters to the new one
      newArr = newArr.filter((cf) => !patientContactsFilters.find((elem2) => elem2.actualValue === cf.actualValue));
    }
    if (!exists) {
      newArr.push(daFilter);
    }
    setCurrentFilters(newArr);
  };

  const filterResults = (initialPatients: PatientContact[]) => {
    let filteredPatientContacts: PatientContact[] = [...initialPatients];
    currentFilters.forEach((currentFilter) => {
      if (currentFilter.actualValue === PcRoleEn.PARENT) {
        filteredPatientContacts = filteredPatientContacts.filter((pc) => pc.role === PcRoleEn.PARENT);
      }
      if (currentFilter.actualValue === PcRoleEn.TEACHER) {
        filteredPatientContacts = filteredPatientContacts.filter((pc) => pc.role === PcRoleEn.TEACHER);
      }
    });
    return _.uniq(filteredPatientContacts);
  };

  const applyOrder = (initialContacts: PatientContact[]) => {
    let orderedPatients: PatientContact[] = [...initialContacts];
    switch (currentOrder) {
      case PatientContactsOrders.L_NAME_AZ:
        orderedPatients = _.sortBy(orderedPatients, [
          function tempFunc(o) {
            return o.last_name.toLowerCase();
          },
        ]);
        break;
      case PatientContactsOrders.L_NAME_ZA:
        orderedPatients = _.sortBy(orderedPatients, [
          function tempFunc(o) {
            return o.last_name.toLowerCase();
          },
        ]).reverse();
        break;
      case PatientContactsOrders.F_NAME_AZ:
        orderedPatients = _.sortBy(orderedPatients, [
          function tempFunc(o) {
            return o.first_name.toLowerCase();
          },
        ]);
        break;
      case PatientContactsOrders.F_NAME_ZA:
        orderedPatients = _.sortBy(orderedPatients, [
          function tempFunc(o) {
            return o.first_name.toLowerCase();
          },
        ]).reverse();
        break;
      case PatientContactsOrders.LAST_CREATED:
        orderedPatients = orderedPatients.sort((a, b) => {
          if (!a.created_at || !b.created_at) {
            return -1;
          }
          return Date.parse(b.created_at) - Date.parse(a.created_at);
        });
        break;
      case PatientContactsOrders.LAST_MODIFIED:
        orderedPatients = orderedPatients.sort((a, b) => {
          if (!a.updated_at || !b.updated_at) {
            return -1;
          }
          return Date.parse(b.updated_at) - Date.parse(a.updated_at);
        });
        break;
      default:
        break;
    }
    return orderedPatients;
  };

  const showEmptyResultsMessage = () => {
    if (shownResults?.length === 0) {
      if (_.trim(searchQuery).length > 0) {
        return <div>{t('EMPTY_SEARCHED_LIST')}</div>;
      }
      if (currentFilters.length > 0) {
        return <div>{t('EMPTY_FILTERED_LIST')}</div>;
      }
      return <div>{t('EMPTY_CONTACT_LIST')}</div>;
    }
    return null;
  };
  return (
    <div style={styles.container}>
      <div style={styles.toolbar}>
        <div style={{ flex: 1, flexDirection: 'row', columnGap: AppSizes.SIZE1 }}>
          <AppSearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          {ignoreFiltering === false && (
            <AppFilterBox
              possibleFilters={patientContactsFilters}
              currentFilters={currentFilters}
              clickedFilter={clickedFilter}
              resetFilter={() => setCurrentFilters([])}
            />
          )}
          {ignoreSorting === false && (
            <AppOrderBox
              possibleOrders={Object.values(PatientContactsOrders)}
              currentOrder={currentOrder}
              clickedOrder={(order: string) => setCurrentOrder(order)}
              resetOrder={() => setCurrentOrder(PatientContactsOrders.F_NAME_AZ)}
            />
          )}
        </div>
        {newContactPressed && <AppButton label="Neuer Kontakt" color={Color.MONOGREY6} action={newContactPressed} />}
      </div>
      {selectable && <div style={styles.label}>Empfänger / Empfängerin auswählen*</div>}
      {!_.isEmpty(shownResults) &&
        shownResults.map((pc: PatientContact, index) => (
          <PatientContactRow
            key={`patientContact-${index + 1}`}
            selected={selectedPatientContacts?.find((spc) => spc.uuid === pc.uuid) !== undefined}
            deletable={!questionnaires.some((q) => q.patientContact?.uuid === pc.uuid && !isDeletable(q.status))}
            patientContact={pc}
            selectable={selectable}
            onRowClick={
              onRowClick
                ? () => {
                    onRowClick(pc);
                  }
                : undefined
            }
            deletePatientContact={() => {
              setPatientContactToDelete(pc);
            }}
          />
        ))}
      {showEmptyResultsMessage()}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    minHeight: 300,
    width: '100%',
  },
  innerContainer: {
    alignSelf: 'center',
    maxWidth: 1368,
    width: '100%',
  },
  buttonsBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: AppSizes.SIZE2,
  },
  toolbar: {
    marginBottom: AppSizes.SIZE5,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: AppSizes.SIZE3,
  },
  label: {
    marginLeft: AppSizes.SIZE1,
    marginBottom: AppSizes.DEFAULT,
    pointerEvents: 'none',
    fontSize: FontSize.P3,
    fontWeight: FontWeight.REGULAR,
    color: Color.APPBLACK,
  },
};
