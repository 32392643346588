import { ENV } from '../../configuration';
import { InMemoryPatientContactsInteractor } from '../adapter/inmemory/InMemoryPatientContactsInteractor';
import { InMemoryPatientsInteractor } from '../adapter/inmemory/InMemoryPatientsInteractor';
import { RealPatientContactsInteractor } from '../adapter/real/RealPatientContactsInteractor';
import { RealPatientsInteractor } from '../adapter/real/RealPatientsInteractor';
import { PatientContactsInteractor } from '../domain/gateways/PatientContacts.interactor';
import { PatientsInteractor } from '../domain/gateways/Patients.interactor';

export class PatientsContextFactory {
  static patientsInteractor(): PatientsInteractor {
    switch (ENV) {
      case 'inmemory':
        return new InMemoryPatientsInteractor();
      default:
        return new RealPatientsInteractor();
    }
  }

  static patientContactsInteractor(): PatientContactsInteractor {
    switch (ENV) {
      case 'inmemory':
        return new InMemoryPatientContactsInteractor();
      default:
        return new RealPatientContactsInteractor();
    }
  }
}
