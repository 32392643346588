import React from 'react';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import './assets/styles/index.scss';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { isIE } from 'react-device-detect';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import fetchi from 'fetchi-request';
import * as serviceWorker from './serviceWorker';
import { configureStore, history } from './configuration/redux/store';
import { IESupport } from './common/ui/Others/IESupport';
import { BACKEND_CONFIG } from './configuration';
import UserContextProvider from './contexts/user/store';
import FeedbackContextProvider from './contexts/feedback/store';
import HCPContextProvider from './contexts/doctor/store';
import PatientContentContextProvider from './contexts/patientContact/store';
import Navigator from './navigationContext/ui/Navigator';

const appStore = configureStore();

Sentry.init({
  dsn: 'https://502ad617c8134f67b9b4e302d4f97ca0@sentry.eyelevel.dev/7',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

fetchi.global.config.baseUrl = BACKEND_CONFIG.endpoints.baseUrl;

let ShowingApp = (
  <UserContextProvider>
    <HCPContextProvider>
      <PatientContentContextProvider>
        <Provider store={appStore.store}>
          <ConnectedRouter history={history}>
            <FeedbackContextProvider>
              <Navigator />
            </FeedbackContextProvider>
          </ConnectedRouter>
        </Provider>
      </PatientContentContextProvider>
    </HCPContextProvider>
  </UserContextProvider>
);

if (isIE) {
  ShowingApp = <IESupport />;
}

// TODO: Update loading screen
ReactDOM.render(<React.StrictMode>{ShowingApp}</React.StrictMode>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
