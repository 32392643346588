import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AssignedQsRow } from './AssignedQsRow';
import { AssignedQuestionnaire } from '../../../../questionnairesContext/domain/entities/AssignedQuestionnaire';
import { StyleSheet } from '../../../../common/domain/entities/StyleSheet';
import { AppSizes, Color } from '../../../../assets/styles/constantStyles';
import CommonFunctions from '../../../../common/helpers/CommonFunctions';
import { AppFilterBox } from '../../../../common/ui/Others/AppFilterBox';
import { AppOrderBox } from '../../../../common/ui/Others/AppOrderBox';
import { AppSearchInput } from '../../../../common/ui/Others/AppSearchInput';
import { getGrStatusString, InvitationStatus } from '../../../../questionnairesContext/domain/entities/QInvitation';
import { AppButton } from '../../../../common/ui/Others/AppButton';
import { ListFilter } from '../../../domain/entities/ListFilter';

const AssignedQsListFilters: ListFilter[] = [
  {
    displayName: `Status: ${getGrStatusString(InvitationStatus.OPENED)}`,
    actualValue: 'STATUS_OPEN',
  },
  {
    displayName: `Status: ${getGrStatusString(InvitationStatus.STARTED)}`,
    actualValue: 'STATUS_STARTED',
  },
  {
    displayName: `Status: ${getGrStatusString(InvitationStatus.PROCESSED)}`,
    actualValue: 'STATUS_DONE',
  },
];
enum AssignedQsListOrders {
  NAME_AZ = 'Name A-Z',
  NAME_ZA = 'Name Z-A',
  TYPE_FBB = 'Type: FBB',
  TYPE_DCL = 'Type: DCL',
  LAST_CREATED = 'Zuletzt hinzugefügt',
  LAST_MODIFIED = 'Zuletzt geändert',
}

interface AssignedQsListProps {
  QsList: AssignedQuestionnaire[];
  newQuestionButtonTapped?: () => void;
  refreshButtonTapped: () => void;
}
export const AssignedQsList = ({ QsList, refreshButtonTapped, newQuestionButtonTapped }: AssignedQsListProps) => {
  const [shownResults, setShownResults] = useState<AssignedQuestionnaire[]>(QsList);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [currentFilters, setCurrentFilters] = useState<ListFilter[]>([]);
  const [currentOrder, setCurrentOrder] = useState<string>(AssignedQsListOrders.LAST_MODIFIED);
  const { t } = useTranslation();

  useEffect(() => {
    setShownResults(QsList);
  }, [QsList]);

  useEffect(() => {
    changeShownResults();
  }, [searchQuery, currentFilters, currentOrder, QsList]);

  const changeShownResults = (questionnaires?: AssignedQuestionnaire[]) => {
    // patients just for faking deletion's sake
    let finalResults: AssignedQuestionnaire[] = questionnaires ? [...questionnaires] : [...QsList];
    // apply search
    if (_.trim(searchQuery).length >= 3) {
      finalResults = CommonFunctions.searchArrayForString(searchQuery, QsList);
    }
    // apply filter
    if (currentFilters.length) {
      finalResults = filterResults(finalResults);
    }
    // apply order
    finalResults = applyOrder(finalResults);
    setShownResults(finalResults);
  };

  const filterResults = (initialQs: AssignedQuestionnaire[]) => {
    let filteredQs: AssignedQuestionnaire[] = [...initialQs];
    currentFilters.forEach((currentFilter) => {
      if (currentFilter.actualValue === 'STATUS_OPEN') {
        filteredQs = filteredQs.filter((q) => q.answers.length === 0);
      }
      if (currentFilter.actualValue === 'STATUS_STARTED') {
        filteredQs = filteredQs.filter((q) => q.answers.length > 0 && !q.locked);
      }
      if (currentFilter.actualValue === 'STATUS_DONE') {
        filteredQs = filteredQs.filter((q) => q.locked);
      }
      if (currentFilter.actualValue === 'DCL') {
        filteredQs = filteredQs.filter((q) => q.questionnaire.title?.toLowerCase().includes('dcl'));
      }
      if (currentFilter.actualValue === 'FBB') {
        filteredQs = filteredQs.filter((q) => q.questionnaire.title?.toLowerCase().includes('fbb'));
      }
    });
    return _.uniq(filteredQs);
  };

  const applyOrder = (initialQs: AssignedQuestionnaire[]) => {
    let orderedQs: AssignedQuestionnaire[] = [...initialQs];
    switch (currentOrder) {
      case AssignedQsListOrders.NAME_AZ:
        orderedQs = _.sortBy(orderedQs, [
          function tempFunc(o) {
            return o.questionnaire?.title?.toLowerCase();
          },
        ]);
        break;
      case AssignedQsListOrders.NAME_ZA:
        orderedQs = _.sortBy(orderedQs, [
          function tempFunc(o) {
            return o.questionnaire?.title?.toLowerCase();
          },
        ]).reverse();
        break;
      case AssignedQsListOrders.TYPE_DCL:
        orderedQs = _.sortBy(orderedQs, [
          function tempFunc(o) {
            return !o.questionnaire?.title?.toLowerCase().includes('dcl');
          },
        ]);
        break;
      case AssignedQsListOrders.TYPE_FBB:
        orderedQs = _.sortBy(orderedQs, [
          function tempFunc(o) {
            return !o.questionnaire?.title?.toLowerCase().includes('dcl');
          },
        ]).reverse();
        break;
      case AssignedQsListOrders.LAST_CREATED:
        orderedQs = orderedQs.sort((a, b) => {
          if (!a.created_at || !b.created_at) {
            return -1;
          }
          return Date.parse(a.created_at) - Date.parse(b.created_at);
        });
        break;
      default:
        // AssignedQsListOrders.LAST_MODIFIED:
        orderedQs = orderedQs.sort((a, b) => {
          if (!a.updated_at || !b.updated_at) {
            return -1;
          }
          return Date.parse(b.updated_at) - Date.parse(a.updated_at);
        });
        break;
    }
    return orderedQs;
  };

  const clickedFilter = (daFilter: ListFilter) => {
    const exists: boolean = [...currentFilters].includes(daFilter);
    let newArr: ListFilter[] = currentFilters.filter((filter) => filter !== daFilter);
    if (AssignedQsListFilters.filter((f) => f.actualValue === daFilter.actualValue).length) {
      // this means the new filter is in the troublesome group with opposing filters, so let's make sure we remove any opposing filters to the new one
      newArr = newArr.filter((cf) => !AssignedQsListFilters.find((elem2) => elem2.actualValue === cf.actualValue));
    }
    if (!exists) {
      newArr.push(daFilter);
    }
    setCurrentFilters(newArr);
  };

  const showEmptyResultsMessage = () => {
    if (shownResults?.length === 0) {
      if (_.trim(searchQuery).length > 0) {
        return <div>{t('EMPTY_SEARCHED_LIST')}</div>;
      }
      if (currentFilters.length > 0) {
        return <div>{t('EMPTY_FILTERED_LIST')}</div>;
      }
      return <div>{t('EMPTY_QUESTIONNAIRE_LIST')}</div>;
    }
    return null;
  };

  return (
    <div style={styles.container}>
      <div style={styles.toolbar}>
        <div style={{ flexDirection: 'row', columnGap: AppSizes.SIZE1 }}>
          <AppSearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          <AppFilterBox
            possibleFilters={AssignedQsListFilters}
            currentFilters={currentFilters}
            clickedFilter={clickedFilter}
            resetFilter={() => setCurrentFilters([])}
          />
          <AppOrderBox
            possibleOrders={Object.values(AssignedQsListOrders)}
            currentOrder={currentOrder}
            clickedOrder={(order: string) => setCurrentOrder(order)}
            resetOrder={() => setCurrentOrder(AssignedQsListOrders.LAST_MODIFIED)}
          />
          <button
            onClick={refreshButtonTapped}
            type="button"
            className="justify-content-center align-items-center rounded-circle p-3 btn-light-background"
          >
            <img
              alt="refresh"
              style={{ width: 20, height: 20 }}
              src={require('../../../../assets/icons/refresh_icon.svg').default}
            />
          </button>
        </div>
        {newQuestionButtonTapped && (
          <AppButton
            label="Neuer Fragebogen"
            color={Color.MONOGREY6}
            action={newQuestionButtonTapped}
            extraStyle={{ maxWidth: 300 }}
          />
        )}
      </div>
      <div>
        {shownResults.map((assignedQ, i) => (
          <AssignedQsRow key={`assignedQ-${i + 1}`} assignedQ={assignedQ} />
        ))}
      </div>
      {showEmptyResultsMessage()}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    minHeight: 300,
  },
  toolbar: {
    marginBottom: AppSizes.SIZE5,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: AppSizes.SIZE1,
  },
};
