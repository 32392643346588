import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../../common/domain/entities/StyleSheet';
import { AppButton } from '../../../common/ui/Others/AppButton';
import { AppTextInput } from '../../../common/ui/Others/AppTextInput';
import { AppFormErrorList } from '../../../common/ui/Others/AppFormErrorList';
import { EMPTY_INPUT_ERROR_TEXT } from '../../../common/helpers/ConstantTexts';
import { AppDropDownMenu } from '../../../common/ui/Others/AppDropDownMenu';
import { AppAlert } from '../../../common/ui/Others/AppAlert';
import { useFeedback } from '../../../contexts/feedback/store';
import Messaging from '../../../core/components/messaging';
import { defaultEmptyPatient, Patient } from '../../domain/entities/Patient';
import { useHcpContext } from '../../../contexts/doctor/store';
import { PatientStatusDe } from '../../domain/entities/PatientContactRole';
import { AppTextArea } from '../../../common/ui/Others/AppTextArea';

export const PatientCreationDumb = () => {
  // hooks
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const { getContent, createPatient, updatePatient } = useHcpContext();
  const { getFeedbackIfNecessary } = useFeedback();
  const currentPatient = useLocation<Patient>().state;

  // states
  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [hasAccepted, setHasAccepted] = useState<boolean>(true);
  const [filledData, setFilledData] = useState<Patient>(currentPatient ?? { ...defaultEmptyPatient });
  const [showError, setShowError] = useState<boolean>(false);
  const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);
  const [formErrorsList, setFormErrorsList] = useState<string[]>();
  const [confirmation, setConfirmation] = useState<
    | {
        visible: true;
        message: string;
        acceptButton?: string;
        rejectButton?: string;
      }
    | {
        visible: false;
      }
  >({
    visible: false,
  });

  // references
  const confirmationResponse = useRef<(response: boolean) => void>();

  useEffect(() => {
    currentPatient?.uuid && setHasAccepted(true);
    if (mandatoryFieldsValid()) {
      setFormErrorsList([]);
      setFormIsValid(true);
    } else {
      setFormErrorsList([EMPTY_INPUT_ERROR_TEXT]);
      setFormIsValid(false);
    }
  }, [filledData, hasAccepted]);

  const mandatoryFieldsValid = () => {
    let allFilled = true;
    Object.entries(filledData).map(([key, val]) => {
      // if (key === 'last_name' || key === 'first_name' || key === 'birth_date' || key === 'gender') {
      if (key === 'first_name') {
        if (_.isEmpty(val)) {
          allFilled = false;
        }
      }
      return null;
    });
    allFilled = allFilled && hasAccepted;
    return allFilled;
  };

  const saveAndNavigate = () => {
    if (!awaitingResponse) {
      filledData.uuid ? updateThePatient() : insertPatient();
      setAwaitingResponse(true);
    }
  };
  const insertPatient = () => {
    getContent(['patients'])
      .then(
        (result) =>
          (
            result.patients?.filter(
              (item) =>
                item.first_name.toLowerCase() === filledData.first_name.toLowerCase() &&
                item.last_name.toLowerCase() === filledData.last_name.toLowerCase() &&
                item.birth_date === filledData.birth_date,
            ) ?? []
          ).length > 0,
      )
      .then((isDuplicated) => {
        if (isDuplicated) {
          return getConfirm({
            message: `Sind Sie sicher, dass Sie ${filledData.last_name}, ${filledData.first_name} erstellen möchten? Diese:r Patient:in besteht bereits.`,
            acceptButton: 'Ja, ich bin mir sicher',
            rejectButton: 'Nein, abbrechen',
          });
        }
        return Promise.resolve(true);
      })
      .then((shouldContinue: boolean) => {
        if (shouldContinue) {
          return createPatient(filledData).rawPromise();
        }
        goBack();
        throw Error('rejected');
      })
      .then(() => {
        Messaging.toast(t('IT_WORKED'));
        setAwaitingResponse(false);
        goBack();
        getFeedbackIfNecessary('Create patient');
      })
      .catch(() => {});
  };

  const updateThePatient = () => {
    updatePatient(filledData)
      .then(() => {
        Messaging.toast(t('IT_WORKED'));
        setAwaitingResponse(false);
        goBack();
      })
      .catch((err) => alert(`updating patient failed\n${err}`));
  };

  const getConfirm = ({
    message,
    acceptButton,
    rejectButton,
  }: {
    message: string;
    acceptButton?: string;
    rejectButton?: string;
  }) => {
    setConfirmation({ visible: true, message, acceptButton, rejectButton });
    return new Promise<boolean>((resolve) => {
      confirmationResponse.current = resolve;
    });
  };

  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <p style={styles.title}>
          {currentPatient?.uuid
            ? `Patient:in bearbeiten: ${currentPatient.first_name}`
            : 'Bitte vervollständigen Sie die Angaben zur / zum neue:n Patient:in:'}
        </p>
        <AppTextInput
          label="Patient-ID*"
          placeholder="ID"
          currentVal={filledData.first_name}
          onChange={(str: string) => {
            const sevenYearsAgo = moment().subtract(7, 'years').format('YY-MM-DD');
            setFilledData((v) => ({
              ...v,
              first_name: str,
              last_name: str,
              birth_date: sevenYearsAgo,
            }));
          }}
          showError={showError && _.isEmpty(filledData.first_name)}
        />
        <AppDropDownMenu
          label="Patienten Status"
          list={Object.values(PatientStatusDe)}
          shownValue={filledData.status}
          placeholder="Bitte wählen Sie die relevante status aus."
          onChangeFunc={(newVal: string) => {
            setFilledData((v) => ({
              ...v,
              status: newVal.toLowerCase(),
            }));
          }}
          extraStyle={{ width: '100%' }}
        />
        <AppTextArea
          label="Kommentar"
          placeholder="Kommentar"
          shownValue={filledData.comments}
          onChangeFunc={(newVal: string) => {
            setFilledData((v) => ({
              ...v,
              comments: newVal,
            }));
          }}
          extraStyle={{ width: '100%' }}
        />
        {/* <AppTextInput
          label="Nachname*"
          placeholder="Nachname"
          currentVal={filledData.last_name}
          onChange={(str: string) => {
            setFilledData((v) => ({ ...v, last_name: str }));
          }}
          showError={showError && _.isEmpty(filledData.last_name)}
        />
        <AppDateInput
          label="Geburtsdatum*"
          currentVal={filledData.birth_date}
          onChange={(newDate: string) => {
            setFilledData((v) => ({ ...v, birth_date: newDate }));
          }}
          showError={showError && _.isEmpty(filledData.birth_date)}
        />
        <AppDropDownMenu
          label="Geschlecht*"
          list={['weiblich', 'männlich']}
          shownValue={CommonFunctions.translateGenderToGr(filledData.gender)}
          placeholder="Gender auswählen"
          disabled={!filledData.gender}
          onChangeFunc={(newVal: string) => {
            setFilledData((v) => ({ ...v, gender: CommonFunctions.translateGenderToEn(newVal) }));
          }}
          showError={showError && _.isEmpty(filledData.gender)}
          extraStyle={{ width: '100%', marginBottom: -4 }}
        />
        <AppTextInput
          label="Schultyp / Art der Berufsausbildung"
          placeholder="Schultyp / Art der Berufsausbildung"
          currentVal={filledData.school}
          onChange={(str: string) => {
            setFilledData((v) => ({ ...v, school: str }));
          }}
          showError={false}
        />
        <AppTextInput
          label="Klasse"
          placeholder="Klasse"
          currentVal={filledData.class ?? ''}
          onChange={(str: string) => {
            setFilledData((v) => ({ ...v, class: str }));
          }}
          showError={false}
        />
        {currentPatient?.uuid === undefined && (
          <div style={{ marginTop: AppSizes.SIZE1, width: '100%' }}>
            <AppButtonV2
              topTitle="Datenschutz*"
              label="Ja, ich habe die Erlaubnis eingeholt, das Umfeld des Patienten / der Patientin zu kontaktieren."
              isOn={hasAccepted}
              showError={showError && !hasAccepted}
              action={() => {
                setHasAccepted((v) => !v);
              }}
            />
          </div>
        )} */}
      </div>
      <AppFormErrorList formErrorsList={formErrorsList ?? []} shown={showError} />
      <div style={styles.buttonsOuterContainer}>
        <div style={styles.buttonsCouple}>
          <AppButton
            disabled={!formIsValid}
            label="Abbrechen"
            color={Color.MONOGREY6}
            strokeOnly
            action={() => goBack()}
          />
          <AppButton
            disabled={!formIsValid}
            label="Speichern"
            color={Color.MONOGREY6}
            action={() => {
              formIsValid ? saveAndNavigate() : setShowError(true);
            }}
          />
        </div>
      </div>
      <AppAlert
        isOn={confirmation.visible}
        content={
          <div style={{ padding: AppSizes.SIZE3, paddingTop: AppSizes.SIZE4 }}>
            <p>{confirmation.visible ? confirmation.message : ''}</p>
          </div>
        }
        close={() => {
          confirmationResponse.current?.(false);
          setConfirmation({ visible: false });
          confirmationResponse.current = undefined;
        }}
        buttons={[
          {
            label: confirmation.visible ? confirmation.acceptButton ?? 'Abbrechen' : 'Abbrechen',
            action: () => {
              confirmationResponse.current?.(true);
              setConfirmation({ visible: false });
              confirmationResponse.current = undefined;
            },
            strokeOnly: true,
          },
          {
            label: confirmation.visible ? confirmation.rejectButton ?? 'Löschen' : 'Löschen',
            action: () => {
              confirmationResponse.current?.(false);
              setConfirmation({ visible: false });
              confirmationResponse.current = undefined;
            },
          },
        ]}
      />
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    alignItems: 'center',
    padding: AppSizes.DEFAULT,
  },
  topbar: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'right',
    alignItems: 'center',
  },
  innerContainer: {
    marginTop: AppSizes.SIZE2,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    maxWidth: '40vw',
    alignSelf: 'center',
    width: '100%',
  },
  title: {
    marginBottom: AppSizes.SIZE2,
    fontWeight: FontWeight.BOLD,
    fontSize: FontSize.H4,
    textAlign: 'center',
    width: '100vw',
    maxWidth: 600,
  },
  buttonsOuterContainer: {
    width: '100%',
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonsCouple: {
    flexDirection: 'row',
    columnGap: 10,
    columnWidth: '50%',
    width: '95vw',
    maxWidth: 500,
  },
  breadcrumbs: {
    flexDirection: 'row',
    fontWeight: FontWeight.BOLD,
    fontSize: FontSize.P1,
  },
};
