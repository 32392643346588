import { SagaIterator } from 'redux-saga';
// import { DependenciesType } from '../configuration/dependencies'
import { Action } from 'redux-actions';
import { all, call, getContext, put, takeLatest } from 'redux-saga/effects';
import {
  sendHelpAnswersFailure,
  sendHelpAnswersRequest,
  sendHelpAnswersSuccess,
  setBreadCrumbsFailure,
  setBreadCrumbsRequest,
  setBreadCrumbsSuccess,
} from './common.actions';
import { DependenciesType } from '../../configuration/dependencies';
import { HelpObj } from '../domain/entities/HelpObj';

export function* getDependencies(): SagaIterator {
  const dependencies = yield getContext('dependencies');
  return dependencies;
}

function* setBreadCrumbsSaga(action: Action<string[]>) {
  try {
    yield put(setBreadCrumbsSuccess(action.payload));
  } catch (error) {
    yield put(setBreadCrumbsFailure(error));
  }
}
function* sendHelpAnswersSaga(action: Action<HelpObj>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: string = yield call(dep.helpSpace.sendHelpAnswers, action.payload);
    yield put(sendHelpAnswersSuccess(res));
  } catch (error) {
    yield put(sendHelpAnswersFailure(error));
  }
}

export function* commonSagas() {
  yield all([
    takeLatest(setBreadCrumbsRequest, setBreadCrumbsSaga),
    takeLatest(sendHelpAnswersRequest, sendHelpAnswersSaga),
  ]);
}
