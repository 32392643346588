import { Color } from '../styles/constantStyles';

export interface MiniPersonProps {
  color?: string;
}

export const MiniPerson = ({ color }: MiniPersonProps) => (
  <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 10.6667C12.7072 10.6667 13.3855 10.9476 13.8856 11.4477C14.3857 11.9478 14.6667 12.6261 14.6667 13.3333V15.3333C14.6667 18.072 11.8827 20 7.33333 20C2.784 20 0 18.072 0 15.3333V13.3333C0 12.6261 0.280951 11.9478 0.781048 11.4477C1.28115 10.9476 1.95942 10.6667 2.66667 10.6667H12ZM12 12H2.66667C2.31304 12 1.97391 12.1405 1.72386 12.3905C1.47381 12.6406 1.33333 12.9797 1.33333 13.3333V15.3333C1.33333 17.2093 3.43867 18.6667 7.33333 18.6667C11.228 18.6667 13.3333 17.2093 13.3333 15.3333V13.3333C13.3333 12.9797 13.1929 12.6406 12.9428 12.3905C12.6928 12.1405 12.3536 12 12 12ZM7.33333 0C7.94617 -9.13196e-09 8.553 0.120707 9.11919 0.355229C9.68538 0.589751 10.1998 0.933495 10.6332 1.36683C11.0665 1.80018 11.4102 2.31462 11.6448 2.88081C11.8793 3.447 12 4.05383 12 4.66667C12 5.2795 11.8793 5.88634 11.6448 6.45252C11.4102 7.01871 11.0665 7.53316 10.6332 7.9665C10.1998 8.39984 9.68538 8.74358 9.11919 8.97811C8.553 9.21263 7.94617 9.33333 7.33333 9.33333C6.09566 9.33333 4.90867 8.84167 4.0335 7.9665C3.15833 7.09133 2.66667 5.90434 2.66667 4.66667C2.66667 3.42899 3.15833 2.242 4.0335 1.36683C4.90867 0.491665 6.09566 1.84428e-08 7.33333 0ZM7.33333 1.33333C6.44928 1.33333 5.60143 1.68452 4.97631 2.30964C4.35119 2.93477 4 3.78261 4 4.66667C4 5.55072 4.35119 6.39857 4.97631 7.02369C5.60143 7.64881 6.44928 8 7.33333 8C8.21739 8 9.06523 7.64881 9.69036 7.02369C10.3155 6.39857 10.6667 5.55072 10.6667 4.66667C10.6667 3.78261 10.3155 2.93477 9.69036 2.30964C9.06523 1.68452 8.21739 1.33333 7.33333 1.33333Z"
      fill={color || Color.MONOGREY6}
    />
  </svg>
);
