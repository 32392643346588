import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { CommonNavScreens } from '../../navigationContext/configuration/commonScreens';

const TopBar = () => {
  const [isMenuVisible, setIsMenuVisible] = React.useState<boolean>(false);
  return (
    <>
      <nav className="px-3 py-2 full-width">
        <div className="row align-items-center">
          <div className="col-2 d-inline d-md-none">
            <button className="p-1" type="button" onClick={() => setIsMenuVisible((p) => !p)}>
              <img
                alt="menu"
                style={{ backgroundSize: 'cover', width: 21, height: 20 }}
                src={require('../../assets/icons/menu.svg').default}
              />
            </button>
          </div>
          <div className="col-md-4 col-6 d-none d-md-inline">
            <img className="nav-bar-logo navbar-brand" alt="logoImage" src={require('../../assets/images/logo.png')} />
          </div>
          {/* <div className="col-md-4 col-8 text-start">{title}</div> */}
        </div>
      </nav>
      {isMenuVisible && (
        <motion.div
          className="hamburgerMenuContainer d-inline d-md-none d-flex"
          initial={{ transform: 'translateX(-50%)', opacity: 0 }}
          animate={{ transform: 'translateX(0%)', opacity: 1 }}
          transition={{ type: 'just' }}
        >
          <div className="px-3 py-2">
            <div className="row">
              <div className="col-2">
                <button className="p-1 d-contents d-md-none" type="button" onClick={() => setIsMenuVisible((p) => !p)}>
                  <img
                    alt="close"
                    style={{ backgroundSize: 'cover', width: 20, height: 20 }}
                    src={require('../../assets/icons/cross.svg').default}
                  />
                </button>
              </div>
              <div className="col-8">
                <span className="text-center fs-5 fw-bold">Menu</span>
              </div>
            </div>
          </div>
          <div className="flex-fill d-flex justify-content-between flex-wrap">
            <div className="mt-5">
              {CommonNavScreens.map((src) => (
                <div className="mx-3 mb-5" key={src.routePath}>
                  <Link to={`/${src.routePath}`} target="_blank" className="nav-link text-decoration-underline ts-3">
                    {src.routePath}
                  </Link>
                </div>
              ))}
            </div>
            <div className="mx-3 mb-4 ts-5">© {new Date().getFullYear()} Medigital GmbH</div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default TopBar;
