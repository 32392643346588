import _ from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../domain/entities/StyleSheet';

export interface AppSearchInputProps {
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  triggerSearch?: () => void;
}
export const AppSearchInput = ({ searchQuery, setSearchQuery, triggerSearch }: AppSearchInputProps) => {
  const [InputIsFocused, setInputIsFocused] = useState<boolean>(false);
  const [LabelIsFloatating, setLabelIsFloatating] = useState<boolean>(false);

  useEffect(() => {
    setLabelIsFloatating(searchQuery !== '' || InputIsFocused);
  }, [searchQuery, InputIsFocused]);

  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <input
          style={styles.input}
          type="text"
          required
          value={searchQuery ?? ''}
          onChange={(e) => setSearchQuery(e.target.value)}
          onFocus={() => setInputIsFocused(true)}
          onBlur={() => {
            setInputIsFocused(false);
            setSearchQuery(_.trim(searchQuery));
          }}
        />
        <div
          style={{
            ...styles.label,
            ...(LabelIsFloatating && styles.floatingLabel),
          }}
        >
          Suche
        </div>
      </div>
      <BsSearch
        title="More options"
        color={Color.MONOGREY6}
        size={20}
        style={styles.searchIcon}
        onClick={() => {
          triggerSearch && triggerSearch();
        }}
      />
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    border: `1px solid ${Color.APPBLACK}`,
    borderRadius: 10,
    overflow: 'hidden',
    width: '100%',
    maxWidth: 254,
  },
  innerContainer: {
    position: 'relative',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 300ms',
  },
  input: {
    fontSize: FontSize.P1,
    maxWidth: '100%',
    width: '100%',
    height: AppSizes.SIZE5,
    fontWeight: FontWeight.SEMIBOLD,
    color: Color.APPBLACK,
    outline: 'none',
    border: 'none',
    cursor: 'text',
    margin: 0,
    padding: AppSizes.SIZE1,
  },
  label: {
    transition: 'all 300ms',
    fontSize: FontSize.P1,
    fontWeight: FontWeight.SEMIBOLD,
    color: Color.MONOGREY6,
    position: 'absolute',
    height: AppSizes.SIZE5,
    justifyContent: 'center',
    textAlign: 'left',
    top: 0,
    left: AppSizes.SIZE1,
    pointerEvents: 'none',
  },
  floatingLabel: {
    color: 'transparent',
    transform: 'translateX(40px)',
  },
  searchIcon: {
    marginLeft: AppSizes.DEFAULT,
    marginRight: AppSizes.DEFAULT,
    cursor: 'pointer',
  },
};
