import { AppButton } from '../../../../common/ui/Others/AppButton';

type Props = {
  backButtonPressed: (i?: number) => void;
  submitButtonPressed: () => void;
};

export const QuestionnaireSubmissionView = ({ backButtonPressed, submitButtonPressed }: Props) => (
  <div className="container mt-3 mt-sm-5">
    <div className="row mt-sm-5 mt-3">
      <div className="col-lg-10 offset-lg-1 text-center">
        <p className="fs-5 fw-bold">Super, Sie haben den Fragebogen vollständig ausgefüllt!</p>
        <p className="fs-6">
          Wenn Sie bereit sind, senden Sie den Fragebogen ab. Sie können Ihre Antworten danach nicht mehr ändern.
        </p>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-6 col-md-4 offset-sm-0 offset-md-2 mb-3 pe-sm-auto pe-1">
        <AppButton
          extraStyle={{ maxWidth: 'inherit', borderColor: '#1246DA' }}
          label="Zurück"
          color="#1246DA"
          strokeOnly
          action={backButtonPressed}
        />
      </div>
      <div className="col-6 col-md-4 offset-sm-0 offset-sd-2 ps-sm-auto ps-1">
        <AppButton
          extraStyle={{ maxWidth: 'inherit' }}
          className="fs-6"
          label="Absenden"
          color="#1246DA"
          action={submitButtonPressed}
        />
      </div>
    </div>
  </div>
);
