import { useEffect, useState } from 'react';
import _ from 'lodash';
import { UserPersonalDataForm } from './Components/UserPersonalDataForm';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { StyleSheet } from '../../../common/domain/entities/StyleSheet';
import CommonFunctions from '../../../common/helpers/CommonFunctions';
import { emptyUser, User } from '../../../contexts/user/entities/User';
import { AppButton } from '../../../common/ui/Others/AppButton';
import { AppFormErrorList } from '../../../common/ui/Others/AppFormErrorList';
import { BETA_LOGIN_WARNING, EMPTY_INPUT_ERROR_TEXT } from '../../../common/helpers/ConstantTexts';
import { AppAlert } from '../../../common/ui/Others/AppAlert';
import { useUser } from '../../../contexts/user/store';
import { useFeedback } from '../../../contexts/feedback/store';

interface UserPersonalDataPageProps {
  navigateTo: (p: NavigationPayload) => void;
  navigateBack: () => void;
}
export const UserPersonalDataPageDumb = ({ navigateTo, navigateBack }: UserPersonalDataPageProps) => {
  const [filledData, setFilledData] = useState<User>({ ...emptyUser });
  const [formErrorsList, setFormErrorsList] = useState<string[]>();
  const [showError, setShowError] = useState<boolean>(false);
  const [showAppAlert, setShowAppAlert] = useState<boolean>(false);
  const [formIsValid, setFormIsValid] = useState<boolean>();
  const { updateProfile, profile } = useUser();
  const { getFeedbackIfNecessary } = useFeedback();
  useEffect(() => {
    updateErrors();
  }, [filledData]);

  useEffect(() => {
    setFilledData((user) => {
      if (profile) {
        return {
          ...user,
          data_security_version: profile.data_security_version,
          data_use_version: profile.data_use_version,
          tos_version: profile.tos_version,
        };
      }
      return user;
    });
  }, [profile]);

  const updateErrors = () => {
    const errList: string[] = [];
    !mandatoryFieldsValid() && errList.push(EMPTY_INPUT_ERROR_TEXT);
    setFormErrorsList([...errList]);
    setFormIsValid(!errList.length);
    errList.length === 0 && setShowError(false);
  };

  const mandatoryFieldsValid = () => {
    let allFilled = true;
    Object.entries(filledData).map(([key, val]) => {
      if (
        key === 'title' ||
        key === 'first_name' ||
        key === 'last_name' ||
        key === 'street' ||
        key === 'house_number' ||
        key === 'postcode' ||
        key === 'city'
      ) {
        if (_.isEmpty(val)) {
          allFilled = false;
        }
      }
      return null;
    });
    return allFilled;
  };

  const updateConnectedUserProfile = () => {
    updateProfile({ ...filledData, notification_status_list: ['processed'] })
      .then(() => {
        if (profile?.email) {
          getFeedbackIfNecessary('Create account');
          navigateTo({ routeName: 'Patients-List' });
        }
      })
      .catch((err) => {
        alert(`Error caught updateConnectedUserProfile\n${JSON.stringify(err)}`);
      });
  };

  return (
    <div style={styles.container}>
      <img alt="logoImage" style={styles.sideBarLogoStyle} src={require('../../../assets/images/logo.png')} />
      <div style={styles.innerContainer}>
        <div style={{ justifyContent: 'center', alignItems: 'center' }}>
          <p style={styles.title}>Bitte vervollständigen Sie die Angaben zum Ihrem neuen Account:</p>
          <UserPersonalDataForm showError={showError} filledData={filledData} setFilledData={setFilledData} />
        </div>
        <AppFormErrorList formErrorsList={formErrorsList ?? []} shown={showError} />
        <div style={styles.buttonsOuterContainer}>
          <div style={styles.buttonsCouple}>
            <AppButton
              disabled={!formIsValid}
              label="Zurück"
              color={Color.MONOGREY6}
              strokeOnly
              action={() => {
                navigateBack();
              }}
            />
            <AppButton
              disabled={!formIsValid}
              label="Weiter"
              color={Color.MONOGREY6}
              action={() => {
                formIsValid ? updateConnectedUserProfile() : setShowError(true);
              }}
            />
          </div>
        </div>
      </div>
      <AppAlert
        isOn={showAppAlert}
        content={
          <div>
            <p>{BETA_LOGIN_WARNING}</p>
          </div>
        }
        close={() => {
          setShowAppAlert(false);
        }}
        buttons={[
          {
            label: 'Verstanden',
            action: () => {
              setShowAppAlert(false);
            },
          },
        ]}
      />
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    padding: AppSizes.SIZE1,
  },
  innerContainer: {
    maxWidth: CommonFunctions.mobileSized() ? '95vw' : '40vw',
    marginTop: AppSizes.SIZE2,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    alignSelf: 'center',
    flex: 1,
  },
  sideBarLogoStyle: {
    width: 200,
  },
  title: {
    marginBottom: AppSizes.SIZE2,
    fontWeight: FontWeight.BOLDER,
    fontSize: FontSize.H4,
    textAlign: 'center',
    width: '100vw',
    maxWidth: 600,
  },
  buttonsCouple: {
    flexDirection: 'row',
    columnGap: 10,
    columnWidth: '50%',
    width: '95vw',
    maxWidth: 500,
  },
  buttonsOuterContainer: {
    width: '95vw',
    maxWidth: 500,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    // marginTop: AppSizes.SIZE5,
  },
};
