import { Action } from 'redux';
import { connect } from 'react-redux';
import { WelcomingPageDumb } from './WelcomePage.dumb';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { navigateToRequest } from '../../../navigationContext/usecases/navigation.actions';

const mapState = () => ({});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  navigateTo: (p: NavigationPayload) => dispatch(navigateToRequest(p)),
});

export const WelcomingPageSmart = connect(mapState, mapDispatch)(WelcomingPageDumb);
