import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnyAsyncService } from 'fetchi-request';
import useClamp from '../../../../core/helpers/useClamp';
import { Feedback, FeedbackCategory } from '../../types';
import { TranslationKeys } from '../../../../configuration/translations/types';

type Props = {
  category: FeedbackCategory;
  submit: (feedback: Feedback) => AnyAsyncService<void>;
  abort: () => void;
};

const useFeedbackPopupViewModel = ({ submit, category, abort }: Props) => {
  const [selectedRate, setSelectedRate] = useClamp<number>({ min: 1, max: 7 });
  const [sendingMessage, setSendingMessage] = React.useState<string>();
  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const isActive = React.useRef<boolean>(true);
  const { t } = useTranslation();

  const isGettingFeedbackDone = currentStep === -1;

  React.useEffect(
    () => () => {
      isActive.current = false;
    },
    [],
  );

  React.useEffect(() => {
    // submission the numeric rate by user
    if (selectedRate !== undefined) {
      if (selectedRate > 3) {
        submitFeedback();
      } else {
        setCurrentStep(2);
      }
    }
  }, [selectedRate]);

  const closeFeedback = () => {
    if (isGettingFeedbackDone === false) {
      if (selectedRate !== undefined) {
        submit({ score: selectedRate });
      } else {
        submit({ score: -1 }); // means the user rejected to answer!
      }
    }

    abort();
  };

  const submitFeedback = () => {
    if (selectedRate !== undefined) {
      setCurrentStep(-1); // which means it's done
      submit({
        score: selectedRate,
        text: sendingMessage,
      }).catch(() => {
        /** do nothng just catch it */
      });
    }
  };

  const convertScopeToTranslateDoingSomethingLabel = (input: FeedbackCategory): TranslationKeys =>
    input === 'Create account'
      ? 'CREATE_AN_ACCOUNT'
      : input === 'Create patient'
      ? 'CREATE_A_PATIENT'
      : input === 'Create contact'
      ? 'CREATE_A_CONTACT'
      : 'SENDING_A_QUESTIONNARE';

  const convertCategoryToTranslateJobTitleLabel = (input: FeedbackCategory): TranslationKeys =>
    input === 'Create account'
      ? 'ACCOUNT_CREATION'
      : input === 'Create patient'
      ? 'PATIENT_CREATION'
      : input === 'Create contact'
      ? 'CONTACT_CREATION'
      : 'QUESTIONNARE_SENDING';

  const generateTitleHeader = (): string | undefined => {
    if (currentStep === 1) {
      return t('SUCCESSFUL_DO_SOMETHING_MESSAGE', {
        job: t(convertScopeToTranslateDoingSomethingLabel(category)),
      });
    }

    if (currentStep === 2) {
      return t('PLEASE_SEND_FEEDBACK_FOR_HEADER_TITLE', { job: t(convertCategoryToTranslateJobTitleLabel(category)) });
    }

    if (isGettingFeedbackDone) {
      return t('THANK_YOU');
    }

    return undefined;
  };

  const generateMessage = (): string | undefined => {
    if (currentStep === 2) {
      return t('PLEASE_SEND_FEEDBACK_FOR_MESSAGE', { job: t(convertCategoryToTranslateJobTitleLabel(category)) });
    }
    return undefined;
  };

  return {
    message: generateMessage(),
    currentStep,
    isDone: currentStep === -1,
    headerTitle: generateTitleHeader(),
    selectedRate,
    sendingMessage,
    feedbackMoreInfoReqMessage: '',
    submitFeedback,
    closeFeedback,
    setSelectedRate,
    setSendingMessage,
  };
};

export default useFeedbackPopupViewModel;
