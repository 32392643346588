import { Actions, ActionTypes } from './actions';
import { UserStore } from './types';

export const userStoreReducer = (state: UserStore, action: Actions): UserStore => {
  switch (action.type) {
    case ActionTypes.Authorize:
      return {
        ...state,
        isAuthorized: true,
        authorities: { ...state.authorities, [action.role]: true },
        isFirstTimePanelAppOpen: action.isFirstTimePanelAppOpen ?? false,
        [action.role === 'doctor' ? 'docSessionKey' : 'contactSessionKey']: action.sessionKey,
      };
    case ActionTypes.ContactLogout:
      return {
        ...state,
        contactSessionKey: undefined,
        authorities: { ...state.authorities, patient: false },
      };
    case ActionTypes.GetProfile:
      return {
        ...state,
        profile: action.profile,
      };
    case ActionTypes.UpdateProfile:
      return {
        ...state,
        profile: action.profile,
      };
    case ActionTypes.Logout:
      return {
        isAuthorized: false,
        authorities: { doctor: false, patient: false },
        isFirstTimePanelAppOpen: false,
      };
    default:
      return state;
  }
};
