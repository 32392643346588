import { Action } from 'redux-actions';
import {
  loadAllContactsSuccess,
  loadPatientContactsSuccess,
  loadPatientsSuccess,
  setAppCurrentPatientSuccess,
} from './Patients.actions';
import { createTypedHandler, handleTypedActions } from '../../common/usecases/actions.helper';
import { PatientsState } from '../configuration/Patients.state';
import { Patient } from '../domain/entities/Patient';
import { PatientContact } from '../domain/entities/PatientContact';

const defaultState: PatientsState = {
  currentPatient: null,
  patients: null,
  contacts: null,
  patientContact: null,
  allPatientContacts: [],
};

const setCurrentPatientReducer = (state: PatientsState, action: Action<Patient | null>) => ({
  ...state,
  currentPatient: action.payload,
});
const loadPatientsReducer = (state: PatientsState, action: Action<Patient[] | null>) => ({
  ...state,
  patients: action.payload,
});
const loadPatientContactsReducer = (state: PatientsState, action: Action<PatientContact[]>) => ({
  ...state,
  allPatientContacts: action.payload,
});

const loadContactsReducer = (state: PatientsState, action: Action<PatientContact[] | null>) => ({
  ...state,
  contacts: action.payload,
});
export const patientsReducer = handleTypedActions(
  [
    createTypedHandler(loadPatientsSuccess, loadPatientsReducer),
    createTypedHandler(loadAllContactsSuccess, loadContactsReducer),
    createTypedHandler(loadPatientContactsSuccess, loadPatientContactsReducer),
    createTypedHandler(setAppCurrentPatientSuccess, setCurrentPatientReducer),
  ],
  defaultState,
);
