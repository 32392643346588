import { ConfirmationPopUpContent, MessagePopUpContent, Toast } from './type';

export enum ActionType {
  ADD_TOAST = 'add_toast',
  UPDATE_TOAST = 'update_toast',
  UPSERT_TOAST = 'upset_toast', // update or insert toast
  DISMISS_TOAST = 'dismiss_toast',
  REMOVE_TOAST = 'remove_toast',
  START_PAUSE_TOAST = 'start_pause_toast',
  END_PAUSE_TOAST = 'end_pause_toast',
  ADD_POPUP_MESSAGE = 'add_popup_message',
  REMOVE_POPUP_MESSAGE = 'remove_popup_message',
  ADD_CONFIMATION = 'add_confirmation_popup',
  REMOVE_CONFIMATION = 'remove_confirmation_popup',
}

export type Action =
  | {
      type: ActionType.ADD_TOAST;
      toast: Toast;
    }
  | {
      type: ActionType.UPSERT_TOAST;
      toast: Toast;
    }
  | {
      type: ActionType.UPDATE_TOAST;
      toast: Partial<Toast>;
    }
  | {
      type: ActionType.DISMISS_TOAST;
      toastId?: string;
    }
  | {
      type: ActionType.REMOVE_TOAST;
      toastId?: string;
    }
  | {
      type: ActionType.START_PAUSE_TOAST;
      time: number;
    }
  | {
      type: ActionType.END_PAUSE_TOAST;
      time: number;
    }
  | {
      type: ActionType.ADD_POPUP_MESSAGE;
      content: MessagePopUpContent;
    }
  | {
      type: ActionType.REMOVE_POPUP_MESSAGE;
      id: string;
    }
  | {
      type: ActionType.ADD_CONFIMATION;
      content: ConfirmationPopUpContent;
    }
  | {
      type: ActionType.REMOVE_CONFIMATION;
      id: string;
    };
