import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import {
  deleteQInvitationFailure,
  deleteQInvitationRequest,
  deleteQInvitationSuccess,
  loadAllAssignedQsFailure,
  loadAllAssignedQsRequest,
  loadAllAssignedQsSuccess,
  // loadInvitationReportFailure,
  // loadInvitationReportRequest,
  // loadInvitationReportSuccess,
  loadQInitialDataFailure,
  loadQInitialDataRequest,
  loadQInitialDataSuccess,
  loadQInvitationsFailure,
  loadQInvitationsRequest,
  loadQInvitationsSuccess,
  loadQuestionnairesFailure,
  loadQuestionnairesRequest,
  loadQuestionnairesSuccess,
  loadSingleQuestionnaireFailure,
  loadSingleQuestionnaireRequest,
  loadSingleQuestionnaireSuccess,
  sendQInvitationFailure,
  sendQInvitationRequest,
  sendQInvitationSuccess,
} from './questionnaires.actions';
import { getAllQuestionnaires } from './questionnaires.selectors';
import { getDependencies } from '../../common/usecases/common.sagas';
import { DependenciesType } from '../../configuration/dependencies';
import { QuestionnaireObj } from '../domain/entities/QuestionnaireObj';
import { QInitialData } from '../domain/entities/QInitialData';
import { Patient } from '../../patientsContext/domain/entities/Patient';
import { AssignedQuestionnaire } from '../domain/entities/AssignedQuestionnaire';
import { PatientContact } from '../../patientsContext/domain/entities/PatientContact';
import { QInvitation } from '../domain/entities/QInvitation';
import { getAllContacts, getAllPatients } from '../../patientsContext/usecases/Patients.selectors';
import { loadPatientContactsSuccess } from '../../patientsContext/usecases/Patients.actions';

function* loadQuestionnairesSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: QuestionnaireObj[] = yield call(dep.questionnairesInteractor.loadQuestionnaires);
    yield put(loadQuestionnairesSuccess(res));
  } catch (error) {
    yield put(loadQuestionnairesFailure(error));
  }
}
function* loadSingleQuestionnaireSaga(action: Action<string>) {
  try {
    const QID: string = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: QuestionnaireObj = yield call(dep.questionnairesInteractor.loadSingleQuestionnaire, QID);
    yield put(loadSingleQuestionnaireSuccess(res));
  } catch (error) {
    yield put(loadSingleQuestionnaireFailure(error));
  }
}

function* loadQInitialDataSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: QInitialData = yield call(dep.questionnairesInteractor.loadQInitialData);
    yield put(loadQInitialDataSuccess(res));
  } catch (error) {
    yield put(loadQInitialDataFailure(error));
  }
}
function* loadQInvitationsSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: QInvitation[] = yield call(dep.questionnairesInteractor.loadQInvitations);
    yield put(loadQInvitationsSuccess(res));
  } catch (error) {
    yield put(loadQInvitationsFailure(error));
  }
}
function* sendQInvitationSaga(action: Action<QInvitation[]>) {
  try {
    const inv: QInvitation[] = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: QInvitation[] = yield call(dep.questionnairesInteractor.sendQInvitation, inv);
    yield put(sendQInvitationSuccess(res));
  } catch (error) {
    yield put(sendQInvitationFailure(error));
  }
}

function* loadAllAssignedQsSaga() {
  try {
    const questionnaires: QuestionnaireObj[] = yield select(getAllQuestionnaires);
    const contacts: PatientContact[] = yield select(getAllContacts);
    const patients: Patient[] = yield select(getAllPatients);
    // const currentPatient: Patient | null = yield select(getCurrentPatient);
    if (questionnaires !== undefined && contacts !== undefined && patients !== undefined) {
      const dep: DependenciesType = yield getDependencies();
      const qInvs: QInvitation[] = yield call(dep.questionnairesInteractor.loadQInvitations);
      const assignedQs: AssignedQuestionnaire[] = [];
      qInvs.forEach((inv) => {
        const thePatient: Patient | undefined = patients?.find((p) => p.uuid === inv.patient_uuid);
        const theQ: QuestionnaireObj | undefined = questionnaires?.find((q) => q.uuid === inv.questionnaire_uuid);
        const thePc: PatientContact | undefined = contacts?.find((c) => c.uuid === inv.contact_uuid);
        if (!theQ || !thePc || !thePatient || inv.status === 'deleted') {
          return;
        }
        assignedQs.push({
          questionnaire: theQ,
          patientContact: thePc,
          patient: thePatient,
          answers: inv.answers,
          locked: inv.locked ?? false,
          status: inv.status,

          assessment: inv.assessment,
          exploration: inv.exploration,

          doctor_uuid: inv.doctor_uuid,
          link: inv.link,
          title: inv.title,
          uuid: inv.uuid,
          created_at: inv.created_at,
          updated_at: inv.updated_at,
        });
      });
      yield put(loadAllAssignedQsSuccess(assignedQs));
    }
  } catch (error) {
    yield put(loadAllAssignedQsFailure(error));
  }
}

function* deleteAssignedQuestionnaireSaga(action: Action<string>) {
  try {
    const invUuid: string = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: boolean = yield call(dep.questionnairesInteractor.deleteQInvitation, invUuid);
    yield put(deleteQInvitationSuccess(res));
  } catch (error) {
    yield put(deleteQInvitationFailure(error));
  }
}

// function* loadInvitationReportSaga(action: Action<string>) {
//   try {
//     const invID: string = action.payload;
//     const dep: DependenciesType = yield getDependencies();
//     const res: string = yield call(dep.questionnairesInteractor.loadInvitationReport, invID);
//     yield put(loadInvitationReportSuccess(res));
//   } catch (error) {
//     yield put(loadInvitationReportFailure(error));
//   }
// }

export function* questionnairesSagas() {
  yield all([
    takeLatest([loadQuestionnairesRequest], loadQuestionnairesSaga),
    takeLatest(loadSingleQuestionnaireRequest, loadSingleQuestionnaireSaga),
    takeLatest(loadQInitialDataRequest, loadQInitialDataSaga),
    takeEvery([loadQInvitationsRequest, sendQInvitationSuccess], loadQInvitationsSaga),
    takeEvery(sendQInvitationRequest, sendQInvitationSaga),
    takeLatest(deleteQInvitationRequest, deleteAssignedQuestionnaireSaga),
    // takeLatest(loadInvitationReportRequest, loadInvitationReportSaga),
    takeLatest(
      [
        loadAllAssignedQsRequest,
        loadPatientContactsSuccess,
        deleteQInvitationSuccess,
        loadQInvitationsSuccess,
        loadQuestionnairesSuccess,
      ],
      loadAllAssignedQsSaga,
    ),
  ]);
}
