import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { FetchiError } from 'fetchi-request';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { StyleSheet } from '../../../common/domain/entities/StyleSheet';
import CommonFunctions from '../../../common/helpers/CommonFunctions';
import { emptyUserAccount, UserAccount } from '../../../contexts/user/entities/UserAccount';
import { AppButton } from '../../../common/ui/Others/AppButton';
import { AppTextInput } from '../../../common/ui/Others/AppTextInput';
import { AppTextInputRequirement } from '../../../common/domain/entities/AppTextInputRequirement';
import { AppFormErrorList } from '../../../common/ui/Others/AppFormErrorList';
import { SignUpResult } from '../../../contexts/user/types';
import {
  BAD_EMAIL_ERROR_TEXT,
  BAD_PASSWORD_ERROR_TEXT,
  BETA_REGISTRATION_WARNING,
  EMPTY_INPUT_ERROR_TEXT,
  EXISTING_EMAIL_ERROR_TEXT,
  PASS_REPEAT_NO_MATCH_ERROR_TEXT,
} from '../../../common/helpers/ConstantTexts';
import { AppCheckBoxV3 } from '../../../common/ui/Others/AppCheckBoxV3';
import { AppAlert } from '../../../common/ui/Others/AppAlert';
import { DU_VERSION_FE, TOS_VERSION_FE } from '../../../common/ui/Others/TermsAndConditions';
import { AGB_VERSION_FE } from '../../../common/ui/Others/AgbPage';
import { useUser } from '../../../contexts/user/store';

interface SignUpProps {
  navigateTo: (p: NavigationPayload) => void;
  navigateBack: () => void;
}
export const SignUpDumb = ({ navigateTo, navigateBack }: SignUpProps) => {
  const [showError, setShowError] = useState<boolean>(false);
  const [showNotAllowedEmailOverlay, setShowNotAllowedEmailOverlay] = useState<boolean>(false);
  const [emailAlreadyExists, setEmailAlreadyExists] = useState<boolean>();
  const [tosAccepted, setTosAccepted] = useState<boolean>(true);
  const [dataSecurityAccepted, setDataSecurityAccepted] = useState<boolean>(false);
  const [dataUseAccepted, setDataUseAccepted] = useState<boolean>(false);
  const [formIsValid, setFormIsValid] = useState<boolean>();
  const [formErrorsList, setFormErrorsList] = useState<string[]>();
  const [inputRequirements, setInputRequirements] = useState<AppTextInputRequirement[]>();
  const [filledData, setFilledData] = useState<UserAccount>({
    ...emptyUserAccount,
  });
  const { register } = useUser();

  useEffect(() => {
    updateErrors();
  }, [filledData, showError, tosAccepted, dataSecurityAccepted, emailAlreadyExists]);

  const updateErrors = () => {
    const errList: string[] = [];
    !passwordValid() && errList.push(BAD_PASSWORD_ERROR_TEXT);
    !passwordRepeatValid() && errList.push(PASS_REPEAT_NO_MATCH_ERROR_TEXT);
    !CommonFunctions.isValidEmail(filledData.email) && errList.push(BAD_EMAIL_ERROR_TEXT);
    (!tosAccepted || !dataSecurityAccepted) && errList.push(EMPTY_INPUT_ERROR_TEXT);
    emailAlreadyExists && errList.push(EXISTING_EMAIL_ERROR_TEXT);
    setFormErrorsList([...errList]);
    setFormIsValid(!errList.length);
  };

  const passwordValid = () => {
    const requirements: AppTextInputRequirement[] = [];
    requirements.push({ name: 'Mindestens', fulfilled: false });
    requirements.push({ name: '8 Zeichen,', fulfilled: filledData.password.length >= 8 });
    requirements.push({ name: '1 Groß,', fulfilled: CommonFunctions.containsUpperCase(filledData.password) });
    requirements.push({
      name: '-und 1 Kleinbuchstabe,',
      fulfilled: CommonFunctions.containsLowerCase(filledData.password),
    });
    requirements.push({ name: '1 Zahlen', fulfilled: CommonFunctions.containsNumber(filledData.password) });
    setInputRequirements(requirements);

    const passIsValid = !_.isEmpty(filledData.password) && requirements.filter((r) => !r.fulfilled).length === 1; // 1 because Mindestens should be the only false
    return passIsValid;
  };
  const passwordRepeatValid = () => {
    const passRepeatIsValid = filledData.password === filledData.passRepeat;
    return passRepeatIsValid;
  };

  const goToNextStep = () => {
    navigateTo({ routeName: 'Email-Activation-Code-Sent' });
  };

  const sendSignUp = () => {
    register({
      email: filledData.email,
      password: filledData.password,
      password_confirmation: filledData.passRepeat ?? filledData.password,
      tos_version: TOS_VERSION_FE,
      data_use_version: DU_VERSION_FE,
      data_security_version: AGB_VERSION_FE,
      rememberUser: filledData.rememberUser ?? false,
    })
      .then((res) => {
        if (res === SignUpResult.EmailVerificationCodeSent) {
          goToNextStep();
        } else if (res === SignUpResult.EmailVerificationCodeSentButWithNotAuthorizedEmailAddress) {
          setShowNotAllowedEmailOverlay(true);
        }
      })
      .catch((err: FetchiError) => {
        if (err.status === 422) {
          setEmailAlreadyExists(true);
          setShowError(true);
        } else {
          alert(`Error caught at sendSignUpAsync\n${JSON.stringify(err)}`);
        }
      });
  };

  return (
    <div style={styles.container}>
      <img alt="logoImage" style={styles.sideBarLogoStyle} src={require('../../../assets/images/logo.png')} />

      <div style={styles.innerContainer}>
        <div style={styles.innerContainer2}>
          <p style={styles.title}>Bitte legen Sie Ihren Account an:</p>

          <AppTextInput
            label="E-Mail-Adresse*"
            placeholder="E-Mail-Adresse"
            currentVal={filledData.email}
            onChange={(str: string) => {
              setEmailAlreadyExists(false);
              setFilledData((v) => ({ ...v, email: str }));
            }}
            showError={showError && (_.isEmpty(filledData?.email) || !CommonFunctions.isValidEmail(filledData.email))}
          />

          <AppTextInput
            label="Passwort*"
            placeholder="Passwort"
            currentVal={filledData.password}
            onChange={(str: string) => {
              setFilledData((v) => ({ ...v, password: str }));
            }}
            showError={showError && !inputRequirements?.filter((r) => !r.fulfilled).length}
            isPass
            requirementsList={inputRequirements}
          />

          <AppTextInput
            label="Passwort wiederholen*"
            placeholder="Passwort wiederholen"
            currentVal={filledData.passRepeat ?? ''}
            onChange={(str: string) => {
              setFilledData((v) => ({ ...v, passRepeat: str }));
            }}
            showError={showError && (_.isEmpty(filledData.passRepeat) || filledData.passRepeat !== filledData.password)}
            isPass
          />
          <AppCheckBoxV3
            hidden
            topLabel="AGB*"
            labelJSX={
              <p style={{ margin: 0, padding: 0 }}>
                Ich akzeptiere die{' '}
                <Link
                  to="AGB"
                  target="_blank"
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: tosAccepted ? Color.APPWHITE : Color.APPBLACK,
                  }}
                  onClick={() => {
                    setTosAccepted((v) => !v);
                  }}
                >
                  AGB
                  <br />
                </Link>
              </p>
            }
            checked={tosAccepted}
            action={() => {
              setTosAccepted((v) => !v);
            }}
            errorShown={showError && !tosAccepted}
          />
          <AppCheckBoxV3
            topLabel="Datenschutz*"
            labelJSX={
              <p style={{ margin: 0 }}>
                Ich akzeptiere die{' '}
                <Link
                  to="Datenschutzerklärung"
                  target="_blank"
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: dataSecurityAccepted ? Color.APPWHITE : Color.APPBLACK,
                  }}
                  onClick={() => {
                    setDataSecurityAccepted((v) => !v);
                  }}
                >
                  Datenschutzerklärung
                  <br />
                </Link>
              </p>
            }
            checked={dataSecurityAccepted}
            action={() => {
              setDataSecurityAccepted((v) => !v);
            }}
            errorShown={showError && !dataSecurityAccepted}
          />
          <AppCheckBoxV3
            topLabel="Datennutzung"
            labelJSX={
              <p style={{ margin: 0 }}>
                Ich akzeptiere, dass meine Daten zum Zwecke der dauerhaften Gewährleistung der technischen
                Funktionsfähigkeit, der Nutzerfreundlichkeit und der Weiterentwicklung der Applikation verarbeitet
                werden.
              </p>
            }
            checked={dataUseAccepted}
            action={() => {
              setDataUseAccepted((v) => !v);
            }}
            errorShown={false}
          />
        </div>
        <AppFormErrorList formErrorsList={formErrorsList ?? []} shown={showError} />
        <div style={styles.buttonsOuterContainer}>
          <div style={styles.buttonsCouple}>
            <AppButton
              label="Zurück"
              color={Color.MONOGREY6}
              strokeOnly
              action={() => {
                navigateBack();
              }}
            />
            <AppButton
              disabled={!formIsValid}
              label="Weiter"
              color={Color.MONOGREY6}
              action={() => {
                formIsValid ? sendSignUp() : setShowError(true);
              }}
            />
          </div>
        </div>
      </div>
      <AppAlert
        isOn={showNotAllowedEmailOverlay}
        content={
          <div>
            <p>{BETA_REGISTRATION_WARNING}</p>
          </div>
        }
        close={() => {
          setShowNotAllowedEmailOverlay(false);
        }}
        buttons={[
          {
            label: 'Verstanden',
            action: () => {
              setShowNotAllowedEmailOverlay(false);
              goToNextStep();
            },
          },
        ]}
      />
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    padding: AppSizes.SIZE1,
  },
  innerContainer: {
    marginTop: AppSizes.SIZE2,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    maxWidth: CommonFunctions.mobileSized() ? '95vw' : '40vw',
    alignSelf: 'center',
  },
  innerContainer2: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 600,
  },
  sideBarLogoStyle: {
    width: 200,
  },
  title: {
    marginBottom: AppSizes.SIZE2,
    fontWeight: FontWeight.BOLDER,
    fontSize: FontSize.H4,
    textAlign: 'center',
    width: '100vw',
    maxWidth: 600,
  },
  buttonsCouple: {
    flexDirection: 'row',
    columnGap: AppSizes.SIZE5,
    columnWidth: '50%',
    width: '95vw',
    maxWidth: 600,
  },
  buttonsOuterContainer: {
    width: '95vw',
    maxWidth: 600,
    alignItems: 'center',
  },
  nameAndCheckBox: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: `1px solid ${Color.APPWHITE}`,
    height: AppSizes.SIZE5,
  },
  rowTitleContainer: {
    fontWeight: FontWeight.BOLDER,
    fontSize: FontSize.P1,
    marginLeft: 12,
  },
};
