const log = {
  info: (...param: any) => {
    process.env.NODE_ENV === 'development' && console.info(param);
  },
  log: (...param: any) => {
    process.env.NODE_ENV === 'development' && console.log(param);
  },
  error: (...param: any) => {
    process.env.NODE_ENV === 'development' && console.error(param);
  },
};

export default log;
