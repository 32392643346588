import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { SettingsEditAccountForm } from './Components/SettingsEditAccountForm';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../../common/domain/entities/StyleSheet';
import CommonFunctions from '../../../common/helpers/CommonFunctions';
import { emptyUser, User } from '../../../contexts/user/entities/User';
import { AppButton } from '../../../common/ui/Others/AppButton';
import { AppFormErrorList } from '../../../common/ui/Others/AppFormErrorList';
import { EMPTY_INPUT_ERROR_TEXT } from '../../../common/helpers/ConstantTexts';
import { emptyUserAccount, UserAccount } from '../../../contexts/user/entities/UserAccount';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { useUser } from '../../../contexts/user/store';
import Messaging from '../../../core/components/messaging';

interface UserPersonalDataEditProps {
  navigateBack: () => void;
  navigateTo: (p: NavigationPayload) => void;
}
export const UserPersonalDataEditDumb = ({ navigateBack, navigateTo }: UserPersonalDataEditProps) => {
  const [filledData, setFilledData] = useState<User>({ ...emptyUser });
  const [newUserAccount, setNewUserAccount] = useState<UserAccount>({ ...emptyUserAccount });
  const [formErrorsList, setFormErrorsList] = useState<string[]>();
  const [showError, setShowError] = useState<boolean>(false);
  const [formIsValid, setFormIsValid] = useState<boolean>();
  const { updateProfile, profile } = useUser();
  const { t } = useTranslation();

  useEffect(() => {
    if (profile) {
      setFilledData({ ...profile });
      setNewUserAccount((v) => ({ ...v, email: profile?.email ?? '' }));
    }
  }, [profile]);

  useEffect(() => {
    updateErrors();
  }, [filledData, newUserAccount.passRepeat, showError]);

  const updateErrors = () => {
    const errList: string[] = [];
    !mandatoryFieldsValid() && errList.push(EMPTY_INPUT_ERROR_TEXT);
    setFormErrorsList([...errList]);
    setFormIsValid(!errList.length);
  };

  const mandatoryFieldsValid = () => {
    let allFilled = true;
    Object.entries(filledData).map(([key, val]) => {
      if (
        key === 'title' ||
        key === 'first_name' ||
        key === 'last_name' ||
        key === 'street' ||
        key === 'house_number' ||
        key === 'postcode' ||
        key === 'city'
      ) {
        if (_.isEmpty(val)) {
          allFilled = false;
        }
      }
      return null;
    });
    return allFilled;
  };

  const updateConnectedUserProfile = () => {
    updateProfile(filledData)
      .then(() => {
        Messaging.toast(t('IT_WORKED'));
        navigateBack();
      })
      .catch((err) => {
        alert(`Error updateConnectedUserProfile \n${err}`);
      });
  };

  return (
    <div style={styles.container}>
      <div style={{ fontWeight: FontWeight.BOLDER, fontSize: FontSize.H5 }}>Mein Profil bearbeiten:</div>
      <div style={styles.innerContainer}>
        <div style={{ justifyContent: 'center', alignItems: 'center' }}>
          <SettingsEditAccountForm
            showError={showError}
            filledData={filledData}
            setFilledData={setFilledData}
            navigateTo={navigateTo}
          />
        </div>
        <AppFormErrorList formErrorsList={formErrorsList ?? []} shown={showError} />
        <div style={styles.buttonsOuterContainer}>
          <div style={styles.buttonsCouple}>
            <AppButton
              label="Zurück"
              color={Color.MONOGREY6}
              strokeOnly
              action={() => {
                navigateBack();
              }}
            />
            <AppButton
              disabled={!formIsValid}
              label="Speichern"
              color={Color.MONOGREY6}
              action={() => {
                setShowError(true);
                formIsValid ? updateConnectedUserProfile() : updateErrors();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    padding: AppSizes.SIZE1,
  },
  innerContainer: {
    maxWidth: CommonFunctions.mobileSized() ? '95vw' : '40vw',
    marginTop: AppSizes.SIZE2,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    alignSelf: 'center',
    flex: 1,
  },
  title: {
    marginBottom: AppSizes.SIZE2,
    fontWeight: FontWeight.BOLDER,
    fontSize: FontSize.H4,
    textAlign: 'center',
    width: '100vw',
    maxWidth: 600,
  },
  buttonsCouple: {
    flexDirection: 'row',
    columnGap: 10,
    columnWidth: '50%',
    width: '95vw',
    maxWidth: 500,
  },
  buttonsOuterContainer: {
    width: '95vw',
    maxWidth: 500,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    // marginTop: AppSizes.SIZE3,
  },
};
