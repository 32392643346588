// COLORS
export enum Color {
  PUREBLACK = '#000',
  PUREWHITE = '#fff',
  PRIMARYBLUE = '#004562',
  PALEBLUE = '#5B738E',
  DARKGREEN = '#3BA5AC',
  VALID = '#73bf81',
  DANGER = '#E20000',
  MONOGREY1 = '#F6F6F6',
  MONOGREY2 = '#eeeeee',
  MONOGREY3 = '#F2F2F2',
  MONOGREY8 = '#E3E3E3',
  MONOGREY7 = '#D8D8D8',
  MONOGREY9 = '#C4C4C4',
  MONOGREY10 = '#B5B5B5',
  MONOGREY4 = '#979797',
  MONOGREY11 = '#747474',
  MONOGREY5 = '#6F6F6F',
  MONOGREY6 = '#3F3F3F',
  APPBLACK = '#1a1a1a',
  APPWHITE = '#fafafa',
  APPTRANSPARENTBG = 'rgba(0,0,0,.35)',
}

// FONT WEIGHTS
export enum FontWeight {
  THIN = 100,
  EXTRALIGHT = 200,
  LIGHT = 300,
  REGULAR = 400,
  MEDIUM = 500,
  SEMIBOLD = 600,
  BOLD = 700,
  EXTRABOLD = 800,
  BOLDER = 900,
}

// FONT SIZES
export enum FontSize {
  H1 = 48,
  H2 = 36,
  H3 = 28,
  H4 = 24,
  H5 = 22,
  P1 = 18,
  P2 = 16,
  P3 = 14,
  P4 = 12,
  P5 = 10,
  CAPTION = 12,
}

// PADDINGS
export enum AppSizes {
  DEFAULT = 8,
  SIZE1 = 16,
  SIZE2 = 24,
  SIZE3 = 32,
  SIZE4 = 40,
  SIZE5 = 48,
  SIZE6 = 56,
  SIZE8 = 64,
  SIZE9 = 72,
  SIZE10 = 80,
  SIZE11 = 88,
  SIZE12 = 96,
}
// FONT SIZES
export enum LineHeight {
  DEFAULT = '1em',
  MINSPACE = '.9em',
  SHYSPACE = '1.1em',
  DOUBLESPACE = '2em',
}
// BORDER RADIUS
export enum BorderRadius {
  R1 = 1,
  R2 = 2,
  R3 = 3,
  R4 = 8,
  R5 = 10,
}
export const TopBarHeight = 48;
