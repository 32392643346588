import { CSSProperties } from 'react';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { LoadingCircle } from '../../../assets/svg/LoadingCircle';
import { StyleSheet } from '../../domain/entities/StyleSheet';

export interface AppButtonProps {
  label: string;
  color?: string;
  loadingIndicatorColor?: string;
  strokeOnly?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  extraStyle?: CSSProperties;
  action: () => void;
}
export const AppButton = ({
  label,
  isLoading = false,
  loadingIndicatorColor,
  strokeOnly,
  className,
  disabled,
  color = 'transparent',
  extraStyle,
  action,
}: AppButtonProps) => (
  <button
    type="button"
    onClick={action}
    className={className}
    style={{
      ...styles.buttonContainer,
      border: disabled ? '0px' : `1px solid ${strokeOnly ? Color.MONOGREY10 : color}`,
      ...(extraStyle && { ...extraStyle }),
      ...(!strokeOnly && { background: disabled ? '#9B9B98' : color }),
    }}
  >
    {isLoading && (
      <div style={styles.loadingContainer}>
        <LoadingCircle color={loadingIndicatorColor} size={25} />
      </div>
    )}
    <div style={{ ...styles.buttonText, ...(strokeOnly && { color }) }}>{label}</div>
  </button>
);

const styles: StyleSheet = {
  loadingContainer: {
    width: AppSizes.SIZE2,
    height: AppSizes.SIZE2,
    position: 'relative',
    marginRight: AppSizes.DEFAULT,
  },
  buttonContainer: {
    paddingRight: AppSizes.DEFAULT + AppSizes.SIZE1,
    paddingLeft: AppSizes.DEFAULT + AppSizes.SIZE1,
    flexDirection: 'row',
    borderRadius: 40,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 300,
    height: AppSizes.SIZE5,
    transition: 'all 500ms',
    position: 'relative',
  },
  buttonText: {
    transition: 'all 500 linear',
    fontSize: FontSize.P3,
    fontWeight: FontWeight.BOLDER,
    color: 'white',
    lineHeight: '1.5em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
};
