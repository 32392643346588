import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { AppSizes, BorderRadius, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../domain/entities/StyleSheet';

export interface AppTextAreaProps {
  label: string;
  maxChar?: number;
  placeholder: string;
  shownValue?: string;
  onChangeFunc: (newVal: string) => void;
  showError?: boolean;
  disabled?: boolean;
  extraStyle?: CSSProperties;
}
export const AppTextArea = ({
  label,
  maxChar,
  shownValue,
  placeholder,
  onChangeFunc,
  disabled,
  showError,
  extraStyle,
}: AppTextAreaProps) => {
  const { t } = useTranslation();
  return (
    <div style={{ ...styles.container, ...extraStyle }}>
      <div
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div style={{ ...styles.inputLabel, ...(showError && { color: Color.DANGER }) }}>{label}</div>
        {maxChar && (
          <div style={{ ...styles.inputLabel, marginRight: AppSizes.SIZE1, color: Color.MONOGREY11 }}>
            {t('MAX_CHARACTERS', { count: maxChar })}
          </div>
        )}
      </div>
      <div style={styles.textAreaContainer}>
        <textarea
          rows={5}
          maxLength={maxChar}
          required
          disabled={disabled}
          value={shownValue ?? ''}
          placeholder={placeholder}
          onChange={(event) => {
            onChangeFunc(event.target.value);
          }}
          style={{
            ...styles.textAreaInput,
            background: shownValue && shownValue.length ? 'white' : Color.MONOGREY3,
            borderColor: showError ? Color.DANGER : shownValue && shownValue.length ? Color.APPBLACK : 'transparent',
          }}
        />
      </div>
      {(maxChar && (
        <p style={styles.textCounter}>
          {shownValue?.length ?? 0}/{maxChar}
        </p>
      )) || <p style={styles.textCounter}>{shownValue?.length ?? 0} zeichen</p>}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    minWidth: 200,
    marginTop: AppSizes.SIZE1,
    marginBottom: AppSizes.SIZE1,
    alignItems: 'start',
    justifyContent: 'center',
    position: 'relative',
  },
  textAreaContainer: {
    transition: 'all 300ms',
    width: '100%',
  },
  inputLabel: {
    marginLeft: AppSizes.SIZE1,
    fontWeight: FontWeight.BOLD,
    fontSize: FontSize.P3,
    color: Color.APPBLACK,
  },
  textAreaInput: {
    fontWeight: FontWeight.REGULAR,
    fontSize: FontSize.P1,
    backgroundColor: '#F2F2F2',
    outline: 'none',
    borderRadius: BorderRadius.R3,
    cursor: 'text',
    minHeight: 100,
    maxHeight: 400,
    resize: 'vertical',
  },
  textCounter: {
    width: '100%',
    color: Color.MONOGREY11,
    fontWeight: FontWeight.REGULAR,
    fontSize: FontSize.P3,
    textAlign: 'right',
  },
};
