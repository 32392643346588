import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PatientOverviewRow } from './components/PatientOverviewRow';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { StyleSheet } from '../../../common/domain/entities/StyleSheet';
import CommonFunctions from '../../../common/helpers/CommonFunctions';
import { AppAlert } from '../../../common/ui/Others/AppAlert';
import { AppButton } from '../../../common/ui/Others/AppButton';
import { AppFilterBox } from '../../../common/ui/Others/AppFilterBox';
import { AppOrderBox } from '../../../common/ui/Others/AppOrderBox';
import { AppSearchInput } from '../../../common/ui/Others/AppSearchInput';
import { getGrStatusString, InvitationStatus } from '../../../questionnairesContext/domain/entities/QInvitation';
import { useUser } from '../../../contexts/user/store';
import Messaging from '../../../core/components/messaging';
import { ListFilter } from '../../domain/entities/ListFilter';
import { Patient } from '../../domain/entities/Patient';
import { useHcpContext } from '../../../contexts/doctor/store';
import { ContactInvitation } from '../../../contexts/patientContact/types';

const statusFilterGroup: ListFilter[] = [
  {
    displayName: `Status: ${getGrStatusString(InvitationStatus.OPENED)}`,
    actualValue: 'STATUS_OPEN',
  },
  {
    displayName: `Status: ${getGrStatusString(InvitationStatus.STARTED)}`,
    actualValue: 'STATUS_STARTED',
  },
  {
    displayName: `Status: ${getGrStatusString(InvitationStatus.PROCESSED)}`,
    actualValue: 'STATUS_DONE',
  },
];

const patientsOverviewFilters: ListFilter[] = [
  ...statusFilterGroup,
  {
    displayName: 'Mit Pin',
    actualValue: 'PINNED',
  },
];

enum PatientsOverviewOrders {
  F_NAME_AZ = 'Vorname A-Z',
  L_NAME_AZ = 'Nachname A-Z',
  F_NAME_ZA = 'Vorname Z-A',
  L_NAME_ZA = 'Nachname Z-A',
  DOB_AZ = 'Geburtsdatum: Jüngste zuerst',
  DOB_ZA = 'Geburtsdatum: Älteste zuerst',
  LAST_CREATED = 'Zuletzt hinzugefügt',
  LAST_MODIFIED = 'Zuletzt geändert',
}
interface PatientsOverviewProps {
  navigateTo: (p: NavigationPayload) => void;
}
export const PatientsOverviewDumb = ({ navigateTo }: PatientsOverviewProps) => {
  // hooks
  const { profile } = useUser();
  const { t } = useTranslation();
  const { push } = useHistory();
  const { getContent, deletePatient, updatePatient } = useHcpContext();

  // states
  const [alertIsOn, setAlertIsOn] = useState<boolean>(false);
  const [shownResults, setShownResults] = useState<Patient[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [currentFilters, setCurrentFilters] = useState<ListFilter[]>([]);
  const [currentOrder, setCurrentOrder] = useState<string>(PatientsOverviewOrders.F_NAME_AZ);
  const [patientToDelete, setPatientToDelete] = useState<Patient>();

  const allPatients = React.useRef<Patient[]>([]);
  const allInvitations = React.useRef<ContactInvitation[]>([]);

  useEffect(() => {
    const reqDelay = setTimeout(() => {
      getContent(['contacts', 'invitations', 'questionnaires'])
        .then((result) => {
          allInvitations.current = result.invitations ?? [];
          changeShownResults();
        })
        .catch((err) => {
          console.error(err);
          // alert(`receive error ${JSON.stringify(err)}`);
        });
      getContent(['patients'])
        .then((value) => {
          allPatients.current = value.patients ?? [];
          changeShownResults();
        })
        .catch((err) => {
          console.error(err);
          // alert(`error receiving patient list ${JSON.stringify(err)}`);
        });
    }, 100);
    return () => {
      clearTimeout(reqDelay);
    };
  }, []);

  useEffect(() => {
    changeShownResults();
  }, [searchQuery, currentFilters, currentOrder]);

  const changeShownResults = () => {
    // patients just for faking deletion's sake
    let finalResults: Patient[] = allPatients.current ? [...allPatients.current] : [];
    // apply search
    if (_.trim(searchQuery).length >= 3) {
      finalResults = CommonFunctions.searchArrayForString(searchQuery, allPatients.current);
    }
    // apply filter
    if (currentFilters.length) {
      finalResults = filterResults(finalResults);
    }
    // apply order
    finalResults = applyOrder(finalResults);
    setShownResults(finalResults);
  };

  const deleteThePatient = (patient: Patient) => {
    deletePatient(patient.uuid)
      .then(() => {
        allPatients.current = allPatients.current.filter((item) => item.uuid !== patient.uuid);
        changeShownResults();
        Messaging.toast(t('IT_WORKED'));
      })
      .catch((err) => alert(`deleting patient failed\n${err}`));
  };
  const togglePin = (patient: Patient) => {
    updateThePatient({ ...patient, pinned: !patient.pinned });
  };
  const updateThePatient = (patient: Patient) => {
    updatePatient(patient)
      .then(() => {
        Messaging.toast(t('IT_WORKED'));
        allPatients.current = allPatients.current.map((item) => (item.uuid === patient.uuid ? patient : item));
        changeShownResults();
      })
      .catch((err) => alert(`updating patient failed\n${err}`));
  };

  const filterResults = (initialPatients: Patient[]) => {
    let filteredPatients: Patient[] = [...initialPatients];
    currentFilters.forEach((currentFilter) => {
      if (currentFilter.actualValue === 'PINNED') {
        filteredPatients = filteredPatients.filter((p) => p.pinned);
      }
      if (currentFilter.actualValue === 'STATUS_OPEN') {
        filteredPatients = filteredPatients.filter(
          (p) =>
            !p.readyForDiag &&
            allInvitations.current.find(
              (assignedQ) => assignedQ.patient_uuid === p.uuid && assignedQ.answers.length === 0,
            ),
        );
      }
      if (currentFilter.actualValue === 'STATUS_STARTED') {
        filteredPatients = filteredPatients.filter(
          (p) =>
            !p.readyForDiag &&
            allInvitations.current.find(
              (assignedQ) => assignedQ.patient_uuid === p.uuid && assignedQ.answers.length > 0,
            ),
        );
      }
      if (currentFilter.actualValue === 'STATUS_DONE') {
        filteredPatients = filteredPatients.filter((p) => p.readyForDiag);
      }
    });
    return _.uniq(filteredPatients);
  };

  const clickedFilter = (daFilter: ListFilter) => {
    const exists: boolean = [...currentFilters].includes(daFilter);
    let newArr: ListFilter[] = currentFilters.filter((filter) => filter !== daFilter);
    if (statusFilterGroup.filter((f) => f.actualValue === daFilter.actualValue).length) {
      // this means the new filter is in the troublesome group with opposing filters, so let's make sure we remove any opposing filters to the new one
      newArr = newArr.filter((cf) => !statusFilterGroup.find((elem2) => elem2.actualValue === cf.actualValue));
    }
    if (!exists) {
      newArr.push(daFilter);
    }
    setCurrentFilters(newArr);
  };

  const applyOrder = (initialPatients: Patient[]) => {
    let orderedPatients: Patient[] = [...initialPatients];
    switch (currentOrder) {
      case PatientsOverviewOrders.DOB_AZ:
        orderedPatients = _.sortBy(orderedPatients, [
          function tempFunc(o) {
            return o.birth_date;
          },
        ]);
        break;
      case PatientsOverviewOrders.DOB_ZA:
        orderedPatients = _.sortBy(orderedPatients, [
          function tempFunc(o) {
            return o.birth_date;
          },
        ]).reverse();
        break;
      case PatientsOverviewOrders.L_NAME_AZ:
        orderedPatients = _.sortBy(orderedPatients, [
          function tempFunc(o) {
            return o.last_name.toLowerCase();
          },
        ]);
        break;
      case PatientsOverviewOrders.L_NAME_ZA:
        orderedPatients = _.sortBy(orderedPatients, [
          function tempFunc(o) {
            return o.last_name.toLowerCase();
          },
        ]).reverse();
        break;
      case PatientsOverviewOrders.F_NAME_AZ:
        orderedPatients = _.sortBy(orderedPatients, [
          function tempFunc(o) {
            return o.first_name.toLowerCase();
          },
        ]);
        break;
      case PatientsOverviewOrders.F_NAME_ZA:
        orderedPatients = _.sortBy(orderedPatients, [
          function tempFunc(o) {
            return o.first_name.toLowerCase();
          },
        ]).reverse();
        break;
      case PatientsOverviewOrders.LAST_CREATED:
        orderedPatients = orderedPatients.sort((a, b) => {
          if (!a.created_at || !b.created_at) {
            return -1;
          }
          return Date.parse(b.created_at) - Date.parse(a.created_at);
        });
        break;
      case PatientsOverviewOrders.LAST_MODIFIED:
        orderedPatients = orderedPatients.sort((a, b) => {
          if (!a.updated_at || !b.updated_at) {
            return -1;
          }
          return Date.parse(b.updated_at) - Date.parse(a.updated_at);
        });
        break;
      default:
        break;
    }
    return orderedPatients;
  };

  const goToPatientCreationScreen = (patient?: Patient) => {
    navigateTo({
      routeName: 'Patient-Creation',
      params: patient,
    });
  };

  const pinnedFirst = (arr: Patient[]) =>
    _.sortBy(arr, [
      function tempFunc(o) {
        return !o.pinned;
      },
    ]);
  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        {profile?.first_name && (
          <div style={styles.welcomeText}>
            Herzlich Willkommen, {profile?.first_name[0]}. {profile?.last_name}
          </div>
        )}
        <div style={styles.toolbar}>
          <div style={{ flex: 1, flexDirection: 'row', columnGap: AppSizes.SIZE1 }}>
            <AppSearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            <AppFilterBox
              possibleFilters={patientsOverviewFilters}
              currentFilters={currentFilters}
              clickedFilter={clickedFilter}
              resetFilter={() => setCurrentFilters([])}
            />
            <AppOrderBox
              possibleOrders={Object.values(PatientsOverviewOrders)}
              currentOrder={currentOrder}
              clickedOrder={(order: string) => setCurrentOrder(order)}
              resetOrder={() => setCurrentOrder(PatientsOverviewOrders.F_NAME_AZ)}
            />
          </div>

          <AppButton label="Neue:r Patient:in" color={Color.MONOGREY6} action={() => goToPatientCreationScreen()} />
        </div>
        {shownResults &&
          pinnedFirst(shownResults).map((patient, index) => (
            <PatientOverviewRow
              key={`PatientRow-${index + 1}`}
              patient={patient}
              patientAssignedQs={allInvitations.current.filter((q) => q.patient_uuid === patient.uuid)}
              onRowClick={() => {
                push({ pathname: `/Patient-Details/${patient.uuid}` });
              }}
              goToEdit={() => {
                goToPatientCreationScreen(patient);
              }}
              togglePin={() => {
                togglePin(patient);
              }}
              deletePatient={(p: Patient) => {
                setPatientToDelete(p);
                setAlertIsOn(true);
              }}
            />
          ))}
        {shownResults?.length === 0 && _.trim(searchQuery).length > 0 && <div>No results found</div>}
      </div>
      <AppAlert
        isOn={alertIsOn}
        content={
          <div style={{ padding: AppSizes.SIZE3, paddingTop: AppSizes.SIZE4 }}>
            <p>Sind Sie sicher, dass Sie die Patientin “{patientToDelete?.first_name}” löschen wollen?</p>
            <p>Gelöschte Patienten finden Sie im Archiv.</p>
          </div>
        }
        close={() => {
          setAlertIsOn(false);
        }}
        buttons={[
          {
            label: 'Abbrechen',
            action: () => {
              setPatientToDelete(undefined);
              setAlertIsOn(false);
            },
            strokeOnly: true,
          },
          {
            label: 'Löschen',
            action: () => {
              patientToDelete && deleteThePatient(patientToDelete);
              setAlertIsOn(false);
            },
          },
        ]}
      />
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    padding: AppSizes.SIZE1,
  },
  innerContainer: {
    alignSelf: 'center',
    maxWidth: 1368,
    width: '100%',
  },
  toolbar: {
    marginBottom: AppSizes.SIZE1,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  welcomeText: {
    fontSize: FontSize.H5,
    fontWeight: FontWeight.BOLDER,
    marginBottom: AppSizes.SIZE5,
    marginTop: AppSizes.SIZE3,
  },
};
