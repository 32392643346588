import { Actions, ActionTypes } from './actions';
import { PatientContactStore } from './types';

export const patientContactStoreReducer = (state: PatientContactStore, action: Actions): PatientContactStore => {
  switch (action.type) {
    case ActionTypes.setToken:
      return {
        ...state,
        lastToken: action.token,
      };
    case ActionTypes.presetDataOnce:
      return {
        ...state,
        dataHasBeenPresentedOnce: true,
      };
    case ActionTypes.flushContent:
      return {
        isFetching: true,
        dataHasBeenPresentedOnce: false,
        content: {},
      };
    case ActionTypes.getContent: {
      const newContent = {
        contact: action.contact ?? state.content.contact,
        patient: action.patient ?? state.content.patient,
        doctor: action.doctor ?? state.content.doctor,
        questionnaire: action.questionnaire ?? state.content.questionnaire,
        invitation: action.invitation ?? state.content.invitation,
      };
      return {
        ...state,
        isFetching:
          newContent.contact === undefined ||
          newContent.doctor === undefined ||
          newContent.invitation === undefined ||
          newContent.patient === undefined ||
          newContent.questionnaire === undefined,
        content: {
          ...state.content,
          ...newContent,
        },
      };
    }
    case ActionTypes.gotError:
      return {
        isFetching: false,
        dataHasBeenPresentedOnce: false,
        error: action.error,
        content: {},
      };
    case ActionTypes.getContact:
      return {
        ...state,
        isFetching:
          action.contact === undefined ||
          state.content.doctor === undefined ||
          state.content.invitation === undefined ||
          state.content.patient === undefined ||
          state.content.questionnaire === undefined,
        content: {
          ...state.content,
          contact: action.contact,
        },
      };
    case ActionTypes.getDoctor:
      return {
        ...state,
        isFetching:
          state.content.contact === undefined ||
          action.doctor === undefined ||
          state.content.invitation === undefined ||
          state.content.patient === undefined ||
          state.content.questionnaire === undefined,
        content: {
          ...state.content,
          // sometimes it's possible that the doctor has not provide their information in the panel
          // so we present a dummy value for the doc's information.
          doctor: {
            ...action.doctor,
            first_name: action.doctor.first_name ?? 'Doc',
            last_name: action.doctor.last_name ?? 'Tortest',
            title: action.doctor.title ?? 'Dr',
          },
        },
      };
    case ActionTypes.getInvitation:
      return {
        ...state,
        isFetching:
          state.content.contact === undefined ||
          state.content.doctor === undefined ||
          action.invitation === undefined ||
          state.content.patient === undefined ||
          state.content.questionnaire === undefined,
        content: {
          ...state.content,
          invitation: action.invitation,
        },
      };
    case ActionTypes.getPatient:
      return {
        ...state,
        isFetching:
          state.content.contact === undefined ||
          state.content.doctor === undefined ||
          state.content.invitation === undefined ||
          action.patient === undefined ||
          state.content.questionnaire === undefined,
        content: {
          ...state.content,
          patient: action.patient,
        },
      };
    case ActionTypes.getQuestionnaire:
      return {
        ...state,
        isFetching:
          state.content.contact === undefined ||
          state.content.doctor === undefined ||
          state.content.invitation === undefined ||
          state.content.patient === undefined ||
          action.questionnaire === undefined,
        content: {
          ...state.content,
          questionnaire: action.questionnaire,
        },
      };
    default:
      return state;
  }
};
