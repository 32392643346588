import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { AppSizes, Color, FontSize, FontWeight } from '../../../../assets/styles/constantStyles';
import { EMPTY_INPUT_ERROR_TEXT } from '../../../helpers/ConstantTexts';
import { AppButton } from '../../Others/AppButton';
import { AppFormErrorList } from '../../Others/AppFormErrorList';
import { AppTextArea } from '../../Others/AppTextArea';
import { HelpObj } from '../../../domain/entities/HelpObj';
import { StyleSheet } from '../../../domain/entities/StyleSheet';

export interface FeedbackFormProps {
  resetChoice: () => void;
  sendHelpAnswers: (obj: HelpObj) => void;
}
export const FeedbackForm = ({ resetChoice, sendHelpAnswers }: FeedbackFormProps) => {
  const [showError, setShowError] = useState<boolean>(false);
  const [formIsValid, setFormIsValid] = useState<boolean>();
  const [feedbackMessage, setFeedbackMessage] = useState<string>();
  const [formErrorsList, setFormErrorsList] = useState<string[]>();

  useEffect(() => {
    updateErrors();
  }, [feedbackMessage, showError]);

  const sendHelp = () => {
    const helpObj: HelpObj = {
      text: feedbackMessage,
      type: 'feedback',
    };
    sendHelpAnswers(helpObj);
  };

  const updateErrors = () => {
    const errList: string[] = [];
    if (!feedbackMessage) {
      errList.push(EMPTY_INPUT_ERROR_TEXT);
    }
    setFormErrorsList(errList);
    setFormIsValid(!errList.length);
  };

  return (
    <AnimatePresence initial={false}>
      <motion.section
        key="content"
        initial="collapsed"
        animate="open"
        exit="collapsed"
        variants={{
          open: { opacity: 1, height: 'auto' },
          collapsed: { height: 0 },
        }}
        transition={{ duration: 0.5 }}
        style={{ width: '100%', overflow: 'hidden' }}
      >
        <div style={{ ...styles.formContainer }}>
          <div style={styles.weirdTextContainer}>
            <p style={{ ...styles.title, marginBottom: AppSizes.SIZE2 }}>Diese Infos sind für uns hilfreich:</p>
            <p style={styles.title}>• Welche Funktion fehlt Ihnen?</p>
            <p style={styles.title}>• Was stört Sie bei der Verwendung von medigital® expert?</p>
          </div>
          <AppTextArea
            label="Wir freuen uns über Ihr Feedback jeder Art!*"
            maxChar={800}
            placeholder="Wir freuen uns über Ihr Feedback jeder Art!"
            shownValue={feedbackMessage}
            onChangeFunc={(newVal: string) => {
              setFeedbackMessage(newVal);
            }}
            showError={showError && !feedbackMessage}
          />

          <AppFormErrorList formErrorsList={formErrorsList ?? []} shown={showError} />
          <div style={styles.buttonsOuterContainer}>
            <div style={styles.buttonsCouple}>
              <AppButton
                label="Abbrechen"
                color={Color.MONOGREY6}
                strokeOnly
                action={() => {
                  resetChoice();
                }}
              />
              <AppButton
                disabled={!formIsValid}
                label="Senden"
                color={Color.MONOGREY6}
                action={() => {
                  formIsValid ? sendHelp() : setShowError(true);
                }}
              />
            </div>
          </div>
        </div>
      </motion.section>
    </AnimatePresence>
  );
};

const styles: StyleSheet = {
  formContainer: {
    flex: 1,
    overflow: 'hidden',
    transition: 'all ease 500ms',
    padding: 2,
  },
  title: {
    flex: 1,
    fontSize: FontSize.H5,
    fontWeight: FontWeight.REGULAR,
    textAlign: 'center',
    margin: 0,
    lineHeight: '1em',
  },
  weirdTextContainer: {
    alignSelf: 'center',
    maxWidth: 400,
    margin: `${AppSizes.SIZE1}px 0px `,
  },
  buttonsOuterContainer: {
    width: '100%',
    maxWidth: 600,
    alignItems: 'center',
    marginBottom: AppSizes.SIZE5,
  },
  buttonsCouple: {
    flexDirection: 'row',
    columnGap: AppSizes.SIZE5,
    columnWidth: '50%',
    width: '100%',
    maxWidth: 600,
  },
};
