import { Patient } from '../../domain/entities/Patient';
import { hardcodedPatientContacts, PatientContact } from '../../domain/entities/PatientContact';
import { PatientContactsInteractor } from '../../domain/gateways/PatientContacts.interactor';

export class InMemoryPatientContactsInteractor implements PatientContactsInteractor {
  loadAllContacts(): Promise<PatientContact[]> {
    return Promise.resolve(hardcodedPatientContacts);
  }

  loadPatientContacts(patient: Patient): Promise<PatientContact[]> {
    return Promise.resolve(hardcodedPatientContacts.filter((pc) => pc.patient_uuid === patient.uuid));
  }

  insertPatientContact(newPatientContact: PatientContact): Promise<PatientContact> {
    return Promise.resolve(newPatientContact);
  }

  updatePatientContact(newPatientContact: PatientContact): Promise<PatientContact> {
    return Promise.resolve(newPatientContact);
  }

  deletePatientContact(patientContactToDelete: PatientContact): Promise<PatientContact> {
    return Promise.resolve(patientContactToDelete);
  }
}
