import { ENV } from '../../configuration';
import { InMemoryQuestionnairesInteractor } from '../adapter/inmemory/InMemoryQuestionnairesInteractor';
import { RealQuestionnairesInteractor } from '../adapter/real/RealQuestionnairesInteractor';
import { QuestionnairesInteractor } from '../domain/gateways/Questionnaires.interactor';

export class QuestionnairesContextFactory {
  static questionnairesInteractor(): QuestionnairesInteractor {
    switch (ENV) {
      case 'inmemory':
        return new InMemoryQuestionnairesInteractor();
      default:
        return new RealQuestionnairesInteractor();
    }
  }
}
