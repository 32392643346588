// import { AgbPage } from '../../common/ui/Others/AgbPage';
// eslint-disable-next-line import/no-cycle
import { Impressum } from '../../common/ui/Others/Impressum';
import { TermsAndConditions } from '../../common/ui/Others/TermsAndConditions';
import { NavigationScreen } from '../domain/entities/NavigationScreen';

export const CommonNavScreens: NavigationScreen[] = [
  // {
  //   routePath: 'AGB',
  //   component: AgbPage,
  // },
  {
    routePath: 'Datenschutzerklärung',
    component: TermsAndConditions,
  },
  {
    routePath: 'Impressum',
    component: Impressum,
  },
];
