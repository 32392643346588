import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserSettingsDeleteAccountRow } from './components/UserSettingsDeleteAccountRow';
import { UserSettingsPageRow } from './components/UserSettingsPageRow';
import { UserSettingsSwitchRow } from './components/UserSettingsSwitchRow';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../../common/domain/entities/StyleSheet';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { User } from '../../../contexts/user/entities/User';
import { EmailNotifTypes } from '../../../contexts/user/entities/EmailNotifTypes';
import { useUser } from '../../../contexts/user/store';
import Messaging from '../../../core/components/messaging';

interface UserSettingsPageProps {
  navigateTo: (p: NavigationPayload) => void;
}
export const UserSettingsPageDumb = ({ navigateTo }: UserSettingsPageProps) => {
  const [emailNotifList, setEmailNotifList] = useState<EmailNotifTypes[]>([]);
  const [enableMatomo, setEnableMatomo] = useState<boolean>(false);
  const { profile, updateProfile } = useUser();
  const { t } = useTranslation();

  useEffect(() => {
    setEnableMatomo(!!profile?.matomo_status);
    setEmailNotifList(profile?.notification_status_list ?? []);
  }, [profile?.matomo_status, profile?.notification_status_list]);

  const updateConnectedUserProfile = (newMatomo?: boolean, newList?: EmailNotifTypes[]) => {
    if (!profile) {
      return;
    }
    let updatedUser: User = {
      ...profile,
    };
    if (newMatomo !== undefined) {
      setEnableMatomo(newMatomo);
      updatedUser = { ...updatedUser, matomo_status: newMatomo };
    }
    if (newList !== undefined) {
      setEmailNotifList(newList);
      updatedUser = { ...updatedUser, notification_status_list: newList };
    }
    updateProfile(updatedUser)
      .then(() => {
        Messaging.toast(t('IT_WORKED'));
      })
      .catch((err) => {
        setEnableMatomo(profile.matomo_status);
        setEmailNotifList(profile.notification_status_list);
        alert(`Error updateprofileProfile\n${JSON.stringify(err.error)}`);
        alert(`Error caught updateConnectedUserProfile\n${JSON.stringify(err)}`);
      });
  };

  const notifSwitched = (notifName: EmailNotifTypes) => {
    const newList: EmailNotifTypes[] = emailNotifList.filter((notif) => notif !== notifName);
    if (newList.length === emailNotifList.length) {
      newList.push(notifName);
    }
    updateConnectedUserProfile(undefined, newList);
  };
  const goToSettingsEditScreen = () => {
    navigateTo({ routeName: 'Settings-Edition' });
  };
  if (!profile) {
    return null;
  }
  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <div style={styles.sectionTitle}>Mein Profil</div>
        <UserSettingsPageRow connectedUser={profile} editClicked={goToSettingsEditScreen} />
        <div style={styles.sectionTitle}>Benachrichtigungen</div>
        <div style={styles.switchRowsContainer}>
          <UserSettingsSwitchRow
            label="E-Mail-Benachrichtigungen, wenn ein Fragebogen vollständig ausgefüllt ist"
            isOn={emailNotifList.includes('processed' as EmailNotifTypes)}
            onClick={() => {
              notifSwitched('processed');
            }}
          />
        </div>
        <div style={styles.sectionTitle}>Erweiterte Einstellungen</div>
        <div style={styles.switchRowsContainer}>
          <UserSettingsSwitchRow
            label="Hier können Sie entscheiden, ob wir die von Ihnen ausgeführten Aktionen analysieren dürfen. Wenn Sie “Aus” wählen wird Ihre Privatsphäre geschützt, allerdings verhindert das eine auf Sie abgestimmte Weiterentwicklung von medigital® expert. Mit “Ein” unterstützen Sie uns, eine bessere Erfahrung für Sie und andere Nutzer:innen zu schaffen."
            isOn={enableMatomo}
            onClick={() => {
              updateConnectedUserProfile(!enableMatomo, undefined);
            }}
          />
        </div>
        <div style={styles.sectionTitle}>Account löschen</div>
        <div style={styles.switchRowsContainer}>
          <UserSettingsDeleteAccountRow navigateTo={navigateTo} />
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    padding: AppSizes.SIZE1,
  },
  innerContainer: {
    alignSelf: 'center',
    maxWidth: 1368,
    width: '100%',
  },
  sectionTitle: {
    fontWeight: FontWeight.BOLD,
    fontSize: FontSize.H4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: AppSizes.SIZE4,
    marginTop: AppSizes.SIZE5,
  },
  personalInfoContainer: {
    background: Color.MONOGREY3,
    borderRadius: 10,
    overflow: 'hidden',
    marginBottom: AppSizes.SIZE2,
  },
  sectionsContainer: {
    flexDirection: 'row',
  },
  rowSection: {
    flex: 1,
    padding: AppSizes.SIZE1,
    borderRight: `1px solid ${Color.PUREWHITE}`,
  },
  rowTitleContainer: {
    fontWeight: FontWeight.BOLD,
    fontSize: FontSize.P1,
  },
  overviewTabs: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: `${AppSizes.SIZE2}px 0`,
    columnGap: AppSizes.SIZE1,
  },
  singleTab: {
    background: Color.MONOGREY3,
    fontWeight: FontWeight.REGULAR,
    fontSize: FontSize.P1,
    width: '50%',
    height: 64,
    borderRadius: 40,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Color.PUREBLACK,
    justifyContent: 'center',
    alignItems: 'center',
  },
  singleTabFocused: {
    background: Color.MONOGREY9,
    fontWeight: FontWeight.BOLDER,
    borderColor: Color.PUREBLACK,
  },
  switchRowsContainer: {
    borderRadius: AppSizes.DEFAULT,
    background: Color.MONOGREY3,
  },
};
