// import _ from 'lodash';
import { InvitationStatus } from '../../questionnairesContext/domain/entities/QInvitation';
import { QuestionnaireAnswer } from '../../questionnairesContext/domain/entities/QuestionnaireAnswer';
import { QuestionnaireObj } from '../../questionnairesContext/domain/entities/QuestionnaireObj';
import { QuestionnaireSection } from '../../questionnairesContext/domain/entities/QuestionnaireSection';
import { SingleQuestion } from '../../questionnairesContext/domain/entities/SingleQuestion';

interface Invitation {
  status: InvitationStatus;
}

export default class QuestionnaireCommonFunctions {
  static getQsBegan(patientAssignedQs: Invitation[]) {
    return patientAssignedQs.filter(
      (q) => q.status === InvitationStatus.STARTED || q.status === InvitationStatus.REJECTED,
    );
  }

  static getQsOpen(patientAssignedQs: Invitation[]) {
    return patientAssignedQs.filter(
      (q) =>
        q.status === InvitationStatus.OPENED ||
        q.status === InvitationStatus.UNREAD ||
        q.status === InvitationStatus.DRAFTED,
    );
  }

  static isQuestionnaireDone({ status }: Invitation) {
    return (
      status === InvitationStatus.PROCESSED ||
      status === InvitationStatus.COMMENTED ||
      status === InvitationStatus.REVIEWED ||
      status === InvitationStatus.FINISHED ||
      status === InvitationStatus.SENT
    );
  }

  static getQsDone(patientAssignedQs: Invitation[]) {
    return patientAssignedQs.filter(QuestionnaireCommonFunctions.isQuestionnaireDone);
  }

  static getQsNotSeen(patientAssignedQs: Invitation[]) {
    return patientAssignedQs.filter((q) => q.status === InvitationStatus.UNREAD || q.status === 'drafted');
  }

  static questionsFromSections(sections: QuestionnaireSection[]): SingleQuestion[] {
    return sections.flatMap((item) => item.questions);
  }

  static hideFSection(questionnaire: QuestionnaireObj, answers: QuestionnaireAnswer[]) {
    let hide = false;
    if (questionnaire.title === 'ADHSVFBB' || questionnaire.title === 'ADHSFBB') {
      const sectionsAb = questionnaire.sections.filter(
        (sec) => sec.label.toLowerCase() === 'a' || sec.label.toLowerCase() === 'b',
      );
      const abQuestions = this.questionsFromSections(sectionsAb);
      const abAnswers = answers.filter((answer) => abQuestions.find((q) => q.uuid === answer.question_uuid));
      hide = abAnswers.filter((a) => +a.value !== 0).length === 0;
    }
    return hide;
  }

  static isCompletelyAnswered(questions: SingleQuestion[], answers: QuestionnaireAnswer[]) {
    if (questions === undefined) {
      return false;
    }
    const answeredQuestions = answers.filter((a) => questions.find((q) => q.uuid === a.question_uuid));
    return answeredQuestions.length === questions.length;
  }

  static getSectionFQuestions(questionnaire: QuestionnaireObj) {
    return questionnaire.sections.filter((s) => s.label.toLowerCase() === 'f')[0].questions;
  }

  static getSectionFAnswers(questionnaire: QuestionnaireObj, answers: QuestionnaireAnswer[]) {
    return answers.filter(
      (a) => this.getSectionFQuestions(questionnaire).find((q) => q.uuid === a.question_uuid) === undefined,
    );
  }

  static getNoneFSectionAnswers(questionnaire: QuestionnaireObj, answers: QuestionnaireAnswer[]) {
    return answers.filter(
      (a) => this.getSectionFQuestions(questionnaire).find((q) => q.uuid === a.question_uuid) === undefined,
    );
  }
}
