import { Action } from 'redux';
import { connect } from 'react-redux';
import { PatientCreationDumb } from './PatientCreation.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { navReplaceAndRemoveRequest } from '../../../navigationContext/usecases/navigation.actions';
import { getAllPatients } from '../../usecases/Patients.selectors';

const mapState = (state: AppState) => ({
  stateAllPatients: getAllPatients(state) || [],
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  navReplaceAndRemove: (p: NavigationPayload) => dispatch(navReplaceAndRemoveRequest(p)),
});

export const PatientCreationSmart = connect(mapState, mapDispatch)(PatientCreationDumb);
