import { Actions, ActionTypes } from './actions';
import { FeedbackStore } from './types';

export const feedbackStoreReducer = (state: FeedbackStore, action: Actions): FeedbackStore => {
  switch (action.type) {
    case ActionTypes.SendFeed: {
      return {
        ...state,
        lastRecordedFeedbackSessionKey: action.sessionKey,
        records: {
          ...state.records,
          [action.category]: action.feedback,
        },
      };
    }
    case ActionTypes.UpdateStore: {
      return {
        ...state,
        records: {
          ...action.payload.reduce(
            (acc, item) => ({
              ...acc,
              [item.category]: {
                score: item.score,
                text: item.text,
              },
            }),
            {},
          ),
        },
      };
    }
    default: {
      return state;
    }
  }
};
