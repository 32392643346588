export const PcRoleEn = {
  PARENT: 'parent',
  TEACHER: 'teacher',
};
export const PcRoleGr = {
  PARENT: 'Eltern',
  TEACHER: 'Lehrer:in',
};
export const PatientStatusDe = {
  AKTIV: 'aktiv',
  Ausgeschlossen: 'ausgeschlossen',
  Abgebrochen: 'abgebrochen',
};
