import { Action } from 'redux-actions';
import { createTypedHandler, handleTypedActions } from './actions.helper';
import { setBreadCrumbsSuccess } from './common.actions';
import { CommonState } from '../configuration/app.states';

const defaultState: CommonState = {
  breadcrumbs: [],
};

const setBreadCrumbsReducer = (state: CommonState, action: Action<string[]>) => ({
  ...state,
  breadcrumbs: action.payload,
});

export const commonReducer = handleTypedActions(
  [createTypedHandler(setBreadCrumbsSuccess, setBreadCrumbsReducer)],
  defaultState,
);
