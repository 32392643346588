import basicInfo from '../env.json';

import './translations/config';

export const ENV: 'dev' | 'prod' | 'inmemory' | 'qa' = basicInfo.env as any;

export const BACKEND_CONFIG = basicInfo.backendConfig;

export const TOKEN_KEY_NAME = basicInfo.tokenKeyName;

export const CREDENTIALS_KEY_NAME = basicInfo.credentialsKeyName;

export const ACCESS_TOKEN_LIFE_SPAN = basicInfo.accessTokenLifeSpanSeconds;

export const RENEW_TOKEN_LIFE_SPAN = basicInfo.renewTokenLifeSpanSeconds;
