export interface MiniPersonProps {
  color?: string;
}

export const HelpQuestionMark = ({ color = '#363636' }: MiniPersonProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.25" fill={color} stroke={color} strokeWidth="1.5" />
    <path
      d="M15.4429 9.76039L14.6929 9.76039L14.6929 9.76914L14.6931 9.77788L15.4429 9.76039ZM11.9915 7.35388C13.6576 7.35388 14.6929 8.46257 14.6929 9.76039L16.1929 9.76039C16.1929 7.5408 14.3895 5.85388 11.9915 5.85388L11.9915 7.35388ZM9.29004 9.97449C9.29004 8.60027 10.3874 7.35388 11.9915 7.35388L11.9915 5.85388C9.53152 5.85388 7.79004 7.79951 7.79004 9.97449L9.29004 9.97449ZM14.6931 9.77788C14.707 10.373 14.5497 10.7625 14.3277 11.0667C14.087 11.3964 13.7462 11.6618 13.311 11.9693C12.5301 12.5209 11.2415 13.2825 11.2415 14.9629L12.7415 14.9629C12.7415 14.1975 13.2139 13.8743 14.1765 13.1944C14.6129 12.8861 15.1394 12.499 15.5393 11.951C15.9578 11.3776 16.2142 10.6643 16.1927 9.7429L14.6931 9.77788Z"
      fill="white"
    />
    <path d="M11.9922 17.9434L11.9922 16.4509" stroke="white" strokeWidth="1.5" />
  </svg>
);
