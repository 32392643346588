import React from 'react';
import { StyleSheet } from '../../../../common/domain/entities/StyleSheet';
import useClamp from '../../../../core/helpers/useClamp';

export type Date = {
  year: number;
  month: number;
};

type Props = {
  initialValue?: Date;
  labels: string[];
  onChange: (value: Date) => void;
};

const DateSelection = ({ initialValue, onChange, labels }: Props) => {
  const [presentingYear, setPresentingYear] = useClamp({ initialValue: initialValue?.year, min: 0, max: 17 });
  const [presentingMonth, setPresentingMonth] = useClamp({ initialValue: initialValue?.month, min: 0, max: 11 });

  React.useEffect(() => {
    setPresentingYear(initialValue?.year);
    setPresentingMonth(initialValue?.month);
  }, [initialValue]);

  React.useEffect(() => {
    if (presentingYear !== undefined && presentingMonth !== undefined) {
      onChange({ year: presentingYear, month: presentingMonth });
    }
  }, [presentingYear, presentingMonth]);

  const handleState = (setState: (val: number | undefined) => void) => (newVale: string) => {
    if (newVale.length === 0) {
      setState(undefined);
      return;
    }
    const newNumberValue = parseInt(newVale);
    if (Number.isNaN(newNumberValue)) {
      return;
    }
    setState(newNumberValue);
  };

  return (
    <div className="d-flex flex-row justify-content-center align-items-center">
      <input
        type="number"
        value={presentingYear ?? ''}
        onChange={(event) => handleState(setPresentingYear)(event.target.value)}
        placeholder={labels[0]}
        className="me-1"
        style={styles.inputStyle}
      />
      <p className="ms-1 me-2 p-0 m-0">:</p>
      <input
        type="number"
        value={presentingMonth ?? ''}
        onChange={(event) => handleState(setPresentingMonth)(event.target.value)}
        placeholder={labels[1]}
        className="ms-1"
        style={styles.inputStyle}
      />
    </div>
  );
};

const styles: StyleSheet = {
  inputStyle: {
    backgroundColor: '#E6EDFF',
    borderRadius: '8px',
    width: '180px',
    height: '56px',
    border: 0,
    textAlign: 'center',
  },
};

export default DateSelection;
