import { all } from 'redux-saga/effects';

export const renewalSafeTime = 60 * 1000;
// renewalSafeTime: the amount of seconds before actual expiry, that we want to renew tokens, to be safe

// the following function was put here for the moment, in case of a failure where the fetch helper would send the message 'logout'
// function* handleFailureSaga(err: any) {
//   if (err.payload.message === 'logout') {
//     yield put(sendSignOutRequest());
//   }
//   yield null;
// }

// function* appRehydrated() {
//   const authTokens: AuthTokens = yield select(getAuthTokens);
//   authTokens.ready = false;

//   const now = Date.now();
//   const accessExpired = authTokens.accessExpirationTime && now > authTokens.accessExpirationTime - renewalSafeTime;
//   const renewExpired = authTokens.renewExpirationTime && now > authTokens.renewExpirationTime - renewalSafeTime;
//   const isDoctor = authTokens.accessExpirationTime && authTokens.renewExpirationTime;
//   // isDoctor: other users don't have token expiry dates
//   if (isDoctor) {
//     setTokensToFetch(authTokens);
//     if (accessExpired) {
//       if (renewExpired) {
//         yield put(sendSignOutRequest());
//       } else if (authTokens.stayLoggedIn) {
//         yield put(renewTokensRequest());
//       } else {
//         yield put(sendSignOutRequest());
//       }
//     } else {
//       authTokens.ready = true;
//       yield put(renewTokensSuccess(authTokens));
//     }
//   } else {
//     yield put(sendSignOutRequest());
//   }
// }

// const matchFailedActionType = ({ type }: { type: string }) => type.endsWith('_FAILURE');
// const matchRehydrateActionType = ({ type }: { type: string }) => type.endsWith('persist/REHYDRATE');

export function* applicationSagas(): any {
  yield all([
    // takeLatest(matchFailedActionType, handleFailureSaga),
    // takeLatest(matchRehydrateActionType, appRehydrated),
  ]);
}
