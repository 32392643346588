import { useEffect, useRef, useState } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { AppSizes, Color, FontSize } from '../../../assets/styles/constantStyles';
import { ListFilter } from '../../../patientsContext/domain/entities/ListFilter';
import { StyleSheet } from '../../domain/entities/StyleSheet';

interface AppFilterBoxProps {
  currentFilters: ListFilter[];
  possibleFilters: ListFilter[];
  clickedFilter: (filter: ListFilter) => void;
  resetFilter: () => void;
}

export const AppFilterBox = ({ currentFilters, possibleFilters, clickedFilter, resetFilter }: AppFilterBoxProps) => {
  const [inputIsOpen, setInputIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  // this is only for handling click outside the component, to close it
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setInputIsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  /// /////////////////////////////////////////////////////////////////////

  return (
    <div ref={ref} style={styles.container}>
      <button type="button" onClick={() => setInputIsOpen((v) => !v)} style={styles.innerContainer}>
        Filter
        <IoMdArrowDropdown
          title="More options"
          color={Color.MONOGREY6}
          size={20}
          style={{
            ...styles.arrowIcon,
            transform: inputIsOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
          }}
          onClick={() => setInputIsOpen((v) => !v)}
        />
      </button>
      <div style={inputIsOpen ? { ...styles.box, ...styles.floatBox } : { ...styles.box }}>
        <div>
          <button type="button" onClick={resetFilter} style={styles.resetButton}>
            Zurücksetzen
          </button>
          <div
            style={{
              margin: AppSizes.SIZE1,
              marginTop: AppSizes.DEFAULT,
              marginBottom: AppSizes.DEFAULT,
              display: 'block',
            }}
          >
            {possibleFilters.map((ele, i) => {
              const selected = currentFilters.includes(ele);
              return (
                <button
                  key={`${i * 996}`}
                  type="button"
                  onClick={() => clickedFilter(ele)}
                  style={{
                    ...styles.filterType,
                    background: !selected ? Color.MONOGREY3 : Color.PUREWHITE,
                    border: `1px solid ${selected ? Color.APPBLACK : 'transparent'}`,
                  }}
                >
                  {ele.displayName}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'relative',
    maxWidth: '100%',
  },
  innerContainer: {
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${Color.APPBLACK}`,
    borderRadius: 10,
    transition: 'all 300ms',
    paddingLeft: AppSizes.SIZE1,
  },
  arrowIcon: {
    marginLeft: AppSizes.DEFAULT,
    marginRight: AppSizes.DEFAULT,
    cursor: 'pointer',
    transition: 'all 300ms',
  },
  box: {
    transition: 'all 500ms',
    width: 346 - AppSizes.SIZE1,
    background: Color.PUREWHITE,
    zIndex: 1,
    position: 'absolute',
    top: '100%',
    left: 0,
    borderRadius: 10,
    border: `1px solid ${Color.APPBLACK}`,
    marginTop: AppSizes.DEFAULT,
    overflow: 'hidden',
    maxHeight: 0,
    padding: 0,
    borderWidth: 0,
  },
  floatBox: {
    maxHeight: 1000,
    borderWidth: 1,
  },
  filterType: {
    transition: 'all 300ms',
    paddingTop: AppSizes.SIZE1,
    paddingBottom: AppSizes.SIZE1,
    padding: AppSizes.DEFAULT,
    display: 'inline-block',
    marginRight: AppSizes.DEFAULT,
    marginBottom: AppSizes.DEFAULT,
    borderRadius: 10,
    fontSize: FontSize.P3,
    cursor: 'pointer',
  },
  boxFooter: {
    padding: AppSizes.SIZE1,
    paddingBottom: AppSizes.DEFAULT,
    paddingTop: AppSizes.DEFAULT,
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderTop: `1px solid ${Color.APPBLACK}`,
    fontSize: FontSize.P3,
    alignContent: 'center',
  },
  validateButton: {
    background: Color.MONOGREY6,
    padding: AppSizes.DEFAULT,
    color: Color.APPWHITE,
    borderRadius: 10,
    cursor: 'pointer',
  },
  resetButton: {
    cursor: 'pointer',
    color: Color.MONOGREY6,
    textDecoration: 'underline',
    justifyContent: 'center',
    padding: AppSizes.SIZE1,
  },
};
