import React from 'react';
import fetchi, { AnyAsyncService } from 'fetchi-request';
import { ContactInvitation, PatientContactContext, PatientContactStore } from './types';
import { patientContactStoreReducer } from './reducer';
import { ActionTypes } from './actions';
import { NotImpAsyncService } from '../user/types';
import { useUser } from '../user/store';
import useToken from '../../core/helpers/useToken';
import { usePersistedReducer } from '../../core/helpers/persistedReducer';
import { PatientContact } from '../../patientsContext/domain/entities/PatientContact';
import { Patient } from '../../patientsContext/domain/entities/Patient';
import { QuestionnaireObj } from '../../questionnairesContext/domain/entities/QuestionnaireObj';
import { User } from '../user/entities/User';

const notImpt = new NotImpAsyncService();

const PatientContactContextContainer = React.createContext<PatientContactContext>({
  isFetching: false,
  dataHasBeenPresentedOnce: false,
  content: {},
  retry: () => {},
  update: () => notImpt,
  reject: () => notImpt,
  dataPresented: () => {},
});

const initState: PatientContactStore = {
  isFetching: false,
  dataHasBeenPresentedOnce: false,
  content: {},
};

const PatientContentContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { authorities } = useUser();
  const { getTokens } = useToken();
  const [store, dispatch] = usePersistedReducer(patientContactStoreReducer, initState, 'PatientContentReducer');

  React.useEffect(() => {
    retrieveContentIfPossible();
  }, [authorities]);

  const retrieveContentIfPossible = () => {
    if (authorities.patient) {
      const isContentAlreadySynced =
        store.error === undefined &&
        store.isFetching === false &&
        store.content.contact &&
        store.content.doctor &&
        store.content.invitation &&
        store.content.patient &&
        store.content.questionnaire;

      const tokens = getTokens();
      if (
        tokens &&
        tokens.bearer_token &&
        (store.lastToken === undefined || store.lastToken !== tokens.bearer_token || isContentAlreadySynced === false)
      ) {
        dispatch({ type: ActionTypes.flushContent });
        dispatch({ type: ActionTypes.setToken, token: tokens.bearer_token });
        getContent(tokens.bearer_token);
      }
    } else {
      dispatch({ type: ActionTypes.flushContent });
    }
  };

  const update = (invitation: ContactInvitation): AnyAsyncService<ContactInvitation> => {
    if (store.lastToken === undefined) {
      return fetchi.reject(Error('There is no valid token'));
    }
    return fetchi<{ data: ContactInvitation }>({
      url: '/contact',
      method: 'PUT',
      params: { data: invitation },
      headers: { Authorization: `Bearer ${store.lastToken}` },
    }).then(({ data }) => {
      dispatch({ type: ActionTypes.getInvitation, invitation: data });
      return data;
    });
  };

  const reject = (): AnyAsyncService<void> => {
    if (store.lastToken === undefined) {
      return fetchi.reject('There is no valid token');
    }
    return fetchi({
      url: '/contact',
      method: 'DELETE',
      headers: { Authorization: `Bearer ${store.lastToken}` },
    });
  };

  const retry = () => {
    dispatch({ type: ActionTypes.flushContent });
    retrieveContentIfPossible();
  };

  const getContent = (token: string) => {
    fetchi
      .all([
        fetchi<{ data: PatientContact }>({
          url: '/contact/me/',
          headers: { Authorization: `Bearer ${token}` },
        }),
        fetchi<{ data: Patient }>({
          url: '/contact/patient/',
          headers: { Authorization: `Bearer ${token}` },
        }),
        fetchi<{ data: ContactInvitation }>({
          url: '/contact',
          headers: { Authorization: `Bearer ${token}` },
        }),
        fetchi<{ data: QuestionnaireObj }>({
          url: '/contact/questionnaire/',
          headers: { Authorization: `Bearer ${token}` },
        }),
        fetchi<{ data: User }>({
          url: '/contact/doctor',
          headers: { Authorization: `Bearer ${token}` },
        }),
      ])
      .promise.then((value) => {
        dispatch({
          type: ActionTypes.getContent,
          contact: value[0].data,
          patient: value[1].data,
          invitation: value[2].data,
          questionnaire: value[3].data,
          doctor: value[4].data,
        });
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.gotError, error });
      });
  };

  const dataPresented = () => {
    dispatch({ type: ActionTypes.presetDataOnce });
  };

  const value = React.useMemo(
    () => ({
      ...store,
      update,
      reject,
      retry,
      dataPresented,
    }),
    [store],
  );

  return <PatientContactContextContainer.Provider value={value}>{children}</PatientContactContextContainer.Provider>;
};

export const useContact = () => React.useContext(PatientContactContextContainer);

export default PatientContentContextProvider;
