import React from 'react';
import { StyleSheet } from '../../../../common/domain/entities/StyleSheet';

type Props = {
  value?: string;
  onChange?: (value: string) => void;
};

const QuestionnaireTextInput = ({ value, onChange }: Props) => {
  React.useEffect(() => {
    onChange?.('');
  }, []);

  return (
    <textarea
      className="w-100 text-start p-3"
      value={value}
      onChange={(event) => onChange?.(event.target.value)}
      placeholder="Körperbereich, Körperteil, etc…"
      style={styles.inputStyle}
    />
  );
};

const styles: StyleSheet = {
  inputStyle: {
    backgroundColor: '#E6EDFF',
    borderRadius: '8px',
    width: '80px',
    height: '56px',
    border: 0,
    minHeight: '100px',
    textAlign: 'center',
  },
};

export default QuestionnaireTextInput;
