import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import fetchi from 'fetchi-request';
import { feedbackStoreReducer } from './reducer';
import { Feedback, FeedbackCategory, FeedbackContext, ServerStoredFeedback } from './types';
import { ActionTypes } from './actions';
import FeedbackPopupView from './views/FeedbackPopupView';
import { usePersistedReducer } from '../../core/helpers/persistedReducer';
import { useUser } from '../user/store';

const FeedbackContextContainer = React.createContext<FeedbackContext>({
  records: {},
  getFeedbackIfNecessary: () => {},
});

const FeedbackContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [store, dispatch] = usePersistedReducer(feedbackStoreReducer, { records: {} }, 'Feedback_Persited_Store');
  const [visibleFeedbackCategory, setVisibleFeedbackCategory] = React.useState<FeedbackCategory>();
  // references values
  const queuedCategory = React.useRef<FeedbackCategory>();
  const hasBeenSynced = React.useRef<boolean>(false);
  const { docSessionKey, authorities } = useUser();

  const getFeedbackIfNecessary = (category: FeedbackCategory, isNecessaryByForce = false) => {
    if (hasBeenSynced.current === false) {
      queuedCategory.current = category;
      return;
    }
    // we have never had any feedbacks for this category & no feedback in this session
    if (
      ((store.lastRecordedFeedbackSessionKey === undefined || store.lastRecordedFeedbackSessionKey !== docSessionKey) &&
        store.records[category] === undefined) ||
      isNecessaryByForce
    ) {
      setVisibleFeedbackCategory(category);
    }
  };

  React.useEffect(() => {
    let waiting: NodeJS.Timeout;
    if (authorities.doctor) {
      // wait for all variables (like tokens) to be set
      waiting = setTimeout(() => {
        syncStoreWithServer();
      }, 100);
    } else {
      hasBeenSynced.current = false;
      queuedCategory.current = undefined;
    }
    return () => {
      waiting !== undefined && clearTimeout(waiting);
    };
  }, [authorities.doctor]);

  const syncStoreWithServer = () => {
    fetchi<{ data: ServerStoredFeedback[] }>({ url: '/feedbacks', retryConfig: { count: 1, delay: 500 } })
      .then((response) => {
        dispatch({ type: ActionTypes.UpdateStore, payload: response.data });
      })
      .catch(() => {
        /** do nothing just ignore it for now:( */
      })
      .finally(() => {
        hasBeenSynced.current = true;
        if (queuedCategory.current !== undefined) {
          getFeedbackIfNecessary(queuedCategory.current);
          queuedCategory.current = undefined;
        }
      });
  };

  const submitFeedback = (category: FeedbackCategory, content: Feedback) =>
    fetchi<void>({
      url: '/feedbacks',
      method: 'POST',
      params: {
        feedback: {
          category,
          score: content.score,
          text: content.text,
        },
      },
    })
      .then(() => {
        if (docSessionKey !== undefined) {
          dispatch({ type: ActionTypes.SendFeed, feedback: content, category, sessionKey: docSessionKey });
        }
      })
      .finally(() => {
        dispatch;
      });

  const value = React.useMemo(
    () => ({
      ...store,
      getFeedbackIfNecessary,
    }),
    [store],
  );
  return (
    <FeedbackContextContainer.Provider value={value}>
      {children}
      <AnimatePresence initial={false}>
        {visibleFeedbackCategory !== undefined && (
          <motion.div animate="fade-in" exit="fade-out">
            <FeedbackPopupView
              category={visibleFeedbackCategory}
              abort={() => setVisibleFeedbackCategory(undefined)}
              submit={(feedback) => submitFeedback(visibleFeedbackCategory, feedback)}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </FeedbackContextContainer.Provider>
  );
};

export const useFeedback = () => React.useContext(FeedbackContextContainer);
export default FeedbackContextProvider;
