import { CSSProperties } from 'react';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { Close } from '../../../assets/svg/Close';
import { StyleSheet } from '../../domain/entities/StyleSheet';

export interface AppCheckBoxV3Props {
  topLabel?: string;
  labelJSX: JSX.Element;
  checked: boolean;
  errorShown: boolean;
  hidden?: boolean;
  action: () => void;
  extraStyle?: CSSProperties;
}
export const AppCheckBoxV3 = ({
  topLabel,
  hidden = false,
  labelJSX,
  checked,
  errorShown,
  action,
  extraStyle,
}: AppCheckBoxV3Props) =>
  hidden ? null : (
    <div style={{ ...extraStyle, ...styles.boxContainer }}>
      {topLabel}
      <button
        type="button"
        onClick={action}
        style={{
          ...styles.itemContainer,
          borderColor: errorShown ? Color.DANGER : 'transparent',
          background: checked ? Color.MONOGREY6 : Color.MONOGREY3,
        }}
      >
        <div style={styles.innerContainer}>
          <div style={styles.checkBox}>
            {checked && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  background: Color.APPWHITE,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Close color={Color.APPBLACK} />
              </div>
            )}
          </div>
          {labelJSX && (
            <div
              style={{
                ...styles.itemText,
                color: checked ? Color.APPWHITE : Color.APPBLACK,
                fontWeight: checked ? FontWeight.EXTRABOLD : FontWeight.REGULAR,
              }}
            >
              {labelJSX}
            </div>
          )}
        </div>
      </button>
    </div>
  );

const styles: StyleSheet = {
  boxContainer: {
    width: '100%',
    margin: `${AppSizes.SIZE1}px 0`,
  },
  itemContainer: {
    cursor: 'pointer',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 3,
    minHeight: 48,
    // alignItems: 'center',
    justifyContent: 'center',
  },
  innerContainer: {
    margin: `0 ${AppSizes.SIZE1}px`,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${AppSizes.DEFAULT}px 0`,
  },
  itemText: {
    fontSize: FontSize.P1,
    marginLeft: AppSizes.DEFAULT,
    flex: 1,
    textAlign: 'left',
  },
  checkBox: {
    background: Color.APPWHITE,
    borderRadius: 5,
    border: `1px solid ${Color.APPBLACK}`,
    width: 20,
    minWidth: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
};
