import { CSSProperties, useEffect, useRef, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { ErrorExclamationMark } from './ErrorExclamationMark';
import { AppSizes, Color, FontSize, FontWeight } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../domain/entities/StyleSheet';
import { ToRightArrow } from '../../../assets/svg/ToRightArrow';

export interface AppDropDownMenuProps {
  label: string;
  list: string[];
  shownValue?: string;
  placeholder: string;
  onChangeFunc: (newVal: string) => void;
  showError?: boolean;
  disabled?: boolean;
  selectedMultiChoices?: string[];
  // disabledHint?: string;
  extraStyle?: CSSProperties;
  forceOpen?: boolean;
}
export const AppDropDownMenu = ({
  label,
  list,
  shownValue,
  placeholder,
  onChangeFunc,
  disabled,
  selectedMultiChoices,
  // disabledHint,
  showError,
  extraStyle,
  forceOpen,
}: AppDropDownMenuProps) => {
  const [inputIsOpen, setInputIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setInputIsOpen(false);
  }, [shownValue]);

  // this is only for handling click outside the component, to close it
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setInputIsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  /// /////////////////////////////////////////////////////////////////////

  const buttonIsDark = (option: string) => {
    if (selectedMultiChoices === undefined) {
      if (shownValue === option) {
        return styles.darkInput;
      }
    } else if (shownValue?.includes(option)) {
      return styles.darkInput;
    }
    return null;
  };
  const checkBoxRender = (option: string) => {
    if (selectedMultiChoices === undefined) {
      return null;
    }
    return (
      <div style={styles.checkBoxContainer}>
        <IoMdClose
          size={16}
          color={selectedMultiChoices.includes(option) ? Color.APPBLACK : 'transparent'}
          style={{ cursor: 'pointer' }}
        />
      </div>
    );
  };
  if (list.length > 2 && !forceOpen) {
    return (
      <div ref={ref} style={{ ...styles.container, ...extraStyle }}>
        <div
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div style={{ ...styles.inputLabel, ...(showError && { color: Color.DANGER }) }}>{label}</div>
          <div
            style={{ ...styles.inputLabel, marginRight: AppSizes.SIZE1, fontStyle: 'italic', color: Color.MONOGREY11 }}
          >
            {selectedMultiChoices === undefined ? 'Nur 1 Antwort' : 'Mehrere Antworten möglich'}
          </div>
        </div>
        <div
          role="none"
          onClick={() => setInputIsOpen((v) => !v)}
          style={{
            ...styles.innerContainer,
            borderColor: showError ? Color.DANGER : 'transparent',
            ...(shownValue?.length && styles.darkInput),
          }}
        >
          <div style={styles.selectedValueContainer}>
            <p
              style={{
                ...styles.selectedValueStyle,
                ...(disabled && { color: Color.MONOGREY4 }),
                ...(shownValue?.length && styles.darkInput),
              }}
            >
              {shownValue?.length ? shownValue : placeholder}
            </p>
            <ErrorExclamationMark shown={showError} />
            <div
              style={{
                ...styles.arrowIcon,
                transform: inputIsOpen ? 'translateY(-50%) rotate(-90deg)' : 'translateY(-50%) rotate(90deg)',
              }}
            >
              <ToRightArrow color={!shownValue?.length ? Color.MONOGREY6 : Color.APPWHITE} />
            </div>
          </div>

          <div style={inputIsOpen ? { ...styles.box, ...styles.floatBox } : { ...styles.box }}>
            <div>
              <div style={styles.floatationMenu}>
                {list.map((option, i) => (
                  <button
                    key={`option-${i + 1}`}
                    type="button"
                    onClick={() => {
                      onChangeFunc(option);
                    }}
                    style={{
                      ...styles.menuItem,
                      ...buttonIsDark(option),
                      ...(selectedMultiChoices === undefined && { justifyContent: 'center' }),
                    }}
                  >
                    {checkBoxRender(option)}
                    <div style={{ flexDirection: 'row' }}>{option}</div>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div ref={ref} style={{ ...styles.container, ...extraStyle }}>
      <div
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div style={{ ...styles.inputLabel, ...(showError && { color: Color.DANGER }) }}>{label}</div>
        <div
          style={{ ...styles.inputLabel, marginRight: AppSizes.SIZE1, fontStyle: 'italic', color: Color.MONOGREY11 }}
        >
          {selectedMultiChoices === undefined ? 'Nur 1 Antwort' : 'Mehrere Antworten möglich'}
        </div>
      </div>
      <div
        style={{
          transition: 'all 300ms',
          width: '100%',
          background: Color.PUREWHITE,
          // zIndex: 1,
          top: '100%',
          left: 0,
          borderRadius: 3,
          borderColor: showError ? Color.DANGER : Color.APPBLACK,
          borderStyle: 'solid',
          padding: 0,
          marginTop: AppSizes.DEFAULT,
          borderWidth: 1,
        }}
      >
        <div>
          <div style={styles.floatationMenu}>
            <div
              style={{
                position: 'absolute',
                width: 1,
                height: 1,
                top: '50%',
                right: -AppSizes.SIZE1,
              }}
            >
              <ErrorExclamationMark shown={showError} />
            </div>
            {list.map((option, i) => (
              <button
                key={`option-${i + 1}`}
                type="button"
                onClick={() => {
                  onChangeFunc(option);
                }}
                style={{
                  ...styles.menuItem,
                  ...buttonIsDark(option),
                  ...(selectedMultiChoices === undefined && { justifyContent: 'center' }),
                }}
              >
                {checkBoxRender(option)}
                <div style={{ flexDirection: 'row' }}>{option}</div>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    minWidth: 200,
    width: '100%',
    marginTop: AppSizes.SIZE1,
    marginBottom: AppSizes.SIZE1,
    alignItems: 'start',
    justifyContent: 'center',
    position: 'relative',
  },
  innerContainer: {
    transition: 'all 300ms',
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
    margin: 0,
    height: AppSizes.SIZE5,
    fontSize: FontSize.P1,
    fontWeight: FontWeight.BOLD,
    color: Color.APPBLACK,
    outline: 'none',
    display: 'inline-flex',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 3,
    background: Color.MONOGREY3,
    justifyContent: 'center',
  },
  selectedValueContainer: {
    marginLeft: AppSizes.SIZE1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%',
  },
  selectedValueStyle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'left',
    width: '100%',
    marginBottom: 0,
    textTransform: 'capitalize',
  },
  inputLabel: {
    marginLeft: AppSizes.SIZE1,
    pointerEvents: 'none',
    fontSize: FontSize.P3,
    fontWeight: FontWeight.REGULAR,
    color: Color.APPBLACK,
  },
  floatationMenu: {
    margin: AppSizes.SIZE1,
    rowGap: AppSizes.SIZE1,
    position: 'relative',
  },
  arrowIcon: {
    transition: 'all 300ms',
    position: 'absolute',
    top: '50%',
    right: AppSizes.SIZE1,
  },
  menuItem: {
    padding: `${AppSizes.SIZE1}px`,
    background: Color.MONOGREY3,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    whiteSpace: 'break-spaces',
    flexDirection: 'row',
    textTransform: 'capitalize',
  },
  box: {
    transition: 'all 300ms',
    width: '100%',
    background: Color.PUREWHITE,
    zIndex: 1,
    position: 'absolute',
    top: '100%',
    left: 0,
    borderRadius: 3,
    border: `1px solid ${Color.APPBLACK}`,
    marginTop: AppSizes.SIZE1,
    overflow: 'hidden',
    maxHeight: 0,
    padding: 0,
    borderWidth: 0,
  },
  floatBox: {
    maxHeight: 1000,
    borderWidth: 1,
  },
  darkInput: {
    color: Color.APPWHITE,
    fontWeight: FontWeight.BOLD,
    background: Color.MONOGREY6,
    transition: 'all 300ms',
  },
  checkBoxContainer: {
    background: Color.APPWHITE,
    borderRadius: 3,
    border: '1px solid black',
    justifyContent: 'center',
    alignItems: 'center',
    width: AppSizes.SIZE1,
    height: AppSizes.SIZE1,
    marginRight: AppSizes.SIZE1,
    marginLeft: AppSizes.SIZE1,
  },
};
