import { AnyAsyncService, FetchResponse } from 'fetchi-request';
import { User } from './entities/User';
import { Optional } from '../../core/types/common';

export type Credentials = {
  email: string;
  password: string;
};

export type DataVersion = 'no_version' | 'v1.1';

export type SignUpPayload = Credentials & {
  password_confirmation: string;
  rememberUser: boolean;
  tos_version: DataVersion;
  data_security_version: DataVersion;
  data_use_version: DataVersion;
};

export enum SignUpResult {
  EmailVerificationCodeSent,
  EmailVerificationCodeSentButWithNotAuthorizedEmailAddress,
  EmailAlreadyExits,
}

export type Role = 'doctor' | 'patient';

export type UserStore = {
  docSessionKey?: string;
  contactSessionKey?: string;
  isAuthorized: boolean;
  authorities: { [x in Role]: boolean };
  isFirstTimePanelAppOpen: boolean;
  profile?: User;
};

export type UserContext = UserStore & {
  authorizeWithToken: (token: string) => AnyAsyncService<void>;
  getProfile: (input: { useCache?: boolean }) => AnyAsyncService<User>;
  updateProfile: (newUser: User) => AnyAsyncService<User>;
  login: (credentials: Credentials) => AnyAsyncService<Optional<User>>;
  register: (credential: SignUpPayload) => AnyAsyncService<SignUpResult>;
  emailVerify: (code: string) => AnyAsyncService<User>;
  deleteAccount: (password: string) => AnyAsyncService<void>;
  logout: () => void;
  forgetPass: (email: string) => AnyAsyncService<void>;
  resetPass: (input: { token: string; newPass: string }) => AnyAsyncService<void>;
  changePass: (input: { previousPass: string; newPass: string }) => AnyAsyncService<void>;
};

export class NotImpAsyncService<T = any> implements AnyAsyncService<T> {
  then<TResult1 = T, TResult2 = never>(): AnyAsyncService<TResult1 | TResult2> {
    throw new Error('Method not implemented.');
  }

  rawPromise(): Promise<FetchResponse<T>> {
    throw new Error('Method not implemented.');
  }

  catch<TResult = never>(): AnyAsyncService<T | TResult> {
    throw new Error('Method not implemented.');
  }

  finally(): this {
    throw new Error('Method not implemented.');
  }

  cancel() {
    throw new Error('Method not implemented.');
  }

  retry() {
    throw new Error('Method not implemented.');
  }
}
