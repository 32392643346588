import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { AppSizes, Color, FontSize, FontWeight, LineHeight } from '../../../assets/styles/constantStyles';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import CommonFunctions from '../../../common/helpers/CommonFunctions';
import { StyleSheet } from '../../../common/domain/entities/StyleSheet';
import { AppButton } from '../../../common/ui/Others/AppButton';
import {
  DELETED_QUESTIONNAIRE_ERROR_TEXT,
  FALSE_QUESTIONNAIRE_DATA_P1,
  FALSE_QUESTIONNAIRE_DATA_P2,
  FALSE_QUESTIONNAIRE_DATA_P3,
  USER_DATA_CORRECT_OPTIONS,
} from '../../../common/helpers/ConstantTexts';
import { AppDropDownMenu } from '../../../common/ui/Others/AppDropDownMenu';
import { PatientOverviewRow } from '../../../patientsContext/ui/patientsOverview/components/PatientOverviewRow';
import { AppAlert } from '../../../common/ui/Others/AppAlert';
import { Footer } from '../../../navigationContext/ui/components/Footer';
import Messaging from '../../../core/components/messaging';
import TopBar from '../TopBar';
import { useContact } from '../../../contexts/patientContact/store';
import { LoadingCircle } from '../../../assets/svg/LoadingCircle';
import { isDeadEndStatus } from '../../domain/entities/QInvitation';

type Props = {
  navigateTo: (p: NavigationPayload) => void;
  navigateBack: () => void;
};
export const PatientDataFormDumb = ({ navigateTo, navigateBack }: Props) => {
  const [selectedChoice, setSelectedChoice] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = React.useState<boolean>(false);
  const { content, isFetching, reject: rejectInvitation } = useContact();

  useEffect(() => {
    if (content.invitation?.status && isDeadEndStatus(content.invitation?.status)) {
      navigateTo({ routeName: 'Dead-End-Screen', params: { content: DELETED_QUESTIONNAIRE_ERROR_TEXT } });
    }

    // if the invitation has not been accepted yet
    if (content.invitation?.tos_accepted_at === undefined) {
      navigateTo({ routeName: 'Accept-Terms' });
    }
  }, [content]);

  const nextPressed = () => {
    if (selectedChoice.length === 0) {
      setShowError(true);
    } else {
      setShowError(false);
      if (selectedChoice === USER_DATA_CORRECT_OPTIONS[0]) {
        navigateTo({ routeName: 'Assessment-Sheet' });
      } else {
        setIsConfirmationVisible(true);
      }
    }
  };

  const rejectQ = () => {
    rejectInvitation()
      .then(() => {
        Messaging.toast(
          'Der Arzt wird benachrichtigt, um Korrekturen vorzunehmen und den Fragebogen erneut einzureichen.',
        );
        navigateTo({
          routeName: 'Dead-End-Screen',
          params: {
            content: `${FALSE_QUESTIONNAIRE_DATA_P1} ${content.contact?.email}${FALSE_QUESTIONNAIRE_DATA_P2} ${content.doctor?.first_name} ${content.doctor?.last_name} ${FALSE_QUESTIONNAIRE_DATA_P3}`,
          },
        });
      })
      .catch((err) => alert(`rejecting assigned questionnaire caught error\n${err}`));
  };

  if (isFetching) {
    return (
      <div className="vw-100 vh-100 align-items-center justify-content-center text-center">
        <LoadingCircle />
      </div>
    );
  }
  return (
    <div className="full-screen-container">
      <div className="container-fluid" style={{ flex: 1 }}>
        <TopBar />
        <div className="container">
          <div className="row mt-sm-5 mt-3">
            <div className="col-lg-8 offset-lg-2">
              <p style={styles.title}>Bitte bestätigen Sie folgende Patient:innenangaben:</p>
              <div style={styles.userInfoContainer}>
                {content.patient && <PatientOverviewRow patient={content.patient} patientAssignedQs={[]} noStatus />}
              </div>

              <AppDropDownMenu
                label="Bitte beantworten*"
                list={USER_DATA_CORRECT_OPTIONS}
                shownValue={selectedChoice}
                placeholder="Bitte beantworten"
                onChangeFunc={(newVal: string) => {
                  setSelectedChoice(newVal);
                }}
                showError={showError && _.isEmpty(selectedChoice)}
                extraStyle={{ width: '100%' }}
              />

              <div className="row mb-3">
                <div className="col-6 col-sm-5 mb-3 offset-sm-1 pe-sm-auto pe-1">
                  <AppButton
                    extraStyle={{ maxWidth: 'inherit', borderColor: '#1246DA' }}
                    label="Zurück"
                    color="#1246DA"
                    action={navigateBack}
                    strokeOnly
                  />
                </div>
                <div className="col-6 col-sm-5 mb-3 ps-sm-auto ps-1">
                  <AppButton
                    disabled={_.isEmpty(selectedChoice)}
                    extraStyle={{ maxWidth: 'inherit' }}
                    label="Weiter"
                    color="#1246DA"
                    action={nextPressed}
                  />
                </div>
              </div>
            </div>
          </div>
          <AppAlert
            isOn={isConfirmationVisible}
            content={
              <div style={styles.overlayContent}>
                <p>
                  Sind Sie sicher? <br />
                  Sie haben anschließend keinen Zugriff mehr auf diesen Fragebogen. <br />
                  Wenn Sie ihn doch noch bearbeiten möchten, <br />
                  kontaktieren Sie bitte Ihre:n Behandler:in.
                </p>
              </div>
            }
            close={() => {
              setIsConfirmationVisible(false);
            }}
            buttons={[
              {
                label: 'Abbrechen',
                action: () => {
                  setIsConfirmationVisible(false);
                },
                strokeOnly: true,
              },
              {
                label: 'Ablehnen',
                action: () => {
                  rejectQ();
                  setIsConfirmationVisible(false);
                },
              },
            ]}
          />
        </div>
      </div>
      <div className="d-none d-md-block">
        <Footer />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  overlayContent: {
    textAlign: 'center',
    lineHeight: '26px',
    paddingLeft: AppSizes.SIZE5,
    paddingRight: AppSizes.SIZE5,
    paddingTop: AppSizes.SIZE5,
  },
  container: {
    height: '100%',
    alignItems: 'center',
  },
  innerContainer: {
    marginTop: '10vh',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: CommonFunctions.mobileSized() ? '95vw' : 600,
  },
  userInfoContainer: {
    width: '100%',
  },
  userInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    lineHeight: LineHeight.DOUBLESPACE,
  },
  title: {
    marginBottom: AppSizes.SIZE2,
    fontWeight: FontWeight.BOLD,
    fontSize: FontSize.H5,
    textAlign: 'center',
    flex: 1,
  },
  buttonsOuterContainer: {
    width: '100%',
  },
  buttonsCouple: {
    justifyContent: 'center',
    flexDirection: 'row',
    columnGap: 10,
  },
  boxHeading: {
    fontSize: FontSize.P3,
    fontWeight: FontWeight.REGULAR,
    color: Color.MONOGREY5,
  },
  col1: {
    fontSize: FontSize.P3,
    marginRight: AppSizes.SIZE1,
  },
  col2: {
    fontWeight: FontWeight.BOLDER,
    flex: 1,
    fontSize: FontSize.P3,
  },
};
