import { Color } from '../styles/constantStyles';

export interface CloseProps {
  color?: string;
}

export const Close = ({ color }: CloseProps) => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0.660367L9.33958 0L5 4.33958L0.660425 0L0 0.660367L4.3396 4.99997L0 9.33957L0.660425 9.99994L5 5.66037L9.33958 9.99994L10 9.33957L5.6604 4.99997L10 0.660367Z"
      fill={color || Color.MONOGREY6}
    />
  </svg>
);
