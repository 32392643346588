import { Action } from 'redux-actions';
import { loadAllAssignedQsSuccess, loadQInvitationsSuccess, loadQuestionnairesSuccess } from './questionnaires.actions';
import { createTypedHandler, handleTypedActions } from '../../common/usecases/actions.helper';
import { QuestionnairesState } from '../configuration/Questionnaires.state';
import { AssignedQuestionnaire } from '../domain/entities/AssignedQuestionnaire';
import { QuestionnaireObj } from '../domain/entities/QuestionnaireObj';
import { QInvitation } from '../domain/entities/QInvitation';

const defaultState: QuestionnairesState = {
  questionnaires: [],
  qInvitations: null,
  assignedQuestionnaire: null,
  allAssignedQs: [],
};

const loadQuestionnairesReducer = (state: QuestionnairesState, action: Action<QuestionnaireObj[]>) => {
  const newQuestionnaires: QuestionnaireObj[] = action.payload;
  newQuestionnaires.map((Q) => {
    const q = Q;
    if (Q.title === 'ADHSFBB') {
      q.title = 'FBB-ADHS (DISYPS)';
    }
    if (Q.title === 'ADHSVFBB') {
      q.title = 'FBB-ADHSV (DISYPS)';
    }
    if (Q.title === 'SVVFBB') {
      q.title = 'FBB-SVV (DISYPS)';
    }
    return q;
  });
  return {
    ...state,
    questionnaires: action.payload,
  };
};

const loadAllAssignedQsReducer = (state: QuestionnairesState, action: Action<AssignedQuestionnaire[]>) => ({
  ...state,
  allAssignedQs: action.payload,
});

const loadQInvitationsReducer = (state: QuestionnairesState, action: Action<QInvitation[] | null>) => ({
  ...state,
  qInvitations: action.payload,
});

export const questionnairesReducer = handleTypedActions(
  [
    createTypedHandler(loadQuestionnairesSuccess, loadQuestionnairesReducer),
    createTypedHandler(loadAllAssignedQsSuccess, loadAllAssignedQsReducer),
    createTypedHandler(loadQInvitationsSuccess, loadQInvitationsReducer),
  ],
  defaultState,
);
