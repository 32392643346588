import { IoMdClose } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { AnyAsyncService } from 'fetchi-request';
import useFeedbackPopupViewModel from './viewModel';
import { Feedback, FeedbackCategory } from '../../types';
import { AppTextArea } from '../../../../common/ui/Others/AppTextArea';
import { AppButton } from '../../../../common/ui/Others/AppButton';
import { Color } from '../../../../assets/styles/constantStyles';

type Props = {
  category: FeedbackCategory;
  submit: (feedback: Feedback) => AnyAsyncService<void>;
  abort: () => void;
};

const FeedbackPopupView = ({ category, submit, abort }: Props) => {
  const { t } = useTranslation();
  const vm = useFeedbackPopupViewModel({ category, submit, abort });

  return (
    <div className="feedback-overlay">
      <button onClick={vm.closeFeedback} type="button" className="close-button close" aria-label="Close">
        <IoMdClose size={20} style={{ cursor: 'pointer' }} />
      </button>
      {vm.currentStep === 2 && <div className="top-left-text ">2/2</div>}
      <div className="content-container">
        <div className="description text-center text-bold">
          <span className="bold mb-3">{vm.headerTitle}</span>
          {vm.message}
        </div>
        {vm.currentStep === 1 && (
          <>
            <div className="header">
              <div className="item">{t('VERY_DIFFICULT')}</div>
              <div className="item">{t('VERY_EASY')}</div>
            </div>
            <div className="rating-items-container">
              <div className="item non-touchable transparent-back">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1" y="1" width="26" height="26" rx="13" fill="#F1F1F1" />
                  <circle cx="9" cy="11.8555" r="1.5" fill="#6B6B6B" />
                  <circle cx="19" cy="11.8555" r="1.5" fill="#6B6B6B" />
                  <path
                    d="M8.75 19.9778C9.91667 17.0612 15.75 14.7284 19.25 19.9775"
                    stroke="#6B6B6B"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <rect x="1" y="1" width="26" height="26" rx="13" stroke="#6B6B6B" strokeWidth="2" />
                </svg>
              </div>
              {Array.from(Array(7).keys()).map((item) => (
                <button
                  key={item}
                  type="button"
                  className={`item ${vm.selectedRate === item + 1 ? 'selected' : ''}`}
                  onClick={() => vm.setSelectedRate(item + 1)}
                >
                  {item + 1}
                </button>
              ))}
              <div className="item non-touchable transparent-back">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1" y="1" width="26" height="26" rx="13" fill="#F1F1F1" />
                  <circle cx="9" cy="11.8555" r="1.5" fill="#6B6B6B" />
                  <circle cx="19" cy="11.8555" r="1.5" fill="#6B6B6B" />
                  <path
                    d="M19.25 16.8562C18.0833 19.7728 12.25 22.1056 8.75 16.8565"
                    stroke="#6B6B6B"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <rect x="1" y="1" width="26" height="26" rx="13" stroke="#6B6B6B" strokeWidth="2" />
                </svg>
              </div>
            </div>
          </>
        )}
        {vm.currentStep === 2 && (
          <>
            <AppTextArea
              label={t('WHAT_WAS_DIFFICULT')}
              maxChar={800}
              placeholder={t('WHAT_WAS_DIFFICULT')}
              shownValue={vm.sendingMessage}
              onChangeFunc={(val) => vm.setSendingMessage(val)}
            />
            <div className="flex-row justify-content-between column-gap-size1">
              <AppButton strokeOnly label={t('NOT_NOW')} color={Color.MONOGREY6} action={vm.closeFeedback} />
              <AppButton label={t('SEND_FEEDBACK')} color={Color.MONOGREY6} action={vm.submitFeedback} />
            </div>
          </>
        )}
        {vm.currentStep === 1 && <div className="description text-center">{t('FEEDBACK_INSPIRATION_MESSAGE')}</div>}
        {vm.isDone && (
          <>
            <div className="text-center justify-content-center align-items-center pb-3">
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="26" height="26" rx="13" fill="#F1F1F1" />
                <circle cx="9" cy="11.8555" r="1.5" fill="#6B6B6B" />
                <circle cx="19" cy="11.8555" r="1.5" fill="#6B6B6B" />
                <path
                  d="M19.25 16.8562C18.0833 19.7728 12.25 22.1056 8.75 16.8565"
                  stroke="#6B6B6B"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <rect x="1" y="1" width="26" height="26" rx="13" stroke="#6B6B6B" strokeWidth="2" />
              </svg>
            </div>
            <div className="description text-center">{t('ENCOURAGE_FOR_MORE_FEEDBACK')}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default FeedbackPopupView;
