import { Action } from 'redux';
import { connect } from 'react-redux';
import { SignInDumb } from './SignIn.dumb';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { navigateBackRequest, navigateToRequest } from '../../../navigationContext/usecases/navigation.actions';

const mapState = () => ({});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  navigateTo: (p: NavigationPayload) => dispatch(navigateToRequest(p)),
  navigateBack: () => dispatch(navigateBackRequest()),
});

export const SignInSmart = connect(mapState, mapDispatch)(SignInDumb);
