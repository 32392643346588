import React from 'react';
import { ActionType } from './actions';
import Massaging from './messaging';
import useMessagingStore, { dispatchOnMessageStore } from './store';
import { ConfirmationPopUpContent, DefaultToastOptions, MessagePopUpContent } from './type';

/**
 * this hook can be used by view component of toast and popups
 * the view component should present the toasts list and also have the capability to remove/startPause/endPause for toast
 *
 * @param toastOptions
 * @returns
 */

const useMessaging = (toastOptions: DefaultToastOptions = {}) => {
  const { toasts, popupsQueue, pausedAt } = useMessagingStore(toastOptions);

  React.useEffect(() => {
    if (pausedAt) {
      return () => {};
    }

    const now = Date.now();
    const timeouts = toasts.map((t) => {
      if (t.duration === Infinity) {
        return;
      }

      const durationLeft = (t.duration || 0) + t.pauseDuration - (now - t.createdAt);

      if (durationLeft < 0) {
        if (t.visible) {
          Massaging.toast.dismiss(t.id);
        }
        return;
      }
      // eslint-disable-next-line consistent-return
      return setTimeout(() => {
        Massaging.toast.dismiss(t.id);
      }, durationLeft);
    });

    return () => {
      timeouts.forEach((timeout) => timeout && clearTimeout(timeout));
    };
  }, [toasts, pausedAt]);

  const handlers = React.useMemo(
    () => ({
      startPause: () => {
        dispatchOnMessageStore({
          type: ActionType.START_PAUSE_TOAST,
          time: Date.now(),
        });
      },
      endPause: () => {
        if (pausedAt) {
          dispatchOnMessageStore({ type: ActionType.END_PAUSE_TOAST, time: Date.now() });
        }
      },
      removeToast: (toastId: string) => {
        Massaging.toast.remove(toastId);
      },
      closePopUp: (content: MessagePopUpContent) => {
        content.callBack();
        dispatchOnMessageStore({ type: ActionType.REMOVE_POPUP_MESSAGE, id: content.id });
      },
      respondConfirmation: (response: boolean, content: ConfirmationPopUpContent) => {
        content.callBack(response);
        dispatchOnMessageStore({ type: ActionType.REMOVE_CONFIMATION, id: content.id });
      },
    }),
    [toasts, pausedAt],
  );

  return {
    toasts,
    popupsQueue,
    handlers,
  };
};

export default useMessaging;
