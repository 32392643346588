import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppSizes, Color } from '../../../assets/styles/constantStyles';
import { StyleSheet } from '../../../common/domain/entities/StyleSheet';
import { AppButton } from '../../../common/ui/Others/AppButton';

import {
  AGB_NOT_ACCEPTED_ERROR_TEXT,
  DELETED_QUESTIONNAIRE_ERROR_TEXT,
  FALSE_QUESTIONNAIRE_DATA_P1,
  FALSE_QUESTIONNAIRE_DATA_P2,
  FALSE_QUESTIONNAIRE_DATA_P3,
  PRIVACY_NOT_ACCEPTED_ERROR_TEXT,
} from '../../../common/helpers/ConstantTexts';

import { AppFormErrorList } from '../../../common/ui/Others/AppFormErrorList';
import { AppCheckBoxV3 } from '../../../common/ui/Others/AppCheckBoxV3';
import { DU_VERSION_FE, TOS_VERSION_FE } from '../../../common/ui/Others/TermsAndConditions';
import { AGB_VERSION_FE } from '../../../common/ui/Others/AgbPage';
import { AppAlert } from '../../../common/ui/Others/AppAlert';
import { Footer } from '../../../navigationContext/ui/components/Footer';
import { LoadingCircle } from '../../../assets/svg/LoadingCircle';
import Messaging from '../../../core/components/messaging';
import { useContact } from '../../../contexts/patientContact/store';
import { InvitationStatus, isDeadEndStatus } from '../../domain/entities/QInvitation';
import TopBar from '../TopBar';
import { deeplink } from '../../../core/helpers/handy';

const AcceptTermsPage = () => {
  const [showError, setShowError] = React.useState<boolean>(false);
  const [tosAccepted, setTosAccepted] = React.useState<boolean>(true);
  const [dataSecurityAccepted, setDataSecurityAccepted] = React.useState<boolean>(false);
  const [dataUseAccepted, setDataUseAccepted] = React.useState<boolean>(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = React.useState<boolean>(false);
  const [formErrorsList, setFormErrorsList] = React.useState<string[]>();
  const [sleepAppPresentationStep, setSleepAppPresentationStep] = React.useState<number>(0);
  const { push } = useHistory();

  const {
    content,
    error,
    update: updateInvitation,
    reject: rejectInvitation,
    isFetching: isContentFetching,
    dataPresented,
    dataHasBeenPresentedOnce,
  } = useContact();

  React.useEffect(() => {
    if (error) {
      alert(`Error getting invitation`);
    }
  }, [error]);

  React.useEffect(() => {
    // when the loading is over and it has been loaded from server
    // check if the answers are already there, we should navigate to answering page
    if (
      isContentFetching === false &&
      dataHasBeenPresentedOnce === false &&
      content.invitation &&
      content.invitation.answers.length > 0
    ) {
      push('/Answering-Page');
    }
    isContentFetching === false && dataPresented();
  }, [isContentFetching]);

  React.useEffect(() => {
    updateErrors();
  }, [showError, tosAccepted, dataSecurityAccepted]);

  const updateErrors = () => {
    const errList: string[] = [];
    !tosAccepted && errList.push(AGB_NOT_ACCEPTED_ERROR_TEXT);
    !dataSecurityAccepted && errList.push(PRIVACY_NOT_ACCEPTED_ERROR_TEXT);
    setFormErrorsList([...errList]);
  };

  React.useEffect(() => {
    if (
      content &&
      content.questionnaire &&
      (content.invitation?.status === 'unread' || content.invitation?.status === 'drafted')
    ) {
      updateInvitation({
        ...content.invitation,
        status: InvitationStatus.OPENED,
      });
    }

    if (content.invitation?.status && isDeadEndStatus(content.invitation?.status)) {
      push('/Dead-End-Screen', { content: DELETED_QUESTIONNAIRE_ERROR_TEXT });
    } else {
      // setTosAccepted(content.invitation?.tos_version === TOS_VERSION_FE);
      setDataSecurityAccepted(
        content.invitation?.data_security_accepted_at !== undefined &&
          content.invitation.data_security_accepted_at.length > 0,
      );
      setDataUseAccepted(
        content.invitation?.data_use_accepted_at !== undefined && content.invitation.data_use_accepted_at.length > 0,
      );
    }
  }, [content]);

  const rejectQ = () => {
    if (isComingFromSleepApplication) {
      setIsConfirmationVisible(true);
      return;
    }
    handleRejectionForExpertApproach();
  };

  const handleRejectionForExpertApproach = () => {
    rejectInvitation()
      .then(() => {
        Messaging.toast(
          'Der Arzt wird benachrichtigt, um Korrekturen vorzunehmen und den Fragebogen erneut einzureichen.',
        );
        push('/Dead-End-Screen', {
          content: `${FALSE_QUESTIONNAIRE_DATA_P1} ${content.contact?.email}${FALSE_QUESTIONNAIRE_DATA_P2} ${content.doctor?.first_name} ${content?.doctor?.last_name} ${FALSE_QUESTIONNAIRE_DATA_P3}`,
        });
      })
      .catch((err) => alert(`rejecting assigned questionnaire caught error\n${err}`));
  };

  const submitButtonTapped = () => {
    if (isComingFromSleepApplication) {
      push('Answering-Page');
      return;
    }
    handleSubmissionsInExpertApproach();
  };

  const handleSubmissionsInExpertApproach = () => {
    if (content.invitation === undefined) {
      return;
    }
    if (!tosAccepted || !dataSecurityAccepted) {
      setShowError(true);
      return;
    }

    const payload = {
      ...content.invitation,
      tos_version: TOS_VERSION_FE,
      data_security_version: AGB_VERSION_FE,
    };

    if (dataUseAccepted) {
      payload.data_use_version = DU_VERSION_FE;
    }

    updateInvitation(payload)
      .then(() => {
        // do something
        push('Data-Confirmation');
      })
      .catch(() => {
        // do know what should I do here :(, better to have something for error handlings (visually)
      });
  };

  const isComingFromSleepApplication = content.invitation?.source === 'app';

  React.useEffect(() => {
    if (typeof content.questionnaire?.category === 'string') {
      const byPass = ['ECRF', 'Test'].includes(content.questionnaire.category);
      if (byPass) {
        push('Answering-Page');
      }
    }
  }, [content.questionnaire, content.questionnaire?.category]);

  if (isContentFetching || !content.invitation) {
    return (
      <div className="vw-100 vh-100 align-items-center justify-content-center text-center">
        <LoadingCircle />
      </div>
    );
  }

  const mainDescriptionContent = () => {
    // if the questionnaire is coming from sleep application
    if (isComingFromSleepApplication) {
      return sleepAppPresentationStep === 0 ? (
        <p className="text-start text-md-center fs-6">
          die folgenden aussagen betreffen die schlafgewohnheiten ihres kindes und mögliche schwierigkeiten mit dem
          schlaf. denken sie bei der beantwortung der fragen an die vergangene woche im leben ihres kindes.
          <br />
          <br />
          wenn die letzte woche aus irgendeinem grund keine typische woche für das schlafverhalten ihres kindes
          darstellt (z. b. weil ihr kind krank war und deshalb besonders schlecht geschlafen hat oder weil es
          außergewöhnlich gut geschlafen hat), wählen sie stattdessen bitte eine vergangene, typische woche aus dem
          leben ihres kindes.
        </p>
      ) : (
        <p className="text-start text-md-center fs-6">
          antworten sie mit „gewöhnlich”, wenn eine aussage 5 mal oder öfter pro woche zutrifft; antworten sie mit
          „manchmal”, wenn eine aussage 2–4 mal pro woche zutrifft; antworten sie mit „selten”, wenn eine aussagen nie
          oder 1 mal pro woche zutrifft.
          <br /> bitte geben sie immer zusätzlich an, ob die schlafgewohnheiten ein problem darstellen, indem sie „ja”,
          „nein” oder „weiß ich nicht” auswählen.
        </p>
      );
    }
    return (
      <p className="text-start text-md-center">
        Hallo {content.contact?.email},
        <br />
        <br />
        der/die Behandler:in {content.doctor?.first_name} {content.doctor?.last_name} von {content.patient?.first_name}{' '}
        möchte, dass Sie den Fragebogen {content.questionnaire?.title} ausfüllen, um die Diagnostik zu unterstützen.
        <br />
        <br />
        Der Schutz Ihrer Daten ist uns wichtig. Lesen Sie bitte die{' '}
        <Link
          to="Datenschutzerklärung"
          target="_blank"
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            color: Color.MONOGREY5,
          }}
        >
          Datenschutzerklärung
        </Link>{' '}
        und akzeptiere diese, wenn Sie einverstanden sind.
        <br />
      </p>
    );
  };

  return (
    <div className="full-screen-container">
      <div className="container-fluid" style={{ flex: 1 }}>
        <TopBar />
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div style={styles.textContainer}>
                <p className="fw-bold fs-6 text-md-center text-start">
                  {isComingFromSleepApplication
                    ? sleepAppPresentationStep === 0
                      ? `Herzlich Willkommen zum Fragebogen für${' '} ${content.questionnaire?.description}`
                      : ''
                    : `Herzlich Willkommen zum Fragebogen für${' '} ${content.questionnaire?.title}`}
                </p>
                {mainDescriptionContent()}
              </div>
              {!isComingFromSleepApplication && (
                <>
                  <div style={styles.checkboxContainer}>
                    <AppCheckBoxV3
                      hidden
                      topLabel="AGB*"
                      labelJSX={
                        <p className="m-0">
                          Ich akzeptiere die{' '}
                          <Link
                            to="AGB"
                            target="_blank"
                            style={{
                              textDecoration: 'underline',
                              cursor: 'pointer',
                              color: tosAccepted ? Color.APPWHITE : Color.APPBLACK,
                            }}
                            onClick={() => {
                              setTosAccepted((v) => !v);
                            }}
                          >
                            AGB
                            <br />
                          </Link>
                        </p>
                      }
                      checked={tosAccepted}
                      action={() => {
                        setTosAccepted((v) => !v);
                      }}
                      errorShown={showError && !tosAccepted}
                    />
                    <AppCheckBoxV3
                      topLabel="Datenschutz*"
                      labelJSX={
                        <p className="m-0">
                          Ich akzeptiere die{' '}
                          <Link
                            to="Datenschutzerklärung"
                            target="_blank"
                            style={{
                              textDecoration: 'underline',
                              cursor: 'pointer',
                              color: dataSecurityAccepted ? Color.APPWHITE : Color.APPBLACK,
                            }}
                            onClick={() => {
                              setDataSecurityAccepted((v) => !v);
                            }}
                          >
                            Datenschutzerklärung
                            <br />
                          </Link>
                        </p>
                      }
                      checked={dataSecurityAccepted}
                      action={() => {
                        setDataSecurityAccepted((v) => !v);
                      }}
                      errorShown={showError && !dataSecurityAccepted}
                    />
                    <AppCheckBoxV3
                      topLabel="Datennutzung"
                      labelJSX={
                        <p className="m-0">
                          Ich akzeptiere, dass meine Daten zum Zwecke der dauerhaften Gewährleistung der technischen
                          Funktionsfähigkeit, der Nutzerfreundlichkeit und der Weiterentwicklung der Applikation
                          verarbeitet werden.
                        </p>
                      }
                      checked={dataUseAccepted}
                      action={() => {
                        setDataUseAccepted((v) => !v);
                      }}
                      errorShown={false}
                    />
                  </div>
                  <div className="text-center">
                    <AppFormErrorList
                      formErrorsList={formErrorsList ?? []}
                      shown={showError && !!formErrorsList?.length}
                    />
                    <div className="row no-gutters">
                      <div className="col-6 col-sm-5 mb-3 offset-sm-1 pe-sm-auto pe-1">
                        <AppButton
                          disabled={!tosAccepted}
                          extraStyle={{ maxWidth: 'inherit', borderColor: '#1246DA' }}
                          label="Ablehnen"
                          color="#1246DA"
                          action={() => {
                            setIsConfirmationVisible(true);
                          }}
                          strokeOnly
                        />
                      </div>
                      <div className="col-6 col-sm-5 mb-3 ps-sm-auto ps-1">
                        <AppButton
                          disabled={!tosAccepted || !dataSecurityAccepted}
                          extraStyle={{ maxWidth: 'inherit' }}
                          label="Weiter"
                          color="#1246DA"
                          action={submitButtonTapped}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {isComingFromSleepApplication && (
                <div className="text-center">
                  <div className="row no-gutters">
                    {sleepAppPresentationStep === 1 && (
                      <div className="col-6 col-sm-5 mb-3 offset-sm-1 pe-sm-auto pe-1">
                        <AppButton
                          extraStyle={{ maxWidth: 'inherit', borderColor: '#1246DA' }}
                          label="Zurück"
                          color="#1246DA"
                          action={() => {
                            setSleepAppPresentationStep(0);
                          }}
                          strokeOnly
                        />
                      </div>
                    )}
                    <div
                      className={
                        sleepAppPresentationStep === 1
                          ? 'col-6 col-sm-5 mb-3 ps-sm-auto ps-1'
                          : 'col-12 col-sm-12 mb-6 ps-sm-auto ps-1'
                      }
                    >
                      <AppButton
                        extraStyle={{ maxWidth: 'inherit' }}
                        label="Weiter"
                        color="#1246DA"
                        action={() =>
                          sleepAppPresentationStep === 0 ? setSleepAppPresentationStep(1) : submitButtonTapped()
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 offset-3">
                      <AppButton
                        disabled={!tosAccepted}
                        extraStyle={{ maxWidth: 'inherit', borderColor: 'transparent' }}
                        label="Abbrechen"
                        color="#1246DA"
                        action={() => {
                          setIsConfirmationVisible(true);
                        }}
                        strokeOnly
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <AppAlert
            isOn={isConfirmationVisible}
            title={isComingFromSleepApplication ? 'Fragebogen abbrechen' : 'Sind Sie sicher?'}
            content={
              <div style={styles.overlayContent}>
                {isComingFromSleepApplication ? (
                  <p className="fs-5 text-start">
                    Du wirst nun zurück zur App geleitet. Du kannst den Fragebogen später noch einmal starten.
                  </p>
                ) : (
                  <p>
                    Sie haben anschließend keinen Zugriff mehr auf diesen Fragebogen. <br />
                    Wenn Sie ihn doch noch bearbeiten möchten, <br />
                    kontaktieren Sie bitte Ihre:n Behandler:in.
                  </p>
                )}
              </div>
            }
            close={() => {
              setIsConfirmationVisible(false);
            }}
            buttons={
              isComingFromSleepApplication
                ? [
                    {
                      label: 'Zurück zur App',
                      color: '#1246DA',
                      action: () => {
                        deeplink({
                          bundleLink: 'sleep://',
                          iTunesUrl: 'https://apps.apple.com/sg/app/eyelevel-sleep/id6443792657',
                          playStoreUrl: 'https://play.google.com/store/apps/details?id=care.eyelevel.sleep',
                        });
                      },
                    },
                  ]
                : [
                    {
                      label: 'Ablehnen',
                      action: () => {
                        rejectQ();
                        setIsConfirmationVisible(false);
                      },
                    },
                  ]
            }
          />
        </div>
      </div>
      <div className="d-none d-md-block">
        <Footer />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  overlayContent: {
    textAlign: 'center',
    lineHeight: '26px',
    paddingLeft: AppSizes.SIZE5,
    paddingRight: AppSizes.SIZE5,
    paddingTop: AppSizes.SIZE5,
  },
  textContainer: {
    padding: AppSizes.DEFAULT,
    textAlign: 'center',
  },
};

export default AcceptTermsPage;
