import _ from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { AppSizes, Color, FontSize, FontWeight } from '../../../../assets/styles/constantStyles';
import { StyleSheet } from '../../../../common/domain/entities/StyleSheet';
import { AppTextInput } from '../../../../common/ui/Others/AppTextInput';
import { ErrorExclamationMark } from '../../../../common/ui/Others/ErrorExclamationMark';
import { User } from '../../../../contexts/user/entities/User';
import { AppDropDownMenu } from '../../../../common/ui/Others/AppDropDownMenu';
import { NavigationPayload } from '../../../../navigationContext/domain/entities/NavigationPayload';
import CommonFunctions from '../../../../common/helpers/CommonFunctions';

const genderList: string[] = ['männlich', 'weiblich', 'divers'];
const titleList: string[] = ['Arzt / Ärztin', 'Psychotherapeut:in', 'Andere'];
interface SettingsEditAccountFormProps {
  showError: boolean;
  filledData: User;
  setFilledData: Dispatch<SetStateAction<User>>;
  navigateTo: (p: NavigationPayload) => void;
}
export const SettingsEditAccountForm = ({
  showError,
  filledData,
  setFilledData,
  navigateTo,
}: SettingsEditAccountFormProps) => (
  <div style={styles.container}>
    <div style={{ fontWeight: FontWeight.EXTRABOLD, fontSize: FontSize.H5 }}>Accountangaben</div>
    <AppTextInput
      label="E-Mail-Adresse*"
      placeholder="E-Mail-Adresse"
      currentVal={filledData.email ?? ''}
      onChange={(str: string) => {
        setFilledData((v) => ({ ...v, email: str }));
      }}
      showError={showError && _.isEmpty(filledData.email)}
    />
    <AppTextInput
      label="Passwort*"
      placeholder="Passwort"
      currentVal="*********"
      onChange={() => {}}
      showError={false}
      disabled
    />
    <button
      type="button"
      style={styles.passNav}
      onClick={() => {
        navigateTo({ routeName: 'Password-Edit' });
      }}
    >
      Passwort ändern
    </button>
    <div style={{ fontWeight: FontWeight.EXTRABOLD, fontSize: FontSize.H5, marginTop: AppSizes.SIZE4 }}>Nutzer:in</div>
    <AppDropDownMenu
      label="Titel*"
      list={titleList}
      shownValue={filledData.title}
      placeholder="Titel"
      disabled={!filledData.title}
      onChangeFunc={(newVal: string) => {
        setFilledData((v) => ({ ...v, title: newVal }));
      }}
      showError={showError && _.isEmpty(filledData.title)}
      extraStyle={{ width: '100%' }}
    />
    <AppDropDownMenu
      label="Gender*"
      list={genderList}
      shownValue={CommonFunctions.translateGenderToGr(filledData.gender ?? '')}
      placeholder="Gender auswählen"
      disabled={!filledData.gender}
      onChangeFunc={(newVal: string) => {
        setFilledData((v) => ({ ...v, gender: CommonFunctions.translateGenderToEn(newVal) }));
      }}
      showError={showError && _.isEmpty(filledData.gender)}
      extraStyle={{ width: '100%' }}
    />
    <AppTextInput
      label="Vorname*"
      placeholder="Vorname"
      currentVal={filledData.first_name}
      onChange={(str: string) => {
        setFilledData((v) => ({ ...v, first_name: str }));
      }}
      showError={showError && _.isEmpty(filledData.first_name)}
    />
    <AppTextInput
      label="Nachname*"
      placeholder="Nachname"
      currentVal={filledData.last_name}
      onChange={(str: string) => {
        setFilledData((v) => ({ ...v, last_name: str }));
      }}
      showError={showError && _.isEmpty(filledData.last_name)}
    />
    <div style={{ fontWeight: FontWeight.EXTRABOLD, fontSize: FontSize.H5, marginTop: AppSizes.SIZE4 }}>Anschrift</div>

    <div
      style={{
        ...styles.inlineInputsContainer,
        border: `1px solid ${
          showError && (_.isEmpty(filledData.street) || _.isEmpty(filledData.house_number))
            ? Color.DANGER
            : 'transparent'
        }`,
      }}
    >
      <ErrorExclamationMark shown={showError && (_.isEmpty(filledData.street) || _.isEmpty(filledData.house_number))} />
      <div
        style={{
          ...styles.inlineInputsContainerInner,
          ...(showError &&
            (_.isEmpty(filledData.street) || _.isEmpty(filledData.house_number)) && {
              margin: AppSizes.SIZE1,
              marginTop: 0,
            }),
        }}
      >
        <AppTextInput
          label="Straße*"
          placeholder="Straße"
          currentVal={filledData.street ?? ''}
          onChange={(str: string) => {
            setFilledData((v) => ({
              ...v,
              street: str,
            }));
          }}
          extraStyle={{ margin: 0 }}
        />
        <AppTextInput
          label="Hausnr.*"
          placeholder="Hausnr."
          currentVal={filledData.house_number ?? ''}
          onChange={(str: string) => {
            setFilledData((v) => ({
              ...v,
              house_number: str,
            }));
          }}
          extraStyle={{ width: '20%', margin: 0 }}
        />
      </div>
    </div>

    <AppTextInput
      label="Adresssatz"
      placeholder="Adresszusatz"
      currentVal={filledData.address ?? ''}
      onChange={(str: string) => {
        setFilledData((v) => ({
          ...v,
          address: str,
        }));
      }}
      // showError={showError && _.isEmpty(filledData.address)}
    />

    <div
      style={{
        ...styles.inlineInputsContainer,
        border: `1px solid ${
          showError && (_.isEmpty(filledData.city) || _.isEmpty(filledData.postcode)) ? Color.DANGER : 'transparent'
        }`,
      }}
    >
      <ErrorExclamationMark shown={showError && (_.isEmpty(filledData.city) || _.isEmpty(filledData.postcode))} />
      <div
        style={{
          ...styles.inlineInputsContainerInner,
          ...(showError &&
            (_.isEmpty(filledData.city) || _.isEmpty(filledData.postcode)) && {
              margin: AppSizes.SIZE1,
              marginTop: 0,
            }),
        }}
      >
        <AppTextInput
          label="PLZ*"
          placeholder="PLZ"
          currentVal={filledData.city ?? ''}
          onChange={(str: string) => {
            setFilledData((v) => ({
              ...v,
              city: str,
            }));
          }}
          extraStyle={{ width: '30%', margin: 0 }}
        />
        <AppTextInput
          label="Ort*"
          placeholder="Ort"
          currentVal={filledData.postcode ?? ''}
          onChange={(str: string) => {
            setFilledData((v) => ({
              ...v,
              postcode: str,
            }));
          }}
          extraStyle={{ margin: 0 }}
        />
      </div>
    </div>
  </div>
);

const styles: StyleSheet = {
  container: {
    flex: 1,
    padding: AppSizes.SIZE1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  inlineInputsContainer: {
    transition: 'all 300ms',
    marginTop: AppSizes.SIZE3,
    paddingTop: AppSizes.SIZE2,
    borderRadius: 30,
    position: 'relative',
    width: '100%',
  },
  inlineInputsContainerInner: {
    transition: 'all 300ms',
    flexDirection: 'row',
    columnGap: AppSizes.SIZE1,
    justifyContent: 'space-between',
  },
  passNav: {
    textDecoration: 'underline',
    fontSize: FontSize.CAPTION,
    fontWeight: FontWeight.REGULAR,
    cursor: 'pointer',
    marginTop: AppSizes.DEFAULT,
  },
};
